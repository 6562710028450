import React from 'react';
import '../../../Assets/css/skeleton-profile.css'; // Ensure you create this CSS file

const SkeletonProfile = () => {
    return (
        <div className="profile-skeleton-container">
          <div className="profile-shimmer">
            {/* Header with icon and stats */}
            <div className="profile-skeleton-header">
              <div className="profile-skeleton__circle"></div>
              <div className='profile-skeleton-column'>
                <div className="profile-skeleton__thin_rect"></div>
                <div className="profile-skeleton__thicker_rect"></div>
                <div className="profile-skeleton__thin_rect_2"></div>
              </div>
            </div>
    
            {/* Content with text lines */}
            <div className="profile-skeleton-content">
              <div className="profile-skeleton__square"></div>
              <div className="profile-skeleton__square"></div>
              <div className="profile-skeleton__square"></div>
              <div className="profile-skeleton__square"></div>
              <div className="profile-skeleton__square"></div>
            </div>
          </div>
        </div>
      );
}

export default SkeletonProfile;
