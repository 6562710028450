import {State} from '../model/State'
import * as actionType from '../actions/ActionTypes';

export const state_reducer = (state = new State(), action) =>{
  
    switch (action.type) {
        case actionType.UPDATE_STATE:
            return {...state};
        case actionType.CHANGE_HEIGHT:
            return { ...state, height: action.payload.state };
        case actionType.CHANGE_WIDTH:
            return { ...state, width: action.payload.state };
        case actionType.TOGGLE_OPEN_CLOSE_MENU:
            return { ...state, openCloseMenu: action.payload.state };
        case actionType.TOGGLE_SHOW_PARLAYS:
            return { ...state, showParlays: action.payload.state };
        case actionType.TOGGLE_SHOW_SINGLES:
            return { ...state, showSingles: action.payload.state };
        case actionType.TOGGLE_SHOW_DROPDOWN_FILTERS:
            return { ...state, showDropdownFilters: action.payload.state };
        case actionType.TOGGLE_SHOW_DROPDOWN_LEGS:
            return { ...state, showDropdownLegs: action.payload.state };
        case actionType.SELECT_OVERALL_RECORD:
            return { ...state, overallRecordSelected: action.payload.state };
        case actionType.SELECT_HOT_STREAK:
            return { ...state, hotStreakSelected: action.payload.state };
        case actionType.SELECT_POPULAR:
            return { ...state, popularSelected: action.payload.state };
        case actionType.SELECT_NEW:
            return { ...state, newSelected: action.payload.state };
        case actionType.UPDATE_SELECTION:
            return { ...state, selection: action.payload.state };
        case actionType.SELECT_PROFILE_NEW:
            return { ...state, profileNewSelected: action.payload.state };
        case actionType.SELECT_PROFILE_POPULAR:
            return { ...state, profilePopularSelected: action.payload.state };
        case actionType.SELECT_PROFILE_PROFIT:
            return { ...state, profileProfitSelected: action.payload.state };
        case actionType.UPDATE_PROFILE_SELECTION:
            return { ...state, profileSelection: action.payload.state };
        case actionType.CHANGE_NUMBER_OF_LEGS:
            return { ...state, numberOfLegs: action.payload.state };
        case actionType.CHANGE_NUMBER_OF_LEGS_PROFILE:
            return { ...state, numberOfLegsProfile: action.payload.state };
        case actionType.TOGGLE_EXPAND_PICK_CARD:
            return { ...state, expandPickCard: action.payload.state };
        case actionType.SELECT_PICKS_NEW:
            return { ...state, picksNewSelected: action.payload.state };
        case actionType.SELECT_PICKS_POPULAR:
            return { ...state, picksPopularSelected: action.payload.state };
        case actionType.SELECT_PICKS_RECORD:
            return { ...state, picksRecordSelected: action.payload.state };
        case actionType.SELECT_PICKS_HOT:
            return { ...state, picksHotStreakSelected: action.payload.state };
        case actionType.UPDATE_PICKS_SELECTION:
            return { ...state, picksSelection: action.payload.state };
        case actionType.UPDATE_VOTES:
            return { ...state, votes: action.payload.state };
        case actionType.CHANGE_TITLE:
            return { ...state, title: action.payload.state };
        case actionType.CHANGE_DESCRIPTION:
            return { ...state, description: action.payload.state };
        case actionType.TOGGLE_LOCK:
            return { ...state, lock: action.payload.state };
        case actionType.UPDATE_PRICE:
            return { ...state, price: action.payload.state };
        case actionType.UPDATE_LEGS:
            return { ...state, legs: action.payload.state };
        case actionType.UPDATE_SPORTS:
            return { ...state, sports: action.payload.state };
        case actionType.TOGGLE_REVEAL_ENTIRE_POST:
            return { ...state, revealEntirePost: action.payload.state };
        case actionType.ERASE_FORM:
            return { ...state, price: 0, title: '', description: '', lock: false, tags: [] };
        case actionType.INITIALIZE_STATE:
            return { ...state, width: action.payload.w, height: action.payload.h, openCloseMenu: false, };
        case actionType.ALERT_USER:
            return { ...state, popupMessages: [...state.popupMessages, action.payload.state] };
        case actionType.REMOVE_ALERT:
            return { ...state, popupMessages: state.popupMessages.filter(msg=>msg!==action.payload.state) };
        case actionType.TOGGLE_SHOW_CONTENT_FILTERS:
            return { ...state, showContentFilters: action.payload.state };
        case actionType.TOGGLE_SHOW_CONTENT_LEGS:
            return { ...state, showContentLegs: action.payload.state };
        case actionType.TOGGLE_REVEAL_FOLLOWERS:
            return { ...state, revealFollowers: action.payload.state };
        case actionType.TOGGLE_EDIT_PROFILE:
            return { ...state, selectEditProfile: action.payload.state };
        case actionType.REROUTE_APP:
            return { ...state, route: action.payload.state };
        case actionType.PUSH_ROUTE:
            return { 
                ...state, 
                stack: state.stack?.length > 0 ? 
                    (state.stack[state.stack.length-1] === action.payload ? state.stack : [...state.stack, action.payload]) 
                : 
                    [action.payload.pathname] 
                };
        case actionType.POP_ROUTE:
            return { ...state, stack: state.stack.slice(0, -1) };
        case actionType.RESET_ROUTES:
            return { ...state, stack: [] };
        case actionType.TOGGLE_SHOW_CONTENT_FILTERS_USERS:
            return { ...state, showContentFiltersUsers: action.payload.state };
        case actionType.TOGGLE_SHOW_DROPDOWN_FILTERS_USERS:
            return { ...state, showDropdownFiltersUsers: action.payload.state };
        case actionType.SELECT_USERS_POPULAR:
            return { ...state, usersPopularSelected: action.payload.state };
        case actionType.SELECT_USERS_RECORD:
            return { ...state, usersRecordSelected: action.payload.state };
        case actionType.SELECT_USERS_HOT:
            return { ...state, usersHotStreakSelected: action.payload.state };
        case actionType.SELECT_USERS_PROFIT:
            return { ...state, usersProfitSelected: action.payload.state };
        case actionType.SELECT_USERS_NUM_BETS:
            return { ...state, usersNumBetsSelected: action.payload.state };
        case actionType.SELECT_USERS_NUM_STREAKS:
            return { ...state, usersNumHotStreakSelected: action.payload.state };
        case actionType.UPDATE_USERS_SELECTION:
            return { ...state, usersSelection: action.payload.state };
        case actionType.SET_SEARCH_TOGGLE:
            return { ...state, searchToggle: action.payload.state };
        case actionType.SET_SEARCH_TOGGLE_PICKS:
            return { ...state, searchTogglePicks: action.payload.state };
        case actionType.SET_VERIFY_POPUP:
            return { ...state, verifyPopup: action.payload.state };
        case actionType.SET_PAYMENT_POPUP:
            return { ...state, paymentPopup: action.payload.paymentPopup, postIdToPurchase: action.payload.postIdToPurchase, sellerUsername: action.payload.seller };
        case actionType.SET_WITHDRAWAL_INFO_POPUP:
            return { ...state, withdrawalInfoPopup: action.payload.state };
        case 'SET_SHARE_TOGGLE':
            return { ...state, shareToggle: action.payload.state };
        case 'SET_SHARE_LINK':
            return { ...state, shareLink: action.payload.state };
        case 'SET_SHARE_DM':
            return { ...state, shareDM: action.payload.state };
        case actionType.SET_TAPPED_STATS:
            return { ...state, tappedStatistics: !action.payload.state };   
        case actionType.SET_STEPPER_UNITS:
            return { ...state, stepperBetUnits: action.payload.state}   
        case actionType.SET_PAGE_NUMBER:
            return { ...state, createPicksPageNumber: action.payload.state}   
    
        default:
            return state;
    }
    
}
