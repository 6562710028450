import io from 'socket.io-client';
import { alertUser, rerouteApp } from '../redux/actions/StateActions.js';
import { store } from '../redux/reducers/globalReducers.js';
import { refreshToken } from '../redux/actions/AuthActions.js';

let socket;

// @TODO: Configure staging variable in env for route handling
const SERVER_URL = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:8080` : ''}`;

export const createConnection = () => {
   
    if (socket && socket.connected) {
        return; // Exit if there's already an active connection
    }

    const token = JSON.parse(localStorage.getItem('profile'))?.token;
    
    // OUTGOING
    // open up a websocket connection, add your auth token to the header
    socket = io(SERVER_URL, {
        auth: { token }
    },{
        // reconnection: true, // whether to reconnect automatically
        // reconnectionAttempts: Infinity, // number of reconnection attempts before giving up
        // reconnectionDelay: 1000, // how long to initially wait before attempting a new reconnection
        // reconnectionDelayMax: 5000, // maximum amount of time to wait between reconnection attempts
        pingTimeout: 5000, // 5 seconds
        pingInterval: 25000, // 25 seconds
    });

    // listen for auth issues in middleware, validate session token before connection
    socket.on('connect_error', async (err) => {

        try {
            if (err.toString().includes('Authentication error')) {
                // refresh session token
                // console.log(err)
                // await store.dispatch(alertUser('Refreshing session token...'));
                // Dispatch an action to refresh the token and wait for it to complete
                var success = await store.dispatch(refreshToken());
                if(success){
                    //await store.dispatch(alertUser('Successfully restored session'));
                    // Retrieve the new token from the store
                    const newToken = store.getState().auth_reducer.token;
                    if(newToken){
                        // set it to our socket header
                        socket.auth.token = newToken
                        // Attempt to reconnect
                        socket.connect();
                    }
                }
            } else {
                socket.connect()
            }
        } catch (refreshError) {
            //console.log(refreshError)
        }
    });

    // INCOMING
    // listen for errors (i.e. unauthorized to view conversation)
    socket.on('custom_error', async (data) => {
        console.log(data.message)
        store.dispatch(refreshToken(false))
        //store.dispatch(alertUser(data.message))
    });

    socket.on('connect', async () => {
        const toggle = store.getState().chat_reducer.connectionToggle
        store.dispatch({type: 'TOGGLE_CONNECTION_REJOIN_ROOM', payload: !toggle})
        //console.log('Connected to server');
        
    });

    socket.on('disconnect', async () => {
        //console.log('Disconnected from server');
    });

    socket.on('new_message', async (data) => {
        store.dispatch({type: 'CONVERSATION_USER_TYPING', payload: ''})
        store.dispatch({type: 'UPDATE_MESSAGE', payload: data})
    });

    socket.on('message_read', async (data) => {
        // Update the state of the message to indicate it's been read
        store.dispatch({type: 'UPDATE_USERS_READ_MSG', payload: data})
    });

    socket.on('name_changed', async (data) => {
        store.dispatch({type: 'CHANGE_CONVO_NAME', payload: data})
    });

    socket.on('user_typing', async (data) => {
        store.dispatch({type: 'CONVERSATION_USER_TYPING', payload: data.username})
    });

    socket.on('joined_room', async (data) => {
        store.dispatch({type: 'JOIN_CONVERSATION', payload: data})
    });

    socket.on('get_profile_photos', async (data) => {
        store.dispatch({type: 'UPDATE_CONVERSATION_PHOTOS', payload: data})
    });

    socket.on('getting_conversations', async (data) => {
        store.dispatch({type: 'UPDATE_CONVOS', payload: data})
    });

    socket.on('conversation_created', async (room) => {
        store.dispatch(refreshToken(false))
        store.dispatch(rerouteApp(`/conversation/${room}`))
    });

    socket.on('update_active_participants', async (data) => {
        store.dispatch({type: 'UPDATE_ACTIVE_PARTICIPANTS', payload: data})
    });

};

export const getSocket = () => {

    return socket
}