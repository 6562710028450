// sanitizers.js

/* SANITIZES REDUX DEV TOOLS ONLY - DOES NOT AFFECT REDUX STORE */
export const actionSanitizer = (action) => {
    
    if (action.type === 'UPDATE_CONVERSATION_PHOTOS' && action.payload && action.payload.profilePhotos) {
        return {
            ...action,
            payload: {
                ...action.payload,
                profilePhotos: '<<LONG_BLOB>>  checkout globalReducer.js sanitizers to access actual data in dev tools',
            }
        };
    }

    if(action.type === 'PROFILE' && action.payload && action.payload.profilePhoto){
        return { 
            ...action, 
            payload: {
                    ...action.payload,
                    profilePhoto: '<<LONG_BLOB>>  checkout globalReducer.js sanitizers to access actual data in dev tools' 
            }
        };
    }

    return action;
};

/* SANITIZES REDUX DEV TOOLS ONLY - DOES NOT AFFECT REDUX STORE */
export const stateSanitizer = (state) => {
     // Deep copy the state where necessary to avoid mutating the original state
     let newState = { ...state };
    
     if (state.chat_reducer && state.chat_reducer.profilePhotos) {
         // Deep copy chat_reducer to ensure modifications do not affect the original state
         newState.chat_reducer = {
             ...newState.chat_reducer,
             profilePhotos: '<<LONG_BLOB>>  checkout globalReducer.js sanitizers to access actual data in dev tools'  // Placeholder for the blob
         };
     }
     
     if (newState.user_reducer && newState.user_reducer.user) {
         // Ensure a deep copy is made for user_reducer.user
         newState.user_reducer = {
             ...newState.user_reducer,
             user: {
                 ...newState.user_reducer.user,
                 profilePhoto: '<<LONG_BLOB>> checkout globalReducer.js sanitizers to access actual data in dev tools'  // Placeholder for the blob
             }
         };
     }
    return newState;
};
