import { START_LOADING,END_LOADING,FETCH_ALL, FETCH_BY_SEARCH, FETCH_BY_CREATOR, GET_PROFILE_PHOTOS_FOR_PICKS, FETCH_PICK, CREATE, UPDATE, DELETE, LIKE, COMMENT, SPORTS, GAMES, ODDS, ERROR_MESSAGE, ADD_LEG, UPDATE_LEG, REMOVE_LEG, UPDATE_TAGS, REMOVE_TAG, REMOVE_TAGS, REMOVE_LEGS, UPDATE_ERROR_MSG_SCREENSHOT, SEARCH_PICKS, SEARCH_PICKS_FOLLOWING, START_LOADING_ALL_PICKS, END_LOADING_ALL_PICKS, START_LOADING_FOLLOWING_PICKS, END_LOADING_FOLLOWING_PICKS, START_LOADING_PICKS, END_LOADING_PICKS, START_LOADING_SCREENSHOT, END_LOADING_SCREENSHOT, LIKE_ALL, LIKE_FOLLOWING, SEARCH_PICKS_FORWARD, SEARCH_PICKS_BACKWARD, SET_PARLAY_ODDS } from '../actions/ActionTypes.js';
import { v4 as uuidv4 } from 'uuid';
import { PickState } from '../model/PickState.js';

const id = uuidv4();
export const pick_reducer = (state = new PickState(), action) => {
    switch (action.type) {
      case START_LOADING:
        return { ...state, isLoading: true };
      case END_LOADING:
        return { ...state, isLoading: false };
      case START_LOADING_SCREENSHOT:
        return { ...state, isLoadingScreenshot: true };
      case END_LOADING_SCREENSHOT:
        return { ...state, isLoadingScreenshot: false };
      case START_LOADING_ALL_PICKS:
        return { ...state, isLoadingAllPicks: true };
      case END_LOADING_ALL_PICKS:
        return { ...state, isLoadingAllPicks: false };

      case START_LOADING_FOLLOWING_PICKS:
        return { ...state, isLoadingFollowingPicks: true };
      case END_LOADING_FOLLOWING_PICKS:
        return { ...state, isLoadingFollowingPicks: false };

      case START_LOADING_PICKS:
        return { ...state, isLoadingPicks: true };
      case END_LOADING_PICKS:
        return { ...state, isLoadingPicks: false };

      case FETCH_ALL:
        return {
          ...state,
          picks: action.payload.data,
          currentPage: action.payload.currentPage,
          numberOfPages: action.payload.numberOfPages,
        };
      case SEARCH_PICKS:
        return { ...state, picksAll: action.payload };
      case SEARCH_PICKS_FOLLOWING:
        return { ...state, picksFollowing: action.payload };
      case FETCH_BY_CREATOR:
        return { ...state, picks: action.payload };
      case FETCH_PICK:
        return { ...state, pick: action.payload.pick };
      case LIKE:
        return { 
          ...state, 
          picks: state.picks.map((pick) => (pick._id === action.payload._id ? action.payload : pick)),
          picksAll: state.picksAll.map((pick) => (pick._id === action.payload._id ? action.payload : pick)),
          picksFollowing: state.picksFollowing.map((pick) => (pick._id === action.payload._id ? action.payload : pick)),
        };
      case COMMENT:
        return {
          ...state,
          picks: state.picks.map((pick) => {
            if (pick._id == +action.payload._id) {
              return action.payload;
            }
            return pick;
          }),
        };
      case CREATE:
        return { ...state, pickPost: action.payload, success: true };
      case UPDATE:
        return { ...state, picks: state.picks.map((pick) => (pick._id === action.payload._id ? action.payload : pick)) };
      case DELETE:
        return { ...state, picks: state.picks.filter((pick) => pick._id !== action.payload) };
      case SPORTS:
        return {...state, sports: action.payload};
      case GAMES:
        return {...state, games: action.payload};
      case ODDS:
        return {...state, odds: action.payload};
      case ERROR_MESSAGE:
        return {...state, errorMsg: action.payload}
      case ADD_LEG:
        return { ...state, legs: [...state.legs, action.payload] };
      case UPDATE_LEG:

        return { ...state, legs: state.legs.map((leg) => (leg.id === action.payload.id ? action.payload : leg)) };
      case REMOVE_LEG:
        return { ...state, legs: state.legs.filter((leg) => leg.id !== action.payload) };
      case REMOVE_LEGS:
        const emptyLeg = {sport: '', group:'', date: '', sportsbook: '', game: '', market: '', pick: '', id: id, odds: 0, sportsOptions: [], gameOptions:[], marketOptions: [], oddsOptions: [], errorMessage: '', complete: false, tags: [], flag: false} 
        let newArray = [emptyLeg]
        return { ...state, legs: newArray, tags: [], success: false, pickPost: null };
      case UPDATE_TAGS:
        const tagsArray = [...state.tags,...action.payload]
        return { ...state, tags: [...new Set(tagsArray)] };
      case REMOVE_TAGS:
        return { ...state, tags: state.tags.filter(tag => !action.payload?.includes(tag)) };
      case REMOVE_TAG:
        return { ...state, tags: state.tags.filter((tag) => tag !== action.payload) };
      case GET_PROFILE_PHOTOS_FOR_PICKS:
        return {...state, profilePhotos: action.payload}
      case UPDATE_ERROR_MSG_SCREENSHOT:
        return {...state, errorMsgScreenshot: action.payload}
      case SET_PARLAY_ODDS:
        return {...state, parlayOdds: action.payload}
      default:
        return state;
    }
};

const updateNestedObject = (obj, path, newValue) => {
  if (path.length === 0) {
    return newValue;
  }

  const [firstKey, ...restOfKeys] = path;

  return {
    ...obj,
    [firstKey]: updateNestedObject(obj[firstKey] || {}, restOfKeys, newValue)
  };
};