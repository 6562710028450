
import { createRef, useEffect, useState, React, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeDescription, changeTitle, erasePicksForm, pushRoute, setCreatePicksPageNumber, setVerifyPopup, toggleLock, updatePrice, updateState, } from '../../../State/redux/actions/StateActions.js';
import "../../../Assets/css/createpickspage.css"
import { PickPost } from '../../../State/redux/model/PickPost.js';
import { addLeg, createPick, errorMessageScreenshot, getPicksFromScreenshotOddsJam, removeLeg, removeLegs, updateLeg } from '../../../State/redux/actions/PickActions.js';
import { useNavigate } from 'react-router-dom';
import PicksCardPreview from './PicksCardPreview.js';

// app component
function ThirdPage() {

  const {title, description, price, lock, height, stepperBetUnits} = useSelector(state => state.state_reducer)
  const auth = useSelector(state => state.auth_reducer)
  const {legs, tags, pickPost, success, isLoadingScreenshot, errorMsgScreenshot} = useSelector(state => state.pick_reducer)
  const dispatch = useDispatch() 
  const navigate = useNavigate()
  
  const textareaRef = useRef()
  const [parlayOdds, setParlayOdds] = useState(2.0)
  const [flagTitle, setFlagTitle] = useState(false)
  const [flagText, setFlagText] = useState('')

  const inputRef = useRef(null);

  const [showPopup, setShowPopup] = useState(false)
  
  // component did update
  useEffect(()=>{
    getOddsTotal()
  },[legs])

  useEffect(()=>{
    if(success && pickPost){
      eraseForm()
      const path = `/profile/${auth?.username}`
      dispatch(pushRoute(path));
      navigate(path);
    }
  },[success])

  const checkFormComplete = () =>{

    let complete = true
    legs.forEach((leg)=>{
      if(!leg.complete){
        complete = false
        // highlight leg that is not complete
        leg.flag = true
        dispatch(updateLeg(leg))
      }
    })

    // make sure title is set
    if(title == ''){
      setFlagTitle(true)
      setFlagText('You need a title')
      return false
    }else if(!complete){
      setFlagText('You need to complete one of your picks')
      setFlagTitle(false)
      return false
    }else{
      setFlagText('')
      return true
    }
  }

  const eraseForm  = () =>{
   
    dispatch(removeLegs())
    dispatch(erasePicksForm())
  }


  const prevPage = () => {
    dispatch(setCreatePicksPageNumber(2))
  }

  const submitPost = () => {

    // @TODO: SETUP PAYMENT INFO

    // make sure each leg is complete
    if(checkFormComplete() && auth?.phone_verified){
      const picksPost = new PickPost()
      picksPost.title = title
      picksPost.description = description
      picksPost.legs = legs
      picksPost.price = price
      picksPost.lock = lock
      picksPost.tags = tags
      picksPost.userId = auth?.id
      picksPost.token = auth?.token
      picksPost.username = auth?.username

      picksPost.overallRecordString = `${auth?.statistics.winsOverall}-${auth?.statistics.lossesOverall}`
      picksPost.overallRecord =  auth?.statistics.winRateOverall
      picksPost.winRate = auth?.statistics.winRateOverall
      picksPost.winRateCategory = auth?.statistics.categories?.length && auth?.statistics.categories?.find(catgeory => catgeory.numberOfLegs === legs.length).winRate
      picksPost.hotStreak = auth?.statistics.winStreakOverall
      picksPost.numberOfLegs = legs.length
      picksPost.totalOdds = parlayOdds
      picksPost.totalBetsOverall = auth?.statistics.totalBetsOverall
      picksPost.profit = auth?.statistics.profitUnitsOverall
      picksPost.profitCategory = auth?.statistics.categories?.length && auth?.statistics.categories?.find(catgeory => catgeory.numberOfLegs === legs.length).profitUnits
      picksPost.units = stepperBetUnits

      dispatch(createPick(picksPost, navigate))

    }else if(!auth?.phone_verified){
      dispatch(setVerifyPopup(true))
    }
  }

  const getOddsTotal = () => {

    // Convert inidivdual American odds to decimal odds, and sum/produce them
    let totalParlayOddsDecimal = 1.0
    legs.forEach(leg => {
      const americanOdds = leg.odds;
      let decimalOdds;
      if (americanOdds > 0) {
        decimalOdds = (americanOdds / 100) + 1;
      } else if (americanOdds < 0) {
        decimalOdds = (-100 / americanOdds) + 1;
      } else {
        // Handle the case where American odds are 0 (even odds)
        decimalOdds = 1;
      }
      // Multiply the decimal odds with the running total
      totalParlayOddsDecimal *= decimalOdds;
    });

    totalParlayOddsDecimal !== 1 && setParlayOdds(totalParlayOddsDecimal.toFixed(2))
  }

  return (
    <>
        {showPopup && 
        <div className='confirm-popup'>
          <div className='confirm-popup-card'>
            <div className='confirm-text'>
              Are you sure?
            </div>
            <div className='confirm-text-sm'>
              You can't edit, remove or undo posts unless you nuke your whole record.
            </div>
            <div className='page-buttons'>
              <div className='next-page-button-sm red' onClick={()=>setShowPopup(false)}>
                  Cancel
                </div>
                <div className='next-page-button-sm'  onClick={submitPost}>
                  Submit
                </div>
              </div>
          </div>
        </div>}
        <div className='create-picks-container-white'>

          <PicksCardPreview />

          <div className='page-buttons'>
            <div className='next-page-button-sm' onClick={prevPage}>
              Back
            </div>
            <div className='next-page-button-sm'  onClick={()=>setShowPopup(true)}>
              Submit
            </div>
          </div>
        </div>
    </>
  )

}

export default ThirdPage;
