
import { createRef, useEffect, useState, React } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import { popRoute, pushRoute, toggleEditProfile,  } from '../../State/redux/actions/StateActions.js';
import { useNavigate, useParams } from 'react-router-dom';
import "../../Assets/css/profilepage.css"
import "../../Assets/css/profile-bet-filters.css"
import { editProfile, getProfile, saveProfileEdits } from '../../State/redux/actions/UserActions.js';
import Header from './SubComponents/Header.js';
import MainEdits from './SubComponents/MainEdits.js';
import EditHeader from './SubComponents/EditHeader.js';

// Profile component
function EditProfile() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector(state => state.auth_reducer)
  const { user, stack } = useSelector((state) => state.user_reducer);

  // component did mount
  useEffect(()=>{
    if(!user || user.oldUsername == ''){
      getUser()
    }
  },[])

  function saveEdits(){
    const {bio, tags, profilePhoto, username, oldUsername} = user
    if(user?.oldUsername === auth?.username){
      dispatch(saveProfileEdits(bio, tags, profilePhoto, username, oldUsername, navigate))
    }
    goBack()
  }

  const getUser = async () => {

    const username = JSON.parse(localStorage.getItem('profile'))?.username;
    if(username){
      const u = await dispatch(getProfile(username))
      if(u){
        u.oldUsername = u.username
        dispatch(editProfile(u))
      }
    }else{
      routeTo('/login')
    }
  }

  const routeTo = (route) => {
        
    dispatch(pushRoute(route));
    navigate(route)
  }

  function goBack(){

      const route = stack && stack.length > 1 ? stack[stack.length-2] : `/profile/${auth.username}`
      navigate(route)
      dispatch(popRoute())
  }

  return (
    <>
    <EditHeader />
    <div className='profile-background'>
      
      {/* EDIT PROFILE */}
      <MainEdits />

      <div className='column-buttons'>
        <div className='edit-profile-button' onClick={()=>{saveEdits()}}>
          Save Edits
        </div>
        <div className='cancel-edit-profile-button mb-20' onClick={()=>{goBack()}}>
          Cancel
        </div>
      </div>
    </div>
    </>
    );

}

export default EditProfile;
