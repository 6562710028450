
import { createRef, useEffect, useState, React } from 'react';
import { useDispatch, useSelector, connect, ReactReduxContext, } from 'react-redux';
import { changeHeight, changeWidth, createState, updateState, } from '../../State/redux/actions/StateActions.js';
import "../../Assets/css/homepage.css"
import VerifySMS from '../Account/SubComponents/PhoneUpdates/VerifySMS.js';


// app component
function HomePage() {

  const dispatch = useDispatch()
  const auth = useSelector(state=>state.auth_reducer)

  // component did mount
  useEffect(() => {

   

    // unmount component
    return () => {
     
    }
  }, [])

  // component did update
  useEffect(() => {

  })



  return (
  
    <div className={"body"}>
      
    </div>
      
  )

}

export default HomePage;
