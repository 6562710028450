// components/Conversation.jsx
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import "../../../Assets/css/profilepage.css"
import back from "../../../Assets/icons/back-grey.png"
import share from "../../../Assets/icons/share.png"
import exit from "../../../Assets/icons/cancel-grey.png"
import { useNavigate, useParams } from 'react-router-dom';
import { popRoute, pushRoute } from '../../../State/redux/actions/StateActions';
import { editProfile } from '../../../State/redux/actions/UserActions';


const Header = ({showMenu}) => {

    const { stack, shareToggle } = useSelector(state => state.state_reducer);
    const auth = useSelector(state => state.auth_reducer);
    const {user} = useSelector(state => state.user_reducer);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const routeTo = (route) => {
        
        dispatch(pushRoute(route));
        navigate(route)
    }

    const setEditProfile = () =>{
        
        if(user?.username === auth?.username){
            routeTo('/edit-profile')
            const u = user
            u.oldUsername = u.username
            dispatch(editProfile(u))
        }

    }

    const goBack = () =>{

        const route = stack && stack.length > 1 ? stack[stack.length-2] : `/`
        navigate(route)
        dispatch(popRoute())
    }

    return (
       
        <div className='profile-header-container'>
           
            <div className='profile-header-back'>
                {stack && stack.length > 1 &&
                <img src={back} className='back-btn-img' onClick={goBack}/>}
            </div>
            <div className='profile-header-content'>
                {user?.username}
            </div>
            <div className='profile-header-info'>
                {user?.username === auth?.username ?
                <div className="back-btn-img" onClick={()=>{setEditProfile()}} >
                    edit
                </div>
                :
                <img src={!shareToggle ? share : exit} className='back-btn-img' onClick={()=>{showMenu()}}/>}
            </div>
        </div>
    );
};

export default Header;
