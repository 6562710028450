
import { createRef, useEffect, useState, React } from 'react';
import { useDispatch, useSelector, connect, ReactReduxContext, } from 'react-redux';
import { createState, toggleLock, updateState, } from '../../../State/redux/actions/StateActions.js';
import "../../../Assets/css/knob.css"

// app component
function Knob({focusTextInput}) {

  const {lock} = useSelector(state => state.state_reducer)
  const dispatch = useDispatch() 

  const [isOn, setIsOn] = useState(false);
  const [dragging, setDragging] = useState(false);


  useEffect(()=>{
    setIsOn(lock || false)
  },[lock])

  const handleToggle = () => {
    var lockPicks = false
    if(!isOn){
      lockPicks = true
      focusTextInput()
    }else{
      lockPicks = false
    }
    dispatch(toggleLock(lockPicks))
    setIsOn(!isOn);
  };

  const handleDragStart = () => {
    setDragging(true);
  };

  const handleDragEnd = () => {
    setDragging(false);
  };

  const handleDragMove = (e) => {
    if (dragging) {
      const switchElement = document.getElementById('draggable-switch');
      const switchWidth = switchElement.offsetWidth;
      const switchX = switchElement.getBoundingClientRect().left;
      const mouseX = e.clientX;

      // Calculate the position relative to the switch
      const positionX = mouseX - switchX;

      // Ensure the switch stays within its bounds
      const newPosition = Math.min(Math.max(0, positionX), switchWidth);

      // Calculate the percentage of the switch toggled
      const percentage = (newPosition / switchWidth) * 100;

      // Toggle the switch based on the percentage
      setIsOn(percentage > 50);
    }
  };

  return (
    <div
      id="draggable-switch"
      className={`draggable-switch ${isOn ? 'on' : 'off'} ${dragging ? 'dragging' : ''}`}
      onClick={handleToggle}
      onMouseDown={handleDragStart}
      onMouseUp={handleDragEnd}
      onMouseMove={handleDragMove}
    >
      <div className="knob" />
    </div>
  );

}

export default Knob;
