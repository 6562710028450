
// actions/chatActions.js
import * as api from '../../api/api.js';
import { createConnection, getSocket } from '../../api/socket.js';
import { v4 as uuidv4 } from 'uuid';

export const toggleConversationModal = (modal) => {
  return async (dispatch) => {
      dispatch({ type: 'SHOW_MODAL', payload: modal });
  };
};

export const updateConversationName = (participants) => {
  return async (dispatch) => {
      dispatch({ type: 'UPDATE_CONVO_NAME', payload: participants });
  };
};

export const updateConversationParticipants = (participants) => {
  return async (dispatch) => {
      dispatch({ type: 'UPDATE_PARTICIPANTS', payload: participants });
  };
};

export const searchUsers = (substring) => async (dispatch) => {

  if(substring != ''){
    const { data } = await api.searchUsers(substring);
    dispatch({ type: 'CREATE_CONVO_SEARCH_USERS', payload: data?.users  });
  }else{
    dispatch({ type: 'CREATE_CONVO_SEARCH_USERS', payload: '' });
  }
};

export const createNewConversation = (participants, conversationName, creatorsUsername) => {

  try{
    var room = uuidv4();
    //createConnection()
    var socket = getSocket()
    participants = [...participants, creatorsUsername]
    socket && socket.emit('create_conversation', {room, participants, conversationName, creatorsUsername});

  }catch(e){
    console.log(e)
  }
};

export const getConversations = () => async (dispatch) => {
   
  //createConnection()
  var socket = getSocket()
  socket && socket.emit('get_all_conversations');
};

export const joinConversation = (conversationId) => async (dispatch) => {
   
  dispatch({ type: 'UPDATE_CONVO_ID', payload: conversationId })
  //createConnection()
  var socket = getSocket()
  socket && socket.emit('join_conversation', {conversationId});
};

export const addMessage = (senderId, username, conversationId, content) => async (dispatch) => {
  
  //createConnection()
  var socket = getSocket()
  socket && socket.emit('send_message', {senderId, username, conversationId, content});

};

export const markMessageAsRead = (conversationId) => async (dispatch) => {
  //createConnection()
  var socket = getSocket()
  socket && socket.emit('mark_as_read', {conversationId});
};

export const saveChangeGroupName = (conversationName, conversationId) => async (dispatch) => {
  //createConnection()
  var socket = getSocket()
  socket && socket.emit('change_group_name', {conversationName, conversationId});
};

export const notifyUserTyping = (username, conversationId) => async (dispatch) => {
  //createConnection()
  var socket = getSocket()
  socket && socket.emit('notify_user_typing', {username, conversationId});
};

export const turnOffTypingIndicator = () => async (dispatch) => {

  dispatch({ type: 'CONVERSATION_USER_TYPING', payload: ''  });
};

export const directMesssage = (username, initiator) => async (dispatch) => {
  //createConnection()
  var socket = getSocket()
  socket && socket.emit('direct_message', {username, initiator});
};



