// components/Conversation.jsx
import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import "../../../Assets/css/conversation.css"
import crayon from "../../../Assets/icons/crayon.png"
import back from "../../../Assets/icons/back-grey.png"
import checkmark from "../../../Assets/icons/checkmark-g.png"
import cancel from "../../../Assets/icons/cancel-r.png"
import { joinConversation, saveChangeGroupName } from '../../../State/redux/actions/ChatActions';
import { useNavigate, useParams } from 'react-router-dom';
import { stringToColor } from '../../../Utilities/UtilityFunctions';
import { popRoute, pushRoute } from '../../../State/redux/actions/StateActions';


const HeaderDetails = ({ }) => {
   
    const { username} = useSelector(state => state.auth_reducer);
    const { stack } = useSelector(state => state.state_reducer);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let { conversationId } = useParams();
    const {conversationName, participants} = useSelector(state => state.chat_reducer);
    const [editName, setEditName] = useState(false)
    //const [modifiedConversationName, setModifiedConversationName] = useState('')
    const modifiedConversationName = conversationName && conversationName != '' ? conversationName : (participants.length > 2 ? participants.filter((u)=>username != u).join(", ") : participants.filter((u)=>username != u))
    const [groupName, setGroupName] = useState(modifiedConversationName)
    const inputRef = useRef(null)

    const goBack = (path) => {
        const route = stack && stack.length > 1 ? stack[stack.length-2] : path
        dispatch(popRoute());
        navigate(route)
    }

    const saveNameChange = () =>{
        dispatch(saveChangeGroupName(groupName, conversationId))
        setEditName(false)
    }

    const cancelEditName = () =>{
        setGroupName(modifiedConversationName)
        setEditName(false)
    }

    const startEditName = () =>{
        setEditName(true)
    }

    function updateGroupName(e){
        setGroupName(e.target.value)
    }

    useEffect(()=>{
        if(editName && inputRef?.current){
            inputRef.current.focus()
        }
    },[editName])

    return (
        <div className='messages-header-container'>
            <div className='messages-header-back'>
                <img src={back} className='back-btn-img' onClick={()=>{goBack(`/conversation/${conversationId}`)}}/>
            </div>
            <div className='messages-header-content'>
                {editName ?
                <input ref={inputRef} type='text' className='conversation-name-input-v2' onChange={(e)=>{updateGroupName(e)}} placeholder={groupName} value={groupName}/>
                :
                modifiedConversationName
                }
            </div>
            <div className='messages-header-info'>
                {editName ?
                <div>
                    <img src={checkmark} className='back-btn-img-v2' onClick={()=>{saveNameChange(true)}}/>
                    <img src={cancel} className='back-btn-img-v2 ml-10' onClick={()=>{cancelEditName()}}/>
                </div>
                :
                <img src={crayon} className='back-btn-img' onClick={()=>{startEditName()}}/>}
            </div>
        </div>
    )
};

export default HeaderDetails;
