
import { createRef, useEffect, useState, React } from 'react';
import "../../../Assets/css/users.css"
import { useSelector } from 'react-redux';


// Profile component
function Statistics() {

  const auth = useSelector(state=>state.auth_reducer)
  const {user} = useSelector(state=>state.user_reducer)
  const overallRecordString = `${user?.statistics.winsOverall}-${user?.statistics.lossesOverall}`
  const overallRecord =  user?.statistics.winRateOverall
  const winRate = user?.statistics.winRateOverall
  const hotStreak = parseFloat(user?.statistics.winStreakOverall)
  const profit = parseFloat(user?.statistics.profitUnitsOverall)
  const myUnits = auth?.units ? parseFloat(auth?.units) : 10
  const rank = user?.statistics.rankOverall
  const totalBets = user?.statistics.totalBetsOverall
  return (
      <div className='users-row-stats padding-stats'>
          <div className={'users-statistic-box-no-bg'}>
            <div className={'users-stat-no-bg'}>Win Rate</div>
            <div className={'users-statistic-no-bg'}>{winRate}%</div>
          </div>
          
          <div className={'users-statistic-box-no-bg'}>
            <div className={'users-stat-no-bg'}>Record</div>
            <div className={'users-statistic-no-bg'}>{user?.statistics.winsOverall}W-{user?.statistics.lossesOverall}L</div>
          </div>

          <div className={'users-statistic-box-no-bg'}>
            <div className={'users-stat-no-bg'}>Streak</div>
            <div className={'users-statistic-no-bg'}>{hotStreak>0?hotStreak+"W":hotStreak+"L"}</div>
          </div>

          <div className={'users-statistic-box-no-bg'}>
            <div className={'users-stat-no-bg'}>Profit</div>
            <div className={'users-statistic-no-bg'}>{parseFloat(profit) >= 0 ? "$"+(parseFloat(profit)*myUnits).toFixed(0) : "-$"+((parseFloat(profit)*myUnits).toFixed(0)*-1)}</div>
          </div>

          <div className={'users-statistic-box-no-bg'}>
            <div className={'users-stat-no-bg'}>Rank</div>
            <div className={'users-statistic-no-bg'}>{totalBets < 5 ? "Unranked" : rank}</div>
          </div>
      </div>
    );

}

export default Statistics;