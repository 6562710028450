export const updateState = state => {
    return {
        type: 'UPDATE_STATE',
        payload: {
            state: state
        }
    }
}

export const createState = state => {
    return {
        type: 'CREATE_STATE',
        payload: {
            state: state
        }
    }
}

export const deleteState = state => {
    return {
        type: 'DELETE_STATE',
        payload: {
            state
        }
    }
}

export const resetState = state => {
    return {
        type: 'RESET',
        payload: {
            state
        }
    }
}

export const changeHeight = (state) => {
    return {
        type: 'CHANGE_HEIGHT',
        payload: {
            state: state
        }
    }
}

export const changeWidth = (state) => {
    return {
        type: 'CHANGE_WIDTH',
        payload: {
            state: state
        }
    }
}

export const toggleOpenCloseMenu = (state) => {
    return {
        type: 'TOGGLE_OPEN_CLOSE_MENU',
        payload: {
            state: state
        }
    }
}

export const toggleShowParlays = (state) => {
    return {
        type: 'TOGGLE_SHOW_PARLAYS',
        payload: {
            state: state
        }
    }
}

export const toggleShowSingles = (state) => {
    return {
        type: 'TOGGLE_SHOW_SINGLES',
        payload: {
            state: state
        }
    }
}

export const toggleShowContentFilters = (state) => {
    return {
        type: 'TOGGLE_SHOW_CONTENT_FILTERS',
        payload: {
            state: state
        }
    }
}

export const toggleShowContentLegs = (state) => {
    return {
        type: 'TOGGLE_SHOW_CONTENT_LEGS',
        payload: {
            state: state
        }
    }
}

export const toggleShowDropdownFilters = (state) => {
    return {
        type: 'TOGGLE_SHOW_DROPDOWN_FILTERS',
        payload: {
            state: state
        }
    }
}

export const toggleShowDropdownLegs = (state) => {
    return {
        type: 'TOGGLE_SHOW_DROPDOWN_LEGS',
        payload: {
            state: state
        }
    }
}

export const selectOverallRecord = (state) => {
    return {
        type: 'SELECT_OVERALL_RECORD',
        payload: {
            state: state
        }
    }
}

export const selectHotStreak = (state) => {
    return {
        type: 'SELECT_HOT_STREAK',
        payload: {
            state: state
        }
    }
}

export const selectPopular = (state) => {
    return {
        type: 'SELECT_POPULAR',
        payload: {
            state: state
        }
    }
}

export const selectNew = (state) => {
    return {
        type: 'SELECT_NEW',
        payload: {
            state: state
        }
    }
}

export const updateSelection = (state) => {
    return {
        type: 'UPDATE_SELECTION',
        payload: {
            state: state
        }
    }
}

export const selectProfileNew = (state) => {
    return {
        type: 'SELECT_PROFILE_NEW',
        payload: {
            state: state
        }
    }
}

export const selectProfilePopular = (state) => {
    return {
        type: 'SELECT_PROFILE_POPULAR',
        payload: {
            state: state
        }
    }
}

export const selectProfileProfit = (state) => {
    return {
        type: 'SELECT_PROFILE_PROFIT',
        payload: {
            state: state
        }
    }
}

export const updateProfileSelection = (state) => {
    return {
        type: 'UPDATE_PROFILE_SELECTION',
        payload: {
            state: state
        }
    }
}

export const changeNumberOfLegsProfile = (state) => {
    return {
        type: 'CHANGE_NUMBER_OF_LEGS_PROFILE',
        payload: {
            state: state
        }
    }
}

export const changeNumberOfLegs = (state) => {
    return {
        type: 'CHANGE_NUMBER_OF_LEGS',
        payload: {
            state: state
        }
    }
}

export const toggleExpandPickCard = (state) => {
    return {
        type: 'TOGGLE_EXPAND_PICK_CARD',
        payload: {
            state: state
        }
    }
}

export const selectPicksNew = (state) => {
    return {
        type: 'SELECT_PICKS_NEW',
        payload: {
            state: state
        }
    }
}

export const selectPicksPopular = (state) => {
    return {
        type: 'SELECT_PICKS_POPULAR',
        payload: {
            state: state
        }
    }
}

export const selectPicksRecord = (state) => {
    return {
        type: 'SELECT_PICKS_RECORD',
        payload: {
            state: state
        }
    }
}

export const selectPicksHot = (state) => {
    return {
        type: 'SELECT_PICKS_HOT',
        payload: {
            state: state
        }
    }
}

export const updatePicksSelection = (state) => {
    return {
        type: 'UPDATE_PICKS_SELECTION',
        payload: {
            state: state
        }
    }
}

export const updateVotes = (state) => {
    return {
        type: 'UPDATE_VOTES',
        payload: {
            state: state
        }
    }
}

export const changeTitle = (state) => {
    return {
        type: 'CHANGE_TITLE',
        payload: {
            state: state
        }
    }
}

export const changeDescription = (state) => {
    return {
        type: 'CHANGE_DESCRIPTION',
        payload: {
            state: state
        }
    }
}

export const toggleLock = (state) => {
    return {
        type: 'TOGGLE_LOCK',
        payload: {
            state: state
        }
    }
}

export const updatePrice = (state) => {
    return {
        type: 'UPDATE_PRICE',
        payload: {
            state: state
        }
    }
}

export const updateLegs = (state) => {
    return {
        type: 'UPDATE_LEGS',
        payload: {
            state: state
        }
    }
}

export const updateSports = (state) => {
    return {
        type: 'UPDATE_SPORTS',
        payload: {
            state: state
        }
    }
}

export const toggleRevealEntirePost = (state) => {
    return {
        type: 'TOGGLE_REVEAL_ENTIRE_POST',
        payload: {
            state: state
        }
    }
}

export const erasePicksForm = () => {
    return {
        type: 'ERASE_FORM',
    }
}

export const initializeState = (w,h) => {
    return {
        type: 'INITIALIZE_STATE',
        payload: {
            width: w,
            height: h
        }
    }
}

export const alertUser = (state) => {
    return {
        type: 'ALERT_USER',
        payload: {
            state: state
        }
    }
}

export const removeAlert = (state) => {
    return {
        type: 'REMOVE_ALERT',
        payload: {
            state: state
        }
    }
}

export const toggleRevealFollowers = (state) => {
    return {
        type: 'TOGGLE_REVEAL_FOLLOWERS',
        payload: {
            state: state
        }
    }
}

export const toggleEditProfile = (state) => {
    return {
        type: 'TOGGLE_EDIT_PROFILE',
        payload: {
            state: state
        }
    }
}

export const rerouteApp = (state) => {
    return {
        type: 'REROUTE_APP',
        payload: {
            state: state
        }
    }
}

export const pushRoute = (route) => (dispatch) => {
    
    // close entire post popup
    dispatch(toggleRevealEntirePost(''))
    // close popup filters (users page)
    dispatch(toggleShowDropdownFiltersUsers(false))
    dispatch(toggleShowContentFiltersUsers(false))
    // close popup filters (picks page + profile page)
    dispatch(toggleShowDropdownFilters(false))
    dispatch(toggleShowDropdownLegs(false))
    dispatch(toggleShowContentFilters(false))
    dispatch(toggleShowContentLegs(false))
    dispatch(setShareToggle(false))
    dispatch(setShareAsDM(false))
    dispatch(setCreatePicksPageNumber(1))
    dispatch({
        type: 'PUSH_ROUTE',
        payload: route,
    })
};
  
  export const popRoute = () => ({
    type: 'POP_ROUTE',
  });

  export const resetRoutes = () => ({
    type: 'RESET_ROUTES',
  });


  export const toggleShowDropdownFiltersUsers = (state) => {
    return {
        type: 'TOGGLE_SHOW_DROPDOWN_FILTERS_USERS',
        payload: {
            state: state
        }
    }
}

export const toggleShowContentFiltersUsers = (state) => {
    return {
        type: 'TOGGLE_SHOW_CONTENT_FILTERS_USERS',
        payload: {
            state: state
        }
    }
}



export const selectUsersProfit = (state) => {
    return {
        type: 'SELECT_USERS_PROFIT',
        payload: {
            state: state
        }
    }
  }
  
  export const selectUsersPopular = (state) => {
    return {
        type: 'SELECT_USERS_POPULAR',
        payload: {
            state: state
        }
    }
  }
  
  export const selectUsersRecord = (state) => {
    return {
        type: 'SELECT_USERS_RECORD',
        payload: {
            state: state
        }
    }
  }
  
  export const selectUsersHot = (state) => {
    return {
        type: 'SELECT_USERS_HOT',
        payload: {
            state: state
        }
    }
  }
  
  export const updateUsersSelection = (state) => {
    return {
        type: 'UPDATE_USERS_SELECTION',
        payload: {
            state: state
        }
    }
  }

  export const setSearchToggle = (state) => {
    return {
        type: 'SET_SEARCH_TOGGLE',
        payload: {
            state: state
        }
    }
  }

  export const setSearchTogglePicks = (state) => {
    return {
        type: 'SET_SEARCH_TOGGLE_PICKS',
        payload: {
            state: state
        }
    }
  }

  export const setVerifyPopup = (state) => {
    return {
        type: 'SET_VERIFY_POPUP',
        payload: {
            state: state
        }
    }
  }

  export const setPaymentPopup = (paymentPopup, id='', username='') => {
    return {
        type: 'SET_PAYMENT_POPUP',
        payload: {
            paymentPopup: paymentPopup,
            postIdToPurchase: id,
            seller: username
        }
    }
  }

  export const setWithdrawalInfoPopup = (state) => {
    return {
        type: 'SET_WITHDRAWAL_INFO_POPUP',
        payload: {
            state: state
        }
    }
  }


  export const setShareToggle = (state) => {
    return {
        type: 'SET_SHARE_TOGGLE',
        payload: {
            state: state
        }
    }
  }

  export const setShareLink = (state) => {
    return {
        type: 'SET_SHARE_LINK',
        payload: {
            state: state
        }
    }
  }

  export const setShareAsDM = (state) => {
    return {
        type: 'SET_SHARE_DM',
        payload: {
            state: state
        }
    }
  }

  export const setTappedStatistics = (state) => {
    return {
        type: 'SET_TAPPED_STATS',
        payload: {
            state: state
        }
    }
}

export const setStepperBetUnits = (state) => {
    return {
        type: 'SET_STEPPER_UNITS',
        payload: {
            state: state
        }
    }
}

export const setCreatePicksPageNumber = (state) => {
    return {
        type: 'SET_PAGE_NUMBER',
        payload: {
            state: state
        }
    }
}
