// components/Conversation.jsx
import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import "../../../Assets/css/profilepage.css"
import link from "../../../Assets/icons/link-w.png"
import msg from "../../../Assets/icons/msg-w.png"
import share from "../../../Assets/icons/share_white.png"

import { useNavigate } from 'react-router-dom';
import { pushRoute, setShareAsDM, setShareLink, setShareToggle } from '../../../State/redux/actions/StateActions';
import { searchUsersAll, searchUsersFollowing } from '../../../State/redux/actions/UserActions';
import { stringToColor } from '../../../Utilities/UtilityFunctions';

const SharePopup = ({showMenu, username}) => {
    
    const isMobile = /Mobi|Android/i.test(navigator.userAgent);
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const {shareDM} = useSelector((state)=>state.state_reducer)
    const [searchTerm, setSearchTerm]  = useState('')
    const auth = useSelector((state)=>state.auth_reducer)
    const [copied, setCopied] = useState(false)
    const searchInputRef = useRef(null);

    const {profilePhotos,} = useSelector(state => state.chat_reducer);
    const { usersFollowing, usersAll,isLoadingAll, isLoadingFollowing } = useSelector((state)=>state.user_reducer)

    useEffect(()=>{
        dispatch(searchUsersAll(''))
        dispatch(searchUsersFollowing(''))
    }, [])

    useEffect(()=>{
        dispatch(searchUsersAll(searchTerm, 'username', 'Best Record', 1))
        dispatch(searchUsersFollowing(searchTerm, 'username', 'Best Record', 1))
    }, [searchTerm])

    const reRoute = (user) =>{
        const path = `/profile/${user}`
        dispatch(pushRoute(path));
        navigate(path)
    }

    const routeTo = (route) => {
        dispatch(pushRoute(route));
        navigate(route)
    }

    useEffect(()=>{
            // Check if the input element is currently focused to prevent unnecessary focus change
        if(shareDM){ 
            if (searchInputRef.current && document.activeElement !== searchInputRef.current) {
                searchInputRef.current.focus();
            }
        }
    }, [shareDM])

    const copyToClipboard = async (text) => {
        
        if ('clipboard' in navigator) {
            try {
                await navigator.clipboard.writeText(text);
                setCopied(true)
            } catch (err) {
                console.error('Failed to copy: ', err);
            }
        } else {
            // Fallback for older browsers
            const textarea = document.createElement('textarea');
            textarea.value = text;
            document.body.appendChild(textarea);
            textarea.select();
            try {
                document.execCommand('copy');
                alert('Text copied to clipboard');
            } catch (err) {
                console.error('Fallback: Oops, unable to copy', err);
            }
            document.body.removeChild(textarea);
        }

        setTimeout(()=>{
            showMenu()
        },400)
    
    };

    const shareWithinApp = (link) => {
        
        // Implementation for sharing within the app
        dispatch(setShareLink(link))
        dispatch(setShareAsDM(true))

    };

    const shareWithinPhone = async (link) => {
        
        if (navigator.share) {
            try {
                await navigator.share({
                title: 'Share profile',
                url: link,
                });
            } catch (err) {
                console.log('Error sharing', err);
            }
        } else {
            console.log('Web Share API is not supported in this browser.');
        }
    
        showMenu()
    };

    const handleClick = (event) => {
        if (event.target === event.currentTarget) {
            showMenu()
          }
      
      };

    const updateSearch = (value) => {
        setSearchTerm(value)
    }

    const getProfilePhotoByUsername = (u) => {
        const userObj = profilePhotos?.find(obj => obj.hasOwnProperty(u));
        return userObj ? userObj[u] : '';
    }

    const ProfileIcon = ({u}) => {
  
        return (
        (getProfilePhotoByUsername(u) !== '' ?
            <img src={getProfilePhotoByUsername(u)} className='share-users-img' onClick={()=>{reRoute(u)}}/>
        :
            <div className='share-users-img-default' style={{backgroundColor: `${stringToColor(u)}`}} onClick={()=>{reRoute(u)}}>
               {u.charAt(0)}
            </div>
        ))
    };

    const mapUsers = () => {

        // remove followers from all users
        const users = usersAll.filter(user =>
            !usersFollowing.some(follower => follower.username === user.username)
        );
        // add followers list so its at the top @TODO: test
        const combinedList = usersFollowing.concat(users)
        // remove myself and the person we are sharing from the list
        const usersSansMoi = combinedList.filter((user) => {if(user.username !== username && user.username !== auth.username) {return user}});

        return usersSansMoi?.map((user, index)=>(
        <div key={`${index}-${user._id}`} className='share-with-user-row'>
            <div className='share-column-img'>
                <ProfileIcon u={user.username}/>
            </div>
            <div className='share-username'>{user.username}</div>
            <div className='share-link-button' onClick={()=>{routeTo(`/direct-message/${user.username}`)}}>
                Share
            </div>
        </div>)
            
        )
    }

    return (
       
        <div className='profile-menu '>
            {copied?
                <div className='profile-share-popup-row-top slide-in-out'>
                     <div className='share-title' >Copied!</div>
                </div>
            :
                <div className='profile-share-popup-row-top slide-in-out'>
                    <div onClick={()=>{copyToClipboard(window.location.href)}} className='column-share'>
                        <div className='img-circle-share'>
                            <img src={link} className='share-img-btn'/>
                        </div>
                        <div className='share-button'>Copy</div>
                    </div>

                    {isMobile && 
                    <div onClick={()=>{shareWithinPhone(window.location.href)}} className='column-share'>
                        
                        <div className='img-circle-share'>
                            <img src={share} className='share-img-btn-left'/>
                        </div>
                        <div className='share-button'>More Options</div>
                    </div>}

                    {auth?.loggedIn && 
                    <div onClick={()=>{shareWithinApp(window.location.href)}} className='column-share'>
                        
                        <div className='img-circle-share'>
                            <img src={msg} className='share-img-btn'/>
                        </div>
                        <div className='share-button'>Message</div>
                    </div>}
                </div>
            }
            <div className='share-greyed-background' onClick={handleClick}>
                    {shareDM &&
                    <div className='share-select-users'>

                        <div className='share-title' >
                            Share <span style={{fontWeight:'bold'}}>&nbsp;{username}&nbsp;</span> with users
                        </div>
                        <input value={searchTerm} ref={searchInputRef} className='search-share-users' type='text' onChange={(e)=>{updateSearch(e.target.value)}} placeholder="Search Users" />
                        <div className='share-users-friends-list'>
                            {mapUsers()}
                        </div>
                    </div>}
            </div>
        </div>
    );
};

export default SharePopup;
