
import { createRef, useEffect, useState, React, useRef, useCallback } from 'react';
import { useDispatch, useSelector, connect, ReactReduxContext, } from 'react-redux';
import { createState, rerouteApp, setShareAsDM, setShareLink, setShareToggle, toggleExpandPickCard, toggleRevealEntirePost, toggleShowContentFilters, toggleShowContentLegs, toggleShowDropdownFilters, toggleShowDropdownLegs, } from '../../State/redux/actions/StateActions.js';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import "../../Assets/css/pickspage.css"
import "../../Assets/css/picks-bet-filters.css"
import { TailSpin, ThreeDots } from 'react-loader-spinner';
import arrowDown from '../../Assets/icons/down-white.png';
import eyeG from '../../Assets/icons/eye-w.png';
import newG from '../../Assets/icons/new-w.png';
import legs from '../../Assets/icons/legs-w.png';
import PickCard from './SubComponents/PicksCard.js';
import percentG from '../../Assets/icons/percent-w.png';
import flameG from '../../Assets/icons/flame-white.png';
import PopupSelectionFilters from './SubComponents/PopupSelectionFilters.js';
import { getPick, getProfilePhotosForPicks } from '../../State/redux/actions/PickActions.js';
import EntirePost from './SubComponents/EntirePost.js';
import Header from './SubComponents/Header.js';
import BetweenPostsAdComponent from '../GoogleAds/BetweenPostsAdComponent.js';
import ToggleSwitch from './SubComponents/Toggle.js';
import { searchPicksAll, searchPicksFollowing } from '../../State/redux/actions/PickActions';
import Skeleton from './SubComponents/Skeleton.js';
import VirtualList from './SubComponents/VirtualList.js';
import SharePopup from './SubComponents/SharePopup.js';
import { fetchPick } from '../../State/api/api.js';

// Picks component
function PicksPage() {

  let { post } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {showDropdownFilters, showDropdownLegs, revealEntirePost, picksSelection, numberOfLegs,openCloseMenu, searchTogglePicks, shareToggle} = useSelector(state => state.state_reducer)
   
  const [numLegs, setNumLegs] = useState('');
  const popupRef = createRef();
  const zIndexRef = createRef();

  const { picksFollowing,picksAll,picks, pick, profilePhotos,isLoadingAllPicks, isLoadingFollowingPicks } = useSelector((state) => state.pick_reducer);
  const auth = useSelector((state) => state.auth_reducer);

  const leaderboardsRef = useRef(null);
  const followingRef = useRef(null);
  const [selectedLeaderboards, setSelectedLeaderboards] = useState(true)
  const [underlineStyle, setUnderlineStyle] = useState({});
  const bodyRef = createRef();
  const searchRef = createRef();
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredPicks, setFilteredPicks] = useState([]);

  const location = useLocation();
  const prevLocationRef = useRef();
  const searchParams = new URLSearchParams(location.search);

  const [pageFollowing, setPageFollowing] = useState(1)
  const [pageAll, setPageAll] = useState(1);

  {/* VIRTUAL LIST */}
  const containerRef = useRef(null);
  const expandedPickId = useSelector(state => state.state_reducer.expandPickCard); // Redux state for the expanded card
  const [visibleStartIndexAll, setVisibleStartIndexAll] = useState(0);
  const [visibleEndIndexAll, setVisibleEndIndexAll] = useState(5); // Start with an arbitrary number of items
  const calculateExpandedHeightAll = (pick) => 375 + (90 * (pick.legs.length - 1)); // Dynamic height calculation for expanded items
  const [visibleStartIndexFollowing, setVisibleStartIndexFollowing] = useState(0);
  const [visibleEndIndexFollowing, setVisibleEndIndexFollowing] = useState(5); // Start with an arbitrary number of items
  const calculateExpandedHeightFollowing = (pick) => 375 + (90 * (pick.legs.length - 1)); // Dynamic height calculation for expanded items
  const percentageHeaderSize = 0.15;
  const viewportHeight = window.innerHeight; // Total height of the viewport
  const headerHeight = viewportHeight * percentageHeaderSize; // Percentage-based header
  const secondHeaderHeight = 55; // Fixed height second header
  const containerHeight = viewportHeight - headerHeight - secondHeaderHeight; // Calculate remaining height for list
  const fixedHeight = 255; // Fixed height for collapsed items
  const marginHeight = 20; // Margin height for each item
  const staticElementHeight = auth?.loggedIn ? 160 : 110; // Height of the static element at the start
  const itemHeightsAll = picksAll.map(pick =>
      (pick._id === expandedPickId ? calculateExpandedHeightAll(pick) : fixedHeight) + marginHeight
  );
  const totalContentHeightAll = itemHeightsAll.reduce((acc, height) => acc + height, 0); // Total height of all elements
  const itemHeightsFollowing = picksFollowing.map(pick =>
    (pick._id === expandedPickId ? calculateExpandedHeightFollowing(pick) : fixedHeight) + marginHeight
  );
  const totalContentHeightFollowing = itemHeightsFollowing.reduce((acc, height) => acc + height, 0); // Total height of all elements

  useEffect(()=>{
      const searchTags = searchParams.get('searchTags');
      if(searchTags){
        setSearchTerm(searchTags)
      }else{
        setSearchTerm('')
      }
  },[location])

  {/******************GET PICKS******************/}

  const calculateNewPageSkipTo = () => {

    let accumulatedHeight = staticElementHeight; // Start with the static element height
    let startIndex = 0;
    let endIndex = 0;

    const elementHeight = fixedHeight+marginHeight

    // Adjust start index to keep the item visible until its bottom is out of view
    while (accumulatedHeight < containerHeight) {
        accumulatedHeight += elementHeight;
        if (accumulatedHeight > containerHeight) {
            break; // Break as soon as the bottom of the item exceeds the top of the scroll
        }
        startIndex++;
    }

    // Determine the last visible index, ensuring it does not go beyond the list's length
    accumulatedHeight = containerHeight;
    endIndex = startIndex; // Start calculating from the first visible index
    while (accumulatedHeight < containerHeight + containerHeight) {
        accumulatedHeight += elementHeight;
        endIndex++;
    }

    //console.log("START1: "+startIndex)
    //console.log("END1: "+endIndex)
    
    const numberOfElementsToSkip = endIndex - startIndex
    
    //console.log("SKIP: "+numberOfElementsToSkip)

    const totalHeightDesired = numberOfElementsToSkip * elementHeight
    const cutOffPixels = totalHeightDesired - containerHeight
    const scrollTop = staticElementHeight + (elementHeight * 2) + cutOffPixels
    
    startIndex = 2
    endIndex = startIndex + numberOfElementsToSkip

    const numberOfElementsToAppend = numberOfElementsToSkip + 2

    //console.log("APPEND: "+numberOfElementsToAppend)

    //console.log("SCROLL: "+scrollTop)

    //console.log("START2: "+startIndex)

    //console.log("END2: "+endIndex)

    return {numberOfElementsToAppend, scrollTop, startIndex, endIndex}

}

  const calculatePrevPageSkipTo = () => {

    let accumulatedHeight = staticElementHeight; // Start with the static element height
    let startIndex = 0;
    let endIndex = 0;

    const elementHeight = fixedHeight+marginHeight

    // Adjust start index to keep the item visible until its bottom is out of view
    while (accumulatedHeight < containerHeight) {
        accumulatedHeight += elementHeight;
        if (accumulatedHeight > containerHeight) {
            break; // Break as soon as the bottom of the item exceeds the top of the scroll
        }
        startIndex++;
    }

    // Determine the last visible index, ensuring it does not go beyond the list's length
    accumulatedHeight = containerHeight;
    endIndex = startIndex; // Start calculating from the first visible index
    while (accumulatedHeight < containerHeight + containerHeight) {
        accumulatedHeight += elementHeight;
        endIndex++;
    }
    
    const numberOfElementsToSkip = endIndex - startIndex
    const totalHeightDesired = numberOfElementsToSkip * elementHeight
    const cutOffPixels = totalHeightDesired - containerHeight
    const scrollTop = staticElementHeight + elementHeight + cutOffPixels
    
    startIndex = 2
    endIndex = startIndex + numberOfElementsToSkip

    const numberOfElementsToAppend = numberOfElementsToSkip + 2

    return {numberOfElementsToAppend}

  }

  async function getPicksFromServer(){
    
    // @TODO: Filters, tags, search
    const p = await dispatch(searchPicksAll('', 'tags', picksSelection, pageAll, numberOfLegs ))
    dispatch(getProfilePhotosForPicks(p))
  }

  const getProfilePhotoByUsername = (username) => {
    const userObj = profilePhotos?.find(obj => obj.hasOwnProperty(username));
    return userObj ? userObj[username] : '';
  }

  const [isLoadingFollowing, setIsLoadingFollowing] = useState(false);
  const [hasMoreFollowing, sethasMoreFollowing] = useState(true);

  const observerBottomFollowing = useRef();
  const observerTopFollowing = useRef();

  // scroll to next page, fetch picks, slice virtual list, append last 5 elements from current list to start of new list, scroll to same post/elements new position
  const lastElementRefFollowing = useCallback(node => {
    //console.log('Ref setting callback called', node);
    if (isLoadingFollowing) return;
    //console.log('Returned');

    if (observerBottomFollowing.current) observerBottomFollowing.current.disconnect();
    observerBottomFollowing.current = new IntersectionObserver(entries => {
      //console.log('Observer triggered'); // To check if observer is triggered
        if (entries[0].isIntersecting && hasMoreFollowing) {
            //console.log('Fetching more items'); // Check if the fetch condition is met
            //console.log('pages:'+pageFollowing); // Check if the fetch condition is met
            setIsLoadingFollowing(true);
            const {numberOfElementsToAppend, scrollTop, startIndex, endIndex} = calculateNewPageSkipTo()
            dispatch(searchPicksFollowing(searchTerm, searchTogglePicks ? 'tags':'username', picksSelection, pageFollowing + 1, numberOfLegs, false, false, numberOfElementsToAppend))
                .then((posts) => {
                  if(posts){
                    setPageFollowing(prevPage => prevPage + 1);
                    setVisibleStartIndexFollowing(startIndex);
                    setVisibleEndIndexFollowing(endIndex);
                    // This ensures that the scrollable container scrolls back to top after indices are reset
                    if (containerRef.current) {
                      containerRef.current.scrollTop = scrollTop;
                    }
                  }
                  setIsLoadingFollowing(false);
                }).catch(error => {
                  console.log('Failed to fetch data:', error);
                  setIsLoadingFollowing(false);
                });
        }
    });
    if (node) observerBottomFollowing.current.observe(node);
}, [isLoadingFollowing, hasMoreFollowing, dispatch, searchPicksFollowing, picksSelection, pageFollowing, numberOfLegs]);

// scroll back to previous page, fetch picks, slice virtual list, append first 5 elements from current list to end of new list, scroll to same post/elements new position
const firstElementRefFollowing = useCallback(node => {
    if (isLoadingFollowing) return;
    if (observerTopFollowing.current) observerTopFollowing.current.disconnect();
    observerTopFollowing.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && pageFollowing > 1) {
            setIsLoadingFollowing(true);
            const {numberOfElementsToAppend} = calculatePrevPageSkipTo()
            dispatch(searchPicksFollowing(searchTerm, searchTogglePicks ? 'tags':'username', picksSelection, pageFollowing - 1, numberOfLegs, true, false, numberOfElementsToAppend))
                .then((posts) => {
                  if(posts){
                    setPageFollowing(prevPage => prevPage - 1);
                    setVisibleStartIndexFollowing(posts.length-numberOfElementsToAppend);// @TODO: set to end of the stack
                    setVisibleEndIndexFollowing(posts.length);
                    // This ensures that the scrollable container scrolls back to bottom after indices are reset
                    if (containerRef.current) {
                      containerRef.current.scrollTop = staticElementHeight+((posts.length-numberOfElementsToAppend)*(marginHeight+fixedHeight));
                    }
                  }
                  setIsLoadingFollowing(false);
                }).catch(error => {
                  console.log('Failed to fetch data:', error);
                  setIsLoadingFollowing(false);
                });
        }
    });
    if (node) observerTopFollowing.current.observe(node);
}, [isLoadingFollowing, pageFollowing, dispatch, searchPicksFollowing, picksSelection, numberOfLegs]);

  function mapPicksFollowing(){
    // return(picksFollowing?.map((pick, index)=>{
    //   return (
    //     <div key={index} className='pick-card-ad-container'>
    //       <PickCard id={pick._id} pick={pick} photo={getProfilePhotoByUsername(pick.username)}/>
    //       {/*((index + 1) % 3 === 0) && <BetweenPostsAdComponent />*/}
    //     </div>
    //   )
    // }))
    return(
      <div style={{ height: `${totalContentHeightFollowing}px`, position: 'relative', transition: "height .5s cubic-bezier(.82,.085,.395,.895)" }}>
        {picksFollowing.slice(visibleStartIndexFollowing, visibleEndIndexFollowing + 1).map((pick, index) => (
            <div 
            key={`${pick._id}-following`} 
            ref={(visibleStartIndexFollowing + index) === 0 ? firstElementRefFollowing : (visibleEndIndexFollowing === picksFollowing.length - 1 ? lastElementRefFollowing : null)}
            style={{
                position: 'absolute',
                top: `${itemHeightsFollowing.slice(0, visibleStartIndexFollowing + index).reduce((acc, height) => acc + height, 0)}px`,
                width: '100%',
                transition: "top .5s cubic-bezier(.82,.085,.395,.895)"
            }}>
                <PickCard id={pick._id} pick={pick} photo={getProfilePhotoByUsername(pick.username)} />
            </div>
        ))}
      </div>
    )
  }

  useEffect(()=>{
    //console.log("(start, end): ("+visibleStartIndexAll+", "+visibleEndIndexAll+")")
  },[visibleEndIndexAll, visibleStartIndexAll])

  const [isLoadingAll, setIsLoadingAll] = useState(false);
  const [hasMoreAll, sethasMoreAll] = useState(true);

  const observerBottomAll = useRef();
  const observerTopAll = useRef();

  // scroll to next page, fetch picks, slice virtual list, append last 5 elements from current list to start of new list, scroll to same post/elements new position
  const lastElementRefAll = useCallback(node => {
    //console.log('Ref setting callback called', node);
    if (isLoadingAll) return;
    //console.log('Returned');

    if (observerBottomAll.current) observerBottomAll.current.disconnect();
    observerBottomAll.current = new IntersectionObserver(entries => {
      //console.log('Observer triggered'); // To check if observer is triggered
        if (entries[0].isIntersecting && hasMoreAll) {
            //console.log('Fetching more items'); // Check if the fetch condition is met
            //console.log('pages:'+pageAll); // Check if the fetch condition is met
            setIsLoadingAll(true);
            const {numberOfElementsToAppend, scrollTop, startIndex, endIndex} = calculateNewPageSkipTo()
            dispatch(searchPicksAll(searchTerm, searchTogglePicks ? 'tags':'username', picksSelection, pageAll + 1, numberOfLegs, false, false, numberOfElementsToAppend))
                .then((posts) => {
                  if(posts){
                    setPageAll(prevPage => prevPage + 1);
                    setVisibleStartIndexAll(startIndex);
                    setVisibleEndIndexAll(endIndex);
                    // This ensures that the scrollable container scrolls back to top after indices are reset
                    if (containerRef.current) {
                      containerRef.current.scrollTop = scrollTop;
                    }
                  }
                  setIsLoadingAll(false);
                }).catch(error => {
                  console.log('Failed to fetch data:', error);
                  setIsLoadingAll(false);
                });
        }
    });
    if (node) observerBottomAll.current.observe(node);
}, [isLoadingAll, hasMoreAll, dispatch, searchPicksAll, picksSelection, pageAll, numberOfLegs]);

// scroll back to previous page, fetch picks, slice virtual list, append first 5 elements from current list to end of new list, scroll to same post/elements new position
const firstElementRefAll = useCallback(node => {
    if (isLoadingAll) return;
    if (observerTopAll.current) observerTopAll.current.disconnect();
    observerTopAll.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && pageAll > 1) {
            setIsLoadingAll(true);
            const {numberOfElementsToAppend} = calculatePrevPageSkipTo()
            dispatch(searchPicksAll(searchTerm, searchTogglePicks ? 'tags':'username', picksSelection, pageAll - 1, numberOfLegs, true, false, numberOfElementsToAppend))
                .then((posts) => {
                  if(posts){
                    setPageAll(prevPage => prevPage - 1);
                    setVisibleStartIndexAll(posts.length-numberOfElementsToAppend);// @TODO: set to end of the stack
                    setVisibleEndIndexAll(posts.length);
                    // This ensures that the scrollable container scrolls back to bottom after indices are reset
                    if (containerRef.current) {
                      containerRef.current.scrollTop = staticElementHeight+((posts.length-numberOfElementsToAppend)*(marginHeight+fixedHeight));
                    }
                  }
                  setIsLoadingAll(false);
                }).catch(error => {
                  console.log('Failed to fetch data:', error);
                  setIsLoadingAll(false);
                });
        }
    });
    if (node) observerTopAll.current.observe(node);
}, [isLoadingAll, pageAll, dispatch, searchPicksAll, picksSelection, numberOfLegs]);


  function mapPicksAll(){
    // return(picksAll?.map((pick, index)=>{
    //   return (
    //     <div key={index} className='pick-card-ad-container'>
    //       <PickCard id={pick._id} pick={pick} photo={getProfilePhotoByUsername(pick.username)}/>
    //       {/*((index + 1) % 3 === 0) && <BetweenPostsAdComponent />*/}
    //     </div>
    //   )
    // }))

    return (
      <div style={{ height: `${totalContentHeightAll}px`, position: 'relative', transition: "height .5s cubic-bezier(.82,.085,.395,.895)" }}>
        {picksAll.slice(visibleStartIndexAll, visibleEndIndexAll + 1).map((pick, index) => (
            <div key={pick._id} 
            ref={(visibleStartIndexAll + index) === 0 ? firstElementRefAll : (visibleEndIndexAll === picksAll.length - 1 ? lastElementRefAll : null)}
            style={{
                position: 'absolute',
                top: `${itemHeightsAll.slice(0, visibleStartIndexAll + index).reduce((acc, height) => acc + height, 0)}px`,
                width: '100%',
                transition: "top .5s cubic-bezier(.82,.085,.395,.895)"
            }}>
                <PickCard id={pick._id} pick={pick} photo={getProfilePhotoByUsername(pick.username)} />
            </div>
        ))}
      </div>)
  }
  {/******************GET PICKS END******************/}

  {/******************FILTERS DROPDOWN ANIMATION/SELECTION******************/}

  useEffect(() => {
    if(selectedLeaderboards){
      dispatch(searchPicksAll(searchTerm, searchTogglePicks ? 'tags':'username',  picksSelection, pageAll, numberOfLegs))
    }else{
      dispatch(searchPicksFollowing(searchTerm, searchTogglePicks ? 'tags':'username', picksSelection, pageFollowing, numberOfLegs))
    }  
  }, [picksSelection, numberOfLegs]);

  function getIcon(value){

    switch(value){
      case "Best Record":
        return  <img src={percentG} className='picks-icon-dropdown-left'/>
      case "Hot Streak":
        return  <img src={flameG} className='picks-icon-dropdown-left'/>
      case "Popular Bets":
        return  <img src={eyeG} className='picks-icon-dropdown-left'/>
      case "New Bets":
        return  <img src={newG} className='picks-icon-dropdown-left'/>
    }
  }

  const dropdownSelectionFilter = () => {

    // show effects
    dispatch(toggleShowDropdownFilters(!showDropdownFilters))
    dispatch(toggleShowDropdownLegs(false))

    // show text
    dispatch(toggleShowContentFilters(true))
    dispatch(toggleShowContentLegs(false))

    // change css for animation (refs required for animation transition)
    const ref = popupRef.current
    ref.classList.toggle("picks-is-menu-open")

    const refZ = zIndexRef.current
    refZ.classList.toggle("picks-z-indices")

  }

  const dropdownSelectionLegs = () => {
    
    // show effects
    dispatch(toggleShowDropdownLegs(!showDropdownLegs))
    // show text
    dispatch(toggleShowContentFilters(false))
    dispatch(toggleShowContentLegs(true))

    // change css for animation (refs required for animation transition)
    const refPopup = popupRef.current
    refPopup.classList.toggle("picks-is-menu-open")

    const refZ = zIndexRef.current
    refZ.classList.toggle("picks-z-indices")

    // reset input box on exit
    if(numberOfLegs != numLegs){
      if(numberOfLegs>5){
        setNumLegs(numberOfLegs)
      }else{
        setNumLegs('')
      }
    }
  }

  useEffect(()=>{
    // if we click the menu, close the filter popup
    if(openCloseMenu){
      if(showDropdownFilters){
          dropdownSelectionFilter()
      }else if(showDropdownLegs){
          dropdownSelectionLegs()
      }
    }
  },[openCloseMenu])

  // Fixes UI bug when drawer is open and home button clicked (but css animation div stays open - bad!)
  useEffect(() => {
      // Store current location in a ref to compare with new location on changes
      const currentPath = location.pathname + location.search;

      // Check if the previous path is the same as the current path
      if (prevLocationRef.current === currentPath) {
          
          // Same page link was clicked, handle close drawer if open
          const refPopup = popupRef.current
          const refZ = zIndexRef.current
          // remove css
          if(refPopup.classList.contains('picks-is-menu-open')){
            refPopup.classList.toggle("picks-is-menu-open")
            refZ.classList.toggle("picks-z-indices")
          }
      }
      // Update the previous location ref with the current location
      prevLocationRef.current = currentPath;
  }, [location]); // Only re-run the effect if location changes
  {/******************FILTERS DROPDOWN ANIMATION/SELECTION END******************/}

  {/******************BODY ANITMATIONS******************/}
  {/* SHIFT BODY LEFT => RIGHT */}
  const selectLeaderboards = () => {
    if (!selectedLeaderboards) {
      setSelectedLeaderboards(true);
      // change css for animation (refs required for animation transition)
      const ref = bodyRef.current
      ref.classList.toggle("move-right")
    }
  };

  {/* SHIFT BODY RIGHT => LEFT */}
  const selectFollowing = () => {
    if (selectedLeaderboards) {
      setSelectedLeaderboards(false);
      // change css for animation (refs required for animation transition)
      const ref = bodyRef.current
      ref.classList.toggle("move-right")
    }
  };

    {/* SEARCH PICKS */}
    useEffect(() => {
      if(selectedLeaderboards){
        dispatch(searchPicksAll(searchTerm, searchTogglePicks ? 'tags':'username', picksSelection, pageAll, numberOfLegs))
      }else{
        dispatch(searchPicksFollowing(searchTerm, searchTogglePicks ? 'tags':'username', picksSelection, pageFollowing, numberOfLegs))
      }  
  }, [searchTerm]);

  {/* CHANGE SEARCH BASED ON FOLLOWING OR ALL */}
  useEffect(() => {
    // check which tab is clicked, All or Following
    if(selectedLeaderboards){
      // only search if we dont have picks yet
      if( !picksAll || picksAll?.length == 0 ){
        dispatch(searchPicksAll(searchTerm, searchTogglePicks ? 'tags':'username', picksSelection, pageAll, numberOfLegs))
      }
    }else{
      if( !picksFollowing || picksFollowing?.length == 0 ){
        dispatch(searchPicksFollowing(searchTerm, searchTogglePicks ? 'tags':'username', picksSelection, pageFollowing, numberOfLegs))
      }
    }  
  }, [selectedLeaderboards]);

  {/* LISTEN FOR KEY LOGS FOR SEARCH */}
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const toggled = () => {
    setSearchTerm('')
    searchRef?.current?.focus()
  }
  {/******************BODY ANITMATIONS END******************/}

  {/******************FILTER FUNCTIONS******************/}
  useEffect(() => {
    const filtered = picks?.filter(p => {
      // Logic to filter by name, tags, streak, record, profit, bet count, streak count, popularity
      // based on searchTerm
      return p; // Replace with actual condition
    });
    setFilteredPicks(filtered);
  }, [searchTerm, picks]);
  {/******************FILTER FUNCTIONS END******************/}

  {/******************TAB UNDERLINE ANIMATIONS******************/}
  const updateUnderlinePosition = () => {

    const activeTab = selectedLeaderboards ? leaderboardsRef.current : followingRef.current;
    const width = activeTab?.offsetWidth;
    const left = activeTab?.offsetLeft;
    setUnderlineStyle({
      width: `${width}px`,
      left: `${left}px`,
    });
  };

  // Update underline position on tab change
  useEffect(() => {
    updateUnderlinePosition();
  }, [selectedLeaderboards]);

  // Adjust underline position when the component mounts and if the window is resized
  useEffect(() => {

    getPicksFromServer() // also request picks from server
    const timer = setTimeout(() => {
      updateUnderlinePosition();
    }, 300);

    // Cleanup function to clear the timeout if the component unmounts
    window.addEventListener('resize', updateUnderlinePosition);
    return () => {
      window.removeEventListener('resize', updateUnderlinePosition);
      clearTimeout(timer);
    };
  }, []);
  {/******************TAB UNDERLINE ANIMATIONS END******************/}


  {/******************VIRTUAL LIST******************/}
  const handleScrollAll = () => {
      const scrollTop = containerRef.current.scrollTop;
      let accumulatedHeight = staticElementHeight; // Start with the static element height
      let startIndex = 0;
      let endIndex = 0;

      // Adjust start index to keep the item visible until its bottom is out of view
      while (accumulatedHeight < scrollTop && startIndex < itemHeightsAll.length) {
          accumulatedHeight += itemHeightsAll[startIndex];
          if (accumulatedHeight > scrollTop) {
              break; // Break as soon as the bottom of the item exceeds the top of the scroll
          }
          startIndex++;
      }

      // Determine the last visible index, ensuring it does not go beyond the list's length
      accumulatedHeight = scrollTop;
      endIndex = startIndex; // Start calculating from the first visible index
      while (accumulatedHeight < scrollTop + containerHeight && endIndex < itemHeightsAll.length) {
          accumulatedHeight += itemHeightsAll[endIndex];
          endIndex++;
      }


      if(selectedLeaderboards){
        if(expandedPickId !== 0){
          // close the pick card if its not being rendered
          var flagged = false
          picksAll.slice(startIndex, endIndex).map((post)=>{
            if(post._id === expandedPickId){
              flagged = true
            }
          })
          if(!flagged){
            dispatch(toggleExpandPickCard(0))
            if (containerRef.current) {
              containerRef.current.scrollTop = containerRef.current.scrollTop-(70+(90 * (numberOfLegs)));
            }
          }

          // minimum 4 items being rendered
          if((endIndex - startIndex) <= 4){
            endIndex = startIndex+4
          }
        }
      }

      setVisibleStartIndexAll(startIndex);
      setVisibleEndIndexAll(endIndex); // Ensure we set the state to the last calculated index
      
  };

  const handleScrollFollowing = () => {
      const scrollTop = containerRef.current.scrollTop;
      let accumulatedHeight = staticElementHeight; // Start with the static element height
      let startIndex = 0;
      let endIndex = 0;

      // Adjust start index to keep the item visible until its bottom is out of view
      while (accumulatedHeight < scrollTop && startIndex < itemHeightsFollowing.length) {
          accumulatedHeight += itemHeightsFollowing[startIndex];
          if (accumulatedHeight > scrollTop) {
              break; // Break as soon as the bottom of the item exceeds the top of the scroll
          }
          startIndex++;
      }

      // Determine the last visible index, ensuring it does not go beyond the list's length
      accumulatedHeight = scrollTop;
      endIndex = startIndex; // Start calculating from the first visible index
      while (accumulatedHeight < scrollTop + containerHeight && endIndex < itemHeightsFollowing.length) {
          accumulatedHeight += itemHeightsFollowing[endIndex];
          endIndex++;
      }

      if(!selectedLeaderboards){
        if(expandedPickId !== 0){
          // close the pick card if its not being rendered
          var flagged = false
          picksFollowing.slice(startIndex, endIndex).map((post)=>{
            if(post._id === expandedPickId){
              flagged = true
            }
          })
          if(!flagged){
            dispatch(toggleExpandPickCard(0))
            if (containerRef.current) {
              containerRef.current.scrollTop = containerRef.current.scrollTop-(70+(90 * (numberOfLegs)));
            }
          }
          // minimum 4 items being rendered
          if((endIndex - startIndex) <= 4){
            endIndex = startIndex+4
          }
        }
      }
      setVisibleStartIndexFollowing(startIndex);
      setVisibleEndIndexFollowing(endIndex); // Ensure we set the state to the last calculated index


  };

  useEffect(() => {
    handleScrollAll(); // Handle initial load and resize events
    handleScrollFollowing();
    const handleResize = () => {
      handleScrollAll(); // Handle resize and adjust for 'All' list
      handleScrollFollowing(); // Handle resize and adjust for 'Following' list
  };
    window.addEventListener('resize', handleResize); // Adjust for viewport size changes
    return () => window.removeEventListener('resize', handleResize);
  }, [expandedPickId, picksAll, picksFollowing]); // React to changes in expansion and the list

  {/******************VIRTUAL LIST END******************/}

  useEffect(()=>{

    const initData = async () => {
      var p = null
      // get pick
      if(post){
        p = dispatch(getPick(post))
      }
      // reveal post
      if(p){
        dispatch(toggleRevealEntirePost(post))
      }
    }
    initData()
  },[])//picksAll, picksFollowing

  useEffect(()=>{
    if(revealEntirePost !== ''){
      navigate(`/picks/${revealEntirePost}`)
    }else{
      navigate(`/picks`)
    }
  },[revealEntirePost])

  return (
    <>
    {revealEntirePost !== '' && revealEntirePost && pick?._id === revealEntirePost &&
    <EntirePost post={pick?._id}/>}

    <Header />

    <div className='background' ref={containerRef} onScroll={()=>{selectedLeaderboards ? handleScrollAll() : handleScrollFollowing()}}>

      {(selectedLeaderboards && pageAll != 1) || !selectedLeaderboards && pageFollowing != 1
      ?
        <div className={auth?.loggedIn ? 'loading-indicator' : 'loading-indicator-small'}>
          <ThreeDots color="white" height={80} width={80}/>
        </div>
      :
        <div className='filter-elements'>
          {/* TAB SELECTION */}
          {auth?.loggedIn &&
          <div className='discovery-type-row'>
            <div ref={leaderboardsRef} className="discovery-type" onClick={() => selectLeaderboards()} >
              <h3>All</h3>
            </div>
            <div ref={followingRef} className="discovery-type" onClick={() => selectFollowing()}>
              <h3>Following</h3>
            </div>
            <div className="underline" style={underlineStyle}/> {/* This is the moving underline */}
          </div>}
          {/* SEARCH */}
          <div className='search-and-toggle'>
            <input ref={searchRef} type="text" placeholder={searchTogglePicks? "Search Tags": "Search Usernames"} onChange={handleSearchChange} className='search-users' value={searchTerm}/>
            <ToggleSwitch toggled={toggled} />
          </div>
          {/* FILTERS */}
          <div className='picks-filters'>
            <div className='picks-row-dropdowns'>
              <div onClick={()=>{dropdownSelectionFilter()}} className={'picks-text-selection'}>
                {picksSelection ? getIcon(picksSelection) : getIcon("New Bets")}
                {picksSelection ? picksSelection : "New Bets"}
                <img src={arrowDown} className='picks-icon-dropdown-right'/>
              </div> 
              
              <div onClick={()=>{dropdownSelectionLegs()}} className={'picks-text-selection'}>
                <img src={legs} className='picks-icon-dropdown-left-secondary'/>
                {numberOfLegs > 1 ? numberOfLegs + " Legs ": "Single Bets"}
                <img src={arrowDown} className='picks-icon-dropdown-right'/>
              </div>
            </div>
          </div>
        </div>
      }

      {/* BODY */}
      <div className="discovery-body" ref={bodyRef}>
        {!isLoadingAllPicks ? 
        <div className='picks-feed'>
          {mapPicksAll()}
        </div>
        :
        <div className='picks-feed'>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </div>
        }
        {!isLoadingFollowingPicks ? 
        <div className='picks-feed'>
          {mapPicksFollowing()}
        </div>
        :
        <div className='picks-feed'>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </div>
        }
      </div>
    </div>

    <div ref={zIndexRef} className='picks-feed-overlay'>
      <div className='picks-parent-container-secondary'>
        <div ref={popupRef} className='picks-filter-popup'>
            <PopupSelectionFilters dropdownSelectionLegs={dropdownSelectionLegs} dropdownSelectionFilter={dropdownSelectionFilter}/>
        </div>
      </div>
    </div>

    {showDropdownFilters &&
      <div onClick={()=>{dropdownSelectionFilter()}} className='picks-darken-background'/>
    }
    {showDropdownLegs &&
      <div onClick={()=>{dropdownSelectionLegs()}} className='picks-darken-background'/>
    }
    </>
    );

}

export default PicksPage;
