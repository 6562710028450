import React from 'react';
import '../../../Assets/css/skeleton-picks.css'; // Ensure you create this CSS file

const Skeleton = () => {
    return (
        <div className="skeleton-container">
          <div className="shimmer">
            {/* Header with icon and stats */}
            <div className="skeleton-header">
              <div className="skeleton-column-img">
                <div className="skeleton-name-picks"></div>
                <div className="skeleton__circle"></div>
                <div className="skeleton-record-picks"></div>
              </div>
              <div className="skeleton__square"></div>
              <div className="skeleton__square"></div>
              <div className="skeleton__square_small"></div>
            </div>
    
            {/* Content with text lines */}
            <div className="skeleton-content">
              <div className="skeleton__rectangle wide"></div>
            </div>
    
            {/* Footer with interactive elements */}
            <div className="skeleton-footer">
              <div className="skeleton__rectangle_big"></div>
            </div>
            <div className="skeleton-toe">
              <div className="skeleton__rectangle_tiny"></div>
            </div>
          </div>
        </div>
      );
}

export default Skeleton;
