
import * as actionType from './ActionTypes';
import * as api from '../../api/api.js';
import { refreshToken, refreshTokenForNewUsername } from './AuthActions';
import { alertUser, popRoute } from './StateActions';
import { store } from '../reducers/globalReducers';
import { getPicksByCreator } from './PickActions';

const debounceTimers = {};

const debounceDispatch = (func, delay, key) => {
    return (...args) => {
        if (debounceTimers[key]) {
            clearTimeout(debounceTimers[key]);
        }

        return new Promise((resolve, reject) => {
            debounceTimers[key] = setTimeout(() => {
                func(...args)
                    .then(resolve)
                    .catch(reject)
                    .finally(() => {
                        delete debounceTimers[key]; // Cleanup timer reference once done
                    });
            }, delay);
        });
    };
};

  export const followUser = (user) => async (dispatch) => {
    const follower = JSON.parse(localStorage.getItem('profile'));
  
    try {
      dispatch({ type: actionType.START_LOADING });
      if(follower){
        const { data } = await api.followUser(follower.username, user);
  
        dispatch({ type: actionType.FOLLOW, payload: data.profile });
        dispatch({ type: actionType.END_LOADING });
        
      }else{
        // @TODO: SIGN IN /SIGN UP
        dispatch({ type: actionType.END_LOADING });
      }
    } catch (error) {
      dispatch({ type: actionType.END_LOADING });
      console.log(error);
      
    }
  };

  export const getProfile = (username) => async (dispatch) => {
    
    const actionKey = `getProfile`; // Unique key for this type of action
    const debouncedDispatch = debounceDispatch(async () => {
      try {
        dispatch({ type: actionType.START_LOADING_PROFILE });
        if(username){
          const { data } = await api.getProfile(username);
          dispatch({ type: actionType.PROFILE, payload: data.profile });
          dispatch({ type: actionType.END_LOADING_PROFILE });
          return data.profile
        }else{
          // @TODO: profile not found page
          dispatch({ type: actionType.END_LOADING_PROFILE });
          return null
        }
      } catch (error) {
        dispatch({ type: actionType.END_LOADING_PROFILE });
        console.log(error);
        return null
      }
    }, 700, actionKey); // 1-second delay and pass the unique key

    return debouncedDispatch();
  };

  export const editProfile = (user) => async (dispatch) => {
    
    try {
      dispatch({ type: actionType.PROFILE, payload: user });
    } catch (error) {
      
      console.log(error);
      
    }
  };

  export const saveProfileEdits = (bio, tags, profilePhoto, username, oldUsername, navigate) => async (dispatch) => {
    
    try {

      const { data } = await api.updateProfile(bio, tags, profilePhoto, username, oldUsername);
      dispatch({ type: actionType.PROFILE, payload: data.profile });
      dispatch(alertUser(data?.message))
      if(data?.profile.username != oldUsername && oldUsername != ''){
        // change auth state with updated username
        const done = await dispatch(refreshTokenForNewUsername(username))
        if(done){
          dispatch(popRoute())
          navigate(`/profile/${username}`)
          window.location.reload()
        }
      }
    } catch (error) {
      
      console.log(error);
      
    }
  };

  export const checkUsername = (username) => async (dispatch) => {
    
    try {
      const { data } = await api.checkUsername(username);

      if(data?.exists){
        dispatch({ type: actionType.ERROR_MESSAGE_CHANGE_USERNAME, payload: "This username is already taken. Try another one." });
      }else{
        dispatch({ type: actionType.ERROR_MESSAGE_CHANGE_USERNAME, payload: "" });
      }
    } catch (error) {
      
      console.log(error);
      
    }
  };

  export const updateUsername = (username, oldUsername, navigate) => async (dispatch) => {
    
    try {

      const { data } = await api.updateUsername(username, oldUsername);
      dispatch({ type: actionType.PROFILE, payload: data.profile });
      dispatch(alertUser(data?.message))
      if(data?.profile.username != oldUsername && oldUsername != ''){
        // change auth state with updated username
        const done = await dispatch(refreshTokenForNewUsername(username))
        if(done){
          dispatch(popRoute())
          navigate(`/account`)
          window.location.reload()
        }
      }
    } catch (error) {
      
      console.log(error);
      
    }
  };

  export const nukeRecord = (password, navigate) => async (dispatch) => {
    
    try {

      const { data } = await api.nukeRecord(password);
      if(data?.success){
        dispatch({ type: actionType.PROFILE, payload: data.user });
        dispatch(alertUser(data?.message))
        dispatch(refreshToken())
        dispatch(getPicksByCreator(data.user.username, "New", 1, 0))
        dispatch(getProfile(data.user.username))
        return ''
      }else{
        return data?.message
      }
    } catch (error) {
      console.log(error);
      return "Something went wrong. Try logging out and back in and refreshing the page."
    }
  };

  export const searchUsers = (username) => async (dispatch) => {
    
    try {
      dispatch({ type: actionType.START_LOADING });
      const { data } = await api.searchUsers(username);
      dispatch({ type: actionType.SEARCH_USERS, payload: data.users });
      dispatch({ type: actionType.END_LOADING });
      return data.profile
    } catch (error) {
      console.log(error);
      dispatch({ type: actionType.END_LOADING });
      return null
    }
  };


  export const searchUsersFollowing = (username, filterType, secondaryFilterType, page, back = null, loader = true, skip = 10) => async (dispatch) => {
    const actionKey = `searchUsersFollowing-${filterType}`; // Unique key for this type of action
    const debouncedDispatch = debounceDispatch(async () => {
      try {
        loader && dispatch({ type: actionType.START_LOADING_FOLLOWING });
        const { data } = await api.searchUsersFollowing(username, filterType, secondaryFilterType, page);
        if (data.users.length > 0) {
          if(back === null){
            
              // first page
              dispatch({ type: actionType.SEARCH_USERS_FOLLOWING, payload: data.users });
            
          }else if(back === false){
            
              const oldUsers = store.getState().user_reducer.usersFollowing
              // go forward in pages
              const updatedUsers = [
                  ...oldUsers.slice(-skip), // Get last '5' items from old list (prev page)
                  ...data.users // New items
              ];
              const uniqueUsers = Array.from(new Set(updatedUsers.map(user => JSON.stringify(user)))).map(user => JSON.parse(user));

              dispatch({ type: actionType.SEARCH_USERS_FOLLOWING, payload: uniqueUsers });
          
          }else if(back === true){
            
              // go back in pages
              const oldUsers = store.getState().user_reducer.usersFollowing
              const updatedUsers = [
                  ...data.users, // New items
                  ...oldUsers.slice(0,skip) // Get first '5' items from old list (next page)
              ];
              const uniqueUsers = Array.from(new Set(updatedUsers.map(user => JSON.stringify(user)))).map(user => JSON.parse(user));

              dispatch({ type: actionType.SEARCH_USERS_FOLLOWING, payload: uniqueUsers });
  
          } 
          loader && dispatch({ type: actionType.END_LOADING_FOLLOWING });
          return data.users
        }else{
          loader && dispatch({ type: actionType.END_LOADING_FOLLOWING });
          return null
        }
      } catch (error) {
        console.log(error);
        loader && dispatch({ type: actionType.END_LOADING_FOLLOWING });
        return null
      }
    }, 700, actionKey); // 1-second delay and pass the unique key

    return debouncedDispatch();
  };

  export const searchUsersAll = (username, filterType, secondaryFilterType, page, back = null, loader = true, skip = 10) => async (dispatch) => {
    
    const actionKey = `searchUsersAll-${filterType}`; // Unique key for this type of action
    const debouncedDispatch = debounceDispatch(async () => {
      try {
        loader && dispatch({ type: actionType.START_LOADING_ALL });
        const { data } = await api.searchUsersAll(username, filterType, secondaryFilterType, page);
        if (data.users.length > 0) {
          if(back === null){
            
              // first page
              dispatch({ type: actionType.SEARCH_USERS_ALL, payload: data.users });
            
          }else if(back === false){
            
              const oldUsers = store.getState().user_reducer.usersAll
              // go forward in pages
              const updatedUsers = [
                  ...oldUsers.slice(-skip), // Get last '5' items from old list (prev page)
                  ...data.users // New items
              ];
              const uniqueUsers = Array.from(new Set(updatedUsers.map(user => JSON.stringify(user)))).map(user => JSON.parse(user));
              dispatch({ type: actionType.SEARCH_USERS_ALL, payload: uniqueUsers });
          
          }else if(back === true){
            
              // go back in pages
              const oldUsers = store.getState().user_reducer.usersAll
              const updatedUsers = [
                  ...data.users, // New items
                  ...oldUsers.slice(0,skip) // Get first '5' items from old list (next page)
              ];
              const uniqueUsers = Array.from(new Set(updatedUsers.map(user => JSON.stringify(user)))).map(user => JSON.parse(user));
              dispatch({ type: actionType.SEARCH_USERS_ALL, payload: uniqueUsers });
  
          }
          loader && dispatch({ type: actionType.END_LOADING_ALL });
          return data.users
        }else{
          loader && dispatch({ type: actionType.END_LOADING_ALL });
          return null
        } 

      } catch (error) {
        console.log(error);
        loader && dispatch({ type: actionType.END_LOADING_ALL });
        return null
      }
    }, 700, actionKey); // 1-second delay and pass the unique key

    return debouncedDispatch();
  };

  export const savePhoneNumber = (phone) => async (dispatch) => {

    try {
      dispatch({ type: actionType.START_LOADING_PHONE });
      dispatch({ type: "ERROR_MSG_PHONE", payload: '' });
      const { data } = await api.savePhoneNum(phone);
      if(data?.success == true){
        // get new auth token with phone number in it
        store.dispatch(refreshToken())
        //store.dispatch(sendSMS())
      }else{
        dispatch({ type: "ERROR_MSG_PHONE", payload: data.message });
      }
      dispatch({ type: actionType.END_LOADING_PHONE });
    } catch (error) {
      dispatch({ type: actionType.END_LOADING_PHONE });
      console.log(error);
    }
  };

  export const setErrorMessagePhone = (message) => async (dispatch) => {
    
    try {
      dispatch({ type: "ERROR_MSG_PHONE", payload: message });
    } catch (error) {
      console.log(error);
   
    }
  };

  export const setErrorMessageCode = (message) => async (dispatch) => {
    
    try {
      dispatch({ type: "ERROR_MSG_CODE", payload: message });
    } catch (error) {
      console.log(error);
   
    }
  };

  export const sendSMS = () => async (dispatch) => {

    try {
      dispatch({ type: actionType.START_LOADING_PHONE });
      dispatch({ type: "ERROR_MSG_PHONE", payload: '' });
      dispatch({ type: "ERROR_MSG_CODE", payload: '' });
      const { data } = await api.sendSMS();
      if(data?.success == true){
        dispatch({ type: "UPDATE_TIMESTAMP", payload: data?.timestamp });
      }else{
        if(data?.flag === 'phone'){
          dispatch({ type: "ERROR_MSG_PHONE", payload: data?.message });
        }else{
          dispatch({ type: "ERROR_MSG_CODE", payload: data?.message });
        }
      }
      dispatch({ type: actionType.END_LOADING_PHONE });
    } catch (error) {
      dispatch({ type: actionType.END_LOADING_PHONE });
      console.log(error);
    }
  };

  export const verifySMSCode = (code) => async (dispatch) => {
    
    try {
      dispatch({ type: actionType.START_LOADING_PHONE });
      dispatch({ type: "ERROR_MSG_PHONE", payload: '' });
      dispatch({ type: "ERROR_MSG_CODE", payload: '' });
      const { data } = await api.verifySMSCode(code);
      if(data?.success == true){
        // get new auth token with phone number in it
        store.dispatch(refreshToken())
        dispatch({ type: actionType.END_LOADING_PHONE });
        return true
      }else{
        dispatch({ type: "ERROR_MSG_CODE", payload: data?.message });
        dispatch({ type: actionType.END_LOADING_PHONE });
        return false
      }

    } catch (error) {
      dispatch({ type: actionType.END_LOADING_PHONE });
      return false
      console.log(error);
    }
  };


  export const processPayment = (amount, currency, buyerUsername, sellerUsername, postId, purchaseType) => async (dispatch) => {
    
    try {
      dispatch({ type: actionType.START_LOADING });
      dispatch({ type: "ERROR_MSG_PAYMENT", payload: '' });
      const { data } = await api.processPayment(amount, currency, buyerUsername, sellerUsername, postId, purchaseType);
      if(data?.success == true){
        // get new picks
        dispatch({ type: actionType.END_LOADING });
        return data
      }else{
        dispatch({ type: "ERROR_MSG_PAYMENT", payload: data?.message });
        dispatch({ type: actionType.END_LOADING });
        return null
      }
      
    } catch (error) {
      dispatch({ type: actionType.END_LOADING });
      console.log(error);
      return null
    }
  };