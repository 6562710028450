
import { createRef, useEffect, useState, React } from 'react';
import { useDispatch, useSelector, connect, ReactReduxContext, } from 'react-redux';
import { createState, pushRoute, updateState, } from '../../../State/redux/actions/StateActions.js';
import "../../../Assets/css/pick-card.css"

import tennis from '../../../Assets/icons/tennis.png';
import packers from '../../../Assets/teams/nfl/packers.png';
import football from '../../../Assets/icons/football.png';

import loseCancel from '../../../Assets/icons/cancel.png'
import winCheck from '../../../Assets/icons/checkmark.png';
import baseball from '../../../Assets/icons/baseball.png';
import hockey from '../../../Assets/icons/hockey.png';
import soccer from '../../../Assets/icons/soccer.png';
import golf from '../../../Assets/icons/golf.png';
import rugby from '../../../Assets/icons/rugby.png';
import cricket from '../../../Assets/icons/cricket.png';
import mma from '../../../Assets/icons/mma-glove.png';
import basketball from '../../../Assets/icons/basketball.png';
import boxing from '../../../Assets/icons/boxing.png';
import politics from '../../../Assets/icons/politics.png';


import trophy from '../../../Assets/icons/trophy.png';

import { useNavigate } from 'react-router-dom';
import { getLogosOddsJam } from '../../../State/redux/actions/PickActions.js';
import { stringToColor } from '../../../Utilities/UtilityFunctions.js';

// app component
function PickRow({id, leg, last}) {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {pick, date, sport, group, game, market, graded, win} = leg

  // component did mount
  useEffect(() => {
    

  }, [])

  // component did update
  useEffect(() => {

  })

  const getTeamPlayerLogo = (logo, name) => {
    
    return (
        (logo !== null && String(logo).includes('http') ?
            <img src={logo} className='team-img'/>
        :
            <div className='team-img-default' style={{backgroundColor: `${stringToColor(name)}`}} >
              {name.charAt(0)}
            </div>
        )
    )
};


  const sportIcon = () =>{
    switch(sport){
      case "mma":
        return <img src={mma} className='sport-img'/>
         
      case "basketball":
        return <img src={basketball} className='sport-img'/>
         
      case "baseball":
        return <img src={baseball} className='sport-img'/>
         
      case "football":
        return <img src={football} className='sport-img'/>
          
      case "hockey":
        return <img src={hockey} className='sport-img'/>
         
      case "rugby":
        return <img src={rugby} className='sport-img'/>
          
      case "Aussie Rules":
        return <img src={football} className='sport-img'/>
         
      case "cricket":
        return <img src={cricket} className='sport-img'/>
         
      case "boxing":
        return <img src={boxing} className='sport-img'/>
         
      case "golf":
        return <img src={golf} className='sport-img'/>
         
      case "politics":
        return <img src={politics} className='sport-img'/>
         
      case "soccer":
        return <img src={soccer} className='sport-img'/>
      case "tennis":
        return <img src={tennis} className='sport-img'/>
        
      default:
        return <img src={football} className='sport-img'/>
    }

  }

  const routeTo = (route) => {
        
    dispatch(pushRoute(route));
    navigate(route)
}

    return(
  
      <div className={'row-picks'}>
        {!sport.includes('Winner') ? 
        <div className='team-icons'>
          <div className='team-img-container'>
           { getTeamPlayerLogo(leg?.logos?.length && leg?.logos[0], leg.teams?.length ? leg.teams[0] : 'a')}
          </div>
          { leg.teams?.length > 1 &&
          <div>vs</div>}
          { leg.teams?.length > 1 && 
            <div className='team-img-container'>
            { getTeamPlayerLogo(leg?.logos?.length && leg?.logos[1], leg.teams?.length ? leg.teams[1] : 'b')}
            </div>}
        </div>
        :
        <div className='team-icons'>
          <div className='team-img-container'>
            <img src={trophy} className='team-img'/>
          </div>
        </div>}

        <div className={leg.teams?.length > 1? 'pick-details':'pick-details centered-column'}>
          <div>
            {game == '' ? sport : game}
          </div>
          <div>
            {market}
          </div>
          <div style={{fontWeight:'bold', fontSize:'12px'}}>
            Pick: {pick}
          </div>
        </div>

        <div className='sport-img-container'>
          <div className='sport-icon'>
            {graded ?
              ( win ?
                  <img src={winCheck} className='sport-img'/>
                :
                  <img src={loseCancel} className='sport-img'/>
              )
            :
              sportIcon()}
          </div>
          <div>{date}</div>
         
        </div>
      </div>  
    )
  

}

export default PickRow;
