import axios from 'axios';
import {store} from '../redux/reducers/globalReducers.js'
import { refreshToken } from '../redux/actions/AuthActions.js';

const path = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:8080` : ''}`;

const API = axios.create({ baseURL: path});

API.interceptors.request.use((req) => {
  if (localStorage.getItem('profile')) {
    req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`;
  }

  return req;
});

// Response interceptor for handling 401 errors - get a refresh token
API.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;

    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
       
        //await store.dispatch(alertUser('Refreshing session token...'));
        
        // Dispatch an action to refresh the token and wait for it to complete
        var success = await store.dispatch(refreshToken());
        if(success){
          //await store.dispatch(alertUser('Successfully restored session'));
          // Retrieve the new token from the store or wherever it is stored
          const newToken = store.getState().auth_reducer.token;

          // Update the header with the new token
          originalRequest.headers['Authorization'] = `Bearer ${newToken}`;

          // Retry the original request with the new token
          return API(originalRequest);
        }

      } catch (refreshError) {
        // Handle failed token refresh here
        // go to login screen
        return Promise.reject(refreshError);
      }
    }
    // go to login screen
    return Promise.reject(error);
  }
);


{/* AUTH */}
export const signIn = (formData) => API.post('/auth/v1/auth/signin', formData);
export const signUp = (formData) => API.post('/auth/v1/auth/signup', formData);
export const logout = (user) => API.post('/auth/v1/auth/logout', user);
export const signupWithGoogle = (user) => API.post('/auth/v1/auth/signup-with-google', user);
export const refreshSessionToken = (token) => API.post('/auth/v1/auth/refreshToken', {token: token});
export const refreshSessionTokenForNewUsername = (token, newUsername) => API.post('/auth/v1/auth/refreshTokenForNewUsername', {token: token, newUsername: newUsername});

{/* Picks */}
export const fetchPick = (id) => API.get(`/api/v1/picks/${id}`);
export const fetchPicks = (page) => API.get(`/api/v1/picks?page=${page}`);
export const fetchPicksByCreator = (username, filter, page, numberOfLegs) => API.post(`/api/v1/picks/profile`,{username, filter, page, numberOfLegs});
export const fetchPicksBySearch = (searchQuery) => API.get(`/api/v1/picks/search?searchQuery=${searchQuery.search || 'none'}&tags=${searchQuery.tags}`);
export const createPick = (newPick) => API.post('/api/v1/picks', newPick);
export const likePick = (id, user) => API.patch(`/api/v1/picks/${id}/likePick`, user);
export const comment = (name, msg, date, id) => API.post(`/api/v1/picks/${id}/commentPick`, { name, msg, date,});
export const reply = (name, msg, date, id, path) => API.post(`/api/v1/picks/${id}/replyComment`, { name, msg, date, path});
export const likeReply = (name, id, path) => API.post(`/api/v1/picks/${id}/likeReply`, { name, path});

export const updatePick = (id, updatedPick) => API.patch(`/api/v1/picks/${id}`, updatedPick);
export const deletePick = (id) => API.delete(`/api/v1/picks/${id}`);
export const getSports = () => API.get(`/api/v1/picks/getSports`);
export const getGames = (sport) => API.get(`/api/v1/picks/getGames?sport=${sport}`);
export const getOdds = (sport, event, market) => API.get(`/api/v1/picks/getOdds?sport=${sport}&event=${event}&market=${market}`);

export const getSportsOddsJam = () => API.get(`/api/v1/picks/getSportsOddsJam`);
export const getGradedSportsOddsJam = () => API.get(`/api/v1/picks/getGradedSportsOddsJam`);
export const getGamesOddsJam = (league) => API.get(`/api/v1/picks/getGamesOddsJam?league=${league}`);
export const getOddsOddsJam = (game_id, market_name) => API.get(`/api/v1/picks/getOddsOddsJam?game_id=${game_id}&market_name=${market_name}`);
export const getLogosOddsJam = (name, league, player) => API.get(`/api/v1/picks/getLogosOddsJam?name=${name}&league=${league}&player=${player}`);
export const getMarketOddsJam = (game_id) => API.get(`/api/v1/picks/getMarketsOddsJam?game_id=${game_id}`);
export const getFuturesOddsJam = (league) => API.get(`/api/v1/picks/getFuturesOddsJam?league=${league}`);
export const getFuturesOddsOddsJam = (future_id, league) => API.get(`/api/v1/picks/getFuturesOddsOddsJam?future_id=${future_id}&league=${league}`);

export const getPicksFromScreenshot = (photo) => API.post('/api/v1/picks/getPicksFromScreenshot',{photo});
export const searchPicks = (substring) => API.post('/api/v1/picks/searchPicks', {substring});
export const searchPicksFollowing = (substring, filterType, secondaryFilterType, page, legs) => API.post('/api/v1/picks/searchPicksFollowing', {substring, filterType, secondaryFilterType, page, legs});
export const searchPicksAll = (substring, filterType, secondaryFilterType, page, legs) => API.post('/api/v1/picks/searchPicksAll', {substring, filterType, secondaryFilterType, page, legs});

{/* Users */}
export const followUser = (follower, user) => API.post('/api/v1/users/followUser', {follower, user});
export const getProfile = (username) => API.post('/api/v1/users/getProfile', {username});
export const checkUsername = (username) => API.post('/api/v1/users/checkUsername', {username});
export const updateProfile = (bio, tags, profilePhoto, username, oldUsername) => API.post('/api/v1/users/updateProfile', {bio, tags, profilePhoto, username, oldUsername});
export const updateUsername = (username, oldUsername, password) => API.post('/api/v1/users/updateUsername', {username, oldUsername, password});
export const updatePhone = (phone, password) => API.post('/api/v1/users/updatePhone', {phone, password});
export const updateEmail = (email, password) => API.post('/api/v1/users/updateEmail', {email, password});
export const updatePassword = (password, newPassword) => API.post('/api/v1/users/updatePassword', {password, newPassword});
export const nukeRecord = (password) => API.post('/api/v1/users/nukeRecord', {password});


export const searchUsers = (substring) => API.post('/api/v1/users/searchUsers', {substring});
export const getProfilePhotosForPicks = (usernames) => API.post('/api/v1/users/getProfilePhotosForPicks', {usernames});
export const searchUsersFollowing = (substring, filterType, secondaryFilterType, page) => API.post('/api/v1/users/searchUsersFollowing', {substring, filterType, secondaryFilterType, page});
export const searchUsersAll = (substring, filterType, secondaryFilterType, page) => API.post('/api/v1/users/searchUsersAll', {substring, filterType, secondaryFilterType, page});
export const savePhoneNum = (phone) => API.post('/api/v1/users/savePhoneNum', {phone});
export const sendSMS = () => API.post('/api/v1/users/sendSMS',);
export const verifySMSCode = (code) => API.post('/api/v1/users/verifySMSCode',{code});
export const processPayment = (amount, currency, buyerUsername, sellerUsername, postId, purchaseType) => API.post('/api/v1/payments/processPayment',{amount, currency, buyerUsername, sellerUsername, postId, purchaseType});
