
import * as actionType from './ActionTypes';
import * as api from '../../api/api.js';
import { jwtDecode } from 'jwt-decode';
import { AuthState } from '../model/AuthState.js';
import { epochsTimeDifference, epochsToDateTime, hasOneMonthPassed, splitName } from '../../../Utilities/UtilityFunctions';
import {store} from '../reducers/globalReducers.js'
import { alertUser, rerouteApp, resetRoutes, setVerifyPopup } from './StateActions';
import { errorMessage } from './PickActions';


// @TODO: SAVE STATS, PROFILE, CONVOS ETC

export const loginWithGoogle = (token, width, height, browser) => async (dispatch) =>{

  try{
    
    if(token){
      
      // decode token from google login
      const decodedDataGoogle = jwtDecode(token);
      
      // add to authState model
      var form = new AuthState();
      form.email = decodedDataGoogle.email
      const {firstName, lastName} = splitName(decodedDataGoogle.name)
      form.firstName = firstName
      form.lastName = lastName
      // form.photo = decodedDataGoogle.picture url expires, dont want to save this
      form.width = width
      form.height = height
      form.browser = browser

      // @TODO: get proper data

      // singup with our server
      const { data } = await api.signupWithGoogle(form);
      if(data?.token){
        var authState = new AuthState();
        const decodedDataServer = jwtDecode(data.token);
        
        authState.email = decodedDataServer.email
        authState.firstName = decodedDataServer.firstName
        authState.lastName = decodedDataServer.lastName
        // authState.photo = decodedData.profilePhoto way too big to put in token
        authState.loggedIn = true;
        authState.token = data.token;
        authState.googleSignin = true;
        authState.tokenStart = epochsToDateTime(decodedDataServer.iat)
        authState.tokenExpiry = epochsToDateTime(decodedDataServer.exp)
        authState.tokenDuration = epochsTimeDifference(decodedDataServer.iat, decodedDataServer.exp)
        authState.email_verified = decodedDataServer.verifiedEmail
        authState.phone_verified = decodedDataServer.verifiedPhone
        authState.paymentSetup = decodedDataServer.paymentSetup
        authState.withdrawalSetup = decodedDataServer.withdrawalSetup
        authState.phone = decodedDataServer.phone
        authState.id = decodedDataServer.id
        authState.sessionId = decodedDataServer.sessionId
        authState.username = decodedDataServer.username
        authState.errorMessage = ''
        authState.conversations = decodedDataServer.conversations
        authState.currency = decodedDataServer.currency
        authState.statistics = decodedDataServer.statistics
        authState.units = decodedDataServer.units
        authState.oddsDisplay = decodedDataServer.oddsDisplay
        authState.darkMode = decodedDataServer.darkMode
        authState.lastStatisticsNuke = decodedDataServer.lastStatisticsNuke
        
        // save to state
        dispatch(loginWithServer(authState));

        // if we are signed in and our phone number is not verified with an sms code, show the popup
        if(authState && !authState.phone_verified){
          store.dispatch(setVerifyPopup(true))
        }else{
          store.dispatch(setVerifyPopup(false))
        }
        
        return true

      }else{
        
        authState.errorMessage = data.errorMessage
        // save to state
        dispatch(loginWithServer(authState));
        return false
      }
    }
  } catch (error) {
    console.log(error);
    return false
  }
}

export const signin = (formData, width, height, browser, navigate) => async (dispatch) => {
    try {

      formData.width = width
      formData.height = height
      formData.browser = browser
      const { data } = await api.signIn(formData);
      
      if(!data?.token){

        var authState = new AuthState();
        authState.errorMessage = data?.message || 'Something went wrong, check your connection to the server'
        dispatch(loginWithServer(authState));
      
      }else{

        var authState = new AuthState();
        const decodedData = jwtDecode(data?.token);
        authState.loggedIn = true;
        authState.token = data.token;
        authState.googleSignin = decodedData.googleSignin;
        authState.tokenStart = epochsToDateTime(decodedData.iat)
        authState.tokenExpiry = epochsToDateTime(decodedData.exp)
        authState.tokenDuration = epochsTimeDifference(decodedData.iat, decodedData.exp)
        authState.firstName = decodedData.firstName
        authState.lastName = decodedData.lastName
        authState.username = decodedData.username
        authState.email = decodedData.email
        authState.email_verified = decodedData.verifiedEmail
        authState.phone_verified = decodedData.verifiedPhone
        authState.paymentSetup = decodedData.paymentSetup
        authState.withdrawalSetup = decodedData.withdrawalSetup
        authState.phone = decodedData.phone
        // authState.photo = decodedData.profilePhoto way too big to put in token
        authState.photo = ''
        authState.id = decodedData.id
        authState.sessionId = decodedData.sessionId
        authState.errorMessage = ''
        authState.conversations = decodedData.conversations
        authState.currency = decodedData.currency
        authState.statistics = decodedData.statistics
        authState.units = decodedData.units
        authState.oddsDisplay = decodedData.oddsDisplay
        authState.darkMode = decodedData.darkMode
        authState.lastStatisticsNuke = decodedData.lastStatisticsNuke

        dispatch(loginWithServer(authState));
        dispatch(resetRoutes())
        navigate('/');

        // if we are signed in and our phone number is not verified with an sms code, show the popup
        if(authState && !authState.phone_verified){
          store.dispatch(setVerifyPopup(true))
        }else{
          store.dispatch(setVerifyPopup(false))
        }
      }
      
    } catch (error) {
      console.log(error);
    }
  };

  export const signinFromToken = (token, width, height, browser) => async (dispatch) => {
    try {

      token.width = width
      token.height = height
      token.browser = browser
      
      var result
      if(token.googleSignin){
        result = await api.signupWithGoogle(token);
      }else{
        result = await api.signIn(token);
      }
      const data = result.data

      if(!data?.token){

        var authState = new AuthState();
        authState.errorMessage = data?.message || 'Something went wrong, check your connection to the server'
        dispatch(loginWithServer(authState));
      
      }else{

        var authState = new AuthState();
        const decodedData = jwtDecode(data?.token);
        authState.loggedIn = true;
        authState.token = data.token;
        authState.googleSignin = decodedData.googleSignin;
        authState.tokenStart = epochsToDateTime(decodedData.iat)
        authState.tokenExpiry = epochsToDateTime(decodedData.exp)
        authState.tokenDuration = epochsTimeDifference(decodedData.iat, decodedData.exp)
        authState.firstName = decodedData.firstName
        authState.lastName = decodedData.lastName
        authState.username = decodedData.username
        authState.email = decodedData.email
        authState.email_verified = decodedData.verifiedEmail
        authState.phone_verified = decodedData.verifiedPhone
        authState.paymentSetup = decodedData.paymentSetup
        authState.withdrawalSetup = decodedData.withdrawalSetup
        authState.phone = decodedData.phone
        // authState.photo = decodedData.profilePhoto way too big to put in token
        authState.id = decodedData.id
        authState.sessionId = decodedData.sessionId
        authState.errorMessage = ''
        authState.conversations = decodedData.conversations
        authState.currency = decodedData.currency
        authState.statistics = decodedData.statistics
        authState.units = decodedData.units
        authState.oddsDisplay = decodedData.oddsDisplay
        authState.darkMode = decodedData.darkMode
        authState.lastStatisticsNuke = decodedData.lastStatisticsNuke
        dispatch(loginWithServer(authState));
      }
      
    } catch (error) {
      console.log(error);
    }
  };
  
  export const signup = (formData, width, height, browser, navigate) => async (dispatch) => {
    try {
      
      formData.width = width
      formData.height = height
      formData.browser = browser
      const { data } = await api.signUp(formData);
      
      if(!data?.token){
        
        var authState = new AuthState();
        authState.errorMessage = data?.message || 'Something went wrong, check your connection to the server'
        dispatch(loginWithServer(authState));

      }else{
        
        var authState = new AuthState();
        const decodedData = jwtDecode(data?.token);
        authState.loggedIn = true;
        authState.token = data.token;
        authState.googleSignin = decodedData.googleSignin;
        authState.tokenStart = epochsToDateTime(decodedData.iat)
        authState.tokenExpiry = epochsToDateTime(decodedData.exp)
        authState.tokenDuration = epochsTimeDifference(decodedData.iat, decodedData.exp)
        authState.firstName = decodedData.firstName
        authState.lastName = decodedData.lastName
        authState.username = decodedData.username
        authState.email = decodedData.email
        authState.email_verified = decodedData.verifiedEmail
        authState.phone_verified = decodedData.verifiedPhone
        authState.paymentSetup = decodedData.paymentSetup
        authState.withdrawalSetup = decodedData.withdrawalSetup
        authState.phone = decodedData.phone
        // authState.photo = decodedData.profilePhoto way too big to put in token
        authState.id = decodedData.id
        authState.sessionId = decodedData.sessionId
        authState.errorMessage = ''
        authState.conversations = decodedData.conversations
        authState.currency = decodedData.currency
        authState.statistics = decodedData.statistics
        authState.units = decodedData.units
        authState.oddsDisplay = decodedData.oddsDisplay
        authState.darkMode = decodedData.darkMode
        authState.lastStatisticsNuke = decodedData.lastStatisticsNuke

        dispatch(loginWithServer(authState));
        dispatch(resetRoutes())
        navigate('/');

        // if we are signed in and our phone number is not verified with an sms code, show the popup
        if(authState && !authState.phone_verified){
          store.dispatch(setVerifyPopup(true))
        }else{
          store.dispatch(setVerifyPopup(false))
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  export const loginWithServer = authState =>{

    return{
        type: actionType.AUTH,
        payload:{
            state: authState
        }
    }
  }

  export const logoutWithServer = (authState) => async (dispatch) =>{
     
      localStorage.clear();
      const { data } = await api.logout(authState);
      dispatch(logoutAuthState(authState))
  }
  
  export const logoutAuthState = authState =>{
    return{
        type: actionType.LOGOUT,
        payload:{
            state: authState
        }
    }
  }
  

  export const updateAuth = (token) => async (dispatch)=> {
    var authState = new AuthState();
    const decodedData = jwtDecode(token);
    authState.loggedIn = true;
    authState.token = token;
    authState.googleSignin = decodedData.googleSignin;
    authState.tokenStart = epochsToDateTime(decodedData.iat)
    authState.tokenExpiry = epochsToDateTime(decodedData.exp)
    authState.tokenDuration = epochsTimeDifference(decodedData.iat, decodedData.exp)
    authState.firstName = decodedData.firstName
    authState.lastName = decodedData.lastName
    authState.username = decodedData.username
    authState.email = decodedData.email
    authState.email_verified = decodedData.verifiedEmail
    authState.phone_verified = decodedData.verifiedPhone
    authState.paymentSetup = decodedData.paymentSetup
    authState.withdrawalSetup = decodedData.withdrawalSetup
    authState.phone = decodedData.phone
    // authState.photo = decodedData.profilePhoto way too big to put in token
    authState.id = decodedData.id
    authState.sessionId = decodedData.sessionId
    authState.errorMessage = ''
    authState.conversations = decodedData.conversations
    authState.currency = decodedData.currency
    authState.statistics = decodedData.statistics
    authState.units = decodedData.units
    authState.oddsDisplay = decodedData.oddsDisplay
    authState.darkMode = decodedData.darkMode
    authState.lastStatisticsNuke = decodedData.lastStatisticsNuke

    dispatch(loginWithServer(authState))
  }

  export const refreshToken = (reroute = true) => async () => {
    
    try {
      
      const token =  JSON.parse(localStorage.getItem('profile'));
      if(token){
   
        const dinosaur = hasOneMonthPassed(token.tokenStart)
        // if the token is less than a month old
        if(!dinosaur){
          
          // request new one
          const data = await api.refreshSessionToken(token.token);
          
          if(data && data?.data && data?.data?.token){
            const decodedData = jwtDecode(data?.data?.token);
            var authState = new AuthState();
            authState.loggedIn = true;
            authState.token = data.data.token;
            authState.googleSignin = decodedData.googleSignin;
            authState.tokenStart = epochsToDateTime(decodedData.iat)
            authState.tokenExpiry = epochsToDateTime(decodedData.exp)
            authState.tokenDuration = epochsTimeDifference(decodedData.iat, decodedData.exp)
            authState.firstName = decodedData.firstName
            authState.lastName = decodedData.lastName
            authState.username = decodedData.username
            authState.email = decodedData.email
            authState.email_verified = decodedData.verifiedEmail
            authState.phone_verified = decodedData.verifiedPhone
            authState.paymentSetup = decodedData.paymentSetup
            authState.withdrawalSetup = decodedData.withdrawalSetup
            authState.phone = decodedData.phone
            // authState.photo = decodedData.profilePhoto way too big to put in token
            authState.id = decodedData.id
            authState.sessionId = decodedData.sessionId
            authState.errorMessage = ''
            authState.conversations = decodedData.conversations
            authState.currency = decodedData.currency
            authState.statistics = decodedData.statistics
            authState.units = decodedData.units
            authState.oddsDisplay = decodedData.oddsDisplay
            authState.darkMode = decodedData.darkMode
            authState.lastStatisticsNuke = decodedData.lastStatisticsNuke

            store.dispatch(loginWithServer(authState));
            // call your initial request again, with new token
            return true
          }
        }
      }

      // no refresh allowed, go to login screen
      if(reroute){
        store.dispatch(rerouteApp('/login'))
        if(store.getState().auth_reducer.loggedIn){
          store.dispatch(alertUser('Session expired'))
        }
      }
      return false
      
    } catch (error) {
      // no refresh allowed, go to login screen
      if(reroute){
        store.dispatch(rerouteApp('/login'))
        if(store.getState().auth_reducer.loggedIn){
          store.dispatch(alertUser('Session expiredd'))
        }
      }
      console.log(error);
      return false
    }
  };


  export const refreshTokenForNewUsername = (newUsername) => async () => {
    
    try {
      
      const token =  JSON.parse(localStorage.getItem('profile'));
      
      if(token){
        const dinosaur = hasOneMonthPassed(token.tokenStart)
        // if the token is less than a month old
        if(!dinosaur){
          
          // request new one
          const data = await api.refreshSessionTokenForNewUsername(token.token, newUsername);
          
          if(data && data?.data && data?.data?.token){
            const decodedData = jwtDecode(data?.data?.token);
            var authState = new AuthState();
            authState.loggedIn = true;
            authState.token = data.data.token;
            authState.googleSignin = decodedData.googleSignin;
            authState.tokenStart = epochsToDateTime(decodedData.iat)
            authState.tokenExpiry = epochsToDateTime(decodedData.exp)
            authState.tokenDuration = epochsTimeDifference(decodedData.iat, decodedData.exp)
            authState.firstName = decodedData.firstName
            authState.lastName = decodedData.lastName
            authState.username = decodedData.username
            authState.email = decodedData.email
            authState.email_verified = decodedData.verifiedEmail
            authState.phone_verified = decodedData.verifiedPhone
            authState.paymentSetup = decodedData.paymentSetup
            authState.withdrawalSetup = decodedData.withdrawalSetup
            authState.phone = decodedData.phone
            // authState.photo = decodedData.profilePhoto way too big to put in token
            authState.id = decodedData.id
            authState.sessionId = decodedData.sessionId
            authState.errorMessage = ''
            authState.conversations = decodedData.conversations
            authState.currency = decodedData.currency
            authState.statistics = decodedData.statistics
            authState.units = decodedData.units
            authState.oddsDisplay = decodedData.oddsDisplay
            authState.darkMode = decodedData.darkMode
            authState.lastStatisticsNuke = decodedData.lastStatisticsNuke

            store.dispatch(loginWithServer(authState));
            // call your initial request again, with new token
            return true
          }
        }
      }
      // no refresh allowed, go to login screen
      store.dispatch(rerouteApp('/login'))
      store.dispatch(alertUser('Session expired'))
      return false
      
      
    } catch (error) {
      // no refresh allowed, go to login screen
      store.dispatch(rerouteApp('/login'))
      store.dispatch(alertUser('Session expired'))
      return false
    }
  };
