import React, { useState, useEffect } from 'react';
import '../../Assets/css/popup.css'; // Import the CSS file
import { removeAlert, updateState } from '../../State/redux/actions/StateActions';
import { useDispatch, useSelector } from 'react-redux';
import cancel from '../../Assets/icons/cancel-w.png';

function Popup({popupMessage}) {
  
    const dispatch = useDispatch();

    const [width, setWidth] = useState(0);

    useEffect(() => {
      const interval = setInterval(() => {
        setWidth((prevWidth) => {
          if (prevWidth < 100) {
            return prevWidth + 1;
          }
          clearInterval(interval);
          return prevWidth;
        });
      }, 30);
  
      const timeout = setTimeout(() => {
        onClose();
      }, 4500);
  
      return () => {
        clearInterval(interval);
        clearTimeout(timeout);
      };
    }, []);

    function onClose(){
        dispatch(removeAlert(popupMessage))
    }


    return (
       
        <div className="popup-banner">
            <div className="timer" style={{ width: `${width}%` }}></div>
            <div className="banner-img-container" onClick={onClose}><img width={25} src={cancel}/></div>
            <p className="popup-msg">{popupMessage}</p>
        </div>
     
    );
}

export default Popup;