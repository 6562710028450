
import { v4 as uuidv4 } from 'uuid';

const id = uuidv4();
const emptyLeg = {sport: '', group:'', date: '', sportsbook: '', game: '', market: '', pick: '', id: id, odds: 0, sportsOptions: [], gameOptions:[], marketOptions: [], oddsOptions: [], errorMessage: '', complete: false, tags: [], flag: false} 
const leg  = {sport: '', date: '', sportsbook: '', game: '', market: '', pick: '', id: '', odds: 0, sportsOptions: [], gameOptions:[], marketOptions: [], oddsOptions: [], screenShotted: false, errorMessage: '', complete: false, tags: [], flag: false, gameObject: {}, oddsObject: {}, league: '', bet_name: '', market_name: '', game_id: '', player_id: '', teams: [], logos: [], graded: false, win: null, tournament: '', future_id: ''}


class PickState {
    
    isLoading;
    picks;
    picksAll;
    picksFollowing;
    sports;
    games; 
    odds;
    errorMsg;
    legs; 
    tags;
    success;
    pickPost; 
    pick;
    profilePhotos;
    errorMsgScreenshot;
    isLoadingAllPicks;
    isLoadingFollowingPicks;
    isLoadingPicks;
    isLoadingScreenshot;
    parlayOdds;
    
    constructor (){
        this.isLoading = true; 
        this.picks = [];
        this.picksAll = [];
        this.picksFollowing = []; 
        this.sports = []; 
        this.games = []; 
        this.odds = []; 
        this.errorMsg =''; 
        this.legs = [leg]; 
        this.tags = []; 
        this.success =false; 
        this.pickPost =null; 
        this.pick = null;
        this.profilePhotos = [];
        this.errorMsgScreenshot = '';
        this.isLoadingAllPicks = true;
        this.isLoadingFollowingPicks = true;
        this.isLoadingPicks = true;
        this.isLoadingScreenshot = false;
        this.parlayOdds = 2.0;
    }

}

export {PickState}