
import { createRef, useEffect, useState, React } from 'react';
import { useDispatch, useSelector, connect, ReactReduxContext, } from 'react-redux';
import { createState, resetRoutes, updateState, } from '../../State/redux/actions/StateActions.js';
import { useNavigate } from 'react-router-dom';
import { GoogleLogin, GoogleLogout } from '@react-oauth/google'
import { signin, signup } from '../../State/redux/actions/AuthActions.js';
import { loginWithGoogle } from '../../State/redux/actions/AuthActions.js';
import "../../Assets/css/loginpage.css"
import eyeGrey from '../../Assets/icons/eye-grey.png';
import { TailSpin } from 'react-loader-spinner';
import { hasWhitespace, isEmailAddress, isPhoneNumber, isStrongPassword } from '../../Utilities/UtilityFunctions.js';

// Signup component
function SignupPage() {

  const initialState = { username: '', firstName: '', lastName: '', email: '', phone: 0, password: '', confirmPassword: '', agreement: false };
  const [form, setForm] = useState(initialState);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authState = useSelector(state => state.auth_reducer)
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('')
  
  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  }

  useEffect(()=>{
    setErrorMessage(authState?.errorMessage)
    setTimeout(()=>{
      setIsLoading(false)
    }, 3000)
    
  },[authState])

  const handleSubmit = (e) => {
    // @TODO: phone number and email validation
    if(form.username == ''|| form.firstName == ''|| form.lastName == ''|| form.email == ''|| form.phone == 0 || form.password == ''|| form.confirmPassword == ''){
      setErrorMessage('All fields are required to sign up')
    }else if(form.password != form.confirmPassword){
      setErrorMessage('Passwords must match')
    }else if(isStrongPassword(form.password) != 'Strong Password'){
      setErrorMessage(isStrongPassword(form.password))
    }else if(!form.agreement){
      setErrorMessage('You must agree with the terms in order to sign up')
    }else if(!isPhoneNumber(form.phone)){
      setErrorMessage('You must enter a real phone number')
    }else if(!isEmailAddress(form.email)){
      setErrorMessage('You must enter a real email address')
    }else if(form.username.includes(' ') || hasWhitespace(form.username)){
      setErrorMessage('Usernames cannot have spaces')
    }else if(form.username.length > 32){
      setErrorMessage('Usernames should have a maximum of 32 characters')
      // @TODO: SANITIZE FOR OTHER WEIRD CHARS
    }else{
      e.preventDefault();
      var browser = window.navigator.userAgent
      dispatch(signup(form, window.innerHeight, window.innerWidth, browser, navigate));
    }
  };

  const googleSuccess = async (res) => {
   
    const token = res?.credential;

    try {
      // save to state
      var browser = window.navigator.userAgent
      dispatch(loginWithGoogle(token, window.innerHeight, window.innerWidth, browser));
      // go to home page
      dispatch(resetRoutes())
      navigate('/');
    } catch (error) {
      console.log(error);
    }
  };

  const googleError = () => console.log('Google Sign In was unsuccessful. Try again later');

  const handleChange = (e) => setForm({ ...form, [e.target.name]: e.target.value });

  function routeTo(route){
    dispatch(resetRoutes())
    navigate(route);
  }

  return (
    <div className='login-body'>
      
      <h1 className={'header'}>Sign Up</h1>
      <div className={'form-container'} >
          
          {isLoading?
          <div className='form-container loading-wheel'>
            <TailSpin 
              height="80"
              width="80"
              color="#838383"
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
            :
          <>
            <input className={'form-input'} name="username" label="Username" placeholder="Username" onChange={handleChange}   />
            <input className={'form-input'} name="firstName" label="First Name" placeholder="First Name" onChange={handleChange}   />
            <input className={'form-input'} name="lastName" label="Last Name" placeholder="Last Name" onChange={handleChange}  />
            <input className={'form-input'} name="email" label="Email Address" placeholder="Email Address" onChange={handleChange} type="email" />
            <input className={'form-input'} name="phone" label="Phone Number" placeholder="Phone Number" onChange={handleChange} type="tel" />
            <div className={'input-row'}>
              <input className={'form-input'} name="password" label="Password" placeholder="Password" onChange={handleChange} type={showPassword ? 'text' : 'password'} />
              <div className={'show-password'} onClick={()=>{handleShowPassword()}}>
                <img src={eyeGrey} className='icon-dropdown-selection'/>
              </div>
            </div>
            <input className={'form-input'} name="confirmPassword" label="Repeat Password" placeholder="Repeat Password" onChange={handleChange} type={showPassword ? 'text' : 'password'} />
            <div className='input-row'>
              <input className={'form-input-checkbox'} name="agreement" label="Agree to Terms" onChange={handleChange} type={'checkbox'} defaultValue={false}/>
              <p>I agree to the <span onClick={()=>{}} className='sign-in-mini'>terms of use</span></p>
            </div> 
          </>}

          <p className='error-message'>{errorMessage}</p>
          <div className={'submit'} onClick={handleSubmit}>
            Sign Up
          </div>

          <h5 className={'small-title'}>
            Already have an account? &nbsp; 
            <span className='sign-in-mini' onClick={()=>{routeTo('/login')}}>
              Sign in
            </span>
          </h5> 
          <h5>
            Or
          </h5>
          <div className={'google-login'}>
            <GoogleLogin
              onSuccess={googleSuccess}
              onError={googleError}
            />
          </div>
      </div>
      
      
    </div>
    );

}

export default SignupPage;
