
// Defining the ChatState class
class ChatState {
  messages;
  lastMessage;
  conversations;
  showModal;
  participants;
  conversationName;
  conversationId;
  activeParticipants;
  conversationCreator;
  filters;
  usersSearch;
  read;
  profilePhotos;
  typing;
  connectionToggle;

  constructor() {
    this.messages = [];
    this.lastMessage = null;
    this.conversations = [];
    this.showModal = false;
    this.participants = [];
    this.conversationName = '';
    this.conversationId = '';
    this.activeParticipants = [];
    this.conversationCreator = '';
    this.filters = [];
    this.usersSearch = [];
    this.read = [];
    this.profilePhotos = [];
    this.typing = '';
    this.connectionToggle = false;
  }
}

// Exporting the ChatState class
export { ChatState };
