import { START_LOADING, END_LOADING, FETCH_ALL, FETCH_PICK, FETCH_BY_SEARCH, GET_PROFILE_PHOTOS_FOR_PICKS, CREATE, UPDATE, DELETE, LIKE, COMMENT, FETCH_BY_CREATOR, SPORTS, GAMES, MARKETS, ODDS, ERROR_MESSAGE, ADD_LEG, UPDATE_LEG, REMOVE_LEG, UPDATE_TAGS, REMOVE_TAG, REMOVE_TAGS, REMOVE_LEGS, REPLY, LIKE_COMMENT, LIKE_REPLY, UPDATE_ERROR_MSG_SCREENSHOT, SEARCH_PICKS, SEARCH_PICKS_FOLLOWING, START_LOADING_PICKS, END_LOADING_PICKS, START_LOADING_FOLLOWING_PICKS, END_LOADING_FOLLOWING_PICKS, START_LOADING_ALL_PICKS, END_LOADING_ALL_PICKS, START_LOADING_SCREENSHOT, END_LOADING_SCREENSHOT, LIKE_ALL, SET_PARLAY_ODDS } from './ActionTypes.js';
import * as api from '../../api/api.js';
import { customSort, getFormattedDate, hasFifteenMinutesPassed, removeDuplicates, sortOddsByPrice } from '../../../Utilities/UtilityFunctions.js';
import { v4 as uuidv4 } from 'uuid';
import {store} from '../reducers/globalReducers.js'


const debounceTimers = {};

const debounceDispatch = (func, delay, key) => {
    return (...args) => {
        if (debounceTimers[key]) {
            clearTimeout(debounceTimers[key]);
        }

        return new Promise((resolve, reject) => {
            debounceTimers[key] = setTimeout(() => {
                func(...args)
                    .then(resolve)
                    .catch(reject)
                    .finally(() => {
                        delete debounceTimers[key]; // Cleanup timer reference once done
                    });
            }, delay);
        });
    };
};

export const getPick = (id) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });

    const { data } = await api.fetchPick(id);

    dispatch({ type: FETCH_PICK, payload: { pick: data } });
    dispatch({ type: END_LOADING });

    if(data){
      return data
    }else{
      return null
    }
    
  } catch (error) {
    console.log(error);
    return null
  }
};

export const getPicks = (page) => async (dispatch) => {
  /*try {

    dispatch({ type: START_LOADING });
    const { data: { data, currentPage, numberOfPages } } = await api.fetchPicks(page);
    dispatch({ type: FETCH_ALL, payload: { data, currentPage, numberOfPages } });
    dispatch({ type: END_LOADING });
   
    if(data){
      return data
    }else{
      return null
    }

  } catch (error) {
    console.log(error);
    return null
  }*/
};

export const getPicksByCreator = (username, filter, page, numberOfLegs, back = null, loader=true, skip = 3) => async (dispatch) => {
  const actionKey = `getPicks`; // Unique key for this type of action
  const debouncedDispatch = debounceDispatch(async () => {
    try {
      loader && dispatch({ type: START_LOADING_PICKS });
      const { data: { data } } = await api.fetchPicksByCreator(username, filter, page, numberOfLegs);
   
      if (data.length > 0) {
        if(back === null){
            // first page
            dispatch({ type: FETCH_BY_CREATOR, payload: data });
          
        }else if(back === false){
          
            const oldPicks = store.getState().pick_reducer.picks
            // go forward in pages
            const updatedPicks = [
                ...oldPicks.slice(-skip), // Get last '5' items from old list (prev page)
                ...data // New items
            ];
            const uniquePosts = Array.from(new Set(updatedPicks.map(post => JSON.stringify(post)))).map(post => JSON.parse(post));
            dispatch({ type: FETCH_BY_CREATOR, payload: uniquePosts });
        
        }else if(back === true){
            // go back in pages
            const oldPicks = store.getState().pick_reducer.picks
            const updatedPicks = [
                ...data, // New items
                ...oldPicks.slice(0,skip) // Get first '5' items from old list (next page)
            ];
            const uniquePosts = Array.from(new Set(updatedPicks.map(post => JSON.stringify(post)))).map(post => JSON.parse(post));
            dispatch({ type: FETCH_BY_CREATOR, payload: uniquePosts });
        }
        loader && dispatch({ type: END_LOADING_PICKS });
        return data
      }else{
        loader && dispatch({ type: END_LOADING_PICKS });
        return null
      } 
      
    } catch (error) {
      console.log(error);
      return null
    }
  }, 700, actionKey); // 1-second delay
  return debouncedDispatch();

};

export const getPicksBySearch = (searchQuery) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING_PICKS });
    const { data: { data } } = await api.fetchPicksBySearch(searchQuery);

    dispatch({ type: FETCH_BY_SEARCH, payload: { data } });
    dispatch({ type: END_LOADING_PICKS });
  } catch (error) {
    console.log(error);
  }
};

export const createPick = (picks, navigate) => async (dispatch) => {
  const actionKey = `createPick`; // Unique key for this type of action
  const debouncedDispatch = debounceDispatch(async () => {
    try {
      dispatch({ type: START_LOADING });
      const { data } = await api.createPick(picks);
      if(data.success){
        dispatch({ type: CREATE, payload: data.data });
      }
      dispatch({ type: END_LOADING });
    } catch (error) {
      console.log(error);
      dispatch({ type: END_LOADING });
    }
  }, 700, actionKey); // 1-second delay
  debouncedDispatch();
};

export const updatePick = (id, pick) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.updatePick(id, pick);
    dispatch({ type: UPDATE, payload: data });
    dispatch({ type: END_LOADING });

  } catch (error) {
    console.log(error);
  }
};

export const likePick = (id) => async (dispatch) => {
  const user = JSON.parse(localStorage.getItem('profile'));

  try {
    if(user){
      const { data } = await api.likePick(id, user);

      dispatch({ type: LIKE, payload: data });
    }else{
      // @TODO: SIGN IN /SIGN UP
    }
  } catch (error) {
    console.log(error);
  }
};

export const commentPick = (name, comment, date, id) => async (dispatch) => {
  try {
    const { data } = await api.comment(name, comment, date, id);

    dispatch({ type: COMMENT, payload: data });
    
    return data.comments;
  } catch (error) {
    console.log(error);
  }
};

export const replyComment = (name, comment, date, id, path) => async (dispatch) => {
  try {
    const { data } = await api.reply(name, comment, date, id, path);

    dispatch({ type: REPLY, payload: data });
    
    return data.comments;
  } catch (error) {
    console.log(error);
  }
};

export const likeReply = (name, id, path) => async (dispatch) => {
  
  try {
    const { data } = await api.likeReply(name, id, path);

    dispatch({ type: LIKE_REPLY, payload: data });
    
    return data.comments;
  } catch (error) {
    console.log(error);
  }
};

export const deletePick = (id) => async (dispatch) => {
  try {
    await api.deletePick(id);

    dispatch({ type: DELETE, payload: id });
  } catch (error) {
    console.log(error);
  }
};


export const getSports = (leg) => async (dispatch) => {
  
  try {
    let data = []
    const browserStorage = localStorage.getItem('sports')
    const {lastRetrieval, sports} = browserStorage != null && JSON.parse(browserStorage)

    // check if 15 minutes has passed (to refresh/update data) or if never retrieved sports before
    if(lastRetrieval == null || hasFifteenMinutesPassed(lastRetrieval)){
      // get sports from server
      const serverData = await api.getSports();
      data = serverData.data
      localStorage.setItem('sports', JSON.stringify({ lastRetrieval: new Date(), sports: data }));
    }else{
      // get sports from local/browser storage
      data = sports
    }

    // Filter our list of sports
    // only non-soccer leagues
    const options = data.map(sport=>{if(sport.group!='Soccer' && !sport.key.includes('winner')){return sport}}).filter(i=>i!=undefined)
    const optionsOutrights = data.map(sport=>{if(sport.key.includes('winner')){return sport}}).filter(i=>i!=undefined)
    // put soccer leagues at bottom of list
    const optionsSoccer = data.map(sport=>{if(sport.group=='Soccer'){return sport}}).filter(i=>i!=undefined)
    // sort alphabetically, with soccer at end
    let sortedSports = [...options.sort(customSort), ...optionsOutrights.sort()];
    sortedSports = [...sortedSports, ...optionsSoccer.sort()];

    // save to redux state
    leg.sportsOptions = sortedSports 
    dispatch({ type: UPDATE_LEG, payload: leg });

  } catch (error) {
    console.log(error);
  }
};

// last updated: Tues April 28th 2024
const GradedMarkets = {
  "Sports": ['basketball', 'football', 'hockey', 'baseball', 'soccer', 'tennis', 'mma', 'esports', 'golf'],
  "Leagues":[
    "NBA",
    "NCAAB",
    "NCAAW",
    "NHL",
    "NFL",
    "NCAAF",
    "UFL",
    "MLB",
    "NCAA",
    "DP World Tour",
    "PGA",
    "LIV",
    "UFC",
    "Dota 2",
    "League of Legends",
    "Valorant",
    "CS:GO",
    "ATP",
    "Australia - A-League",
    "Austria - 2. Liga",
    "Austria - Bundesliga",
    "Belgium - Jupiler Pro League",
    "Brazil - Serie A",
    "Brazil - Serie B",
    "China - Super League",
    "CONMEBOL - Copa Libertadores",
    "CONMEBOL - Copa Sudamericana",
    "Croatia - 1. HNL",
    "Czech Republic - FNL",
    "Denmark - Superliga",
    "England - Championship",
    "England - FA Cup",
    "England - League 1",
    "England - League 2",
    "England - Premier League",
    "Finland - Veikkausliiga",
    "France - Ligue 1",
    "France - Ligue 2",
    "Germany - Bundesliga",
    "Germany - Bundesliga 2",
    "Hungary - NB I",
    "Ireland - Premier League",
    "Italy - Serie A",
    "Italy - Serie B",
    "Japan - J1 League",
    "Japan - J2 League",
    "Korea - K1 League",
    "Korea - K2 League",
    "Mexico - Liga MX",
    "Netherlands - Eerste Divisie",
    "Netherlands - Eredivisie",
    "Poland - Ekstraklasa",
    "Poland - I Liga",
    "Portugal - Primeira Liga",
    "Portugal - Segunda Liga",
    "Romania - Liga I",
    "Romania - Liga II",
    "Saudi Arabia - Saudi League",
    "Scotland - Championship",
    "Scotland - Premiership",
    "Spain - La Liga",
    "Sweden - Allsvenskan",
    "Sweden - Superettan",
    "Switzerland - Challenge League",
    "Switzerland - Super League",
    "Turkey - 1. Lig",
    "Turkey - Super Lig",
    "UEFA - Champions League",
    "UEFA - Europa Conference League",
    "UEFA - Europa League",
    "Ukraine - Premier League",
    "USA - Major League Soccer",
    "WTA"
  ],
  "Basketball": {
  "SupportLeagues": ["NBA", "NCAAB", "NCAAW"],
  "GameMarkets": [
      "Moneyline",
      "Moneyline 3-Way",
      "1st Quarter Moneyline",
      "1st Quarter Moneyline 3-Way",
      "2nd Quarter Moneyline",
      "2nd Quarter Moneyline 3-Way",
      "1st Half Moneyline",
      "1st Half Moneyline 3-Way",
      "3rd Quarter Moneyline",
      "3rd Quarter Moneyline 3-Way",
      "4th Quarter Moneyline",
      "4th Quarter Moneyline 3-Way",
      "2nd Half Moneyline",
      "2nd Half Moneyline 3-Way",
      "Point Spread",
      "1st Quarter Point Spread",
      "2nd Quarter Point Spread",
      "1st Half Point Spread",
      "3rd Quarter Point Spread",
      "4th Quarter Point Spread",
      "2nd Half Point Spread",
      "Total Points",
      "1st Quarter Total Points",
      "2nd Quarter Total Points",
      "1st Half Total Points",
      "3rd Quarter Total Points",
      "4th Quarter Total Points",
      "2nd Half Total Points",
      "Team Total",
      "1st Quarter Team Total",
      "2nd Quarter Team Total",
      "1st Half Team Total",
      "3rd Quarter Team Total",
      "4th Quarter Team Total",
      "2nd Half Team Total"
  ],
  "PlayerMarkets": [
      "Player Points",
      "Player Assists",
      "Player Rebounds",
      "Player Offensive Rebounds",
      "Player Defensive Rebounds",
      "Player Steals",
      "Player Blocks",
      "Player Turnovers",
      "Player Points + Rebounds",
      "Player Points + Assists",
      "Player Rebounds + Assists",
      "Player Steals + Blocks",
      "Player Points + Rebounds + Assists",
      "Player Made Threes",
      "Player Free Throws Made",
      "Player Field Goals Attempted",
      "Player Threes Attempted",
      "Player Personal Fouls",
      "Player Minutes Played",
      "Player Double Double",
      "Player Triple Double",
      "First Basket",
      "First Basket Including FT"
  ]
  },
  "Football": {
  "SupportedLeagues": ["NFL", "NCAAF", "UFL"],
  "GameMarkets": [
      "Moneyline",
      "Moneyline 3-Way",
      "1st Quarter Moneyline",
      "1st Quarter Moneyline 3-Way",
      "2nd Quarter Moneyline",
      "2nd Quarter Moneyline 3-Way",
      "1st Half Moneyline",
      "1st Half Moneyline 3-Way",
      "3rd Quarter Moneyline",
      "3rd Quarter Moneyline 3-Way",
      "4th Quarter Moneyline",
      "4th Quarter Moneyline 3-Way",
      "2nd Half Moneyline",
      "2nd Half Moneyline 3-Way",
      "Point Spread",
      "1st Quarter Point Spread",
      "2nd Quarter Point Spread",
      "1st Half Point Spread",
      "3rd Quarter Point Spread",
      "4th Quarter Point Spread",
      "2nd Half Point Spread",
      "Total Points",
      "1st Quarter Total Points",
      "2nd Quarter Total Points",
      "1st Half Total Points",
      "3rd Quarter Total Points",
      "4th Quarter Total Points",
      "2nd Half Total Points",
      "Team Total",
      "1st Quarter Team Total",
      "2nd Quarter Team Total",
      "1st Half Team Total",
      "3rd Quarter Team Total",
      "4th Quarter Team Total",
      "2nd Half Team Total"
  ],
  "PlayerMarkets": [
      "Player Passing Yards",
      "Player Passing Completions",
      "Player Passing Attempts",
      "Player Passing Touchdowns",
      "Player Interceptions",
      "Player Defensive Interceptions",
      "Player Receptions",
      "Player Receiving Yards",
      "Player Receiving Touchdowns",
      "Player Receiving Targets",
      "Player Longest Reception",
      "Player Rushing Attempts",
      "Player Rushing Yards",
      "Player Rushing Touchdowns",
      "Player Longest Rush",
      "Player Kicking Points",
      "Player Field Goals Made",
      "Player Extra Points Made",
      "Player Tackles",
      "Player Sacks Taken",
      "Player Assists",
      "Player Tackles + Assists",
      "Player Rushing + Receiving Yards",
      "Player Passing + Rushing Yards",
      "Player Passing + Rushing + Receiving Touchdowns",
      "Player Touchdowns",
      "Player Punts",
      "Player Sacks",
      "First Touchdown Scorer",
      "Anytime Touchdown Scorer"
  ]
  },
  "Hockey": {
  "SupportedLeagues": ["NHL"],
  "GameMarkets": [
      "Moneyline",
      "Moneyline 3-Way",
      "1st Period Moneyline",
      "1st Period Moneyline 3-Way",
      "2nd Period Moneyline",
      "2nd Period Moneyline 3-Way",
      "3rd Period Moneyline",
      "3rd Period Moneyline 3-Way",
      "Puck Line",
      "Puck Line Reg Time",
      "1st Period Puck Line",
      "2nd Period Puck Line",
      "3rd Period Puck Line",
      "Total Goals",
      "Total Goals Reg Time",
      "1st Period Total Goals",
      "2nd Period Total Goals",
      "3rd Period Total Goals",
      "Team Total",
      "Team Total Reg Time",
      "1st Period Team Total",
      "2nd Period Team Total",
      "3rd Period Team Total",
      "Both Teams To Score",
      "Both Teams To Score Reg Time",
      "1st Period Both Teams To Score",
      "2nd Period Both Teams To Score",
      "3rd Period Both Teams To Score"
  ],
  "PlayerMarkets": [
      "Player Goals Against",
      "Player Saves",
      "Player Points",
      "Player Goals",
      "Player Assists",
      "Player Shots On Goal",
      "Player Hits",
      "Player Blocked Shots",
      "Anytime Goal Scorer"
  ]
  },
  "Baseball": {
  "SupportedLeagues": ["MLB", "NCAA"],
  "GameMarkets": [
      "Moneyline",
      "Moneyline 3-Way",
      "Run Line",
      "Total Runs",
      "Team Total",
      "1st Half Moneyline",
      "1st Half Moneyline 3-Way",
      "1st Half Run Line",
      "1st Half Total Runs",
      "1st Half Team Total",
      "1st 3 Innings Moneyline",
      "1st 3 Innings Moneyline 3-Way",
      "1st 3 Innings Run Line",
      "1st 3 Innings Total Runs",
      "1st 3 Innings Team Total",
      "1st 7 Innings Moneyline",
      "1st 7 Innings Moneyline 3-Way",
      "1st 7 Innings Run Line",
      "1st 7 Innings Total Runs",
      "1st 7 Innings Team Total",
      "1st Inning Moneyline",
      "1st Inning Moneyline 3-Way",
      "1st Inning Run Line",
      "1st Inning Total Runs",
      "1st Inning Team Total",
      "2nd Inning Moneyline",
      "2nd Inning Moneyline 3-Way",
      "2nd Inning Run Line",
      "2nd Inning Total Runs",
      "2nd Inning Team Total",
      "3rd Inning Moneyline",
      "3rd Inning Moneyline 3-Way",
      "3rd Inning Run Line",
      "3rd Inning Total Runs",
      "3rd Inning Team Total",
      "4th Inning Moneyline",
      "4th Inning Moneyline 3-Way",
      "4th Inning Run Line",
      "4th Inning Total Runs",
      "4th Inning Team Total",
      "5th Inning Moneyline",
      "5th Inning Moneyline 3-Way",
      "5th Inning Run Line",
      "5th Inning Total Runs",
      "5th Inning Team Total",
      "6th Inning Moneyline",
      "6th Inning Moneyline 3-Way",
      "6th Inning Run Line",
      "6th Inning Total Runs",
      "6th Inning Team Total",
      "7th Inning Moneyline",
      "7th Inning Moneyline 3-Way",
      "7th Inning Run Line",
      "7th Inning Total Runs",
      "7th Inning Team Total",
      "8th Inning Moneyline",
      "8th Inning Moneyline 3-Way",
      "8th Inning Run Line",
      "8th Inning Total Runs",
      "8th Inning Team Total",
      "9th Inning Moneyline",
      "9th Inning Moneyline 3-Way",
      "9th Inning Run Line",
      "9th Inning Total Runs",
      "9th Inning Team Total"
  ],
  "PlayerMarkets": [
      "Player RBIs",
      "Player Hits",
      "Player Runs",
      "Player Walks",
      "Player Home Runs",
      "Player Earned Runs",
      "Player Hits Allowed",
      "Player Strikeouts",
      "Player Batting Strikeouts",
      "Player Batting Walks",
      "Player Hits + Runs + RBIs",
      "Player Bases",
      "Player Singles",
      "Player Doubles",
      "Player Triples",
      "Player Outs",
      "Player To Record Win"
  ]
  },
  "Soccer": {
  "GameMarkets": [
      "Moneyline",
      "Draw No Bet",
      "Asian Handicap",
      "Total Goals",
      "Team Total",
      "Both Teams To Score",
      "1st Half Moneyline",
      "1st Half Draw No Bet",
      "1st Half Asian Handicap",
      "1st Half Total Goals",
      "1st Half Team Total",
      "1st Half Both Teams To Score",
      "2nd Half Moneyline",
      "2nd Half Draw No Bet",
      "2nd Half Asian Handicap",
      "2nd Half Total Goals",
      "2nd Half Team Total",
      "2nd Half Both Teams To Score"
  ],
  "PlayerMarkets": [
      "Player Clearances",
      "Player Goals",
      "Player Assists",
      "Player Passes Attempted",
      "Player Saves",
      "Player Shots Assisted",
      "Player Shots Attempted",
      "Player Shots On Target",
      "Player Tackles",
      "Player Fouls",
      "Player Fouls Drawn",
      "Player Interceptions"
  ]
  },
  "Tennis": {
  "GameMarkets": [
      "Moneyline",
      "Game Spread",
      "Total Games",
      "Total Sets",
      "Set Handicap",
      "1st Set Moneyline",
      "1st Set Game Spread",
      "1st Set Total Games",
      "2nd Set Moneyline",
      "2nd Set Game Spread",
      "2nd Set Total Games",
      "3rd Set Moneyline",
      "3rd Set Game Spread",
      "3rd Set Total Games",
      "4th Set Moneyline",
      "4th Set Game Spread",
      "4th Set Total Games",
      "5th Set Moneyline",
      "5th Set Game Spread",
      "5th Set Total Games"
  ]
  },
  "MMA": {
  "SupportedLeagues": ["UFC"],
  "GameMarkets": [
      "Moneyline",
      "Total Rounds",
      "Go The Distance",
      "Player Significant Strikes",
      "Player Takedowns"
  ]
  },
  "eSports": {
  "SupportedLeagues": ["Dota 2", "League of Legends", "Valorant", "CS:GO"],
  "PlayerMarkets": [
      "Player Kills",
      "1st 2 Maps Player Kills",
      "1st 3 Maps Player Kills",
      "1st Map Player Kills",
      "2nd Map Player Kills",
      "3rd Map Player Kills",
      "4th Map Player Kills",
      "5th Map Player Kills",
      "Player Headshots",
      "1st 2 Maps Player Headshots",
      "1st 3 Maps Player Headshots",
      "1st Map Player Headshots",
      "2nd Map Player Headshots",
      "3rd Map Player Headshots",
      "4th Map Player Headshots",
      "5th Map Player Headshots",
      "Player Deaths",
      "1st 2 Maps Player Deaths",
      "1st 3 Maps Player Deaths",
      "1st Map Player Deaths",
      "2nd Map Player Deaths",
      "3rd Map Player Deaths",
      "4th Map Player Deaths",
      "5th Map Player Deaths",
      "Player Assists",
      "1st 2 Maps Player Assists",
      "1st 3 Maps Player Assists",
      "1st Map Player Assists",
      "2nd Map Player Assists",
      "3rd Map Player Assists",
      "4th Map Player Assists",
      "5th Map Player Assists"
  ]
  },
  "Golf": {
  "Futures": []
  }
}

export const getSportsOddsJam = (leg) => async (dispatch) => {
  
  try {
    let data = []
    const browserStorage = localStorage.getItem('sports')
    const {lastRetrieval, sports} = browserStorage != null && JSON.parse(browserStorage)

    // check if 15 minutes has passed (to refresh/update data) or if never retrieved sports before
    if(lastRetrieval == null || hasFifteenMinutesPassed(lastRetrieval)){
      // get live sports from server (only gathers current leagues)
      const serverData = await api.getSportsOddsJam();
      data = serverData.data.data

      // get graded sports (sports that can be validated win or loss)
      var gradedSports = await api.getGradedSportsOddsJam();
      gradedSports = gradedSports.data.data
      data = data.filter(item=> gradedSports.includes(item))
      localStorage.setItem('sports', JSON.stringify({ lastRetrieval: new Date(), sports: data }));
    }else{
      // get sports from local/browser storage
      data = sports
    }
    
    // prioritize leagues
    var aList = ['NBA','NHL', 'NFL', 'MLB', 'UFC','ATP', 'WTA','NCAA','NCAAB', 'NCAAW','NCAAF','NCAA Lacrosse', 'PGA','LIV', 'DP World Tour', 'Champions Tour', 'College Baseball','WNBA', 'USA - National Lacrosse League', 'USA - Major League Soccer', 'KBO', 'CPBL', 'AFL', 'UFL',  'NPB','NRL','India - IPL', 'Valorant','CS2','CS:GO', 'Call of Duty', 'Dota 2', 'League of Legends']
    aList = aList.filter(item => data.includes(item));
    
    // get remaining leagues (soccer) and sort them alphabetically
    let remainingItems = data.filter(item => !aList.includes(item));
    remainingItems.sort();

    let finalData = aList.concat(remainingItems);
  
    // save to redux state
    leg.sportsOptions = finalData 
    dispatch({ type: UPDATE_LEG, payload: leg });

  } catch (error) {
    console.log(error);
  }
};

export const getGamesOddsJam = (sport, leg) => async (dispatch) => {
  
  try {
    let gamesResponse = await api.getGamesOddsJam(sport);
    let futuresResponse = await api.getFuturesOddsJam(sport);

    const games = gamesResponse.data.data.map(game=>{
      if(game.sport.includes('boxing') || game.sport.includes('mma')){
        return {...game, name: game.away_team+" vs "+game.home_team}
      }else{
        return {...game, name: game.away_team+" @ "+game.home_team}
      }
    })

    const futures = futuresResponse.data.data.data ?? []

    // only golf markets that can be graded are futures
    // and only futures markets that can be graded are golf
    // therefore, either we get the games or the futures depending if golf
    if(futures.length && futures[0].sport === 'golf'){
      leg.gameOptions = futures
    }else{
      leg.gameOptions = games
    }
    
    dispatch({ type: UPDATE_LEG, payload: leg });

  } catch (error) {
    console.log(error);
  }
};

export const getMarketsOddsJam = (game, leg) => async (dispatch) => {
  
  try {

    let markets = []
    let odds = []
    if(game.away_team){
      // match
      const marketsResponse = await api.getMarketOddsJam(game.id);
      markets = marketsResponse.data.data.data ?? []
      if(marketsResponse.data.error || markets == []){
        dispatch(errorMessage(marketsResponse.data.message, leg))
      }
      // add team names
      leg.teams = []
      leg.teams.push(game.away_team)
      leg.teams.push(game.home_team)
    }else{
      // futures / outright winners
      const futuresResponse = await api.getFuturesOddsOddsJam(game.id, game.league);
      odds = futuresResponse.data.data.data[0].odds ?? []
      if(futuresResponse.data.error || odds == []){
        dispatch(errorMessage(futuresResponse.data.message, leg))
      }else{
        // remove duplicates
        odds = removeDuplicates(odds)
        odds = sortOddsByPrice(odds)
      }
    }

    // refer to limitations on graded markets (validated bets) via google doc
    switch(leg.gameObject.sport){
      case 'basketball':
        markets = markets.filter(market=> GradedMarkets["Basketball"].GameMarkets.includes(market.label) || GradedMarkets["Basketball"].PlayerMarkets.includes(market.label))
        break;
      case 'football':
        markets = markets.filter(market=> GradedMarkets["Football"].GameMarkets.includes(market.label) || (GradedMarkets["Football"].PlayerMarkets.includes(market.label) && (leg.gameObject.league === 'NFL' || leg.gameObject.league === 'NCAAF')))
        break;
      case 'hockey':
        markets = markets.filter(market=> GradedMarkets["Hockey"].GameMarkets.includes(market.label) || (GradedMarkets["Hockey"].PlayerMarkets.includes(market.label)))
        break;
      case 'baseball':
        markets = markets.filter(market=> GradedMarkets["Baseball"].GameMarkets.includes(market.label) || (GradedMarkets["Baseball"].PlayerMarkets.includes(market.label) && leg.gameObject.league === 'MLB'))
        break;
      case 'mma':
        markets = markets.filter(market=> GradedMarkets["MMA"].GameMarkets.includes(market.label))
        break;
      case 'soccer':
        markets = markets.filter(market=> GradedMarkets["Soccer"].GameMarkets.includes(market.label) || GradedMarkets["Soccer"].PlayerMarkets.includes(market.label))
        break;
      case 'tennis':
        markets = markets.filter(market=> GradedMarkets["Tennis"].GameMarkets.includes(market.label))
        break;
      case 'esports':
        markets = markets.filter(market=> GradedMarkets["eSports"].PlayerMarkets.includes(market.label))
        break;
    }
    leg.marketOptions = markets
    leg.oddsOptions = odds
    dispatch({ type: UPDATE_LEG, payload: leg });

  } catch (error) {
    console.log(error);
  }
};

export const getOddsOddsJam = (game_id, market_name, leg) => async (dispatch) => {
  try {
    let data = await api.getOddsOddsJam(game_id, market_name);
    data = data.data.data.data[0].odds

    if(data.error || data == []){
      dispatch(errorMessage(data.message, leg))
    }else{
      data = removeDuplicates(data)
      data = sortOddsByPrice(data)
      leg.oddsOptions = data
      leg.errorMessage = ''

      dispatch({ type: UPDATE_LEG, payload: leg });
    }
    
  } catch (error) {
    console.log(error);
  }
};

export const getLogosOddsJam = (pick) => async (dispatch) => {
  try {

    // get team names
    let data = await api.getLogosOddsJam(pick.teams, pick.league, null);
    data = data.data.data.data
    if(data.error || data == []){
      
    }else{
      data.forEach((logo)=>{
        pick.logos.push(logo.logo)
      })
      dispatch({ type: FETCH_PICK, payload: { pick: pick } });
    }

    // @TODO: get player ids
   
    
  } catch (error) {
    console.log(error);
  }
};

export const getGames = (sport, leg) => async (dispatch) => {
  
  try {
    let data = await api.getGames(sport);
    //dispatch({ type: GAMES, payload: data.data });
    leg.gameOptions = data.data.map(game=>{
      if(sport.includes('boxing') || sport.includes('mma')){
        return {...game, title: game.away_team+" vs "+game.home_team}
      }else{
        return {...game, title: game.away_team+" @ "+game.home_team}
      }
    })
    
    dispatch({ type: UPDATE_LEG, payload: leg });

  } catch (error) {
    console.log(error);
  }
};

export const getMarkets = (sport, leg) => async (dispatch) => {
  try {
    
      let markets = []
      
      // outrights / futures
      if(sport.key.includes('winner')){
        
        markets = [{"key": "outrights", "name": "Outrights, Futures"}]
     
      // soccer
      }else if(sport.key.includes('soccer')){
          
        markets = [
          {"key": "h2h", "name": "Moneyline, Winner"},
          {"key": "spreads", "name": "Points spread, Handicap"},
          {"key": "totals", "name": "Total points/goals, Over/Under"},
          {"key": "outrights", "name": "Outrights, Futures"},
          {"key": "alternate_spreads", "name": "Alternate Spreads (handicap)"},
          {"key": "alternate_totals", "name": "Alternate Totals (Over/Under)"},
          {"key": "h2h_3_way", "name": "Moneyline 3 way (Draw Included)"},
          {"key": "btts", "name": "Both Teams to Score"},
          {"key": "draw_no_bet", "name": "Draw No Bet"},
          {"key": "h2h_h1", "name": "Moneyline 1st Half"},
          {"key": "h2h_h2", "name": "Moneyline 2nd Half"},
          {"key": "spreads_h1", "name": "Spreads 1st Half"},
          {"key": "spreads_h2", "name": "Spreads 2nd Half"},
          {"key": "totals_h1", "name": "Over/under 1st Half"},
          {"key": "totals_h2", "name": "Over/under 2nd Half"}
        ]
  
      // main sports
      }else{
        
        switch(sport.title){
          case 'NHL':
            markets = [
              {"key": "h2h", "name": "Moneyline, Winner"},
              {"key": "spreads", "name": "Points spread, Handicap"},
              {"key": "totals", "name": "Total goals, Over/Under"},
              {"key": "alternate_spreads", "name": "Alternate Spreads (handicap)"},
              {"key": "alternate_totals", "name": "Alternate Totals (Over/Under)"},
              {"key": "h2h_3_way", "name": "Moneyline 3 way (Draw Included)"},
              {"key": "player_points", "name": "Player Points (Over/Under)"},
              {"key": "player_power_play_points", "name": "Power play points (Over/Under)"},
              {"key": "player_assists", "name": "Assists (Over/Under)"},
              {"key": "player_blocked_shots", "name": "Blocked shots (Over/Under)"},
              {"key": "player_shots_on_goal", "name": "Shots on goal (Over/Under)"},
              {"key": "player_total_saves", "name": "Total saves (Over/Under)"},
              {"key": "player_goal_scorer_first", "name": "First Goal Scorer (Yes/No)"},
              {"key": "player_goal_scorer_last", "name": "Last Goal Scorer (Yes/No)"},
              {"key": "player_goal_scorer_anytime", "name": "Anytime Goal Scorer (Yes/No)"},
              {"key": "h2h_p1", "name": "Moneyline 1st Period"},
              {"key": "h2h_p2", "name": "Moneyline 2nd Period"},
              {"key": "h2h_p3", "name": "Moneyline 3rd Period"},
              {"key": "spreads_p1", "name": "Spreads 1st Period"},
              {"key": "spreads_p2", "name": "Spreads 2nd Period"},
              {"key": "spreads_p3", "name": "Spreads 3rd Period"},
              {"key": "totals_p1", "name": "Over/under 1st Period"},
              {"key": "totals_p2", "name": "Over/under 2nd Period"},
              {"key": "totals_p3", "name": "Over/under 3rd Period"}
            ]
            break;
  
          case 'AFL':
            markets = [
              {"key": "h2h", "name": "Moneyline, Winner"},
              {"key": "spreads", "name": "Points spread, Handicap"},
              {"key": "totals", "name": "Total points, Over/Under"},
              {"key": "alternate_spreads", "name": "Alternate Spreads (handicap)"},
              {"key": "alternate_totals", "name": "Alternate Totals (Over/Under)"},
              {"key": "h2h_3_way", "name": "Moneyline 3 way (Draw Included)"},
              {"key": "player_disposals", "name": "Disposals (Over/Under)"},
              {"key": "player_disposals_over", "name": "Disposals (Over only)"},
              {"key": "player_goal_scorer_first", "name": "First Goal Scorer (Yes/No)"},
              {"key": "player_goal_scorer_last", "name": "Last Goal Scorer (Yes/No)"},
              {"key": "player_goal_scorer_anytime", "name": "Anytime Goal Scorer (Yes/No)"},
              {"key": "player_goals_scored_over", "name": "Goals scored (Over only)"},
              {"key": "h2h_q1", "name": "Moneyline 1st Quarter"},
              {"key": "h2h_q2", "name": "Moneyline 2nd Quarter"},
              {"key": "h2h_q3", "name": "Moneyline 3rd Quarter"},
              {"key": "h2h_q4", "name": "Moneyline 4th Quarter"},
              {"key": "h2h_h1", "name": "Moneyline 1st Half"},
              {"key": "h2h_h2", "name": "Moneyline 2nd Half"},
              {"key": "spreads_q1", "name": "Spreads 1st Quarter"},
              {"key": "spreads_q2", "name": "Spreads 2nd Quarter"},
              {"key": "spreads_q3", "name": "Spreads 3rd Quarter"},
              {"key": "spreads_q4", "name": "Spreads 4th Quarter"},
              {"key": "spreads_h1", "name": "Spreads 1st Half"},
              {"key": "spreads_h2", "name": "Spreads 2nd Half"},
              {"key": "totals_q1", "name": "Over/under 1st Quarter"},
              {"key": "totals_q2", "name": "Over/under 2nd Quarter"},
              {"key": "totals_q3", "name": "Over/under 3rd Quarter"},
              {"key": "totals_q4", "name": "Over/under 4th Quarter"},
              {"key": "totals_h1", "name": "Over/under 1st Half"},
              {"key": "totals_h2", "name": "Over/under 2nd Half"}
  
            ]
            break;
  
          case 'MLB':
            markets = [
              {"key": "h2h", "name": "Moneyline, Winner"},
              {"key": "spreads", "name": "Points spread, Handicap"},
              {"key": "totals", "name": "Total points, Over/Under"},
              {"key": "team_totals", "name": "Team Totals"},
              {"key": "alternate_spreads", "name": "Alternate Spreads (handicap)"},
              {"key": "alternate_totals", "name": "Alternate Totals (Over/Under)"},
              {"key": "h2h_3_way", "name": "Moneyline 3 way (Draw Included)"},
              {"key": "batter_home_runs", "name": "Batter home runs (Over/Under)"},
              {"key": "batter_hits", "name": "Batter hits (Over/Under)"},
              {"key": "batter_total_bases", "name": "Batter total bases (Over/Under)"},
              {"key": "batter_rbis", "name": "Batter RBIs (Over/Under)"},
              {"key": "batter_runs_scored", "name": "Batter runs scored (Over/Under)"},
              {"key": "batter_hits_runs_rbis", "name": "Batter hits + runs + RBIs (Over/Under)"},
              {"key": "batter_singles", "name": "Batter singles (Over/Under)"},
              {"key": "batter_doubles", "name": "Batter doubles (Over/Under)"},
              {"key": "batter_triples", "name": "Batter triples (Over/Under)"},
              {"key": "batter_walks", "name": "Batter walks (Over/Under)"},
              {"key": "batter_strikeouts", "name": "Batter strikeouts (Over/Under)"},
              {"key": "batter_stolen_bases", "name": "Batter stolen bases (Over/Under)"},
              {"key": "pitcher_strikeouts", "name": "Pitcher strikeouts (Over/Under)"},
              {"key": "pitcher_record_a_win", "name": "Pitcher to record a win (Yes/No)"},
              {"key": "pitcher_hits_allowed", "name": "Pitcher hits allowed (Over/Under)"},
              {"key": "pitcher_walks", "name": "Pitcher walks (Over/Under)"},
              {"key": "pitcher_earned_runs", "name": "Pitcher earned runs (Over/Under)"},
              {"key": "pitcher_outs", "name": "Pitcher outs (Over/Under)"},
              {"key": "h2h_1st_1_innings", "name": "Moneyline 1st inning"},
              {"key": "h2h_1st_3_innings", "name": "Moneyline 1st 3 innings"},
              {"key": "h2h_1st_5_innings", "name": "Moneyline 1st 5 innings"},
              {"key": "h2h_1st_7_innings", "name": "Moneyline 1st 7 innings"},
              {"key": "h2h_3_way_1st_1_innings", "name": "3-way moneyline 1st inning"},
              {"key": "h2h_3_way_1st_3_innings", "name": "3-way moneyline 1st 3 innings"},
              {"key": "h2h_3_way_1st_5_innings", "name": "3-way moneyline 1st 5 innings"},
              {"key": "h2h_3_way_1st_7_innings", "name": "3-way moneyline 1st 7 innings"},
              {"key": "spreads_1st_1_innings", "name": "Spreads 1st inning"},
              {"key": "spreads_1st_3_innings", "name": "Spreads 1st 3 innings"},
              {"key": "spreads_1st_5_innings", "name": "Spreads 1st 5 innings"},
              {"key": "spreads_1st_7_innings", "name": "Spreads 1st 7 innings"},
              {"key": "alternate_spreads_1st_1_innings", "name": "Alternate spreads 1st inning"},
              {"key": "alternate_spreads_1st_5_innings", "name": "Alternate spreads 1st 5 innings"},
              {"key": "alternate_totals_1st_5_innings", "name": "Alternate over/under 1st 5 innings"},
              {"key": "totals_1st_1_innings", "name": "Over/under 1st inning"},
              {"key": "totals_1st_3_innings", "name": "Over/under 1st 3 innings"},
              {"key": "totals_1st_5_innings", "name": "Over/under 1st 5 innings"},
              {"key": "totals_1st_7_innings", "name": "Over/under 1st 7 innings"}
  
            ]
            break;
  
          case "NCAAF":
          case 'NFL':
            markets = [
              {"key": "h2h", "name": "Moneyline, Winner"},
              {"key": "spreads", "name": "Points spread, Handicap"},
              {"key": "totals", "name": "Total points, Over/Under"},
              {"key": "team_totals", "name": "Team Totals"},
              {"key": "alternate_spreads", "name": "Alternate Spreads (handicap)"},
              {"key": "alternate_totals", "name": "Alternate Totals (Over/Under)"},
              {"key": "h2h_3_way", "name": "Moneyline 3 way (Draw Included)"},
              {"key": "player_pass_tds", "name": "Pass Touchdowns (Over/Under)"},
              {"key": "player_pass_yds", "name": "Pass Yards (Over/Under)"},
              {"key": "player_pass_completions", "name": "Pass Completions (Over/Under)"},
              {"key": "player_pass_attempts", "name": "Pass Attempts (Over/Under)"},
              {"key": "player_pass_interceptions", "name": "Pass Intercepts (Over/Under)"},
              {"key": "player_pass_longest_completion", "name": "Pass Longest Completion (Over/Under)"},
              {"key": "player_rush_yds", "name": "Rush Yards (Over/Under)"},
              {"key": "player_rush_attempts", "name": "Rush Attempts (Over/Under)"},
              {"key": "player_rush_longest", "name": "Longest Rush (Over/Under)"},
              {"key": "player_receptions", "name": "Receptions (Over/Under)"},
              {"key": "player_reception_yds", "name": "Reception Yards (Over/Under)"},
              {"key": "player_reception_longest", "name": "Longest Reception (Over/Under)"},
              {"key": "player_kicking_points", "name": "Kicking Points (Over/Under)"},
              {"key": "player_field_goals", "name": "Field Goals (Over/Under)"},
              {"key": "player_tackles_assists", "name": "Tackles + Assists (Over/Under)"},
              {"key": "player_1st_td", "name": "1st Touchdown Scorer (Yes/No)"},
              {"key": "player_last_td", "name": "Last Touchdown Scorer (Yes/No)"},
              {"key": "player_anytime_td", "name": "Anytime Touchdown Scorer (Yes/No)"},
              {"key": "h2h_q1", "name": "Moneyline 1st Quarter"},
              {"key": "h2h_q2", "name": "Moneyline 2nd Quarter"},
              {"key": "h2h_q3", "name": "Moneyline 3rd Quarter"},
              {"key": "h2h_q4", "name": "Moneyline 4th Quarter"},
              {"key": "h2h_h1", "name": "Moneyline 1st Half"},
              {"key": "h2h_h2", "name": "Moneyline 2nd Half"},
              {"key": "spreads_q1", "name": "Spreads 1st Quarter"},
              {"key": "spreads_q2", "name": "Spreads 2nd Quarter"},
              {"key": "spreads_q3", "name": "Spreads 3rd Quarter"},
              {"key": "spreads_q4", "name": "Spreads 4th Quarter"},
              {"key": "spreads_h1", "name": "Spreads 1st Half"},
              {"key": "spreads_h2", "name": "Spreads 2nd Half"},
              {"key": "totals_q1", "name": "Over/under 1st Quarter"},
              {"key": "totals_q2", "name": "Over/under 2nd Quarter"},
              {"key": "totals_q3", "name": "Over/under 3rd Quarter"},
              {"key": "totals_q4", "name": "Over/under 4th Quarter"},
              {"key": "totals_h1", "name": "Over/under 1st Half"},
              {"key": "totals_h2", "name": "Over/under 2nd Half"}
            ]
            break;
          
          case "NCAAB":
          case "WNBA":
          case "NBA":
            markets = [
              {"key": "h2h", "name": "Moneyline, Winner"},
              {"key": "spreads", "name": "Points spread, Handicap"},
              {"key": "totals", "name": "Total points, Over/Under"},
              {"key": "alternate_spreads", "name": "Alternate Spreads (handicap)"},
              {"key": "alternate_totals", "name": "Alternate Totals (Over/Under)"},
              {"key": "h2h_3_way", "name": "Moneyline 3 way (Draw Included)"},
              {"key": "player_points", "name": "Player Points (Over/Under)"},
              {"key": "player_rebounds", "name": "Rebounds (Over/Under)"},
              {"key": "player_assists", "name": "Assists (Over/Under)"},
              {"key": "player_threes", "name": "Threes (Over/Under)"},
              {"key": "player_double_double", "name": "Double Double (Yes/No)"},
              {"key": "player_blocks", "name": "Blocks (Over/Under)"},
              {"key": "player_steals", "name": "Steals (Over/Under)"},
              {"key": "player_turnovers", "name": "Turnovers (Over/Under)"},
              {"key": "player_points_rebounds_assists", "name": "Points + Rebounds + Assists (Over/Under)"},
              {"key": "player_points_rebounds", "name": "Points + Rebounds (Over/Under)"},
              {"key": "player_points_assists", "name": "Points + Assists (Over/Under)"},
              {"key": "player_rebounds_assists", "name": "Rebounds + Assists (Over/Under)"},
              {"key": "h2h_q1", "name": "Moneyline 1st Quarter"},
              {"key": "h2h_q2", "name": "Moneyline 2nd Quarter"},
              {"key": "h2h_q3", "name": "Moneyline 3rd Quarter"},
              {"key": "h2h_q4", "name": "Moneyline 4th Quarter"},
              {"key": "h2h_h1", "name": "Moneyline 1st Half"},
              {"key": "h2h_h2", "name": "Moneyline 2nd Half"},
              {"key": "spreads_q1", "name": "Spreads 1st Quarter"},
              {"key": "spreads_q2", "name": "Spreads 2nd Quarter"},
              {"key": "spreads_q3", "name": "Spreads 3rd Quarter"},
              {"key": "spreads_q4", "name": "Spreads 4th Quarter"},
              {"key": "spreads_h1", "name": "Spreads 1st Half"},
              {"key": "spreads_h2", "name": "Spreads 2nd Half"},
              {"key": "totals_q1", "name": "Over/under 1st Quarter"},
              {"key": "totals_q2", "name": "Over/under 2nd Quarter"},
              {"key": "totals_q3", "name": "Over/under 3rd Quarter"},
              {"key": "totals_q4", "name": "Over/under 4th Quarter"},
              {"key": "totals_h1", "name": "Over/under 1st Half"},
              {"key": "totals_h2", "name": "Over/under 2nd Half"}
            ]
            break;
  
          case "Boxing":
          case "MMA":
            markets = [ {"key": "h2h", "name": "Moneyline, Winner"},]
            break;
  
          default:
            // basketball, football, etc.
            let markets_quarters_halves = [
              {"key": "h2h_q1", "name": "Moneyline 1st Quarter"},
              {"key": "h2h_q2", "name": "Moneyline 2nd Quarter"},
              {"key": "h2h_q3", "name": "Moneyline 3rd Quarter"},
              {"key": "h2h_q4", "name": "Moneyline 4th Quarter"},
              {"key": "h2h_h1", "name": "Moneyline 1st Half"},
              {"key": "h2h_h2", "name": "Moneyline 2nd Half"},
              {"key": "spreads_q1", "name": "Spreads 1st Quarter"},
              {"key": "spreads_q2", "name": "Spreads 2nd Quarter"},
              {"key": "spreads_q3", "name": "Spreads 3rd Quarter"},
              {"key": "spreads_q4", "name": "Spreads 4th Quarter"},
              {"key": "spreads_h1", "name": "Spreads 1st Half"},
              {"key": "spreads_h2", "name": "Spreads 2nd Half"},
              {"key": "totals_q1", "name": "Over/under 1st Quarter"},
              {"key": "totals_q2", "name": "Over/under 2nd Quarter"},
              {"key": "totals_q3", "name": "Over/under 3rd Quarter"},
              {"key": "totals_q4", "name": "Over/under 4th Quarter"},
              {"key": "totals_h1", "name": "Over/under 1st Half"},
              {"key": "totals_h2", "name": "Over/under 2nd Half"}
            ]
            
            // hockey
            let markets_periods = [
              {"key": "h2h_p1", "name": "Moneyline 1st Period"},
              {"key": "h2h_p2", "name": "Moneyline 2nd Period"},
              {"key": "h2h_p3", "name": "Moneyline 3rd Period"},
              {"key": "spreads_p1", "name": "Spreads 1st Period"},
              {"key": "spreads_p2", "name": "Spreads 2nd Period"},
              {"key": "spreads_p3", "name": "Spreads 3rd Period"},
              {"key": "totals_p1", "name": "Over/under 1st Period"},
              {"key": "totals_p2", "name": "Over/under 2nd Period"},
              {"key": "totals_p3", "name": "Over/under 3rd Period"}
            ]
            
            // baseball
            let markets_innings = [
              {"key": "h2h_1st_1_innings", "name": "Moneyline 1st inning"},
              {"key": "h2h_1st_3_innings", "name": "Moneyline 1st 3 innings"},
              {"key": "h2h_1st_5_innings", "name": "Moneyline 1st 5 innings"},
              {"key": "h2h_1st_7_innings", "name": "Moneyline 1st 7 innings"},
              {"key": "h2h_3_way_1st_1_innings", "name": "3-way moneyline 1st inning"},
              {"key": "h2h_3_way_1st_3_innings", "name": "3-way moneyline 1st 3 innings"},
              {"key": "h2h_3_way_1st_5_innings", "name": "3-way moneyline 1st 5 innings"},
              {"key": "h2h_3_way_1st_7_innings", "name": "3-way moneyline 1st 7 innings"},
              {"key": "spreads_1st_1_innings", "name": "Spreads 1st inning"},
              {"key": "spreads_1st_3_innings", "name": "Spreads 1st 3 innings"},
              {"key": "spreads_1st_5_innings", "name": "Spreads 1st 5 innings"},
              {"key": "spreads_1st_7_innings", "name": "Spreads 1st 7 innings"},
              {"key": "alternate_spreads_1st_1_innings", "name": "Alternate spreads 1st inning"},
              {"key": "alternate_spreads_1st_5_innings", "name": "Alternate spreads 1st 5 innings"},
              {"key": "alternate_totals_1st_5_innings", "name": "Alternate over/under 1st 5 innings"},
              {"key": "totals_1st_1_innings", "name": "Over/under 1st inning"},
              {"key": "totals_1st_3_innings", "name": "Over/under 1st 3 innings"},
              {"key": "totals_1st_5_innings", "name": "Over/under 1st 5 innings"},
              {"key": "totals_1st_7_innings", "name": "Over/under 1st 7 innings"}
            ]
  
            let default_markets = [
              {"key": "h2h", "name": "Moneyline, Winner"},
              {"key": "spreads", "name": "Points spread, Handicap"},
              {"key": "totals", "name": "Total points/goals, Over/Under"},
              {"key": "alternate_spreads", "name": "Alternate Spreads (handicap)"},
              {"key": "alternate_totals", "name": "Alternate Totals (Over/Under)"},
              {"key": "h2h_3_way", "name": "Moneyline 3 way (Draw Included)"}        
            ]
  
            markets = [...default_markets]
            
            if(sport.key.includes('basketball') || sport.key.includes('football')){
              markets = [...markets, ...markets_quarters_halves]
            }else if(sport.key.includes('baseball')){
              markets = [...markets, ...markets_innings]
            }else if(sport.key.includes('hockey')){
              markets = [...markets, ...markets_periods]
            }
            break;  
        }
  
      }
      leg.marketOptions = markets
      
      dispatch({type: UPDATE_LEG, payload: leg})
    
  } catch (error) {
    console.log(error);
  }
};

export const getOdds = (sport, event, market, leg) => async (dispatch) => {
  try {
    let data = await api.getOdds(sport, event, market);
    if(data.data.error || data.data.data == []){
      dispatch(errorMessage(data.data.message, leg))
    }else{
      leg.oddsOptions = data.data.data.map(line=>{
        // data contains: price, name, description?, point?
        return {...line, title: `${line.description?(line.description+" "):('')}${line.name} ${line.point?(line.point+" "):''}(${line.price>0?'+':''}${line.price})`}
      })
      leg.errorMessage = ''
      leg.date = getFormattedDate(data.data.date)

      let tags = []
      let league = leg.sport
      tags = [...tags, league]
      let sport = leg.sportsOptions.find(s=>s.title === leg.sport)
      tags = [...tags, sport.group]
      let game = leg.gameOptions.find(game=>game.title === leg.game)
      if(!sport.key.includes('winner')){
        tags = [...tags, game.away_team]
        tags = [...tags, game.home_team]
      }
      leg.tags = tags

      dispatch({ type: UPDATE_LEG, payload: leg });
    }
    
  } catch (error) {
    console.log(error);
  }
};

export const errorMessage = (msg, leg) => async (dispatch) => {
  try {
    leg.errorMessage = msg
    dispatch({ type: UPDATE_LEG, payload: leg });
  } catch (error) {
    console.log(error);
  }
};


export const addLeg = (leg) => async (dispatch) => {
  try {
    dispatch({ type: ADD_LEG, payload: leg });
  } catch (error) {
    console.log(error);
  }
};

export const updateLeg = (leg) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_LEG, payload: leg });
  } catch (error) {
    console.log(error);
  }
};

export const removeLeg = (id) => async (dispatch) => {
  try {
    dispatch({ type: REMOVE_LEG, payload: id });
  } catch (error) {
    console.log(error);
  }
};

export const removeLegs = () => async (dispatch) => {
  try {
    dispatch({ type: REMOVE_LEGS, payload: null });
  } catch (error) {
    console.log(error);
  }
};

export const updateTags = (tags) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_TAGS, payload: tags });
  } catch (error) {
    console.log(error);
  }
};

export const removeTag = (tag) => async (dispatch) => {
  try {
    dispatch({ type: REMOVE_TAG, payload: tag });
  } catch (error) {
    console.log(error);
  }
};

export const removeTags = (tags) => async (dispatch) => {
  try {
    dispatch({ type: REMOVE_TAGS, payload: tags });
  } catch (error) {
    console.log(error);
  }
};

export const getProfilePhotosForPicks = (picks) => async (dispatch) => {
  try {
    const usernames = Array.from(new Set(picks.map(user => user.username)));
    const data = await api.getProfilePhotosForPicks(usernames);
    const profilePhotos = data?.data?.profilePhotos
    dispatch({ type: GET_PROFILE_PHOTOS_FOR_PICKS, payload: profilePhotos });
    
  } catch (error) {
    console.log(error);
  }
};

export const getPicksFromScreenshotOddsJam = (photo) => async (dispatch) => {

  try {
    dispatch({ type: START_LOADING_SCREENSHOT });
    dispatch(errorMessageScreenshot(""))

    const response = await api.getPicksFromScreenshot(photo);
    const data =  response?.data

    if(!data?.success && !data.picks){
      if(data.messages && data.messages.length && data.messages.length > 0){
        var message = ""
        data.messages.map((msg)=>{
          message = message+" "+msg 
        })
        dispatch(errorMessageScreenshot(message))
    }else{
      dispatch(errorMessageScreenshot("Please try again. Ensure you crop the photo and that all details are in the photo, including the date. Keep in mind parlays can be captured, but nested parlays and same-game-parlays are tricky as their odds are not always visible on a per pick basis."))
    }
  }else{
      try {
        if(data.messages && data.messages.length && data.messages.length > 0){
          var message = ""
          data.messages.map((msg)=>{
            message = message+" "+msg 
          })
          dispatch(errorMessageScreenshot(message))
        }

        const picks = data.picks.legs
        if(picks?.length>0){
          // parlay

          // map picks to state
          picks.map((pick, i)=>{
            // ensure backend gathered all pick details before saving
            if(pick.sport != '' && pick.date != '' && pick.game != '' && pick.pick != ''){
          
              pick.id = uuidv4();
              dispatch(updateTags(pick.tags))

              // overwrite first default empty pick if still empty
              const leg = store.getState().pick_reducer.legs[0]
              if(leg.pick == '' && i == 0){
                pick.id = leg.id
                dispatch(updateLeg(pick))
              }else{
                dispatch(addLeg(pick))
              }
            }else{
              if(pick.date == ''){
                dispatch(errorMessageScreenshot("Please only share picks that are in the future, not ones from the past. Ensure the date is visible in the betslip."))
              }else{
                dispatch(errorMessageScreenshot("Please try again. Ensure you crop the photo and that all details are in the photo, including the date. Keep in mind parlays can be captured, but nested parlays and same-game-parlays are tricky as their odds are not always visible on a per pick basis."))
              }
            }
          })

        }else{
          // single pick

          // ensure chatgpt gathered all pick details before saving
          if(picks.sport != '' && picks.date != '' && picks.game != '' && picks.pick != ''){
            
            picks.id = uuidv4();
            dispatch(updateTags(picks.tags))

            // overwrite first default empty pick if still empty
            const leg = store.getState().pick_reducer.legs[0]
            if(leg.pick == ''){
              picks.id = leg.id
              dispatch(updateLeg(picks))
            }else{
              dispatch(addLeg(picks))
            }
          }else{
            if(picks.date == ''){
              dispatch(errorMessageScreenshot("Please only share picks that are in the future, not ones from the past. Ensure the date is visible in the betslip."))
            }else{
              dispatch(errorMessageScreenshot("Please try again. Ensure you crop the photo and that all details are in the photo, including the date. Keep in mind parlays can be captured, but nested parlays and same-game-parlays are tricky as their odds are not always visible on a per pick basis."))
            }
          }
        }
        //dispatch({ type: END_LOADING_SCREENSHOT });
      } catch (e) {
        //console.error("Parsing error:", e.message);
        dispatch(errorMessageScreenshot("Please try again. Ensure you crop the photo and that all details are in the photo, including the date. Keep in mind parlays can be captured, but nested parlays and same-game-parlays are tricky as their odds are not always visible on a per pick basis."))
        // try again, crop the photo, 20 mb, nested parlays are buggy
        //dispatch({ type: END_LOADING_SCREENSHOT });
      }
      
    }
    dispatch({ type: END_LOADING_SCREENSHOT });
    
  } catch (error) {
    console.log(error);
    dispatch(errorMessageScreenshot("Please try again. Ensure you crop the photo and that all details are in the photo, including the date. Keep in mind parlays can be captured, but nested parlays and same-game-parlays are tricky as their odds are not always visible on a per pick basis."))
  }
};

export const getPicksFromScreenshot = (photo) => async (dispatch) => {

  try {
    dispatch({ type: START_LOADING_SCREENSHOT });
    dispatch(errorMessageScreenshot(""))

    const response = await api.getPicksFromScreenshot(photo);
    const data =  response?.data

    if(!data?.success){
      dispatch(errorMessageScreenshot("Please try again. Ensure you crop the photo and that all details are in the photo, including the date. Keep in mind parlays can be captured, but nested parlays and same-game-parlays are tricky as their odds are not always visible on a per pick basis."))
    }else{
      try {
        let cleanJsonString = data.message
        // Remove any backticks and other non-JSON characters before parsing
        if(cleanJsonString.includes("```json")){
          cleanJsonString = data.message.replace(/```json|```/g, '');
        }
        let picks = JSON.parse(cleanJsonString);

        if(picks.length){
          
          // parlay

          // map picks to state
          picks.map((pick, i)=>{
            // ensure chatgpt gathered all pick details before saving
            if(pick.sport != '' && pick.date != '' && pick.game != '' && pick.market != '' && pick.pick != ''){
              // if date says today, rewrite it
              if(pick.date.includes("Today") || pick.date.includes("today")){
                pick.date = getFormattedDate(new Date())
              }
              pick.id = uuidv4();
              pick.screenShotted = true
              pick.complete = true
              pick.errorMessage = ''

              let tags = []
              tags = [...tags, pick.sport]
              var teams = null
              if(!pick.game.includes('winner')){
                if(pick.game.includes("@")){
                  teams = pick.game.split(/\s+@\s+/);
                }else if(pick.game.includes("Vs.")){
                  teams = pick.game.split(/\s+Vs\.\s+/)
                }else if(pick.game.includes("vs.")){
                  teams = pick.game.split(/\s+vs\.\s+/)
                }else if(pick.game.includes("Vs")){
                  teams = pick.game.split(/\s*Vs\s*/);
                }else if(pick.game.includes("vs")){
                  teams = pick.game.split(/\s*vs\s*/);
                }
              }
              if(teams){
                tags = [...tags, teams[0]]
                tags = [...tags, teams[1]]
              }
              pick.tags = tags
              dispatch(updateTags(tags))

              // overwrite first default empty pick if still empty
              const leg = store.getState().pick_reducer.legs[0]
              if(leg.pick == '' && i == 0){
                pick.id = leg.id
                dispatch(updateLeg(pick))
              }else{
                dispatch(addLeg(pick))
              }
            }else{
              if(pick.date == ''){
                dispatch(errorMessageScreenshot("Please only share picks that are in the future, not ones from the past. Ensure the date is visible in the betslip."))
              }else{
                dispatch(errorMessageScreenshot("Please try again. Ensure you crop the photo and that all details are in the photo, including the date. Keep in mind parlays can be captured, but nested parlays and same-game-parlays are tricky as their odds are not always visible on a per pick basis."))
              }
            }
          })

        }else{
          // single pick

          // ensure chatgpt gathered all pick details before saving
          if(picks.sport != '' && picks.date != '' && picks.game != '' && picks.market != '' && picks.pick != ''){
            // if date says today, rewrite it
            if(picks.date.includes("Today") || picks.date.includes("today")){
              picks.date = getFormattedDate(new Date())
            }
            picks.id = uuidv4();
            picks.screenShotted = true
            picks.complete = true
            picks.errorMessage = ''

            let tags = []
            tags = [...tags, picks.sport]
            var teams = null
            if(!picks.game.includes('winner')){
              if(picks.game.includes("@")){
                teams = picks.game.split(/\s+@\s+/);
              }else if(picks.game.includes("Vs.")){
                teams = picks.game.split(/\s+Vs\.\s+/)
              }else if(picks.game.includes("vs.")){
                teams = picks.game.split(/\s+vs\.\s+/)
              }else if(picks.game.includes("Vs")){
                teams = picks.game.split(/\s*Vs\s*/);
              }else if(picks.game.includes("vs")){
                teams = picks.game.split(/\s*vs\s*/);
              }
            }
            if(teams){
              tags = [...tags, teams[0]]
              tags = [...tags, teams[1]]
            }
            picks.tags = tags
            dispatch(updateTags(tags))

            // overwrite first default empty pick if still empty
            const leg = store.getState().pick_reducer.legs[0]
            if(leg.pick == ''){
              picks.id = leg.id
              dispatch(updateLeg(picks))
            }else{
              dispatch(addLeg(picks))
            }
          }else{
            if(picks.date == ''){
              dispatch(errorMessageScreenshot("Please only share picks that are in the future, not ones from the past. Ensure the date is visible in the betslip."))
            }else{
              dispatch(errorMessageScreenshot("Please try again. Ensure you crop the photo and that all details are in the photo, including the date. Keep in mind parlays can be captured, but nested parlays and same-game-parlays are tricky as their odds are not always visible on a per pick basis."))
            }
          }
        }
        dispatch({ type: END_LOADING_SCREENSHOT });
      } catch (e) {
        //console.error("Parsing error:", e.message);
        dispatch(errorMessageScreenshot("Please try again. Ensure you crop the photo and that all details are in the photo, including the date. Keep in mind parlays can be captured, but nested parlays and same-game-parlays are tricky as their odds are not always visible on a per pick basis."))
        // try again, crop the photo, 20 mb, nested parlays are buggy
        dispatch({ type: END_LOADING_SCREENSHOT });
      }
      
    }
    
  } catch (error) {
    console.log(error);
    dispatch(errorMessageScreenshot("Please try again. Ensure you crop the photo and that all details are in the photo, including the date. Keep in mind parlays can be captured, but nested parlays and same-game-parlays are tricky as their odds are not always visible on a per pick basis."))
  }
};

export const errorMessageScreenshot = (msg) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_ERROR_MSG_SCREENSHOT, payload: msg });
  } catch (error) {
    console.log(error);
  }
};


export const searchPicks = (username) => async (dispatch) => {
    
  try {
    dispatch({ type: START_LOADING_PICKS });
    const { data } = await api.searchPicks(username);
    dispatch({ type: SEARCH_PICKS, payload: data.picks });
    dispatch({ type: END_LOADING_PICKS });
    return data.profile
  } catch (error) {
    console.log(error);
    dispatch({ type: END_LOADING_PICKS });
    return null
  }
};


export const searchPicksFollowing = (username, filterType, secondaryFilterType, page, legs, back = null, loader = true, skip = 3) => async (dispatch) => {
  const actionKey = `searchPicksFollowing-${filterType}`; // Unique key for this type of action
  const debouncedDispatch = debounceDispatch(async () => {
    try {
      loader && dispatch({ type: START_LOADING_FOLLOWING_PICKS });
      const { data } = await api.searchPicksFollowing(username, filterType, secondaryFilterType, page, legs);
      if (data.picks.length > 0) {
        if(back === null){
          
            // first page
            dispatch({ type: SEARCH_PICKS_FOLLOWING, payload: data.picks });
          
        }else if(back === false){
          
            const oldPicks = store.getState().pick_reducer.picksFollowing
            // go forward in pages
            const updatedPicks = [
                ...oldPicks.slice(-skip), // Get last '5' items from old list (prev page)
                ...data.picks // New items
            ];
            const uniquePosts = Array.from(new Set(updatedPicks.map(post => JSON.stringify(post)))).map(post => JSON.parse(post));
            dispatch({ type: SEARCH_PICKS_FOLLOWING, payload: uniquePosts });

        }else if(back === true){
          
            // go back in pages
            const oldPicks = store.getState().pick_reducer.picksFollowing
            const updatedPicks = [
                ...data.picks, // New items
                ...oldPicks.slice(0,skip) // Get first '5' items from old list (next page)
            ];
            const uniquePosts = Array.from(new Set(updatedPicks.map(post => JSON.stringify(post)))).map(post => JSON.parse(post));
            dispatch({ type: SEARCH_PICKS_FOLLOWING, payload: uniquePosts });

        }
        loader && dispatch({ type: END_LOADING_FOLLOWING_PICKS });
        return data.picks
      }else{
        loader && dispatch({ type: END_LOADING_FOLLOWING_PICKS });
        return null
      } 

    } catch (error) {
      console.log(error);
      loader && dispatch({ type: END_LOADING_FOLLOWING_PICKS });
      return null
    }
  }, 700, actionKey); // 1-second delay
  return debouncedDispatch();
};

export const searchPicksAll = (username, filterType, secondaryFilterType, page, legs, back = null, loader = true, skip=3) => async (dispatch) => {
  const actionKey = `searchPicksAll-${filterType}`; // Unique key for this type of action
  const debouncedDispatch = debounceDispatch(async () => {
    try {
      loader && dispatch({ type: START_LOADING_ALL_PICKS });
      const { data } = await api.searchPicksAll(username, filterType, secondaryFilterType, page, legs);
      if (data.picks.length > 0) {
        if(back === null){
          
            // first page
            dispatch({ type: SEARCH_PICKS, payload: data.picks });
          
        }else if(back === false){
          
            const oldPicks = store.getState().pick_reducer.picksAll
            // go forward in pages
            const updatedPicks = [
                ...oldPicks.slice(-skip), // Get last '5' items from old list (prev page)
                ...data.picks // New items
            ];
            const uniquePosts = Array.from(new Set(updatedPicks.map(post => JSON.stringify(post)))).map(post => JSON.parse(post));
            dispatch({ type: SEARCH_PICKS, payload: uniquePosts });
        
        }else if(back === true){
          
            // go back in pages
            const oldPicks = store.getState().pick_reducer.picksAll
            const updatedPicks = [
                ...data.picks, // New items
                ...oldPicks.slice(0,skip) // Get first '5' items from old list (next page)
            ];
            const uniquePosts = Array.from(new Set(updatedPicks.map(post => JSON.stringify(post)))).map(post => JSON.parse(post));
            dispatch({ type: SEARCH_PICKS, payload: uniquePosts });

        } 
        loader && dispatch({ type: END_LOADING_ALL_PICKS });
        return data.picks
      }else{
        loader && dispatch({ type: END_LOADING_FOLLOWING_PICKS });
        return null
      } 
    } catch (error) {
      console.log(error);
      loader && dispatch({ type: END_LOADING_ALL_PICKS });
      return null
    }
  }, 700, actionKey); // 1-second delay
  return debouncedDispatch();
};

export const setParlayOdds = (state) => async (dispatch) => {
  try {
    dispatch({ type: SET_PARLAY_ODDS, payload: state });
  } catch (error) {
    console.log(error);
  }
};