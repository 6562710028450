import React, { useEffect, useRef, useState } from 'react';
import "../../../Assets/css/conversation.css";
import { useDispatch, useSelector } from 'react-redux';
import { createNewConversation, getConversations, searchUsers, toggleConversationModal, updateConversationName, updateConversationParticipants } from '../../../State/redux/actions/ChatActions';
import cancel from "../../../Assets/icons/cancel-r.png"
const CreateConversation = () => {

  const { usersSearch, participants, conversationName } = useSelector((state)=>state.chat_reducer)
  const auth = useSelector((state)=>state.auth_reducer)
  const dispatch = useDispatch()
  const [usersSearchInput, setUsersSearchInput] = useState('')
  const outsideRef = useRef()
  const endOfParticipantsRef = useRef(null);

  useEffect(()=>{
    return () => {
      dispatch(toggleConversationModal(false))
    }
  },[])

  const handleCreateConversation = (value) => {
    dispatch(toggleConversationModal(value))
  };

  const handleUpdateUsers = (value) => {
    setUsersSearchInput(value)
    dispatch(searchUsers(value))
  }

  const handleClickOutside = (event) => {
    if (outsideRef.current && !outsideRef.current.contains(event.target)) {
      dispatch(toggleConversationModal(false))
    }
  };
  
  useEffect(() => {
    // Add both mousedown and touchstart event listeners
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);

    return () => {
      // Remove both event listeners on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    }
  }, [outsideRef]);

  const scrollToBottom = () => {
    endOfParticipantsRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const addParticipant = (user) =>{
    const listParticipants = [...participants, user]
    dispatch(updateConversationParticipants(listParticipants))
    handleUpdateUsers('')
    scrollToBottom();
  }
  
  const handleDelete = (index) =>{
    const listParticipants = participants.filter((_, i) => i !== index)
    dispatch(updateConversationParticipants(listParticipants))
  }

  const updateGroupName = (e) =>{
    
    dispatch(updateConversationName(e.target.value))
  }

  const handleSubmitNewConversation = () =>{
    if(auth?.username){
      createNewConversation(participants, conversationName, auth?.username)
    }else{
      // @TODO: ERROR msg not signed in - reroute
    }
  }

  const usersSearchFiltered = () =>{
    return usersSearch.filter((u)=>u!=auth.username).filter((u)=>!participants?.includes(u))
  }

  return (
    <div className='create-convo-modal-background'>
        <div className='create-convo-modal' ref={outsideRef}>
            <h3 className='create-conversation-title'>Create Conversation</h3>

            {/* GROUP NAME */}
            <input type='text' placeholder='Group Name' className='conversation-name-input' onChange={(e)=>{updateGroupName(e)}} value={conversationName}/>

            {/* GROUP PARTICIPANTS SELECTED */}
            <div className="input-container-create-conversation">
              <div  className='add-participants-bubbles'>
                {participants.map((bubble, index) => (
                  <div key={index} className="add-participants-bubble">
                    {bubble}
                    <img src={cancel} className="add-participants-delete" onClick={() => handleDelete(index)}/> 
                  </div>
                ))}
                <div ref={endOfParticipantsRef}/>
              </div>
              
              {/* GROUP PARTICIPANTS INPUT */}
              <input type='text' placeholder='Add Users' className='conversation-add-user-input' onChange={(e)=>{handleUpdateUsers(e.target.value)}} value={usersSearchInput} />
            
              {/* GROUP PARTICIPANTS SEARCH/DROPDOWN MENU */}
              {usersSearch?.length > 0 && (
              <div className='create-conversation-list-users'>
                  {usersSearchFiltered().map((user,i)=>{
                    return (
                    <div onClick={()=>{addParticipant(user)}} key={i} className={i==usersSearchFiltered().length-1?'create-conversation-username-select':'create-conversation-username-select border-bottom'}>
                      {user}
                    </div>)
                  })}
              </div>)}
            </div>

            <div className='create-conversation-submit-button' onClick={()=>{handleSubmitNewConversation()}}>
              Create
            </div>

            <div className='create-conversation-submit-button cancel-btn' onClick={()=>handleCreateConversation(false)}>
              Cancel
            </div>
        </div>
      </div>
  );
};

export default CreateConversation;
