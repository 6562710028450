
import { v4 as uuidv4 } from 'uuid';

class PickPost{
    
    title;
    description;
    lock;
    price;
    legs;
    tags;
    userId;
    token;
    overallRecordString;
    overallRecord;
    winRate;
    winRateCategory;
    hotStreak;
    username;
    numberOfLegs;
    totalOdds;
    totalBetsOverall;
    profit;
    profitCategory;
    wholeBetGraded;
    wholeBetWin;
    units;

    /*
    Initialize the variables
    */
    constructor(){
       
        this.title = ''
        this.description = ''
        this.lock = false
        this.price = 2.0
        this.legs = [{sport: '', date: '', sportsbook: '', game: '', market: '', pick: '', id: '', odds: 2.0, sportsOptions: [], gameOptions:[], marketOptions: [], oddsOptions: [], screenShotted: false, errorMessage: '', complete: false, tags: [], flag: false, gameObject: {}, oddsObject: {}, league: '', bet_name: '', market_name: '', game_id: '', player_id: '', teams: [], logos: [], graded: false, win: null, tournament: '', future_id: '',}]
        this.tags = []
        this.userId = ''
        this.token = ''
        this.overallRecordString = ''
        this.overallRecord = 0
        this.winRate = 0
        this.winRateCategory = 0
        this.hotStreak = 0
        this.username = ''
        this.numberOfLegs = 0
        this.totalOdds = 2.0
        this.totalBetsOverall = 0
        this.profit = 0;
        this.profitCategory = 0;
        this.wholeBetGraded = false;
        this.wholeBetWin = null;
        this.units = 1
    }
}

export {PickPost};