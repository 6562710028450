
import { createRef, useEffect, useState, React, useRef } from 'react';
import { useDispatch, useSelector, connect, ReactReduxContext, } from 'react-redux';
import "../../../Assets/css/createpickspage.css"
import undo from '../../../Assets/icons/undo-grey.png'
import exit from '../../../Assets/icons/cancel-r.png'
import poofImg from '../../../Assets/icons/cloud.png'
import { getGames, getOdds, errorMessage, updateLeg, getMarkets, getSports, removeTags, updateTags } from '../../../State/redux/actions/PickActions.js';
import { getDate, getFormattedDate } from '../../../Utilities/UtilityFunctions.js';

//const desiredSports = ['Football','Baseball','Basketball','Hockey','Soccer','Volleyball','Formula-1','Handball','Golf','Tennis','Boxing','MMA','Cricket','Table Tennis','Futsal','Badminton','Horse Racing','Greyhounds','Esports','Politics' ]

// app component
function NewPickScreenshotted({id, deleteForm, counter, removeFlag}) {
  
  const dispatch = useDispatch() 
  const { legs } = useSelector(state => state.pick_reducer)  
  const leg = useSelector(state => state.pick_reducer.legs.find(leg=>leg.id==id))
  const [poof, setPoof] = useState(false);
  const [showPoof, setShowPoof] = useState(true);
  const [showContent, setShowContent] = useState(false);

  // component did mount
  useEffect(()=>{
    
    setTimeout(()=>{
      setShowContent(true)
    },500)
  },[])

  function deleteThisForm(id) {
    if(legs.length > 1){
      removeUniqueTags()
      setPoof(true)
      setTimeout(()=>{
        setShowPoof(false)
      },250)
      setTimeout(()=>{
        deleteForm(id)
      },900)
    }
  }

  const removeUniqueTags = () =>{

    let tagsToRemove = leg.tags
    if(tagsToRemove){
      let tagsToKeep = []
      legs.forEach((otherLeg)=>{
        // check that these tags are not being used for other legs, before deleting them
        if(otherLeg.tags){
          tagsToKeep = [...tagsToKeep, ...otherLeg.tags.filter((t)=>tagsToRemove.includes(t) && otherLeg.id !== id)]
        }
      })
      tagsToRemove = tagsToRemove?.filter((t)=>!tagsToKeep?.includes(t))
    
      dispatch(removeTags(tagsToRemove))
    }
  }

  return (
    poof?
      <div className='poof-container'>
        {<img src={poofImg} className={showPoof?'poof-img':'poof-img hidden'} />}
      </div>
      :
      (showContent ? 
      <div className={leg.flag?'pick-form-new flagged':'pick-form-new'}>
        <div className='pick-form-header'>
          
          <div className='header-wrapper'>
            <h4>Create Pick #{counter}</h4>
          </div>
          
          <div className='buttons-corner'>
            {counter > 1 &&
            <div className='img-cancel-container'>
              <img src={exit} className='img-delete' onClick={()=>{deleteThisForm(id)}}/>
            </div>}  
          </div>
          
        </div>
        <div className='pick-form-body'>
          <div className='dropdown-container'>
            <input 
              placeholder={'Sport'} 
              name='sport' 
              className='dropdown-select' 
              value={leg.league} 
              disabled={true} 
              type="text"/>
          </div>
          
          <div className='dropdown-container'>
            <input 
              placeholder={'Game'} 
              name='game' 
              className='dropdown-select' 
              value={leg.game} 
              disabled={true} 
              type="text"/>
          </div>

          <div className='dropdown-container'>
            <input 
              placeholder={'Market/Line'} 
              name='market' 
              className={leg.sport === 'golf' ? 'dropdown-select greyed' : 'dropdown-select' }
              value={leg.market} 
              disabled={true} 
              type="text"/>
          </div>

          <div className='dropdown-container'>
            <input 
              placeholder={'Pick'} 
              name='pick' 
              className='dropdown-select' 
              value={leg.pick} 
              disabled={true} 
              type="text"/>
          </div>
          
          {leg.errorMessage == '' &&
          <div className='bottom-bar-info'>
            {leg.date != '' && <h3 className='date'>{getFormattedDate(leg.date)}</h3>}
            {leg.complete && <h3 className='odds'>{leg.odds > 0 ? "+"+leg.odds : leg.odds}</h3>}
          </div>}
          {leg.errorMessage != '' && <h3 className='red-error'>{leg.errorMessage}</h3>}
        </div>
      </div>
      :
      <div className='pick-form-new'>
        <div className='pick-form-header'>
            <div className='header-wrapper'>
              <h4>Create Pick #{counter}</h4>
            </div>
        </div>
      </div>)
  )

}

export default NewPickScreenshotted;

