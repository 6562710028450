// components/Conversation.jsx
import React from 'react';
import { useSelector } from 'react-redux';
import "../../../Assets/css/conversation.css"
import { stringToColor } from '../../../Utilities/UtilityFunctions';
import TypingAnimation from './TypingAnimation';


const ConversationIndicators = ({ }) => {

    const {username} = useSelector(state => state.auth_reducer);
    const { read, profilePhotos, lastMessage, typing} = useSelector(state => state.chat_reducer);

    const getProfilePhotoByUsername = (username) => {
        const userObj = profilePhotos?.find(obj => obj.hasOwnProperty(username));
        return userObj ? userObj[username] : '';
    }

    return (
       
        ((lastMessage && lastMessage.username === username && read && read.length > 1) || (typing != '' && typing != username) )&&
            (<div className='user-icons-read-conversation'>

                {typing != '' && typing != username && <TypingAnimation />}

                {lastMessage && lastMessage.username === username && read && read.length > 1 &&
                <div className='convo-indicators-right'>
                {read.filter((u)=>u!==username).map((p, i)=>{
                    return getProfilePhotoByUsername(p) !== '' ? (
                        <img key={i} src={getProfilePhotoByUsername(p)} className='read-img-user'/>
                    ) : (
                        <div key={i} className='read-img-default' style={{backgroundColor: `${stringToColor(p)}`}}>
                            {p.charAt(0)}
                        </div>
                    )})}
                </div>}
            </div>)  
    );
};

export default ConversationIndicators;
