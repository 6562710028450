// components/Conversation.jsx
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import "../../../Assets/css/conversation.css"
import back from "../../../Assets/icons/back-grey.png"
import plus from '../../../Assets/icons/add-g.png'
import { useNavigate, useParams } from 'react-router-dom';
import { popRoute, pushRoute, } from '../../../State/redux/actions/StateActions';
import {toggleConversationModal } from '../../../State/redux/actions/ChatActions'


const HeaderMessages = ({ }) => {
    const { stack } = useSelector(state => state.state_reducer);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const routeTo = (route) => {
        
        dispatch(pushRoute(route));
        navigate(route)
    }

    const goBack = (path) => {
        const route = stack && stack.length > 1 ? stack[stack.length-2] : path
        dispatch(popRoute());
        navigate(route)
    }

    const handleCreateConversation = (value) => {
        dispatch(toggleConversationModal(value))
    };

    return (
       
            <div className='messages-header-container'>
                <div className='messages-header-back'>
                    {stack && stack.length > 1 &&
                    <img src={back} className='back-btn-img' onClick={()=>{goBack('/conversations')}}/>}
                </div>
                <div className='messages-header-content'>
                    Messages
                </div>
                <div className='messages-header-info'>
                    <img src={plus} className='back-btn-img' onClick={()=>handleCreateConversation(true)}/>
                </div>
            </div>
           
    );
};

export default HeaderMessages;
