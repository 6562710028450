
import { createRef, useEffect, useState, React } from 'react';
import { useDispatch, useSelector, connect, ReactReduxContext, } from 'react-redux';
import { changeNumberOfLegsProfile, selectProfileNew, selectProfilePopular, selectProfileProfit, updateProfileSelection, } from '../../../State/redux/actions/StateActions.js';
import "../../../Assets/css/profile-bet-filters.css"
import newG from '../../../Assets/icons/new-g.png';
import newW from '../../../Assets/icons/new-w.png';
import eyeG from '../../../Assets/icons/eye-g.png';
import eyeW from '../../../Assets/icons/eye-w.png';
import checkmark from '../../../Assets/icons/checkmark.png'
import moneyG from '../../../Assets/icons/profit-g.png';
import moneyW from '../../../Assets/icons/profit-w.png';

// app component
function PopupSelectionFilters({dropdownSelectionFilter, dropdownSelectionLegs}) {

  const dispatch = useDispatch()
  const {showContentFilters, showContentLegs, numberOfLegsProfile, profileNewSelected, profilePopularSelected, profileProfitSelected} = useSelector(state => state.state_reducer)
  const [numLegs, setNumLegs] = useState('');

  // component did mount
  useEffect(() => {

  }, [])

  // component did update
  useEffect(() => {

  })

  function close(){
    dropdownSelectionFilter()
  }

  function selectedLegs(value){

    dispatch(changeNumberOfLegsProfile(value))
    dropdownSelectionLegs()
  }

  function submitNumberOfLegsProfile(){
    if(numLegs<2){
      dispatch(changeNumberOfLegsProfile(2))
      setNumLegs('')
    }else if(numLegs>1 && numLegs <6){
      dispatch(changeNumberOfLegsProfile(numLegs))
      setNumLegs('')
    }else{
      dispatch(changeNumberOfLegsProfile(numLegs))
    }

    dropdownSelectionLegs()
  }
  function updateNumberOfLegsProfile(value){
    if(value>200){
      value=200
    }
    setNumLegs(value)
  }

  function selectedFilter(value){

    switch(value){
      case 1:
        dispatch(selectProfileNew(true))
        dispatch(selectProfilePopular(false))
        dispatch(selectProfileProfit(false))
        dispatch(updateProfileSelection("New"))
        break;
      case 2:
        dispatch(selectProfileNew(false))
        dispatch(selectProfilePopular(true))
        dispatch(selectProfileProfit(false))
        dispatch(updateProfileSelection("Popular"))
        break;
      case 3:
        dispatch(selectProfileNew(false))
        dispatch(selectProfilePopular(false))
        dispatch(selectProfileProfit(true))
        dispatch(updateProfileSelection("Profit"))
        break;
      
    }
    dropdownSelectionFilter()
  }

  if(showContentFilters){
      return(
      <div className='profile-popup-container'>
        <p className='profile-popup-title'>Filter this persons bets</p>
            
        {!profileNewSelected?
        <div onClick={()=>selectedFilter(1)} className='profile-option'>
          <img src={newG} className='profile-icon-dropdown-selection'/>
          <h3 className='profile-option-text profile-green-unselected'>New</h3>
        </div>
        :
        <div onClick={()=>selectedFilter(1)} className='profile-option'>
          <img src={newW} className='profile-icon-dropdown-selection'/>
          <h3 className='profile-option-text profile-white-selected'>New</h3>
        </div>
        }
        
        {profilePopularSelected?
        <div onClick={()=>selectedFilter(2)} className='profile-option'>
          <img src={eyeW} className='profile-icon-dropdown-selection'/>
          <h3 className='profile-option-text profile-white-selected'>Popular</h3>
        </div>
          :
        <div onClick={()=>selectedFilter(2)} className='profile-option'>
          <img src={eyeG} className='profile-icon-dropdown-selection'/>
          <h3 className='profile-option-text profile-green-unselected'>Popular</h3>
        </div>}

        {profileProfitSelected?
        <div onClick={()=>selectedFilter(3)} className='profile-option'>
          <img src={moneyW}className='profile-icon-dropdown-selection'/>
          <h3 className='profile-option-text profile-white-selected'>Profit</h3>
        </div>
          :
        <div onClick={()=>selectedFilter(3)} className='profile-option'>
          <img src={moneyG}className='profile-icon-dropdown-selection'/>
          <h3 className='profile-option-text profile-green-unselected'>Profit</h3>
        </div>}

        <div className='profile-close-button-container'>
          <div onClick={()=>{close()}} className='profile-close-button'>
            Close
          </div>
        </div>
      </div>
      )
  }else if(showContentLegs){
    return(
    <div className='profile-popup-container'>

      <h3 className='profile-header-text profile-white-selected'>Filter by number of legs</h3>
      {/* Custom Bet Selection */}
      <div className='profile-option-legs'> 

        {/* Single Bet Selection */}
        <div onClick={()=>selectedLegs(1)} className={numberOfLegsProfile == 1?'profile-option-leg-dark':'profile-option-leg'}>
          <h1 className={numberOfLegsProfile == 1?'profile-number-legs-white-selected':'profile-number-legs-green-unselected'}>1</h1>
          <h3 className={numberOfLegsProfile == 1?'profile-option-text profile-white-selected':'profile-option-text profile-green-unselected'}>Single Bets</h3>
        </div>

        {/* 2 Leg Selection */}
        {numberOfLegsProfile == 2?
        <div onClick={()=>selectedLegs(2)} className='profile-option-leg-dark'>
          <h1 className='profile-number-legs-white-selected'>2</h1>
          <h3 className='profile-option-text profile-white-selected'>Leg Parlay</h3>
        </div>
          :
        <div onClick={()=>selectedLegs(2)} className='profile-option-leg'>
          <h1 className='profile-number-legs-green-unselected'>2</h1>
          <h3 className='profile-option-text profile-green-unselected'>Leg Parlay</h3>
        </div>}
        
      </div>

      {/* Row */}
      <div className='profile-option-legs'> 
        
        {/* 3 Leg Selection */}
        {numberOfLegsProfile == 3?
        <div onClick={()=>selectedLegs(3)} className='profile-option-leg-dark'>
          <h1 className='profile-number-legs-white-selected'>3</h1>
          <h3 className='profile-option-text profile-white-selected'>Leg Parlay</h3>
        </div>
          :
        <div onClick={()=>selectedLegs(3)} className='profile-option-leg'>
          <h1 className='profile-number-legs-green-unselected'>3</h1>
          <h3 className='profile-option-text profile-green-unselected'>Leg Parlay</h3>
        </div>}

            {/* 4 Leg Selection */}
            {numberOfLegsProfile == 4?
        <div onClick={()=>selectedLegs(4)} className='profile-option-leg-dark'>
          <h1 className='profile-number-legs-white-selected'>4</h1>
          <h3 className='profile-option-text profile-white-selected'>Leg Parlay</h3>
        </div>
          :  
        <div onClick={()=>selectedLegs(4)} className='profile-option-leg'>
          <h1 className='profile-number-legs-green-unselected'>4</h1>
          <h3 className='profile-option-text profile-green-unselected'>Leg Parlay</h3>
        </div>}

      </div>
      
      {/* Row */}
      <div className='profile-option-legs'>
      
        {/* All Leg Selection */}
        {numberOfLegsProfile == 0?
        <div onClick={()=>selectedLegs(0)} className='profile-option-leg-dark'>
          <h1 className='profile-number-legs-white-selected'>&infin;</h1>
          <h3 className='profile-option-text profile-white-selected'>All Bets</h3>
        </div>
          :  
        <div onClick={()=>selectedLegs(0)} className='profile-option-leg'>
          <h1 className='profile-number-legs-green-unselected'>&infin;</h1>
          <h3 className='profile-option-text profile-green-unselected'>All Bets</h3>
        </div>}

        {/* Custom Input */}
        <div className='profile-option-legs-custom'>
            <input onChange={(e)=>{updateNumberOfLegsProfile(e.target.value)}} type={"number"} className='profile-custom-legs-input' value={numLegs} placeholder='# Legs'/>
            <div className={'profile-submit-button profile-dark'} onClick={()=>{submitNumberOfLegsProfile()}}>
              <img className={'profile-img-icon'} src={checkmark}/> 
            </div>
          </div>
      </div>
    </div>
    )
  }
  

}

export default PopupSelectionFilters;
