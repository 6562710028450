
import { createRef, useEffect, useState, React, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeDescription, changeTitle, erasePicksForm, pushRoute, setVerifyPopup, toggleLock, updatePrice, updateState, } from '../../../State/redux/actions/StateActions.js';
import "../../../Assets/css/createpickspage.css"
import Knob from './Knob.js';
import unlock from '../../../Assets/icons/lock-grey.png'
import locked from '../../../Assets/icons/locked-grey.png'
import { useNavigate } from 'react-router-dom';
import { decimalToAmerican } from '../../../Utilities/UtilityFunctions.js';
import BetUnitStepper from './BetUnitStepper.js';
import { setCreatePicksPageNumber } from '../../../State/redux/actions/StateActions.js';

// app component
function SecondPage() {

  const state = useSelector(state => state.state_reducer)
  const {title, description, price, lock, height, stepperBetUnits} = useSelector(state => state.state_reducer)
  const auth = useSelector(state => state.auth_reducer)
  const {legs, tags, pickPost, success, isLoadingScreenshot, errorMsgScreenshot, parlayOdds} = useSelector(state => state.pick_reducer)
  const dispatch = useDispatch() 
  const navigate = useNavigate()
  
  const textareaRef = useRef()
  const [flagTitle, setFlagTitle] = useState(false)
  const [flagText, setFlagText] = useState('')

  const inputRef = useRef(null);

  const handleChange = (e) => {
    
    if(e.target.name == 'title'){

      if(title == '' && e.target.value != ''){
        setFlagTitle(false)
        setFlagText('')
      }
      
      dispatch(changeTitle(e.target.value))
    }else if(e.target.name == 'description'){

      // Reset height to allow it to shrink if text is deleted
      textareaRef.current.style.height = 'auto';
      // Set the height to the scroll height to expand as needed
      const maxHeight = height * 0.5
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight > maxHeight ? maxHeight : textareaRef.current.scrollHeight}px`;

      dispatch(changeDescription(e.target.value))
    }else if(e.target.name == 'price'){

      const numericValue = e.target.value.replace(/[^0-9.]/g, '');
      dispatch(updatePrice(numericValue))
    }else if(e.target.name == 'lock'){

      dispatch(toggleLock(e.target.value))
    }
    
  };

  const focusTextInput = () =>{
    setTimeout(()=>{
      inputRef.current.focus();
    },500)
  }

  const nextPage = () => {
    dispatch(setCreatePicksPageNumber(3))
  }

  const prevPage = () => {
    dispatch(setCreatePicksPageNumber(1))
  }

  return (

        <div className='create-picks-container' >

          <div className='create-title'>
            <input 
              maxlength="250"
              placeholder='Create Title' className={flagTitle?'input-create-title flagged':'input-create-title'}
              label="Post Title" name="title" value={title || ''} 
              onChange={handleChange}/>
                       
            <div className="text-counter">{title.length}/250</div>
          </div>

          <div className='create-description'>
            <textarea 
              maxlength="7000"
              rows={3} ref={textareaRef}
              placeholder='Create Description' className='input-create-description' 
              label="Post Description" name="description" value={description || ''} 
              onChange={handleChange}/>

            <div className="text-counter">{description.length}/7000</div>
          </div>


          <div className='bet-info-container'>
            {/*<div className='bet-info-sub-container'>
              <h3 className='odds-total'>
                {auth?.oddsDisplay === 'Decimal' ? (parlayOdds+" odds") : decimalToAmerican(parlayOdds)}
              </h3>
              <div className='units-small-msg'>
              Total odds
              </div>
            </div>*/}

            <BetUnitStepper />

            <div className='bet-info-sub-container'>
              <h3 className='odds-total'>
                ${parseFloat(stepperBetUnits*(auth?.units||10)).toFixed(2)}
              </h3>
              <div className='units-small-msg'>
              Total simulated bet
              </div>
            </div>
          </div>
          
          <div className='create-price'>  
            
            <h4 className='text-price'>Charge money for others to see this post</h4>  
            
            <div className='create-price-row'>  
             
              <div className='inline'>
                <input 
                // @TODO: fix hardcoded CAD
                  ref={inputRef}
                  placeholder={lock ? 'Price' : 'Free'} 
                  className='input-create-price' 
                  label="Price" name="price" type='text' 
                  value={lock ? (price != 0 ? `$${price}` : '') : ''} 
                  onChange={handleChange} disabled={lock ? false : true}/>
                  {lock && <h3 style={{color:"#838383", fontSize:'14px'}}>{auth?.currency || "CAD"}</h3>}
              </div>
          
              <div className='inline'>
                {lock == true ?
                <img src={locked} className='lock-img' />
                :
                <img src={unlock} className='lock-img' />}
                <Knob focusTextInput={focusTextInput}/>  
              </div>
            </div>
          </div>

          {flagText != ''&&
          <h3 className='flag-text'>
            {flagText}
          </h3>}

          <div className='page-buttons'>
            <div className='next-page-button-sm' onClick={prevPage}>
              Back
            </div>
            <div className={title === '' ?'next-page-button-sm-disabled':'next-page-button-sm'} onClick={title != '' ? nextPage : ()=>{}}>
              Preview
            </div>
          </div>
        </div>
  )

}

export default SecondPage;
