
import { createRef, useEffect, useState, React } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useDispatch, useSelector, connect, ReactReduxContext, } from 'react-redux';
import {alertUser, pushRoute, rerouteApp, resetRoutes, toggleExpandPickCard, toggleOpenCloseMenu, } from '../../State/redux/actions/StateActions.js';

import money from '../../Assets/icons/brain.png';
import profile from '../../Assets/icons/profile-g.png';
import poopGreen from '../../Assets/icons/poop-g.png';
import addGreen from '../../Assets/icons/add-g.png';
import trophyGreen from '../../Assets/icons/trophy-g.png';
import crownGreen from '../../Assets/icons/crown-g.png';
import listGreen from '../../Assets/icons/list-dark.png';
import checkmark from '../../Assets/icons/checkmark.png';
import Hamburger from 'hamburger-react'

import "../../Assets/css/menu.css"
import "../../Assets/css/homepage.css"
import "../../Assets/css/navigation.css"

import msg from '../../Assets/icons/msg-w.png';


// app component
function NavBar() {

  const dispatch = useDispatch()
  const {openCloseMenu, expandPickCard} = useSelector(state => state.state_reducer)
  const menuRef = createRef();
  const navigate = useNavigate();
  const authState = useSelector(state => state.auth_reducer)
  let location = useLocation();
  const {route, stack} = useSelector(state => state.state_reducer)

  // component did mount
  useEffect(() => {
      // keep track of our route
      if(stack && stack.length == 0){
        dispatch(pushRoute(location))
      } 
  }, [])

  useEffect(()=>{

    // if route is defined, go to that route
    if(route != ''){
      const url = route
      dispatch(rerouteApp(''))
      navigate(url)
    }
  },[route])

  // location did update
  useEffect(()=>{
    
      // change state
      if(expandPickCard){
        dispatch(toggleExpandPickCard(0))
      }

  },[location])

  function setOpenCloseMenu() {

    // change state
    dispatch(toggleOpenCloseMenu(!openCloseMenu))

    // change css for animation (refs required for animation transition)
    const ref = menuRef.current
    ref.classList.toggle("is-menu-open")
  }

  function routeTo(route){
    // if menu open close it
    openCloseMenu && setOpenCloseMenu()
    //dispatch(resetRoutes());
    dispatch(pushRoute(route));
    navigate(route);
  }

  return (
      <>
      <div className={"nav-bar"}>
        
        {/* Logo + Slogans */}
        <div className={"nav-logo"}>
          <div className={"left"}>
            
            <img onClick={()=>{routeTo('/picks')}} className='nav-img' src={money} />
            
            <div className={"nav-slogans-container"}>
              
              <h2 className={"nav-text"}>
                Pick Theory
              </h2>
              
              <div className={"nav-icon-slogan"}>
                  <img height={20} src={checkmark} />
                  <h5 className={"nav-mini-text"}>
                    Find that winning parlay
                  </h5>
              </div>

              <div className={"nav-icon-slogan"}>
                  <img height={20} src={checkmark} />
                  <h5 className={"nav-mini-text"}>
                    Sell your picks
                  </h5>
              </div>
            </div>
          </div>
        </div>

        {/* Hamburger Menu */}
        <div className={"nav-menu"}>
          <div className={"center"}>
            <Hamburger color={'#43A047'} toggle={setOpenCloseMenu} toggled={openCloseMenu} />
          </div>
        </div>
      </div>
      <div ref={menuRef} className={"menu"}>
          <div className='menu-page'>

            {authState?.token != null ? (
            <div onClick={()=>{routeTo(`/profile/${authState.username}`)}} className='menu-option margin-top'>
              Profile
              <img src={profile} className='icon-button margin-left'/>
            </div>): <div className='margin-top' />}
            <div onClick={()=>{routeTo('/create')}} className='menu-option'>
              Create Pick
              <img src={addGreen} className='icon-button margin-left'/>
            </div>
            <div onClick={()=>{routeTo('/picks')}} className='menu-option'>
              Picks
              <img src={listGreen} className='icon-button margin-left'/>
            </div>
            <div onClick={()=>{routeTo('/users')}} className='menu-option'>
              Users
              <img src={crownGreen} className='icon-button margin-left'/>
            </div>
            {/*<div onClick={()=>{routeTo('/hall-of-fame')}} className='menu-option'>
              Hall of Fame
              <img src={trophyGreen} className='icon-button margin-left'/>
            </div>
            <div onClick={()=>{routeTo('/hall-of-shame')}} className='menu-option'>
              Bad Beats
              <img src={poopGreen} className='icon-button margin-left'/>
            </div>*/}
            {authState?.token != null ?
              <>
                <div onClick={()=>{routeTo('/conversations')}} className='menu-option button-profile margin-top'>
                  <img src={msg} className='button-img'/>
                  Message
                </div>
                <div onClick={()=>{routeTo('/account')}} className='menu-option button-profile'>
                  Account
                </div>
              </>  
              : 
              <>
                <div onClick={()=>{routeTo('/login')}} className='menu-option button-profile margin-top'>
                  Log In
                </div>
                <div onClick={()=>{routeTo('/signup')}} className='menu-option button-profile'>
                  Sign Up
                </div>
              </>}
          </div>
          <div className='menu-footer'>
              <div className='menu-footer-option'>terms of use</div>
              <div className='menu-footer-option'>support</div>
          </div>
        </div>
        {openCloseMenu &&
        <div onClick={()=>{setOpenCloseMenu()}} className={"menu-grey-background"} />}
      </>
  )

}

export default NavBar;
