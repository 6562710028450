import React from 'react';
import '../../../Assets/css/skeleton-users.css'; // Ensure you create this CSS file

const Skeleton = () => {
    return (
        <div className="user-skeleton-container">
          <div className="user-shimmer">
            {/* Header with icon and stats */}
            
              <div className='user-skeleton-column-one'>
                <div className="user-skeleton__circle"></div>
              </div>
              <div className='user-skeleton-column-two'>
                <div className='user-skeleton-row-one'>
                  <div className="user-skeleton-name"></div>
                  <div className="user-skeleton-followers"></div>
                </div>
                <div className='user-skeleton-row-two'>
                  <div className="user-skeleton__square"></div>
                  <div className="user-skeleton__square"></div>
                  <div className="user-skeleton__square"></div>
                  <div className="user-skeleton__square"></div>
                  <div className="user-skeleton__square"></div>
                </div>
              </div>
            </div>

        </div>
      );
}

export default Skeleton;
