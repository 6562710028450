import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFormattedNumber, getTimeDate } from '../../../Utilities/UtilityFunctions.js';
import "../../../Assets/css/comments.css"
import x from '../../../Assets/icons/cancel-grey.png'
import { getPick, replyComment, likeReply } from '../../../State/redux/actions/PickActions.js';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import heartRed from '../../../Assets/icons/heart-red.png';
import heartGrey from '../../../Assets/icons/heart-gray.png';
import { useNavigate } from 'react-router-dom';
import { pushRoute } from '../../../State/redux/actions/StateActions.js';

export const Comment = ({ comment, thread, depth, count, scrollCallback }) =>{

  const [focusReply,setFocusReply] = useState(false)
  const [revealReplies,setRevealReplies] = useState((count>3||depth>3)?false:true)
  const [reply, setReply] = useState('');
  const dispatch = useDispatch()
  const navigate = useNavigate()
  
  const {username, name} = JSON.parse(localStorage.getItem('profile')) ?? {username:'', name:''};
  const {pick} = useSelector(state => state.pick_reducer)
  const auth = useSelector(state => state.auth_reducer)

  const handleComment = async () => {
    const date = new Date()
    const n = (username != '' && username) ? username : name
    const path = comment.path ? [...comment.path,comment._id ] : [comment._id,]
    const newComments = await dispatch(replyComment(n, reply, date, pick._id, path));
    dispatch(getPick(pick._id))
    setReply('');
    setFocusReply(false)
    //setComments(newComments);
    //commentsRef.current.scrollIntoView({ behavior: 'smooth' });
    
    scrollCallbackSecondary()
  };

  const handleLikes = async () =>{
    const n = (username != '' && username) ? username : name
    const path = comment.path ? [...comment.path,comment._id ] : [comment._id,]
    const liked = await dispatch(likeReply(n, pick._id, path));
    dispatch(getPick(pick._id))
  }

  const scrollCallbackSecondary = () =>{
    scrollCallback()
  }

  const routeTo = (route) => {
        
    dispatch(pushRoute(route));
    navigate(route)
}

  return (
    <div className={thread?'comment-parent thread':'comment-parent'}>
      <div className={'comment'} >
        <div className='row-likes'>
          <div className='name-comment-date'>
            <div className={'comment-header'} onClick={()=>{setRevealReplies(!revealReplies)}}>
              <div onClick={()=>{routeTo(`/profile/${comment.name}`)}}>
                <strong>
                  {comment.name}
                </strong>
              </div> 
              <div className='time-ago'>
                {getTimeDate(comment.date)}
              </div>
            </div>

            <p className={'comment-content'} onClick={()=>{setRevealReplies(!revealReplies)}}>
              {comment.msg}
            </p>
          </div>

          <div className='comment-like-counter'>
            <img src={comment?.likes?.includes(auth?.username) ? heartRed : heartGrey} className='vote-heart' onClick={handleLikes}/>
            <p className='comment-vote-count'>{comment?.likes ? getFormattedNumber(comment.likes.length) : 0}</p>
          </div>
        </div>
        <div className={'comment-footer'}> 
          <div
            className='reply-button'
            onClick={()=>{setFocusReply(true)}}
          >
              reply
          </div>

          {!revealReplies && comment?.replies?.length > 0 &&
          <div
            className='reply-count'
            onClick={()=>{setRevealReplies(!revealReplies)}}
          >
              view replies
          </div> }
        </div>
      </div>

      {focusReply && (
      <div className='write-reply-parent'>
        <textarea 
        rows={4}
        label="Comment" value={reply} 
        placeholder={"Write a comment"} 
        onChange={(e) => setReply(e.target.value)} 
        className='write-reply'/>
        <div className='reply-buttons'>
          <div className='submit-reply-button' disabled={!reply.length} onClick={handleComment}>
            Submit Reply
          </div>
          <div className='reply-button-exit' disabled={!comment.length} onClick={()=>{setFocusReply(false)}}>
            <img src={x} className='reply-button-exit-img'/>
          </div>
        </div>
      </div>)}
    
      <TransitionGroup style={{marginLeft: '5%', width:'95%'}}>
      {revealReplies && comment?.replies?.map((reply, i) => (
        <CSSTransition
          key={i}
          timeout={500}
          classNames="item"
        >
          <Comment className='item' comment={reply} thread={true} depth={depth+1} scrollCallback={scrollCallbackSecondary}/>
        </CSSTransition>
      ))}
      </TransitionGroup>
      
    </div>
  );
}

{/* <div className={'comment-parent'}>
{comments?.map((c, i) => (
  <div className={'comment'} key={i}>
    <div className={'comment-header'}>
      <div>
        <strong>
          {c.name}
        </strong>
      </div> 
      <div className='time-ago'>
        {getTimeDate(c.date)}
      </div>
    </div>
    <p className={'comment-content'}>
      {c.msg}
    </p>
  </div>
))}
<div ref={commentsRef} />
</div> */}
