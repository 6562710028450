import React, { useState } from 'react';
import '../../../Assets/css/stepper.css'
import { useDispatch, useSelector } from 'react-redux';
import { pushRoute, setStepperBetUnits } from '../../../State/redux/actions/StateActions';
import { useNavigate } from 'react-router-dom';

function BetUnitStepper() {
    
    const dispatch = useDispatch()
    const {stepperBetUnits} = useSelector(state=>state.state_reducer)
    const auth = useSelector(state=>state.auth_reducer)
    const navigate = useNavigate();

    const handleInputChange = (event) => {
        const newValue = parseFloat(event.target.value)
        // Validate the input to ensure it's a number, otherwise revert to previous value
        if (!isNaN(newValue) && newValue <= 10) {
            dispatch(setStepperBetUnits(newValue));
        }
    };

    const handleIncrement = () => {
 
        var val
        if (stepperBetUnits < 1) {
            val = stepperBetUnits + 0.1 // Increment by 0.1 and fix to 2 decimal places
        }else if (stepperBetUnits >= 10) {
            val = 10
        }else{
            val = stepperBetUnits + 1
        }

        val = parseFloat(parseFloat(val).toFixed(2))

        dispatch(setStepperBetUnits(val));
    };

    const handleDecrement = () => {
        
        var val
        if (stepperBetUnits <= 0.1) {
            val = 0.1 // Prevent the value from going below 0
        } else if (stepperBetUnits <= 1) {
            val = stepperBetUnits-0.1 // Decrement by 0.1 and fix to 2 decimal places
        }else{
            val = stepperBetUnits-1
        }
        val = parseFloat(parseFloat(val).toFixed(2))
        dispatch(setStepperBetUnits(val));
    };

    const routeTo = (route) => {
        
        dispatch(pushRoute(route));
        navigate(route)
    }

    return (
        <div className='stepper-parent'>
            <div className='units-title'>
                Bet Units
            </div>
            <div className='stepper-container'>
                <div className='stepper' onClick={()=>{handleDecrement()}}>-</div>
                <input 
                    type="number" 
                    value={stepperBetUnits} 
                    onChange={(event)=>{handleInputChange(event)}} 
                    className='stepper-input'
                    
                />
                <div className='stepper' onClick={()=>{handleIncrement()}}>+</div>
            </div>
            {auth?.loggedIn ?
            <div className='units-small-msg'>
                How many units do you think this bet should be? Your units are ${auth?.units ? auth?.units : 10}.  Change your units <a className='underline-link' onClick={()=>{routeTo("/account")}}>here</a>
            </div>
            :
            <div className='units-small-msg'>
                How many units do you think this bet should be? Your units are ${auth?.units ? auth?.units : 10}.
            </div>
            }
        </div>
    );
}

export default BetUnitStepper;
