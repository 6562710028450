// components/Conversation.jsx
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import "../../../Assets/css/accountpage.css"
import back from "../../../Assets/icons/back-grey.png"
import ellipsis from "../../../Assets/icons/ellipsis-grey.png"
import { useNavigate, useParams } from 'react-router-dom';
import { popRoute, pushRoute } from '../../../State/redux/actions/StateActions';


const Header = ({label = "Account"}) => {

    const { stack } = useSelector(state => state.state_reducer);
    const auth = useSelector(state => state.auth_reducer);
    const {user} = useSelector(state => state.user_reducer);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const routeTo = (route) => {
        
        dispatch(pushRoute(route));
        navigate(route)
    }

    const setEditProfile = () =>{
        
        if(user?.username === auth?.username){
            routeTo('/edit-profile')
            const u = user
            u.oldUsername = u.username
            //dispatch(editProfile(u))
        }

    }

    const goBack = () =>{

        const route = stack && stack.length > 1 ? stack[stack.length-2] : `/`
        navigate(route)
        dispatch(popRoute())
    }

    return (
       
        <div className='account-header-container'>
            <div className='account-header-back'>
                {stack && stack.length > 1 &&
                <img src={back} className='back-btn-img' onClick={goBack}/>}
            </div>
            <div className='account-header-content'>
                {label}
            </div>
            <div className='account-header-info'>
                {/*<img src={ellipsis} className='back-btn-img' onClick={()=>{}}/>*/}
            </div>
        </div>
    );
};

export default Header;
