
import { createRef, useRef, useEffect, useState, React } from 'react';
import { useDispatch, useSelector, connect, ReactReduxContext, } from 'react-redux';
import { createState, pushRoute, setShareAsDM, setShareLink, setShareToggle, setTappedStatistics, toggleRevealEntirePost} from '../../../State/redux/actions/StateActions.js';
import "../../../Assets/css/entire-post.css"
import cancel from '../../../Assets/icons/cancel-grey.png';
import heartRed from '../../../Assets/icons/heart-red.png';
import heartGrey from '../../../Assets/icons/heart-grey.png';

import arrowUpGrey from '../../../Assets/icons/expand-up.png';
import locked from '../../../Assets/icons/locked-grey.png';
import { useNavigate } from 'react-router-dom';
import PickRow from './PickRow.js';
import { getPick, likePick } from '../../../State/redux/actions/PickActions.js';
import { decimalToAmerican, getFormattedNumber, getTimeDate, stringToColor } from '../../../Utilities/UtilityFunctions.js';
import CommentSection from './CommentSection.js';
import Tag from './Tag.js';
import share from "../../../Assets/icons/share.png"
import exit from "../../../Assets/icons/cancel-grey.png"
import SharePopup from './SharePopup.js';

// app component
function EntirePost({post}) {

  const dispatch = useDispatch()
  const {pick} = useSelector(state => state.pick_reducer)
  const {height, tappedStatistics, shareToggle} = useSelector(state => state.state_reducer)
  const auth = useSelector(state => state.auth_reducer)
  

  const revealRef = useRef();
  const navigate = useNavigate();
  const scrollRef = useRef(null);
  const [scrollDirection, setScrollDirection] = useState(1); // 1 for right, -1 for left
  const [yPosition, setYPosition] = useState(null)
  const {username, overallRecordString, winRateCategory, hotStreak, likes, totalOdds, title, description, legs, tags, createdAt, lock, profitCategory, winRate, wholeBetGraded, wholeBetWin, units, id } = pick
  const myUnits = auth?.units ? parseFloat(auth?.units) : 10
  const photo = ''

  // component did mount
  useEffect(() => {

    setTimeout(()=>{
      const ref = revealRef.current
      ref.classList.toggle('reveal-post')
    }, 100)

  }, [])

  
  // component did update
  useEffect(() => {
  
      // const interval = setInterval(() => {
      //   if (scrollRef.current) {
      //     const isAtRightEnd = scrollRef.current.scrollWidth <= scrollRef.current.scrollLeft + scrollRef.current.offsetWidth;
      //     const isAtLeftEnd = scrollRef.current.scrollLeft === 0;
  
      //     if (isAtRightEnd) {
      //       setScrollDirection(-1);
      //     } else if (isAtLeftEnd) {
      //       setScrollDirection(1);
      //     }
  
      //     scrollRef.current.scrollLeft += scrollDirection;
      //   }
      // }, 50); // Adjust the interval for smoother or faster scrolling
      // return () => clearInterval(interval);
  
  }, [scrollDirection])


  const routeTo = (route) => {
        
    dispatch(pushRoute(route));
    navigate(route)
}

  function upVote(){

    dispatch(likePick(id))
  }


  function picks(){
    return(
      <div className={'entire-post-picks'}>
        {legs.map((leg, index)=>{
          
            return <PickRow key={index} leg={leg} entirePost={true}/>
         
        })}  
      </div>
    )
  }

  function getTags(){
    return(
      tags.map((tag, index)=>{
          return (
             <Tag tag={tag} color={photo == '' ? `${stringToColor(pick?.username)}` : null} key={index} className={(index+1) == pick.tags.length?'margin-right-end':''}/>
          )
      })
    )
  }

  function getOdds(){
    let odds = totalOdds
    if(totalOdds>0){
      odds = "+"+totalOdds
    }
    //return odds
    return  auth?.oddsDisplay === 'Decimal'?("x "+totalOdds+" odds"):decimalToAmerican(totalOdds >= 0 ? "+"+totalOdds:totalOdds)

  }

  const goBack = () =>{

    setYPosition(null)
    const ref = revealRef.current
    ref.classList.toggle('reveal-post')
    setTimeout(()=>{
      dispatch(toggleRevealEntirePost(''))
    },500)
  }

  const handleStart = (event, mobile) => {
    // Start dragging logic
    if(mobile){
      if (event.touches.length > 0) {
        const touch = event.touches[0];
        const y = touch.clientY;
        setYPosition(y)
      } 
    }else{
      setYPosition(event.clientY)
    }
  };

  const handleMove = (event, mobile) => {
    // Dragging logic
    if(mobile){
      if (event.touches.length > 0) {
        const touch = event.touches[0];
        const y = touch.clientY;
        setYPosition(y)
      } 
    }else{
      setYPosition(event.clientY)
    }
  };

  const handleEnd = (event, mobile) => {
    // End dragging logic
    if(yPosition > height*0.3){
      goBack()
    }
  };

  const ProfileIcon = () => {
  
    return (
        (photo !== '' ?
            <img src={photo} className='pick-profile-img'/>
        :
            <div className='pick-profile-img-default' style={{backgroundColor: `${stringToColor(pick?.username)}`}} >
              {pick?.username.charAt(0)}
            </div>
        )
    )
};

function determineGoodWinRate(rate, legs){

  var good = false
  switch(legs){
    case 1:
      if(rate>=50){
        return true
      }else{
        return false
      } 
    case 2:
      if(rate>=25){
        return true
      }else{
        return false
      } 
    case 3:
      if(rate>=12){
        return true
      }else{
        return false
      } 
    case 4:
      if(rate>=6){
        return true
      }else{
        return false
      } 
    case 5:
      if(rate>=3){
        return true
      }else{
        return false
      } 
    default:
      if(rate>=2){
        return true
      }else{
        return false
      } 
  }
}

const showMenu = () =>{

  if (shareToggle) {
      // Add the 'exit' class to start the slide-out animation
      const element = document.querySelector('.slide-in-out');
      element.classList.add('exit');
  
      dispatch(setShareAsDM(false))
      // Wait for the animation to finish before changing state
      setTimeout(() => {
        dispatch(setShareToggle(false))
        
      }, 500); // Match the duration of your CSS animation

      dispatch(setShareLink(''))
  } else {
      dispatch(setShareToggle(true))
  }
};

  return (

    <>
      <div className='exit-button-container-post'>
        <div className='exit-box-left' >
          {!shareToggle && <img src={cancel} className='exit-button-img-post' onClick={()=>{goBack()}}/>}
        </div>
        <div className='name-box'>
          <h4 className='entire-post-username'>{pick?.username}</h4>
        </div>
        <div className='exit-box-right' >
          <img src={!shareToggle ? share : exit} className='back-btn-img-post' onClick={()=>{showMenu()}}/>
        </div>
      </div>

      {shareToggle &&
        <SharePopup showMenu={showMenu} post={post}/>
      }



      <div ref={revealRef} className={'entire-post-parent'} style={yPosition && height && (yPosition > height*0.2) ? {top: yPosition, transition:'none'}:{}}>
        {pick &&
        <>
        <div className='entire-post-body'>
        <div className={'entire-pick-card-header'}>
            
            <div className={'pick-card-header-left'}>
              
              <div className='pick-img-container' onClick={()=>{routeTo(`/profile/${username}`)}}>
                <h4 className='pick-text-name'>{username}</h4>
                <ProfileIcon />
                <h4 className='pick-details-text'>({overallRecordString})</h4>
              </div>
            </div>

              {wholeBetGraded ?
                <div className='pick-record-parent' >
                  <div className= {wholeBetWin ?'win-card':'loss-card'} >
                      <div className='text-inner-record'>
                        {wholeBetWin ? "Win" : "Loss"}
                      </div>
                  </div>
                  <div className={wholeBetWin ?'win-card':'loss-card'} >
                    <div className='text-inner-record'>
                    { wholeBetWin ? 
                    "$"+(((parseFloat(totalOdds)*parseFloat(units))-parseFloat(units))*myUnits).toFixed(0)
                      :
                    "-$"+((parseFloat(units)*myUnits).toFixed(0))}
                    </div>
                  </div>  
                </div>
              : 
                (tappedStatistics ? 
                <div className='pick-record-parent' >
                  <div className='pick-record-container' onClick={()=>{dispatch(setTappedStatistics(tappedStatistics))}}>
                    <p className='pick-record-text'>
                      Win Rate
                    </p>
                    <div className={determineGoodWinRate(winRateCategory, legs.length)?'good-record':'bad-record'}>
                      {parseFloat(winRateCategory).toFixed(0)}%
                    </div>
                    <p className='pick-record-text'>
                      {legs && legs.length > 1 ? legs.length + " legs" : "Single bets"}
                    </p>
                  </div>

                  <div className='pick-record-container' onClick={()=>{dispatch(setTappedStatistics(tappedStatistics))}}>
                    <p className='pick-record-text'>
                      Profit
                    </p>
                    <div className={parseFloat(profitCategory) >= 0 ?'good-record  money-small':'bad-record  money-small'}>
                      {parseFloat(profitCategory) >= 0 ? "$"+(parseFloat(profitCategory)*myUnits).toFixed(0) : "-$"+((parseFloat(profitCategory)*myUnits).toFixed(0)*-1)}
                    </div>
                    <p className='pick-record-text'>
                      {legs && legs.length > 1 ? legs.length + " legs" : "Single bets"}
                    </p>
                  </div>  
                </div>
                : 
                <div className='pick-record-parent'>
                  <div className='pick-record-container' onClick={()=>{dispatch(setTappedStatistics(tappedStatistics))}}>
                    <p className='pick-record-text'>
                      Win Rate
                    </p>
                    <div className={determineGoodWinRate(winRate, legs.length)?'good-record':'bad-record'}>
                      {parseFloat(winRate).toFixed(0)}%
                    </div>
                    <p className='pick-record-text'>
                      All bets
                    </p>
                  </div>
                  <div className='pick-record-container' onClick={()=>{dispatch(setTappedStatistics(tappedStatistics))}}>
                    <p className='pick-record-text'>
                      Hot Streak
                    </p>
                    <div className={hotStreak >= 0 ?'good-record':'bad-record'}>
                      {hotStreak}
                    </div>
                    <p className='pick-record-text'>
                      {hotStreak >= 0 ? "Wins" : "Losses"}
                    </p>
                  </div>
                </div>)
              }

            <div className={'pick-card-header-right'}>
              <div className='pick-like-counter'>
                <img src={ likes?.length && likes?.includes(auth?.id) ? heartRed : heartGrey} className='vote-arrows' onClick={()=>{upVote()}}/>
                <p className='vote-count'>{pick?.likes.length ? getFormattedNumber(pick.likes.length) : 0}</p>
                {/*<img src={down} className='vote-arrows' onClick={()=>{downVote()}}/>*/}
              </div>
            </div>
        </div>



        {picks()}
        <div className='expand-unlocked-picks'>
          <div className='card-legs'>{legs.length > 1 ? legs.length + " leg parlay" : "single bet"}</div>
          
          <div className='card-icon'>
           
          </div>
          
          <div className='card-odds'>{getOdds()} &bull; {units} units</div>
        </div>

        
          <div className='entire-description'>
              <div className='mini-text'>
                <p>Created {getTimeDate(createdAt)}</p>
                <p>{getFormattedNumber(pick.totalComments)} comments</p>
              </div>
              <h3>{title}</h3>
              <p>{description!=''?description:"No description"}</p>
          </div>

          <CommentSection pick={pick}/>
        </div>
        </>}
      </div>
    </>
  )

}

export default EntirePost;
