// components/Conversation.jsx
import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import "../../../Assets/css/conversation.css"
import { useNavigate, useParams } from 'react-router-dom';
import { getTime, stringToColor } from '../../../Utilities/UtilityFunctions';
import { popRoute, pushRoute } from '../../../State/redux/actions/StateActions';
import { getProfile } from '../../../State/redux/actions/UserActions';
import share from "../../../Assets/icons/share.png"

const MessageRow = ({m}) => {
    const { username} = useSelector(state => state.auth_reducer);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {profilePhotos,} = useSelector(state => state.chat_reducer);
    const { stack } = useSelector(state => state.state_reducer);
    const [sharedURL, setSharedURL] = useState('')

    useEffect(() => {

        getSharedUrl()
    }, []);

    const getSharedUrl = async () =>{
        const allowedOrigins = [
            'http://localhost:3000',
            'https://staging.picktheory.com',
            'https://picktheory.com'
        ];
        const urls = m.content.includes(allowedOrigins[0]) || m.content.includes(allowedOrigins[1]) || m.content.includes(allowedOrigins[2]);
        if (urls) {
            if(m.content.includes("profile")){
                const profileResult = splitAfterKeyword(m.content, '/profile/');
                const profile = await dispatch(getProfile(profileResult))
                setSharedURL(profile.username)
            }else if(m.content.includes("post")){
                //const postResult = splitAfterKeyword(m.content, '/post/');
                //const post = await dispatch(getPost(postResult))
            }
        }
    }
    function splitAfterKeyword(url, keyword) {
        const index = url.indexOf(keyword);
        
        if (index !== -1) {
            // Add the length of the keyword to start the substring right after it
            const startOfSuffix = index + keyword.length;
            const base = url.substring(0, startOfSuffix); // Contains the URL up to and including the keyword
            const suffix = url.substring(startOfSuffix); // Contains the part of the URL after the keyword
            
            //return { base, suffix };
            return suffix
        } else {
            return null; // Keyword not found
        }
    }

    const getProfilePhotoByUsername = (username) => {
        const userObj = profilePhotos?.find(obj => obj.hasOwnProperty(username));
        return userObj ? userObj[username] : '';
    }

    const routeTo = (route) => {
        
        dispatch(pushRoute(route));
        navigate(route)
    }

    const ProfileIcon = () => {
      
        return (
        m.username != username && (getProfilePhotoByUsername(m.username) !== '' ?
            <img src={getProfilePhotoByUsername(m.username)} className='profile-img-default' onClick={()=>{routeTo(`/profile/${m.username}`)}}/>
        :
            <div className='convo-profile-img-default' style={{backgroundColor: `${stringToColor(m.username)}`}} onClick={()=>{routeTo(`/profile/${m.username}`)}}>
               {m.username.charAt(0)}
            </div>
        ))
    };

    const MessageBubble = () => {
      
        return (
        <div className='message-bubble'>
            <div className="msg-username" style={{color: `${stringToColor(m.username)}`}}>
                {m.username}
            </div>
            <div className="msg-content">
                {sharedURL != '' ? (
      
                    <div onClick={()=>{routeTo(`/profile/${sharedURL}`)}}>
                        <img src={share} className='msg-back-btn-img'/>
                        <strong>{sharedURL}</strong>
                        
                        <div>Have a look at this profile</div>
                    </div>

                ) : (
                    m.content
                )}
            </div>
            <div className="msg-timestamp">
                {getTime(m?.timestamp)}
            </div>
        </div>
        )
    };

    return (
         
    <div className={m.username == username ? 'message-bubble-container right':'message-bubble-container left'}>
        
        <ProfileIcon />
        <MessageBubble />
    </div>
                       
    );
};

export default MessageRow;
