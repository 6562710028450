// ProductSelection.js
import React from 'react';

const ProductSelection = ({ onSelection }) => {
  return (
    <div className="product-selection">
      <h2>Reveal picks</h2>
      <p>One time payment for this one pick</p>
      <button className="payment-button" onClick={() => onSelection(3.99, 'One time payment', 1)}>$3.99</button>
      <p>Monthly subscription to all of this users picks</p>
      <button className="payment-button" onClick={() => onSelection(29.99, 'Monthly subscription', 2)}>$29.99</button>
      <p>Yearly subscription to all of this users picks</p>
      <button className="payment-button" onClick={() => onSelection(180, 'Yearly subscription', 3)}>$180.00</button>
    </div>
  );
};

export default ProductSelection;
