import { useEffect, } from 'react';
import { BrowserRouter, Routes, Route, useLocation, } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google'
import { useDispatch, useSelector,} from 'react-redux';
import {changeHeight, changeWidth, initializeState, pushRoute, } from '../State/redux/actions/StateActions.js';
import HomePage from './Home/HomePage.js';
import NavBar from './NavBar/NavBar.js';
import LoginPage from './Auth/LoginPage.js';
import { refreshToken, signinFromToken, updateAuth } from '../State/redux/actions/AuthActions.js';
import SignupPage from './Auth/SignupPage.js';
import ProfilePage from './Profile/ProfilePage.js'
import PicksPage from './Picks/PicksPage.js';
import CreatePicksPage from './CreatePicks/CreatePicksPage.js';
import { hasSixtyMinutesPassed } from '../Utilities/UtilityFunctions.js';
import Popup from './Popup/Popup.js';
import Conversation from './Messages/Conversation.js';
import ListConversations from './Messages/ListConversations.js';
import ConversationDetails from './Messages/ConversationDetails.js';
import DirectMessage from './Messages/DirectMessage.js';
import EditProfile from './Profile/EditProfile.js';
import Users from './Users/Users.js';
import Account from './Account/Account.js';
import PhoneUpdate from './Account/PhoneUpdate.js';
import PopupVerifySMS from './VerifySMS/PopupVerifySMS.js';
import LockPost from './Payments/LockPost.js';
import UnlockPost from './Payments/UnlockPost.js';
import '../Assets/css/app.css'
import EmailUpdate from './Account/EmailUpdate.js';
import PasswordUpdate from './Account/PasswordUpdate.js';
import UsernameUpdate from './Account/UsernameUpdate.js';
import NukeRecord from './Account/NukeRecord.js';

// app component
function App(){
  
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth_reducer)
  const {popupMessages, verifyPopup, withdrawalInfoPopup, paymentPopup} = useSelector(state => state.state_reducer)

  // component did mount
  useEffect(()=>{
    // add redux state and initialize values
    dispatch(initializeState(window.innerWidth, window.innerHeight))

    initializeAuth()
    document.title = "Pick Theory"

    // resize listener
    window.addEventListener("resize", updateSize);

    // unmount component
    return () => {
      window.removeEventListener("resize", updateSize);
    }
  },[])

  const initializeAuth = () =>{
    const token =  JSON.parse(localStorage.getItem('profile'));
    // sign back in if token exists but were not logged in
    if(token != null && token.token != '' && (token.loggedIn == false || hasSixtyMinutesPassed(token.start))){ 
      var browser = window.navigator.userAgent
      // @TODO: signInFromToken INSTEAD?
      // dispatch(signinFromToken(token, window.innerHeight, window.innerWidth, browser));
      dispatch(refreshToken(false))
    }else if(token && (auth.length == 0 || auth.id == '')){
      
      dispatch(updateAuth(token.token));
    }
  }

  // reset the window size
  function updateSize() {
    dispatch(changeWidth(window.innerWidth))
    dispatch(changeHeight(window.innerHeight))
  }

  return(

    <BrowserRouter>
      <GoogleOAuthProvider clientId={"174643741634-4dk08rublm6bv400ne3d8sd9h5q66mm8.apps.googleusercontent.com"} >
        <div className={"page"}>
          <div className={"centered"}>
            <NavBar/>
            <Routes>
                <Route index element={<PicksPage/>} />
                <Route path="/login" exact element={ <LoginPage/>} />
                <Route path="/signup" exact element={ <SignupPage/>} />
                <Route path="/profile/:username" element={ <ProfilePage/>} />
                <Route path="/edit-profile" element={ <EditProfile/>} />
                <Route path="/picks/:post" element={<PicksPage/>} />
                <Route path="/picks/" element={<PicksPage/>} />
                <Route path="/create" exact element={ <CreatePicksPage/>} />
                <Route path="/conversations" exact element={ <ListConversations/>} />
                <Route path="/conversation/:conversationId" element={ <Conversation/>} />
                <Route path="/conversation/details/:conversationId" element={ <ConversationDetails/>} />
                <Route path="/direct-message/:username" element={ <DirectMessage/>} />
                <Route path="/users" element={ <Users/>} />
                <Route path="/account" element={ <Account/>} />
                <Route path="/update-phone" element={ <PhoneUpdate/>} />
                <Route path="/update-email" element={ <EmailUpdate/>} />
                <Route path="/update-password" element={ <PasswordUpdate/>} />
                <Route path="/update-username" element={ <UsernameUpdate/>} />
                <Route path="/nuke-record" element={ <NukeRecord/>} />
                
                
                {/*
                <Route path="halloffame" exact element={ <FamePage/>} />
                <Route path="payment" exact element={ <PaymentPage/>} />
                */}

            </Routes>
            
            {verifyPopup &&
            <PopupVerifySMS />}

            {paymentPopup &&
            <UnlockPost />}

            {withdrawalInfoPopup &&
            <LockPost />}

            {popupMessages?.length > 0 && 
            <div className='popup-messages'>
              {popupMessages.map((msg, i)=>{return (<Popup key={i} popupMessage={msg}/>)})}
            </div>}
          </div>
        </div>
      </GoogleOAuthProvider>
    </BrowserRouter>

  )
  
}

export default App;

