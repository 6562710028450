// components/Conversation.jsx
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import "../../Assets/css/conversation.css"
import crayon from "../../Assets/icons/crayon.png"
import back from "../../Assets/icons/back.png"
import checkmark from "../../Assets/icons/checkmark.png"
import cancel from "../../Assets/icons/cancel-r.png"
import { joinConversation } from '../../State/redux/actions/ChatActions';
import { useNavigate, useParams } from 'react-router-dom';
import { stringToColor } from '../../Utilities/UtilityFunctions';
import { popRoute, pushRoute } from '../../State/redux/actions/StateActions';
import HeaderDetails from './SubComponents/HeaderDetails';
import { createConnection, getSocket } from '../../State/api/socket';


const ConversationDetails = ({ }) => {
   
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let { conversationId } = useParams();
    const {activeParticipants, profilePhotos, participants} = useSelector(state => state.chat_reducer);


    useEffect(() => {
        
        createConnection(); // Ensures websocket connection is created
        // @TODO: check convo id
        if(activeParticipants?.length < 1 || participants?.length < 1 || profilePhotos?.length < 1){
            dispatch(joinConversation(conversationId))
        }
        return () => {
          // disconnect from websocket connection
          var socket = getSocket();
          if (socket) socket.disconnect();
        };
      }, []);


    useEffect(() => {

        // @TODO: throttle



    }, []);

    const routeTo = (route) => {
        
        dispatch(pushRoute(route));
        navigate(route)
    }

    const getProfilePhotoByUsername = (username) => {
        const userObj = profilePhotos?.find(obj => obj.hasOwnProperty(username));
        return userObj ? userObj[username] : '';
    }

    const ProfileIcon = ({u}) => {
      
        return (
            (getProfilePhotoByUsername(u) !== '' ?
                <img src={getProfilePhotoByUsername(u)} className='profile-img-default'/>
            :
                <div className='convo-profile-img-default' style={{backgroundColor: `${stringToColor(u)}`}} >
                {u.charAt(0)}
                </div>
            )
        )
    };

    return (
        <div className='conversation-details'>
           
                <HeaderDetails />
              
                {participants.map((p, i)=>{
                   return (
                    <div key={i} className='conversation-participants' onClick={()=>{routeTo(`/profile/${p}`)}}>
                        <div className='conversation-participants-column'>
                            <ProfileIcon u={p}/>
                            {p}
                        </div>
                        {activeParticipants.includes(p) && ( <div className='green-active-participant'/> ) }
                    </div>)
                })}
        </div>
    );
};

export default ConversationDetails;
