// components/Conversation.jsx
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import "../../../Assets/css/pickspage.css"
import back from "../../../Assets/icons/back-grey.png"
import info from "../../../Assets/icons/info-g.png"
import { useNavigate } from 'react-router-dom';
import { popRoute, pushRoute } from '../../../State/redux/actions/StateActions';
import share from "../../../Assets/icons/share.png"
import exit from "../../../Assets/icons/cancel-grey.png"

const Header = () => {

    const { stack, shareToggle } = useSelector(state => state.state_reducer);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const routeTo = (route) => {
        
        dispatch(pushRoute(route));
        navigate(route)
    }

    const goBack = () => {
        const route = stack && stack.length > 1 && stack[stack.length-2]
        dispatch(popRoute());
        navigate(route)
    }

    return (
       
            <div className='pickspage-header-container'>
                <div className='pickspage-header-back'>
                    {stack && stack.length > 1 &&
                    <img src={back} className='back-btn-img' onClick={()=>{goBack()}}/>}
                </div>
                <div className='pickspage-header-content'>
                    Top Picks
                </div>
                <div className='pickspage-header-info'>
                    {/*<img src={!shareToggle ? share : exit} className='back-btn-img' onClick={()=>{showMenu()}}/>*/}
                </div>
            </div>
           
    );
};

export default Header;
