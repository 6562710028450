import React, { useState, useEffect, useRef, createRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isTimestampMoreThan30SecondsAgo } from '../../../../Utilities/UtilityFunctions';
import { TailSpin } from 'react-loader-spinner';
import { setErrorMessagePhone, savePhoneNumber, sendSMS, verifySMSCode } from '../../../../State/redux/actions/UserActions';
import { ResendCode } from './ResendCode';
import { popRoute } from '../../../../State/redux/actions/StateActions';
import { refreshToken } from '../../../../State/redux/actions/AuthActions';

export const ApplyCode = ({setChangeNumberClicked, showAnimation}) => {

    //const {phone} = useSelector(state=>state.auth_reducer)
    const auth = useSelector(state => state.auth_reducer);
    const {errorMsgCode, isLoadingPhone, timestampSMS} = useSelector(state => state.user_reducer);
    const dispatch = useDispatch();
    const [inputs, setInputs] = useState(Array(6).fill(''));
    // Create an array of refs
    const inputRefs = useRef(inputs.map(() => createRef()));

    useEffect(() => {
       sendCodeToUser()
    }, []);

    const sendCodeToUser = async () =>{

         // Focus the first input on component mount
         if(!isLoadingPhone && inputRefs?.current[0] && (auth?.phone != 0 && auth?.phone != null)){
            inputRefs?.current[0].focus();
            setTimeout(()=>{
                dispatch(sendSMS())
            },3000)
            
        }
    }

    useEffect(() => {
        // Focus the first input on phone added
        if(!isLoadingPhone && inputRefs?.current[0] && (auth?.phone != 0 && auth?.phone != null)){
            inputRefs?.current[0].focus();
        }
    }, [auth?.phone]);

    const handleChange = (value, index) => {
        if(inputRefs){
            // Prevent input if the user tries to enter more than one character
            if (value.length > 1) {
                return;
            }
        
            const newInputs = [...inputs];
            newInputs[index] = value;
        
            // Clear subsequent inputs if the current input is changed
            for (let i = index + 1; i < newInputs.length; i++) {
                newInputs[i] = '';
            }
            
            setInputs(newInputs);
        
            // Automatically focus the next input field after a value is entered
            if (value && index < inputs.length - 1) {
                inputRefs?.current[index + 1].focus();
            }
        
            // If the last input is filled, trigger verification function
            if (index === inputs.length - 1 && value) {
                verifyCode(newInputs.join(''));
            }
        }
    };

    const handleKeyDown = (event, index) => {
        if(inputRefs){
            if (event.key === "Backspace" && index > 0 && inputs[index] === '') {
            // Move focus backward on backspace
            inputRefs?.current[index - 1].focus();
            }
        }
    };

    // Function to verify the code
    const verifyCode = async (code) => {
        //console.log('Verifying code:', code);
        var success = await dispatch(verifySMSCode(code))
        if(success){
            showAnimation()
        }
    };

    return (
        (isLoadingPhone? 
            <div className='verify-loading-try-code'>
                <TailSpin  
                    height="30"
                    width="30"
                    color="#838383"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                />
            </div>
            :
            <div>
                <p>Code sent to {auth?.phone}</p>
                <div className='verify-input-container'>
                    {inputs.map((value, index) => (
                        <input
                        key={index}
                        ref={(el) => (inputRefs.current[index] = el)}
                        type="number"
                        maxLength="1"
                        value={value}
                        onChange={(e) => handleChange(e.target.value, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        className='verify-input'
                        />
                    ))}
                </div>
                {errorMsgCode != '' && <p className='verify-red'>{errorMsgCode}</p>}
                <ResendCode startTime={timestampSMS}/>
                <p className='change-number' onClick={()=>{setChangeNumberClicked(true)}}>
                    Change number?
                </p>
            </div>)
    );
};
