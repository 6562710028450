
import { createRef, useEffect, useState, React } from 'react';
import { useDispatch, useSelector, connect, ReactReduxContext, } from 'react-redux';
import { updatePicksSelection, selectPicksHot, selectPicksNew, selectPicksRecord,selectPicksPopular, changeNumberOfLegs } from '../../../State/redux/actions/StateActions.js';
import "../../../Assets/css/profile-bet-filters.css"
import arrowDown from '../../../Assets/icons/down-green.png';
import percentG from '../../../Assets/icons/percent-g.png';
import percentW from '../../../Assets/icons/percent-w.png';
import newG from '../../../Assets/icons/new-g.png';
import newW from '../../../Assets/icons/new-w.png';
import flameG from '../../../Assets/icons/flame-green.png';
import flameW from '../../../Assets/icons/flame-white.png';
import eyeG from '../../../Assets/icons/eye-g.png';
import eyeW from '../../../Assets/icons/eye-w.png';
import legs from '../../../Assets/icons/legs-g.png';
import checkmark from '../../../Assets/icons/checkmark.png'
import PickCard from './PicksCard.js'; 

// app component
function PopupSelectionFilters({dropdownSelectionLegs, dropdownSelectionFilter}) {

  const dispatch = useDispatch()
  const {numberOfLegs,showContentFilters, showContentLegs, picksNewSelected, picksPopularSelected, picksRecordSelected, picksHotStreakSelected, picksSelection } = useSelector(state => state.state_reducer)
  const [numLegs, setNumLegs] = useState('');

  // component did mount
  useEffect(() => {

  }, [])

  // component did update
  useEffect(() => {

  })

  function close(){
    dropdownSelectionFilter()
  }
  
  function selectedLegs(value){

    setNumLegs('')
    dispatch(changeNumberOfLegs(value))
    dropdownSelectionLegs()
  }

  function submitNumberOfLegs(){
    if(numLegs<2){
      dispatch(changeNumberOfLegs(2))
      setNumLegs('')
    }else if(numLegs>1 && numLegs <6){
      dispatch(changeNumberOfLegs(numLegs))
      setNumLegs('')
    }else{
      dispatch(changeNumberOfLegs(numLegs))
    }

    dropdownSelectionLegs()
  }

  function updateNumberOfLegs(value){
    if(value>200){
      value=200
    }
    setNumLegs(value)
  }

  function selectedFilter(value){

    switch(value){
      case "Best Record":
        dispatch(selectPicksRecord(true))
        dispatch(selectPicksHot(false))
        dispatch(selectPicksPopular(false))
        dispatch(selectPicksNew(false))
        dispatch(updatePicksSelection("Best Record"))
        break;
      case "Hot Streak":
        dispatch(selectPicksRecord(false))
        dispatch(selectPicksHot(true))
        dispatch(selectPicksPopular(false))
        dispatch(selectPicksNew(false))
        dispatch(updatePicksSelection("Hot Streak"))
        break;
      case "Popular Bets":
        dispatch(selectPicksRecord(false))
        dispatch(selectPicksHot(false))
        dispatch(selectPicksPopular(true))
        dispatch(selectPicksNew(false))
        dispatch(updatePicksSelection("Popular Bets"))
        break;
      case "New Bets":
        dispatch(selectPicksRecord(false))
        dispatch(selectPicksHot(false))
        dispatch(selectPicksPopular(false))
        dispatch(selectPicksNew(true))
        dispatch(updatePicksSelection("New Bets"))
        break;
    }

    dropdownSelectionFilter()
  }

  if(showContentFilters){
      return(
      <div className='picks-popup-container'>
        <p className='picks-popup-title'>Filter by person or bets</p>
            
        {!picksRecordSelected?
        <div onClick={()=>selectedFilter("Best Record")} className='picks-option'>
          <img src={percentG} className='picks-icon-dropdown-selection'/>
          <h3 className='picks-option-text picks-green-unselected'>Best Record</h3>
        </div>
        :
        <div onClick={()=>selectedFilter("Best Record")} className='picks-option'>
          <img src={percentW} className='picks-icon-dropdown-selection'/>
          <h3 className='picks-option-text picks-white-selected'>Best Record</h3>
        </div>
        }
        
        {picksHotStreakSelected?
        <div onClick={()=>selectedFilter("Hot Streak")} className='picks-option'>
          <img src={flameW} className='picks-icon-dropdown-selection'/>
          <h3 className='picks-option-text picks-white-selected'>Hot Streak</h3>
        </div>
          :
        <div onClick={()=>selectedFilter("Hot Streak")} className='picks-option'>
          <img src={flameG} className='picks-icon-dropdown-selection'/>
          <h3 className='picks-option-text picks-green-unselected'>Hot Streak</h3>
        </div>}

        {picksPopularSelected?
        <div onClick={()=>selectedFilter("Popular Bets")} className='picks-option'>
          <img src={eyeW}className='picks-icon-dropdown-selection'/>
          <h3 className='picks-option-text picks-white-selected'>Popular Bets</h3>
        </div>
          :
        <div onClick={()=>selectedFilter("Popular Bets")} className='picks-option'>
          <img src={eyeG}className='picks-icon-dropdown-selection'/>
          <h3 className='picks-option-text picks-green-unselected'>Popular Bets</h3>
        </div>}

        {picksNewSelected?
        <div onClick={()=>selectedFilter("New Bets")} className='picks-option'>
          <img src={newW}className='picks-icon-dropdown-selection'/>
          <h3 className='picks-option-text picks-white-selected'>New Bets</h3>
        </div>
          :
        <div onClick={()=>selectedFilter("New Bets")} className='picks-option'>
          <img src={newG}className='picks-icon-dropdown-selection'/>
          <h3 className='picks-option-text picks-green-unselected'>New Bets</h3>
        </div>}

        <div className='picks-close-button-container'>
          <div onClick={()=>{close()}} className='picks-close-button'>
            Close
          </div>
        </div>
      </div>
      )
    }else if(showContentLegs){
      return(
      <div className='picks-popup-container'>

        <h3 className='picks-header-text picks-white-selected'>Filter by number of legs</h3>
        {/* Custom Bet Selection */}
        <div className='picks-option-legs'> 
        
          {/* Single Bet Selection */}
          <div onClick={()=>selectedLegs(1)} className={numberOfLegs == 1?'picks-option-leg-dark':'picks-option-leg'}>
            <h1 className={numberOfLegs == 1?'picks-number-legs-white-selected':'picks-number-legs-green-unselected'}>1</h1>
            <h3 className={numberOfLegs == 1?'picks-option-text picks-white-selected':'picks-option-text picks-green-unselected'}>Single Bets</h3>
          </div>
         
         {/* 2 Leg Selection */}
         {numberOfLegs == 2?
          <div onClick={()=>selectedLegs(2)} className='picks-option-leg-dark'>
            <h1 className='picks-number-legs-white-selected'>2</h1>
            <h3 className='picks-option-text picks-white-selected'>Leg Parlay</h3>
          </div>
            :
          <div onClick={()=>selectedLegs(2)} className='picks-option-leg'>
            <h1 className='picks-number-legs-green-unselected'>2</h1>
            <h3 className='picks-option-text picks-green-unselected'>Leg Parlay</h3>
          </div>}
        </div>

        {/* Row */}
        <div className='picks-option-legs'> 
          
          {/* 3 Leg Selection */}
          {numberOfLegs == 3?
          <div onClick={()=>selectedLegs(3)} className='picks-option-leg-dark'>
            <h1 className='picks-number-legs-white-selected'>3</h1>
            <h3 className='picks-option-text picks-white-selected'>Leg Parlay</h3>
          </div>
            :
          <div onClick={()=>selectedLegs(3)} className='picks-option-leg'>
            <h1 className='picks-number-legs-green-unselected'>3</h1>
            <h3 className='picks-option-text picks-green-unselected'>Leg Parlay</h3>
          </div>}

          {/* 4 Leg Selection */}
          {numberOfLegs == 4?
            <div onClick={()=>selectedLegs(4)} className='picks-option-leg-dark'>
              <h1 className='picks-number-legs-white-selected'>4</h1>
              <h3 className='picks-option-text picks-white-selected'>Leg Parlay</h3>
            </div>
              :  
            <div onClick={()=>selectedLegs(4)} className='picks-option-leg'>
              <h1 className='picks-number-legs-green-unselected'>4</h1>
              <h3 className='picks-option-text picks-green-unselected'>Leg Parlay</h3>
            </div>}
        </div>
        
        {/* Row */}
        <div className='picks-option-legs'>
      
          {/* 5 Leg Selection */}
          {numberOfLegs == 5?
          <div onClick={()=>selectedLegs(5)} className='picks-option-leg-dark'>
            <h1 className='picks-number-legs-white-selected'>5</h1>
            <h3 className='picks-option-text picks-white-selected'>Leg Parlay</h3>
          </div>
            :  
          <div onClick={()=>selectedLegs(5)} className='picks-option-leg'>
            <h1 className='picks-number-legs-green-unselected'>5</h1>
            <h3 className='picks-option-text picks-green-unselected'>Leg Parlay</h3>
          </div>}

          <div className='picks-option-legs-custom'>
            <input onChange={(e)=>{updateNumberOfLegs(e.target.value)}} type={"number"} className='picks-custom-legs-input' value={numLegs} placeholder='Amount'/>
            <div className={'picks-submit-button picks-dark'} onClick={()=>{submitNumberOfLegs()}}>
              <img className={'picks-img-icon'} src={checkmark}/> 
            </div>
          </div>
        </div>
      </div>
      )
    }

}

export default PopupSelectionFilters;
