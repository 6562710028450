// components/Conversation.jsx
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import "../../../Assets/css/conversation.css"
import back from "../../../Assets/icons/back-grey.png"
import info from "../../../Assets/icons/info-g.png"
import { useNavigate, useParams } from 'react-router-dom';
import { popRoute, pushRoute } from '../../../State/redux/actions/StateActions';


const Header = ({ }) => {
    const {username} = useSelector(state => state.auth_reducer);
    const { stack } = useSelector(state => state.state_reducer);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {conversationName, participants} = useSelector(state => state.chat_reducer);
    let { conversationId } = useParams();

    const routeTo = (route) => {
        
        dispatch(pushRoute(route));
        navigate(route)
    }

    const goBack = (path) => {
        const route = stack && stack.length > 1 ? stack[stack.length-2] : path
        dispatch(popRoute());
        navigate(route)
    }

    return (
       
            <div className='messages-header-container'>
                <div className='messages-header-back'>
                    <img src={back} className='back-btn-img' onClick={()=>{goBack('/conversations')}}/>
                </div>
                <div className='messages-header-content'>
                    {conversationName && conversationName != '' ? conversationName : (participants.length > 2 ? participants.filter((u)=>username != u).join(", ") : participants.filter((u)=>username != u))}
                </div>
                <div className='messages-header-info'>
                    <img src={info} className='back-btn-img' onClick={()=>{routeTo(`/conversation/details/${conversationId}`)}}/>
                </div>
            </div>
           
    );
};

export default Header;
