
import { createRef, useEffect, useState, React } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import { popRoute, pushRoute, toggleEditProfile,  } from '../../State/redux/actions/StateActions.js';
import { useNavigate, useParams } from 'react-router-dom';
import "../../Assets/css/accountpage.css"
import { editProfile, getProfile, saveProfileEdits } from '../../State/redux/actions/UserActions.js';
import Header from './SubComponents/Header.js';
import eyeGrey from '../../Assets/icons/eye-grey.png';

// Profile component
function EmailUpdate() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector(state => state.auth_reducer)
  const { user, stack } = useSelector((state) => state.user_reducer);
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  // component did mount
  useEffect(()=>{
    if(!user){
      getUser()
    }
  },[])

  const getUser = async () => {

    const username = JSON.parse(localStorage.getItem('profile'))?.username;
    if(username){
      const u = await dispatch(getProfile(username))
      if(u){
        u.oldUsername = u.username
        dispatch(editProfile(u))
      }
    }else{
      routeTo('/login')
    }
  }

  const routeTo = (route) => {
        
    dispatch(pushRoute(route));
    navigate(route)
  }

  const goBack = () =>{

    const route = stack && stack.length > 1 ? stack[stack.length-2] : `/`
    navigate(route)
    dispatch(popRoute())
}

  function cancelForm(){

     goBack()
  }

  function saveEdits(){
    const {bio, tags, accountPhoto, username, oldUsername} = user
    if(user?.username === auth?.username){
      //dispatch(saveProfileEdits(bio, tags, accountPhoto, username, oldUsername, navigate))
    }
  }

  const changePassword = (value) => {
    setPassword(value)
  }

  return (
    <>
    <Header label="Update Email"/>
    <div className='account-background'>
      
        {/* Email */}
        <input className='account-input margin-top-80' placeholder='New Email'/>
        
        {auth?.googleSignIn === false &&
          <div className={'input-row'}>
          <input className={'account-form-input'} name="Password" label="Password" placeholder="Password" onChange={(e)=>{changePassword(e.target.value)}} type={showPassword ? 'text' : 'password'} value={password} />
          <div className={'show-password'} onClick={()=>{setShowPassword(!showPassword)}}>
            <img src={eyeGrey} className='icon-dropdown-selection'/>
          </div>
        </div>}
        
        <div className='edit-account-button margin-top-50' onClick={()=>{saveEdits()}}>
          Save
        </div>
        <div className='cancel-edit-account-button mb-20' onClick={()=>{cancelForm()}}>
          Cancel
        </div>

    </div>
    </>
    );

}

export default EmailUpdate;
