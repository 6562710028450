import React, { useEffect } from 'react';
import "../../../Assets/css/conversation.css";
import { useNavigate } from 'react-router-dom';
import { getTimeDate } from '../../../Utilities/UtilityFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { pushRoute } from '../../../State/redux/actions/StateActions';

const ConversationRow = ({ conversation, index }) => {
  
    const { conversationName, participants, lastMessage } = conversation;
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {username} = useSelector(state=>state.auth_reducer)

    const reRoute = (room) =>{
        const path = `/conversation/${room}`
        dispatch(pushRoute(path));
        navigate(path)
    }

    return (
    <div className={index==0 ? "conversation-row": "conversation-row border"} onClick={() => reRoute(conversation?.conversationId)}>
        <div className="conversation-info">
            
            <div className="message-info">
                <div className="conversation-title">
                    {conversationName && conversationName != '' ? conversationName : (participants.length > 2 ? participants.filter((u)=>username != u).join(", ") : participants.filter((u)=>username != u))}
                </div>
                <div className="message-time">{lastMessage? getTimeDate(lastMessage?.timestamp) : ""}</div>
            </div>
            
            <div className="message-info">
                {lastMessage ?
                    <div className="message-sender">{lastMessage?.username}: {lastMessage?.content}</div>
                    :
                    <div className="message-sender">New conversation</div>
                }
            </div>
        </div>
    </div>
    );
};

export default ConversationRow;
