
import { createRef, useEffect, useState, React, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {  erasePicksForm, pushRoute, setCreatePicksPageNumber } from '../../../State/redux/actions/StateActions.js';
import "../../../Assets/css/createpickspage.css"
import plus from '../../../Assets/icons/add-g.png'
import right from '../../../Assets/icons/right-g.png'
import NewPick from './NewPick.js';
import { v4 as uuidv4 } from 'uuid';
import { addLeg, errorMessageScreenshot, getPicksFromScreenshotOddsJam, removeLeg, removeLegs, setParlayOdds, } from '../../../State/redux/actions/PickActions.js';
import { useNavigate } from 'react-router-dom';
import cameraGrey from '../../../Assets/icons/camera-grey.png'
import NewPickScreenshotted from './NewPickScreenShotted.js';
import { TailSpin } from 'react-loader-spinner';
import { decimalToAmerican } from '../../../Utilities/UtilityFunctions.js';
import BetUnitStepper from './BetUnitStepper.js';
import Countdown from './Countdown.js';

// app component
function FirstPage() {

  const state = useSelector(state => state.state_reducer)
  const {title, description, price, lock, height, stepperBetUnits} = useSelector(state => state.state_reducer)
  const auth = useSelector(state => state.auth_reducer)
  const {legs, tags, pickPost, success, isLoadingScreenshot, errorMsgScreenshot, parlayOdds} = useSelector(state => state.pick_reducer)
  const dispatch = useDispatch() 
  const navigate = useNavigate()
  
  const textareaRef = useRef()
  
  const [flagTitle, setFlagTitle] = useState(false)
  const [flagText, setFlagText] = useState('')

  const inputRef = useRef(null);
  const [picksComplete, setPicksComplete] = useState(false)
  
  // component did update
  useEffect(()=>{
    getOddsTotal()
    ensurePicksComplete()
  },[legs])

  useEffect(()=>{
    if(success && pickPost){
      eraseForm()
      const path = `/profile/${auth.username}`
      dispatch(pushRoute(path));
      navigate(path);
    }
  },[success])

  const eraseForm  = () =>{
   
    dispatch(removeLegs())
    dispatch(erasePicksForm())
  }

  const addLegToState = () => {

    const id = uuidv4();
    const leg = {sport: '', date: '', sportsbook: '', game: '', market: '', pick: '', id: id, odds: 0, sportsOptions: [], gameOptions:[], marketOptions: [], oddsOptions: [], screenShotted: false} 
    dispatch(addLeg(leg))
  }

  const removeLegFromState = (id) => {
    
    if(legs.length > 1){
      dispatch(removeLeg(id))
    } 
  }

  const getOddsTotal = () => {

    // Convert inidivdual American odds to decimal odds, and sum/produce them
    let totalParlayOddsDecimal = 1.0
    legs.forEach(leg => {
      const americanOdds = leg.odds;
      let decimalOdds;
      if (americanOdds > 0) {
        decimalOdds = (americanOdds / 100) + 1;
      } else if (americanOdds < 0) {
        decimalOdds = (-100 / americanOdds) + 1;
      } else {
        // Handle the case where American odds are 0 (even odds)
        decimalOdds = 1;
      }
      // Multiply the decimal odds with the running total
      totalParlayOddsDecimal *= decimalOdds;
    });

    totalParlayOddsDecimal != 1 && dispatch(setParlayOdds(totalParlayOddsDecimal.toFixed(2)))
  }

  // Function to handle file upload
  const handleFileUpload = (event) => {
      try{
        
        // cancelled
        if(!event.target.files.length){
          dispatch(errorMessageScreenshot(''))
        }else{
          const file = event.target.files[0];
          var maxSize = 20 * 1024 * 1024; // max 20 MB
          const validTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/webp']
          console.log(file.type)
          if (file && file.size <= maxSize) {
            if (validTypes.includes(file.type)) {
              // READ the file
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = (e) => {
                const base64String = e.target.result;
                // Now, you can send this base64String to your backend
                dispatch(getPicksFromScreenshotOddsJam(base64String));
                dispatch(errorMessageScreenshot(""));
              };
            } else {
              dispatch(errorMessageScreenshot('The image should be either format: .jpg, .png, .jpeg, or .webp'));
            }
          } else if (file.size > maxSize) {
            dispatch(errorMessageScreenshot('The image should be smaller than 20 MB'));
          }
        }
      } catch (e) {
        console.log(e);
        dispatch(errorMessageScreenshot('There is something wrong with that image. Try refreshing the page or try another image.'));
      }
  };

  const nextPage = () => {
    dispatch(setCreatePicksPageNumber(2))
  }

  const ensurePicksComplete = () =>{

    var complete = true
    legs.forEach((otherLeg)=>{
      
        // check if complete
        if(otherLeg.complete === false){
          complete = false
        }
      
    })
  
    setPicksComplete(complete)
  }



  return (
        <div className='create-picks-container'>
          <div className='upload-screenshot' onClick={()=>document.getElementById('file-upload').click()}>
              <div className='upload-screenshot-column'>
                Upload screenshot to our AI vision
              </div>
              
              {isLoadingScreenshot?
                  <TailSpin  
                      height="30"
                      width="30"
                      color="#838383"
                      ariaLabel="tail-spin-loading"
                      radius="1"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                  />
                :
                  <img src={cameraGrey} className='upload-screenshot-img'/>
              }
              {isLoadingScreenshot&&
              <div className='countdown-timer'>
                <Countdown />
              </div>}

              {errorMsgScreenshot === '' ? 
                  <div className='upload-screenshot-column-sm'>Make sure your betslip is cropped to reduce noise</div> 
                : 
                  <div className={'screenshot-error-message'}>{errorMsgScreenshot}</div>
              }

              <input type="file" id="file-upload" accept=".png,.jpg,.jpeg,.webp" style={{display: 'none'}} onChange={(e)=>{handleFileUpload(e)}}/>
          </div>

          <div className='create-or'>Or manually create pick(s) below</div>
          {legs?.map((e,index)=>{
            if(e.screenShotted){
              return(<NewPickScreenshotted key={e.id} id={e.id} deleteForm={removeLegFromState} counter={index+1} removeFlag={()=>setFlagText('')}/>)
            }else{
              return(<NewPick key={e.id} id={e.id} deleteForm={removeLegFromState} counter={index+1} removeFlag={()=>setFlagText('')}/>)
            }
          })}

          <div className='add-leg' onClick={addLegToState}>
            <div className='left-area'/>
            <div className='middle-area'>
              Add Another Leg
            </div>
            <div className='right-area'>
            <img src={plus} className='add-leg-img' />

            </div>
          </div>

          <div className={legs[0].complete && picksComplete ? 'next-page-button' : 'next-page-button-disabled'} onClick={legs[0].complete && picksComplete ? nextPage : ()=>{}} >
            <div className='left-area'/>
            <div className='middle-area'>
              Finish your post
            </div>
            <div className='right-area'>
                <img src={right} className='add-leg-img' />
            </div>
          </div>
        </div>
  )

}

export default FirstPage;
