// components/Conversation.jsx
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import "../../../Assets/css/profilepage.css"
import back from "../../../Assets/icons/back-grey.png"
import { useNavigate, useParams } from 'react-router-dom';
import { popRoute, pushRoute } from '../../../State/redux/actions/StateActions';



const EditHeader = () => {

    const { stack } = useSelector(state => state.state_reducer);
    const auth = useSelector(state => state.auth_reducer);
    const {user} = useSelector(state => state.user_reducer);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const routeTo = (route) => {
        
        dispatch(pushRoute(route));
        navigate(route)
    }

    const goBack = () =>{
           const route = stack && stack.length > 1 ? stack[stack.length-2] : `/profile/${auth.username}`
           navigate(route)
           dispatch(popRoute())
       
    }

    return (
       
            <div className='profile-header-container'>
                <div className='profile-header-back'>
                    <img src={back} className='back-btn-img' onClick={goBack}/>
                </div>
                <div className='profile-header-content'>
                    {user?.username}
                </div>
                <div className='profile-header-info'>
                    
                </div>
            </div>
           
    );
};

export default EditHeader;
