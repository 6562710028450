

export const epochsToDateTime = (epochSeconds) => {

    const date = new Date(epochSeconds * 1000);

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return formattedDateTime
}

export const epochsTimeDifference = (epochSecondsStart, epochSecondsEnd) => {

    // Convert to Date objects
    const date1 = new Date(epochSecondsStart / 60); // Divide by 60 to convert to minutes
    const date2 = new Date(epochSecondsEnd / 60);

    // Calculate the time difference in minutes
    const timeDifferenceInMinutes= date2 - date1;

    return timeDifferenceInMinutes
}


export const isStrongPassword = (password) => {

    // Check if the password meets all the criteria
    // Minimum 8 characters
    const lengthRegex = /.{8,}/; 
    if(!lengthRegex.test(password)){
        return 'Password needs to be a minimum 8 characters'
    }
      
    // At least one uppercase letter
    const uppercaseRegex = /[A-Z]/;
    if(!uppercaseRegex.test(password)){
        return ('Password needs to have at least one uppercase letter')
    }
     
    // At least one lowercase letter
    const lowercaseRegex = /[a-z]/;
    if(!lowercaseRegex.test(password)){
        return ('Password needs to have at least one lowercase letter')
    }

    // At least one digit
    const digitRegex = /\d/;
    if(!digitRegex.test(password)){
        return ('Password needs to have at least one digit')
    } 
    
      
    // At least one special character
    const specialCharRegex = /[!@#$%^&*]/;
    if(!specialCharRegex.test(password)){
        return ('Password needs to have at least one special character: !@#$%^&* ')
    } 
    
    return 'Strong Password'
}

    

export const isPhoneNumber = (phoneNumber) => {

    // Regular expression for checking phone numbers format
    const phoneRegex = /^[0-9]{10}$/;
    // check validity
    const validNumber = phoneRegex.test(phoneNumber);
    return validNumber
}

export const isEmailAddress = (email) => {

    // Regular expression for checking email format
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    // check validity
    const validEmail = emailRegex.test(email);
    return validEmail
}

export const hasFifteenMinutesPassed = (startTime) => {
    startTime = new Date(startTime)
    let currentTime = new Date();
    let differenceInMinutes = (currentTime - startTime) / (1000 * 60);
    return differenceInMinutes >= 15;
}

export const hasSixtyMinutesPassed = (startTime) => {
    startTime = new Date(startTime)
    let currentTime = new Date();
    let differenceInMinutes = (currentTime - startTime) / (1000 * 60);
    return differenceInMinutes >= 60;
}

export const hasOneMonthPassed = (startTime) => {
  startTime = new Date(startTime);
  let currentTime = new Date();

  let yearDifference = currentTime.getFullYear() - startTime.getFullYear();
  let monthDifference = currentTime.getMonth() - startTime.getMonth();
  let totalMonthDifference = yearDifference * 12 + monthDifference;

  // Check if at least one month has passed
  if (totalMonthDifference > 1) {
      return true;
  } else if (totalMonthDifference === 1) {
      // Check days if the month difference is exactly 1
      return currentTime.getDate() >= startTime.getDate();
  } else {
      return false;
  }
}

export const customSort = (a, b) => {
    const startsWithNOrM = char => char === 'N' || char === 'M';

    // Check if both start with N or M
    if (startsWithNOrM(a.title[0]) && startsWithNOrM(b.title[0])) {
        return a.title.localeCompare(b.title);
    }

    // If only a starts with N or M, a gets priority
    if (startsWithNOrM(a.title[0])) {
        return -1;
    }

    // If only b starts with N or M, b gets priority
    if (startsWithNOrM(b.title[0])) {
        return 1;
    }

    // Otherwise sort alphabetically
    return a.title.localeCompare(b.title);
}

export const getFormattedDate = (dateString) => {
    const date = new Date(dateString).toLocaleString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    })
    return date
}

export const getTimeDate = (createdAt) => {

    // Parse the input date string
    const timeDate = new Date(createdAt);

    // Get the current date
    const currentDate = new Date();

    // Calculate the time difference in milliseconds
    const timeDifference = currentDate - timeDate;

    // Calculate days, hours, minutes, and seconds
    const date = timeDate.toDateString()
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference / (1000 * 60 * 60)));
    const minutes = Math.floor((timeDifference / (1000 * 60)));
    const seconds = Math.floor((timeDifference / 1000));
    if(days>7){
      return date
    }else if(hours>24){
      if(days == 1){
        return days + " day ago"
      }else{
        return days + " days ago"
      }
    }else if(minutes > 60){
      if(hours == 1){
        return hours + " hour ago"
      }else{
        return hours + " hours ago"
      }
    }else if(seconds > 60){
      if(minutes == 1){
        return minutes + " minute ago"
      }else{
        return minutes + " minutes ago"
      }
    }else{
      if(seconds == 1){
        return seconds + " second ago"
      }else{
        return seconds + " seconds ago"
      } 
    }
  }

  export const getFormattedNumber = (number) =>{
  
    if (number >= 1000000) {
        // Divide the number by 1 million and round to two decimal places
        var formattedNum = (number / 1000000).toFixed(2);
    
        // Add 'M' to the formatted number
        return formattedNum + 'M';
      } else if (number >= 1000) {
        // Divide the number by 1000 and round to two decimal places
        var formattedNum = (number / 1000).toFixed(2);
    
        // Add 'K' to the formatted number
        return formattedNum + 'K';
      } else {
        // If the number is less than 1000, return it as is
        return number.toString();
      }
  }

  export const splitName = (name) =>{
    let parts = name.trim().split(" ");
    
    if (parts.length === 1) {
        // Only first name is present
        return { firstName: parts[0], lastName: null };
    } else {
        // Both first and last names are present
        // The last part is considered as the last name, and all preceding parts as the first name
        let lastName = parts.pop(); // Remove and store the last part
        let firstName = parts.join(" "); // Combine the remaining parts
        return { firstName, lastName };
    }
}

export const hasItBeen90Days = (previousTime) => {
    
  try{
    if(previousTime == ""){
        return true
    }
    // Assuming previousTime is your previous time in string format "2023-10-19T00:00:00.000Z"
    let previousDate = new Date(previousTime);
    let currentDate = new Date();

    // Calculate the difference in milliseconds
    let differenceInMilliseconds = currentDate - previousDate;

    // Convert milliseconds to days
    let differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

    // Check if 90 days have passed
    if (differenceInDays >= 90) {
        return true
    } else {
        return false
    }
  }catch(e){
      console.log(e)
      return false
  }
}

export const addDaysToDate = (dateString, days)=> {
    try{
      if(dateString == "" || dateString == null || dateString == undefined){
        return "Today"
      }
      let date = new Date(dateString);
      date.setDate(date.getDate() + days);

      let year = date.getFullYear();
      let month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
      let day = date.getDate().toString().padStart(2, '0');

      return `${year}-${month}-${day}`;
    }catch(e){
      console.log(e)
      return "Today"
    }
}

export const hasWhitespace = (s) => {
  for (let i = 0; i < s.length; i++) {
      const charCode = s.charCodeAt(i);
      if (charCode === 32 || charCode === 9 || charCode === 10 || charCode === 13) {
          // Space, Tab, Line Feed, Carriage Return
          return true;
      }
  }
  return false;
}

export const stringToColor = (username) => {

  const colors = ['#FF4248', '#FF247A', '#B93EC2', '#8555C9', '#586CC6', '#0099FA', '#00ACFB', '#00BFD8','#009988', '#00B241', '#6BB527', '#FF9100', '#FF4400', '#FFAF00', '#587E8D'];

  let sum = 0;
  for (let i = 0; i < username.length; i++) {
    sum += username.charCodeAt(i);
  }

  // Incorporate the first character's ASCII value into the sum
  const firstCharValue = username.charCodeAt(0);
  const combinedValue = sum + firstCharValue;

  const colorIndex = combinedValue % colors.length;
  return colors[colorIndex];
}

export const getTime = (createdAt) => {
  // Parse the input date string
  const timeDate = new Date(createdAt);

  // Format the time in 12-hour format with AM/PM
  let hours = timeDate.getHours();
  const minutes = timeDate.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  const minutesStr = minutes < 10 ? '0'+minutes : minutes;

  // Return the formatted time
  return hours + ':' + minutesStr + ' ' + ampm;
}

  // Function to format date
  export const formatDate = (date) => {
    const currentDate = new Date();
    const givenDate = new Date(date);
  
    const options = { 
      weekday: 'short', 
      month: 'short', 
      day: 'numeric' 
    };
  
    // Include the year if the given date is not in the current year
    if (givenDate.getFullYear() !== currentDate.getFullYear()) {
      options.year = 'numeric';
    }
  
    return givenDate.toLocaleDateString('en-US', options);
  }

  export const isTimestampMoreThan30SecondsAgo = (timestamp) => {
    const timestampDate = new Date(timestamp);
    const currentTime = new Date();
    const differenceInSeconds = (currentTime - timestampDate) / 1000;
    return differenceInSeconds > 30;
  };

  // Convert Decimal Odds to American Odds
export const decimalToAmerican = (decimalOdds) => {
  if (decimalOdds >= 2.0) {
      return "+" + Math.round((decimalOdds - 1) * 100);
  } else {
      return Math.round(-100 / (decimalOdds - 1));
  }
}

// Convert American Odds to Decimal Odds
export const americanToDecimal = (americanOdds) => {
    // Ensure we're working with a number even if a string with a sign is passed
    let odds = parseInt(americanOdds, 10);
    if (odds > 0) {
        return (odds / 100) + 1;
    } else {
        return 1 - (100 / odds);
    }
}

// Function to convert odds to American format with the appropriate sign
export const convertToAmericanOdds = (odds) => {
  if (odds >= 100) {
      return `+${Math.round(odds)}`;
  } else if (odds < 0) {
      return `${Math.round(odds)}`;
  } else {
      return `-${Math.round(100 / odds)}`;
  }
};


export const removeDuplicates = (arr) => {
  const seen = new Set();

  return arr.filter(item => {
      // Create a unique key by combining name and price
      const key = `${item.name}:${item.price}`;
      
      // Check if this combination has been seen before
      if (seen.has(key)) {
          // If seen, filter out this item
          return false;
      } else {
          // If not seen, add to the set and keep this item
          seen.add(key);
          return true;
      }
  });
}

export const sortOddsByPrice = (arr) => {
  return arr.sort((a, b) => {
      return a.price - b.price; // Ascending order
  });
}

export const convertOddsToDecimal = (americanOdds) => {
  // Check if the input is already in decimal format (not starting with '+' or '-')
  if (!americanOdds.startsWith('+') && !americanOdds.startsWith('-')) {
      // Assuming the input is already a decimal if not prefixed by '+' or '-'
      return parseFloat(americanOdds);
  }

  // Convert the string to a number
  const numericOdds = parseInt(americanOdds, 10);

  // Calculate decimal odds based on whether they are positive or negative
  if (numericOdds > 0) {
      return numericOdds / 100 + 1;
  } else {
      return 1 + 100 / Math.abs(numericOdds);
  }
}

export const hastItBeen30Days = (date) => {

  // check last reset
  if (date!=null && date!= undefined && date !== '') {
    const lastResetDate = new Date(date);
    const currentDate = new Date();
    const timeDifference = currentDate.getTime() - lastResetDate.getTime();
    const thirtyDaysInMilliseconds = 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds
    if (timeDifference < thirtyDaysInMilliseconds) {
        return false
    }
  }
    
  return true
  
}