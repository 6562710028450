
import { createRef, useEffect, useState, React } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import { popRoute, pushRoute, toggleEditProfile,  } from '../../State/redux/actions/StateActions.js';
import { useNavigate, useParams } from 'react-router-dom';
import "../../Assets/css/accountpage.css"
import { editProfile, getProfile, saveProfileEdits } from '../../State/redux/actions/UserActions.js';
import Header from './SubComponents/Header.js';
import nuke from "../../Assets/icons/mushroom-cloud.png"
import cardGrey from "../../Assets/icons/card-solid-grey.png"
import txGrey from "../../Assets/icons/transactions-grey.png"
import ToggleSwitch from './SubComponents/Toggle.js';
import { logoutWithServer } from '../../State/redux/actions/AuthActions.js';
import { googleLogout } from '@react-oauth/google';
import { formatPhoneNumber } from '../../Utilities/UtilityFunctions.js';
import {VerifySMS} from './SubComponents/PhoneUpdates/VerifySMS.js';

// Profile component
function Account() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector(state => state.auth_reducer)
  const { user, stack } = useSelector((state) => state.user_reducer);
  const [units, setUnits] = useState(10)
  const [showLogoutPopup, setShowLogoutPopup] = useState(false)


  // component did mount
  useEffect(()=>{
    if(!user){
      getUser()
    }
  },[])

  const getUser = async () => {

    const username = JSON.parse(localStorage.getItem('profile'))?.username;
    if(username){
      const u = await dispatch(getProfile(username))
      if(u){
        u.oldUsername = u.username
        dispatch(editProfile(u))
      }
    }else{
      routeTo('/login')
    }
  }

  const routeTo = (route) => {
        
    dispatch(pushRoute(route));
    navigate(route)
  }

  function logout(){
    
    dispatch(logoutWithServer(auth))
    googleLogout();
    // go home
    //navigate('/');
    window.location.reload()
    
  }

  const changeUnits = (value) => {

    value = value.replace(/\$/g, '')
    setUnits(value)
    //dispatch(updateUnits(value.trim("$")))
  } 

  return (
    <>
    <Header />
    <div className='account-background'>

    {showLogoutPopup && 
        <div className='confirm-popup'>
          <div className='confirm-popup-card'>
            <div className='confirm-text'>
              Are you sure?
            </div>
            
            <div className='page-buttons'>
              <div className='next-page-button-sm red' onClick={()=>setShowLogoutPopup(false)}>
                  Cancel
                </div>
                <div className='next-page-button-sm'  onClick={()=>logout()}>
                  Logout
                </div>
              </div>
          </div>
        </div>}

        <div className='account-row margin-top-50'>
            <div className='account-link'>
                <img src={cardGrey} className='img-link' onClick={()=>{routeTo('/cards')}}/>
                <div className='account-link-text'>
                    Cards
                </div>
            </div>

            <div className='account-link'>
                <img src={txGrey} className='img-link' onClick={()=>{routeTo('/cards')}}/>
                <div className='account-link-text'>
                    Transactions
                </div>
            </div>
        </div>

        <div className='account-row'>
            <div className='account-link-small'>
                <div className='account-link-text'>
                    Balance
                </div>
                <div className='account-link-text'>
                    $0.00 CAD
                </div>
            </div>

            <div className='account-link-small'>
                <div className='account-link-text'>
                    Page Views
                </div>
                <div className='account-link-text'>
                    123K
                </div>
            </div>
        </div>

        {/* Settings */}
        <div className='settings-container margin-top-20'>
            <h4 className='account-label'>Dark mode</h4> <ToggleSwitch />
        </div>
        
        <h4 className='account-heading-dropdown'>Odds Display</h4>
        <select className='account-dropdown'>
            <option>American (+100)</option>
            <option>Decimal (2.0)</option>
        </select>

        <h4 className='account-heading-dropdown'>1 Unit</h4>
        <input className='account-input' placeholder='Units' value={units ? `$${units}` : '$'} onChange={(e)=>{changeUnits(e.target.value)}}/>

        {/* Username */}
        <h4 className='account-heading'>Account Details</h4>
        <div className='account-input-button' onClick={()=>{routeTo('/update-username')}}>
            Change Username
        </div>      
        <div className='account-input-button' onClick={()=>{routeTo('/update-email')}}>
            Change Email
        </div>
        <div className='account-input-button' onClick={()=>{routeTo('/update-password')}}>
            Change Password
        </div>

        <div className='account-input-button' onClick={()=>{routeTo('/update-phone')}}>
            Change Phone Number
        </div>

        {/* Nuke Record */}
        <h4 className='account-heading'>Statistics & Posts</h4>
        <div className='nuke-record' onClick={()=>{routeTo('/nuke-record')}}>
            {/*<img src={nuke} className='nuke-img'/>*/}
            <div className='account-button-text'>
                Nuke Record
            </div>
            {/*<img src={nuke} className='nuke-img'/>*/}
        </div>

        <div className='logout-button' onClick={()=>{setShowLogoutPopup(true)}}>
            Logout
        </div>
    </div>
    </>
    );

}

export default Account;
