
import { v4 as uuidv4 } from 'uuid';
/*
This object holds all the state variables globally (similar to object oriented programming - but for javascript!). 
It keeps track of the user and where they are in the app, which buttons have been clicked and so on. Throughout the lifetime of 
the app, there will only be 1 UserFrame object for simplicity, even though in redux it is in an array. Each time a change is dispatched 
to the redux store, it forces a re-render (using the useSelector) so that everything looks and runs smoothly.
*/
class State{

    height;
    width;
    openCloseMenu;
    showParlays;
    showSingles;
    showDropdownFilters;
    showDropdownLegs;
    showContentFilters;
    showContentLegs;
    overallRecordSelected;
    hotStreakSelected;
    popularSelected;
    newSelected;
    selection;
    profileNewSelected
    profilePopularSelected
    profileProfitSelected
    profileSelection
    numberOfLegs;
    numberOfLegsProfile;
    expandPickCard;
    picksNewSelected;
    picksPopularSelected;
    picksRecordSelected;
    picksHotSelected
    picksSelection;
    votes;
    title;
    description;
    lock;
    price;
    legs;
    sports;
    revealEntirePost;
    popupMessages;
    revealFollowers;
    selectEditProfile;
    route;
    stack;
    showDropdownFiltersUsers;
    showContentFiltersUsers;
    usersProfitSelected;
    usersOverallRecordSelected;
    usersHotStreakSelected;
    usersPopularSelected;
    usersNumHotStreakSelected;
    usersNumBetsSelected;
    usersSelection;
    searchToggle;
    verifyPopup;
    paymentPopup;
    withdrawalInfoPopup;
    postIdToPurchase;
    sellerUsername;
    searchTogglePicks;
    shareToggle;
    shareLink;
    shareDM;
    tappedStatistics;
    stepperBetUnits;
    createPicksPageNumber;

    /*
    Initialize the variables
    */
    constructor(){
        this.height = 0;
        this.width = 0;
        this.openCloseMenu = false;
        this.showParlays = true;
        this.showSingles = false;
        
        this.showDropdownFilters = false;
        this.showDropdownLegs = false;
        this.showContentFilters = false;
        this.showContentLegs = false;

        this.overallRecordSelected = true;
        this.hotStreakSelected = false;
        this.popularSelected = false;
        this.newSelected = false;
        this.selection = "Overall Record";
        
        this.profileNewSelected = true;
        this.profilePopularSelected = false;
        this.profileProfitSelected = false;
        this.profileSelection = "New"

        this.numberOfLegs = 1;
        this.numberOfLegsProfile = 0;

        this.expandPickCard = 0;

        this.picksRecordSelected = true;
        this.picksHotStreakSelected = false;
        this.picksPopularSelected = false;
        this.picksNewSelected = false;
        this.picksSelection = "Best Record";

        this.votes=123;


        this.title = '';
        this.description = '';
        this.lock = false;
        this.price = 0;
        this.legs = [{sport: '', date: '', sportsbook: '', game: '', market: '', pick: '', id: uuidv4(), odds: 0} ]
        this.tags = [];

        this.sports = [];

        this.revealEntirePost = '';

        this.popupMessages = [];
        this.revealFollowers = false;
        this.selectEditProfile = false;
        this.route = '';
        this.stack = [];

        this.showDropdownFiltersUsers = false;
        this.showContentFiltersUsers = false;

        this.usersProfitSelected = true;
        this.usersOverallRecordSelected = false;
        this.usersHotStreakSelected = false;
        this.usersPopularSelected = false;
        this.usersNumHotStreakSelected = false;
        this.usersNumBetsSelected = false;
        this.usersSelection = "Profit";
        this.searchToggle = false; // false is username, true is tags
        this.verifyPopup = false;
        this.paymentPopup = false;
        this.withdrawalInfoPopup = false;
        this.postIdToPurchase = '';
        this.sellerUsername = '';
        this.searchTogglePicks = true;
        this.shareLink = '';
        this.shareToggle = false;
        this.shareDM = false;
        this.tappedStatistics = false;
        this.stepperBetUnits = 1.0;
        this.createPicksPageNumber = 1;
    }
}

export {State};