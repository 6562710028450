
import { createRef, useEffect, useState, React } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import { popRoute, pushRoute, toggleEditProfile,  } from '../../State/redux/actions/StateActions.js';
import { useNavigate, useParams } from 'react-router-dom';
import "../../Assets/css/accountpage.css"
import { editProfile, getProfile, nukeRecord, saveProfileEdits } from '../../State/redux/actions/UserActions.js';
import Header from './SubComponents/Header.js';
import eyeGrey from '../../Assets/icons/eye-grey.png';
import { hastItBeen30Days } from '../../Utilities/UtilityFunctions.js';

// Profile component
function NukeRecord() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector(state => state.auth_reducer)
  const { user, stack } = useSelector((state) => state.user_reducer);
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const nextResetDate = (auth?.lastStatisticsNuke && auth?.lastStatisticsNuke != '') ? (new Date(new Date(auth.lastStatisticsNuke).getTime() + (30 * 24 * 60 * 60 * 1000))) : ("today, actually. Refresh the page.")
  const [showPopup, setShowPopup] = useState(false)

  // component did mount
  useEffect(()=>{
    if(!user){
      getUser()
    }
  },[])

  const getUser = async () => {

    const username = JSON.parse(localStorage.getItem('profile'))?.username;
    if(username){
      const u = await dispatch(getProfile(username))
      if(u){
        u.oldUsername = u.username
        dispatch(editProfile(u))
      }
    }else{
      routeTo('/login')
    }
  }

  const routeTo = (route) => {
        
    dispatch(pushRoute(route));
    navigate(route)
  }

  const goBack = () =>{

    const route = stack && stack.length > 1 ? stack[stack.length-2] : `/`
    navigate(route)
    dispatch(popRoute())
}


  async function nukeRecordConfirm(){
    
    if(hastItBeen30Days(auth?.lastStatisticsNuke)){
      var failureMessage = await dispatch(nukeRecord(password, navigate))
      if(failureMessage === ''){
          dispatch(popRoute())
          navigate(`/account`)
          //window.location.reload()
      }else{
        setErrorMessage(failureMessage)
      }
    }
  }

  function nukeRecordPreConfirm(){
    if(hastItBeen30Days(auth?.lastStatisticsNuke)){
      setShowPopup(true)
    }
  }
  
  const changePassword = (value) => {
    setPassword(value)
  }

  return (
    <>
    <Header label="Nuke Record"/>

    <div className='account-background'>
      
      {showPopup && 
        <div className='confirm-popup'>
          <div className='confirm-popup-card'>
            <div className='confirm-text'>
              Are you sure?
            </div>
            
            <div className='page-buttons'>
              <div className='next-page-button-sm red' onClick={()=>setShowPopup(false)}>
                  Cancel
                </div>
                <div className='next-page-button-sm'  onClick={()=>nukeRecordConfirm()}>
                  Nuke Record
                </div>
              </div>
          </div>
        </div>}

        {/* Nuke Record */}
        <h3 className='account-labels margin-top-80'>Are you sure?</h3>
        
        {hastItBeen30Days(auth?.lastStatisticsNuke)
          ?
        <h5 className='account-labels-sm'>This will delete all your posts and statistics. Your previous record and posts will be irretrievable. Also, you can only start fresh every 30 days. </h5>
          :
        <h5 className='account-labels-sm red-text'>You recently nuked your record. You can only start fresh every 30 days. Your next nuclear detonation can be on {nextResetDate.toDateString()}</h5>
        }
        
        {auth?.googleSignIn === false &&
          <div className={'input-row'}>
          <input className={'account-form-input'} name="Password" label="Password" placeholder="Password" onChange={(e)=>{changePassword(e.target.value)}} type={showPassword ? 'text' : 'password'} value={password} />
          <div className={'show-password'} onClick={()=>{setShowPassword(!showPassword)}}>
            <img src={eyeGrey} className='icon-dropdown-selection'/>
          </div>
        </div>}

        {errorMessage !== '' && 
        <p className='account-labels-sm red-text'>{errorMessage}</p>}

        <div className={hastItBeen30Days(auth?.lastStatisticsNuke)?'edit-account-button margin-top-50':'edit-account-button margin-top-50 greyed'} onClick={()=>{nukeRecordPreConfirm()}}>
          Nuke Record
        </div>

        <div className='cancel-edit-account-button mb-20' onClick={()=>{goBack()}}>
          Cancel
        </div>

    </div>
    </>
    );

}

export default NukeRecord;
