import React, { useEffect, useState } from 'react';

const TypingAnimation = () => {

  return (
    <div className="cloud">
        <div className="dot" style={{ animationDelay: '0s' }}></div>
        <div className="dot" style={{ animationDelay: '0.4s' }}></div>
        <div className="dot" style={{ animationDelay: '0.8s' }}></div>
    </div>
  );
};

export default TypingAnimation;
