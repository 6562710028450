import "../../../../Assets/css/verify-sms.css"
import React from 'react';

const VerifiedCheckmark = () => {
    return (
       <div className="verified-container">

            <svg className="verified-checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
            {/* Ensure the circle fills the viewBox area and is centered */}
            <circle className="verified-checkmark-circle" cx="26" cy="26" r="25" fill="#4CAF50"/>
            {/* Adjusted the path to ensure it appears on top of the filled circle */}
            <path className="verified-checkmark-check" fill="none" stroke="#FFFFFF" strokeWidth="4" d="M16 26 l8 8 16-16"/>
            </svg>
            <div className="verified-text">Verified</div>
      </div>
    );
  };
  
  
export default VerifiedCheckmark;