// components/Conversation.jsx
import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import "../../../Assets/css/conversation.css"
import { markMessageAsRead, turnOffTypingIndicator } from '../../../State/redux/actions/ChatActions';
import { useParams } from 'react-router-dom';
import { formatDate,} from '../../../Utilities/UtilityFunctions';
import MessageRow from './MessageRow';
import ConversationIndicators from './ConversationIndicators';

const MessagesContainer = ({ }) => {
    const { username} = useSelector(state => state.auth_reducer);
    const dispatch = useDispatch();
    let { conversationId } = useParams();
    const {messages, read, typing} = useSelector(state => state.chat_reducer);
    const containerRef = useRef(null)

    useEffect(() => {
        setTimeout(scrollToBottom, 500);
        // mark message as read
        if(messages && messages.length>1 && read && !read?.includes(username)){
            dispatch(markMessageAsRead(conversationId))
        }
    }, []);

    useEffect(()=>{
        setTimeout(scrollToBottom, 1000);
        // mark message as read
        if(messages && messages.length>1 && read && !read?.includes(username)){
            dispatch(markMessageAsRead(conversationId))
        }
    },[messages])

    useEffect(()=>{
        if(typing!='' && typing != username){
            setTimeout(scrollToBottom, 1000);
            setTimeout(()=>dispatch(turnOffTypingIndicator()), 4000)
        }
    },[typing])

    const scrollToBottom = () => {
        if(containerRef.current){
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    };

    // Function to check if the message is the first of a new day
    const isFirstMessageOfDay = (currentMsg, index) => {
        if (index === 0) return true; // Always show date for the first message
        const currentDate = new Date(currentMsg.timestamp).setHours(0, 0, 0, 0);
        const previousDate = new Date(messages[index - 1].timestamp).setHours(0, 0, 0, 0);
        return currentDate > previousDate;
    };

    const TimeOfDay = ({ m, i }) => {
      
        return isFirstMessageOfDay(m, i) && 
           
            <div className="date-header">
                {formatDate(m?.timestamp)}
            </div>
    };

    return (
        <div className='messages-container' ref={containerRef}>
            {messages?.length > 0 && messages.map((m, index) => (
                <div key={index} className='message-row'>
                    
                    <TimeOfDay m={m} i={index}/>

                    <MessageRow m={m}/>

                </div>
            ))}
            <ConversationIndicators />
        </div>
    );
};

export default MessagesContainer;
