import * as actionType from '../actions/ActionTypes';

export const auth_reducer = (state=[], action) => {
  switch (action.type) {
    
    case actionType.AUTH:

      // set data to browser storage to persist across a forced refresh
      localStorage.setItem('profile', JSON.stringify({ ...action.payload.state }));
      return action.payload.state
    
    case actionType.LOGOUT:
      // @TODO: clear just profile, keep settings
      return state
  
    default:
      return state;
  }
};

