// components/Conversation.jsx
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import "../../Assets/css/conversation.css"
import { joinConversation } from '../../State/redux/actions/ChatActions';
import { useParams } from 'react-router-dom';
import MessageInput from './SubComponents/MessageInput';
import MessagesContainer from './SubComponents/MessagesContainer';
import Header from './SubComponents/Header';
import { createConnection, getSocket } from '../../State/api/socket';

const Conversation = ({ }) => {
    const dispatch = useDispatch();
    let { conversationId } = useParams();
    const {connectionToggle} = useSelector(state=>state.chat_reducer)

    useEffect(() => {

        createConnection(); // Ensures websocket connection is created
        dispatch(joinConversation(conversationId))
        return () => {
          // disconnect from websocket connection
          var socket = getSocket();
          if (socket) socket.disconnect();
        };
    }, []);

    useEffect(()=>{
      dispatch(joinConversation(conversationId))
    },[connectionToggle])

    return (
        <div className='conversation-container'>
           <Header/>
           <MessagesContainer />
           <MessageInput />
        </div>
    );
};

export default Conversation;
