import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isTimestampMoreThan30SecondsAgo } from '../../../../Utilities/UtilityFunctions';
import { TailSpin } from 'react-loader-spinner';
import { setErrorMessagePhone, savePhoneNumber, sendSMS, verifySMSCode } from '../../../../State/redux/actions/UserActions';

export const NewNumber = ({setChangeNumberClicked}) => {

  //const {phone} = useSelector(state=>state.auth_reducer)
  const auth = useSelector(state => state.auth_reducer);
  const {errorMsgPhone, isLoadingPhone} = useSelector(state => state.user_reducer);
  const phoneRef = useRef()
  const [countryCode, setCountryCode] = useState("1")
  const [phone, setPhone] = useState('')
  const dispatch = useDispatch();
  

    useEffect(() => {
    
        phoneRef?.current?.focus()
        
    }, []);

    useEffect(() => {
        
        phoneRef?.current?.focus()

    }, [auth?.phone]);

    const savePhoneNum = () => {
        const number = countryCode+phone
        if(countryCode === ''){
            dispatch(setErrorMessagePhone("You need to add a country code"))
        }else if(!phone){
            dispatch(setErrorMessagePhone("You need to add a phone number"))
        }else{
            dispatch(savePhoneNumber(number))
            setChangeNumberClicked(false)
        }
        
    }   

    const onChangeCountryCode = (value) => {
        if (value.startsWith('+')) {
            value = value.substring(1);
        }
        setCountryCode(value)
    }

    const onChangePhone = (value) => {
        // Remove any character that is not a number
        let numericValue = value.replace(/[^\d]/g, '');
        setPhone(numericValue)
    }

  return (
    <div>
     
        <div className='verify-get-phone-num'>
            <input 
                className='verify-input-phone-code' 
                type='text' value={"+"+countryCode} 
                onChange={(e)=>{onChangeCountryCode(e.target.value)}}/>
            <input 
                className='verify-input-phone'
                placeholder={auth?.phone_verified && auth?.phone ? auth?.phone : 'Phone Number'} 
                type='number' 
                value={phone} 
                onChange={(e)=>{onChangePhone(e.target.value)}} 
                ref={phoneRef}/>
            {isLoadingPhone? 
            <div className='verify-loading-save-number'>
                <TailSpin  
                    height="30"
                    width="30"
                    color="#838383"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                />
            </div>
            :
            <div className={phone===''?'verify-save-number-button verify-disabled':'verify-save-number-button'} onClick={()=>{savePhoneNum()}} disabled={phone===''}>
                Save
            </div>}
        </div>
        {errorMsgPhone != '' && <p className='verify-red'>{errorMsgPhone}</p>}

        {auth?.phone != 0 &&
        <p className='change-number' onClick={()=>{setChangeNumberClicked(false)}}>
            Already have a code?
        </p>}
    </div>
  );
};
