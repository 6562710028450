
import {state_reducer} from './StateReducer.js'
import {auth_reducer} from './AuthReducer.js'
import {pick_reducer} from './PickReducer.js';
import {user_reducer} from './UserReducer.js';
import {chat_reducer} from './ChatReducer.js';
import {socket_reducer} from './SocketReducer.js';
import thunk from "redux-thunk" 
import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import { actionSanitizer, stateSanitizer } from './sanitizers';

/*
Simplified version of redux - without dev tools
const combine = combineReducers({
    state_reducer,
    auth_reducer,
    pick_reducer,
    user_reducer,
    chat_reducer,
    socket_reducer
});

export const store = createStore(combine, compose)
*/

const combine = combineReducers({
    state_reducer,
    auth_reducer,
    pick_reducer,
    user_reducer,
    chat_reducer,
    socket_reducer
});

const composeEnhancer = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ? 
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        actionSanitizer,
        stateSanitizer: (state) => stateSanitizer(state),
    }) : compose;

const enhancer = composeEnhancer(
    applyMiddleware(thunk)
);

export const store = createStore(combine, enhancer);