// components/Conversation.jsx
import React, { useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import "../../../Assets/css/conversation.css"
import { addMessage, notifyUserTyping } from '../../../State/redux/actions/ChatActions';
import { useParams } from 'react-router-dom';
import { setShareLink } from '../../../State/redux/actions/StateActions';


const MessageInput = ({ }) => {
    const {userId, username} = useSelector(state => state.auth_reducer);
    const dispatch = useDispatch();
    let { conversationId } = useParams();
    const { shareLink } = useSelector((state)=>state.state_reducer)
    const [message, setMessage] = useState(shareLink != '' ? shareLink : '');
    
    const sendMessage = () => {
        dispatch(addMessage(userId, username, conversationId, message));
        setMessage('');
    };

    const notifyTyping = () =>{
        dispatch(notifyUserTyping(username, conversationId))
    }

    useEffect(()=>{
        setTimeout(()=>{
            dispatch(setShareLink(''))
        },300)
    },[])

    return (
            <div className='message-input-container'>
                <input
                    className='message-input'
                    type="text"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyDown={()=>{notifyTyping()}}
                />
                <div 
                className='message-send-btn'
                onClick={sendMessage}>
                    Send
                </div>
            </div>
    );
};

export default MessageInput;
