// components/Conversation.jsx
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import "../../../Assets/css/conversation.css"
import back from "../../../Assets/icons/back-grey.png"
import info from "../../../Assets/icons/info-g.png"
import { useNavigate, useParams } from 'react-router-dom';
import { popRoute, pushRoute } from '../../../State/redux/actions/StateActions';
import { decimalToAmerican } from '../../../Utilities/UtilityFunctions';


const Header = ({ }) => {

    const { stack, createPicksPageNumber } = useSelector(state => state.state_reducer);
    const { legs, parlayOdds } = useSelector(state => state.pick_reducer);
    const auth = useSelector(state => state.auth_reducer);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const routeTo = (route) => {
        
        dispatch(pushRoute(route));
        navigate(route)
    }

    const goBack = () => {
        const route = stack && stack.length > 1 && stack[stack.length-2]
        dispatch(popRoute());
        navigate(route)
    }

    return (
       
            <div className='create-picks-header-container'>
                <div className='create-picks-header-back'>
                    {stack && stack.length > 1 && createPicksPageNumber === 1 &&
                    <img src={back} className='back-btn-img' onClick={()=>{goBack()}}/>}
                </div>
                <div className='create-picks-header-content'>
                    {createPicksPageNumber == 3 && auth?.username ?
                        auth?.username
                    :
                        (legs && legs?.length !== 0 && ((legs.length > 1) || legs[0].complete == true) ? 
                            auth?.oddsDisplay === 'Decimal'?(parlayOdds+" odds"): decimalToAmerican(parlayOdds)
                        :
                            "Share Your Picks")
                    }
                </div>
                <div className='create-picks-header-info'>
                    {/*<img src={info} className='back-btn-img' onClick={()=>{}}/>*/}
                </div>
            </div>
           
    );
};

export default Header;
