import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { commentPick, getPick } from '../../../State/redux/actions/PickActions.js'
import { getFormattedDate, getTimeDate } from '../../../Utilities/UtilityFunctions.js';
import "../../../Assets/css/comments.css"
import { Comment } from './Comment.js';
import x from '../../../Assets/icons/cancel-grey.png'

const CommentSection = () => {
  const {pick} = useSelector((state)=>state.pick_reducer)
  const {username, name} = JSON.parse(localStorage.getItem('profile')) ?? {username:'', name:''};
  const [comment, setComment] = useState('');
  const dispatch = useDispatch();
  const [comments, setComments] = useState(pick?.comments);
  const commentsRef = useRef();
  const [focusComment,setFocusComment] = useState(false)

  const handleComment = async () => {
    const date = new Date()
    const n = (username != '' && username) ? username : name
    const newComments = await dispatch(commentPick(n, comment, date, pick._id));
    dispatch(getPick(pick._id))
    setComment('');
    setComments(newComments);
    scrollCallback()
  };

  useEffect(()=>{
    pick?.comments && setComments(pick?.comments)
  },[pick])

  const scrollCallback = () =>{
    commentsRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  return (

      <div className={'comment-parent margin-b'}>
        <div className='write-comment-parent'>
          <textarea 
            onFocus={()=>{setFocusComment(true)}}
            label="Comment" value={comment} 
            placeholder={"Write a comment"} 
            onChange={(e) => setComment(e.target.value)} 
            className={focusComment?'write-comment expanded':'write-comment'}
          />

          {focusComment && (
          <div className='comment-buttons'>
            <div className='comment-button' disabled={!comment.length} onClick={handleComment}>
            Submit Comment
            </div>
            <div className='comment-button-exit' disabled={!comment.length} onClick={()=>{setFocusComment(false)}}>
              <img src={x} className='comment-button-exit-img'/>
            </div>
          </div>)}
        </div>

        {comments?.map((c, i) => (
          <Comment key={i} comment={c} depth={1} count={i} scrollCallback={scrollCallback}/>
        ))}

        <div ref={commentsRef} />
      </div>

  );
};

export default CommentSection;

