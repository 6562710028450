
import { React } from 'react';
import { useSelector } from 'react-redux';

import "../../../Assets/css/profilepage.css"
import "../../../Assets/css/profile-bet-filters.css"
import arrowDown from '../../../Assets/icons/down-white.png';
import eyeG from '../../../Assets/icons/eye-w.png';
import newG from '../../../Assets/icons/new-w.png';
import moneyG from '../../../Assets/icons/profit-w.png';
import legs from '../../../Assets/icons/legs-w.png';


// Profile component
function Filters({dropdownSelectionLegs, dropdownSelectionFilter}) {

  const { numberOfLegsProfile, profileSelection} = useSelector(state => state.state_reducer)

  function getIcon(value){

    switch(value){
      case "New":
        return  <img src={newG} className='profile-icon-dropdown-left'/>
      case "Popular":
        return  <img src={eyeG} className='profile-icon-dropdown-left'/>
      case "Profit":
        return  <img src={moneyG} className='profile-icon-dropdown-left'/>
    }
  }

  return (
      <div className='profile-filters'>
        <div className='profile-row-dropdowns'>
          <div onClick={()=>{dropdownSelectionFilter()}} className={'profile-text-selection'}>
            {profileSelection ? getIcon(profileSelection) : getIcon("New")}
            {profileSelection ? profileSelection : "New"}
            <img src={arrowDown} className='profile-icon-dropdown-right'/>
          </div> 
          
          <div onClick={()=>{dropdownSelectionLegs()}} className={'profile-text-selection'}>
            <img src={legs} className='profile-icon-dropdown-left-secondary'/>
            {numberOfLegsProfile >= 2 ? (numberOfLegsProfile + " Legs ") : (numberOfLegsProfile === 0 ? "All Bets" : "Single Bets")}
            <img src={arrowDown} className='profile-icon-dropdown-right'/>
          </div>
        </div>
        <div className='profile-row-tags'>
          
        </div>
        <div className='profile-row-search'>
          
        </div>
      </div>
    );

}

export default Filters;
