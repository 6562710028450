// components/Conversation.jsx
import React, { createRef, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import "../../Assets/css/verify-sms.css"
import { useNavigate, useParams } from 'react-router-dom';
import { popRoute, pushRoute, setVerifyPopup, setWithdrawalInfoPopup } from '../../State/redux/actions/StateActions';
import cancel from '../../Assets/icons/cancel-grey.png'
import VerifiedCheckmark from '../Account/SubComponents/PhoneUpdates/Verified';

const LockPost = () => {
    const auth = useSelector(state => state.auth_reducer);
    const {errorMsgWithdrawalInfo} = useSelector(state => state.user_reducer);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [changeNumberClicked, setChangeNumberClicked] = useState(false)
    const [showAnimation, setShowAnimation] = useState(false)

    useEffect(() => {
        // if verified, get rid of popup
        if(auth?.withdrawalSetup){
            setShowAnimation(true)
            setTimeout(()=>{
                dispatch(setWithdrawalInfoPopup(false))
            },3000)
        }
    }, [auth?.phone_verified]);

    const exitPopup = () => {
        dispatch(setWithdrawalInfoPopup(false))
    }

    return ( 
        <div className='popup-container'>
            <div className="verify-identity">
                <div className='verify-row'>
                    <h1>Bank Account Info</h1>
                    <div className='verify-cancel-btn-container'>
                        <img src={cancel} className='verify-cancel-btn' onClick={()=>{exitPopup()}}/>
                    </div>
                </div>

                { showAnimation ?
                    <VerifiedCheckmark />
                :
                    (auth?.phone != 0 && auth?.phone != null && errorMsgWithdrawalInfo === '' && !changeNumberClicked ?
                    <></>
                    :
                        <div></div>)
                    }
            </div>
        </div>
    );
};

export default LockPost;
