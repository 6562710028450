
  // Defining the UserState class
  class UserState {
    isLoading;
    users;
    user;
    errorMsg;
    success;
    errorMsgUsername;
    errorMsgPhone;
    timestampSMS;
    errorMsgCode;
    errorMsgWithdrawalInfo;
    errorMsgPayment;
    usersFollowing;
    usersAll;
    isLoadingProfile;
    isLoadingAll;
    isLoadingFollowing;
    isLoadingPhone;
  
    constructor() {
      this.isLoading = true;
      this.users = [];
      this.user = null;
      this.errorMsg = '';
      this.success = false;
      this.errorMsgUsername = '';
      this.errorMsgPhone = '';
      this.timestampSMS = '';
      this.errorMsgCode = '';
      this.errorMsgWithdrawalInfo = '';
      this.errorMsgPayment = '';
      this.usersFollowing = [];
      this.usersAll = [];
      this.isLoadingProfile = true;
      this.isLoadingAll = true;
      this.isLoadingFollowing = true;
      this.isLoadingPhone = false;
    }
  }
  
  // Exporting the UserState class
  export { UserState };
  