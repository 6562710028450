// reducers/socketReducer.js
const initialState = {
  connection_authenticated: false,
  connection_established: false,
  room: '',
};

export const socket_reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SOCKET_CONNECTED':
      return { ...state, connection_established: true };
    case 'SOCKET_DISCONNECTED':
      return { ...state, connection_established: false };
    case 'SOCKET_AUTHENTICATED':
      return { ...state, connection_authenticated: action.payload };
    case 'JOIN_ROOM':
      return { ...state, room: action.payload };
    default:
        return state;
  }
};

