
import { createRef, useEffect, useState, React, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeDescription, changeTitle, erasePicksForm, pushRoute, setVerifyPopup, toggleLock, updatePrice, updateState, } from '../../State/redux/actions/StateActions.js';
import "../../Assets/css/createpickspage.css"
import plus from '../../Assets/icons/add-g.png'
import NewPick from './SubComponents/NewPick.js';
import { v4 as uuidv4 } from 'uuid';
import Knob from './SubComponents/Knob.js';
import unlock from '../../Assets/icons/lock-grey.png'
import locked from '../../Assets/icons/locked-grey.png'
import HashTags from './SubComponents/HashTags.js';
import { PickPost } from '../../State/redux/model/PickPost.js';
import { addLeg, createPick, errorMessageScreenshot, getPicksFromScreenshotOddsJam, removeLeg, removeLegs, updateLeg } from '../../State/redux/actions/PickActions.js';
import { useNavigate } from 'react-router-dom';
import Header from './SubComponents/Header.js';
import cameraGrey from '../../Assets/icons/camera-grey.png'
import NewPickScreenshotted from './SubComponents/NewPickScreenShotted.js';
import { TailSpin } from 'react-loader-spinner';
import { decimalToAmerican } from '../../Utilities/UtilityFunctions.js';
import BetUnitStepper from './SubComponents/BetUnitStepper.js';
import Countdown from './SubComponents/Countdown.js';
import FirstPage from './SubComponents/FirstPage.js';
import SecondPage from './SubComponents/SecondPage.js';
import ThirdPage from './SubComponents/ThirdPage.js';

// app component
function CreatePicksPage() {

  const state = useSelector(state => state.state_reducer)
  const {title, description, price, lock, height, stepperBetUnits, createPicksPageNumber} = useSelector(state => state.state_reducer)
  const auth = useSelector(state => state.auth_reducer)
  const {legs, tags, pickPost, success, isLoadingScreenshot, errorMsgScreenshot} = useSelector(state => state.pick_reducer)
  const dispatch = useDispatch() 
  const navigate = useNavigate()
  
  const textareaRef = useRef()
  const [parlayOdds, setParlayOdds] = useState(2.0)
  const [flagTitle, setFlagTitle] = useState(false)
  const [flagText, setFlagText] = useState('')

  const inputRef = useRef(null);

  const [errorMessage, setErrorMessage] = useState('')
  const scrollRef = useRef(null);

  // component did update
  useEffect(()=>{
    getOddsTotal()
  },[legs])

  useEffect(()=>{
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0;
    }
  },[createPicksPageNumber])

  useEffect(()=>{
    if(success && pickPost){
      eraseForm()
      const path = `/profile/${auth.username}`
      dispatch(pushRoute(path));
      navigate(path);
    }
  },[success])

  const eraseForm  = () =>{
   
    dispatch(removeLegs())
    dispatch(erasePicksForm())
  }

  const getOddsTotal = () => {

    // Convert inidivdual American odds to decimal odds, and sum/produce them
    let totalParlayOddsDecimal = 1.0
    legs.forEach(leg => {
      const americanOdds = leg.odds;
      let decimalOdds;
      if (americanOdds > 0) {
        decimalOdds = (americanOdds / 100) + 1;
      } else if (americanOdds < 0) {
        decimalOdds = (-100 / americanOdds) + 1;
      } else {
        // Handle the case where American odds are 0 (even odds)
        decimalOdds = 1;
      }
      // Multiply the decimal odds with the running total
      totalParlayOddsDecimal *= decimalOdds;
    });

    totalParlayOddsDecimal !== 1 && setParlayOdds(totalParlayOddsDecimal.toFixed(2))
  }

  const showPages = () =>{
    switch(createPicksPageNumber){
      case 1:
        return <FirstPage />
      case 2:
        return <SecondPage/>
      case 3:
        return <ThirdPage />
      default:
        return <FirstPage />
    }
  }

  return (
    <div>
      <Header />
      <div 
        className={createPicksPageNumber === 3 ? "body white-background" : "body"} 
        ref={scrollRef}>
          {showPages()}
      </div>
    </div>
  )

}

export default CreatePicksPage;
