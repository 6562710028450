// components/Conversation.jsx
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import "../../Assets/css/conversation.css"
import { directMesssage, joinConversation } from '../../State/redux/actions/ChatActions';
import { useNavigate, useParams } from 'react-router-dom';
import MessageInput from './SubComponents/MessageInput';
import MessagesContainer from './SubComponents/MessagesContainer';
import Header from './SubComponents/Header';
import { popRoute, pushRoute } from '../../State/redux/actions/StateActions';
import { createConnection, getSocket } from '../../State/api/socket';

const DirectMessage = ({ }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    let { username } = useParams();
    const auth = useSelector((state)=>state.auth_reducer)
    const { conversationId } = useSelector((state)=>state.chat_reducer)
    
    useEffect(() => {
     

    }, []);

    useEffect(() => {
        
        createConnection(); // Ensures websocket connection is created
        dispatch(directMesssage(username, auth?.username))
        return () => {
          // disconnect from websocket connection
          var socket = getSocket();
          if (socket) socket.disconnect();
        };
      }, []);

    useEffect(()=>{
        if(conversationId!=''){
            routeTo(`/conversation/${conversationId}`)
        }
    },[conversationId])

    const routeTo = (route) => {
        dispatch(popRoute())
        dispatch(pushRoute(route));
        navigate(route)
    }

    return (
        <div className='conversation-container'>
           <Header/>
           <MessagesContainer />
           <MessageInput />
        </div>
    );
};

export default DirectMessage;
