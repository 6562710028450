
import { React } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import { pushRoute, } from '../../../State/redux/actions/StateActions.js';
import { useNavigate, useParams } from 'react-router-dom';
import "../../../Assets/css/profilepage.css"
import "../../../Assets/css/profile-bet-filters.css"
import msg from '../../../Assets/icons/msg-w.png';
import plus from '../../../Assets/icons/add-w.png';
import unlock from '../../../Assets/icons/lock-w.png';
import { followUser, } from '../../../State/redux/actions/UserActions.js';

// Profile component
function ProfileActions() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector(state => state.auth_reducer)
  const { user,} = useSelector((state) => state.user_reducer);
  let { username } = useParams();

  function follow(){
    dispatch(followUser(username))
  }

  const routeTo = (route) => {
        
    dispatch(pushRoute(route));
    navigate(route)
  }

  return (
      username != auth.username &&
      <div className='row-buttons'>
        <div className='column-third'>
          <div className='profile-button-third'>
            <img src={unlock} className='button-img'/>
            Unlock
          </div>
        </div>
       
        <div className='column-third'>
          <div className='profile-button-third-middle' onClick={()=>{follow()}}>
            {
            user?.followers && user?.followers.includes(auth.username) ?
            "Unfollow"
            :
            <>
            <img src={plus} className='button-img'/>
            Follow
            </>}
          </div>
        </div>

        <div className='column-third'>
          <div className='profile-button-third' onClick={()=>{routeTo(`/direct-message/${user?.username}`)}}>
            <img src={msg} className='button-img'/>
            Message
          </div>
        </div>
      </div>
    );
}

export default ProfileActions;
