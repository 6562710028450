import React, { useState, useEffect, useRef, createRef, useCallback } from 'react';
import "../../Assets/css/users.css";
import { useDispatch, useSelector } from 'react-redux';
import HeaderUsers from './SubComponents/HeaderUsers';
import UsersRow from './SubComponents/UsersRow';
import { searchUsers, searchUsersAll, searchUsersFollowing } from '../../State/redux/actions/UserActions';
import percentG from '../../Assets/icons/percent-w.png';
import flameG from '../../Assets/icons/flame-white.png';
import eyeG from '../../Assets/icons/eye-w.png';
import profitG from '../../Assets/icons/profit-w.png';
import hashtagG from '../../Assets/icons/hashtag-w.png';
import counterG from '../../Assets/icons/counter-w.png';
import arrowDown from '../../Assets/icons/down-white.png';
import PopupSelectionFiltersUsers from './SubComponents/PopupSelectionFiltersUsers';
import { setSearchToggle, toggleShowContentFiltersUsers, toggleShowDropdownFiltersUsers } from '../../State/redux/actions/StateActions';
import ToggleSwitch from './SubComponents/Toggle';
import Skeleton from './SubComponents/Skeleton';
import { useLocation } from 'react-router-dom';
import { ThreeDots } from 'react-loader-spinner';

const Users = () => {
  
  const {showDropdownFiltersUsers, usersSelection,openCloseMenu, searchToggle} = useSelector(state => state.state_reducer)
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const { users, usersFollowing, usersAll,isLoadingAll, isLoadingFollowing } = useSelector((state)=>state.user_reducer)
  const auth = useSelector((state)=>state.auth_reducer)
  const dispatch = useDispatch()
  const [selectedLeaderboards, setSelectedLeaderboards] = useState(true)
  const leaderboardsRef = useRef(null);
  const followingRef = useRef(null);
  const [underlineStyle, setUnderlineStyle] = useState({});
  const bodyRef = createRef();
  const popupRef = createRef();
  const zIndexRef = createRef();
  const searchRef = createRef();

  const location = useLocation();
  const prevLocationRef = useRef();
  const searchParams = new URLSearchParams(location.search);

  const [pageAll, setPageAll] = useState(1)
  const [pageFollowing, setPageFollowing] = useState(1)


  {/******************VIRTUAL LIST******************/}
  const containerRef = useRef(null);
  const [visibleStartIndexAll, setVisibleStartIndexAll] = useState(0);
  const [visibleEndIndexAll, setVisibleEndIndexAll] = useState(12); // Start with an arbitrary number of items

  const [visibleStartIndexFollowing, setVisibleStartIndexFollowing] = useState(0);
  const [visibleEndIndexFollowing, setVisibleEndIndexFollowing] = useState(12); // Start with an arbitrary number of items

  const percentageHeaderSize = 0.15;
  const viewportHeight = window.innerHeight; // Total height of the viewport
  const headerHeight = viewportHeight * percentageHeaderSize; // Percentage-based header
  const secondHeaderHeight = 55; // Fixed height second header
  const containerHeight = viewportHeight - headerHeight - secondHeaderHeight; // Calculate remaining height for list

  const fixedHeight = 65; // Fixed height for collapsed items
  const marginHeight = 10; // Margin height for each item

  const itemHeightsAll = usersAll.map(user => fixedHeight + marginHeight);

  const staticElementHeight = auth?.loggedIn ? 160 : 110; // Height of the static elements at the start
  const totalContentHeightAll = itemHeightsAll.reduce((acc, height) => acc + height, 0); // Total height of all elements

  const itemHeightsFollowing = usersFollowing.map(user => fixedHeight + marginHeight);
  const totalContentHeightFollowing = itemHeightsFollowing.reduce((acc, height) => acc + height, 0); // Total height of all elements

  const calculateNewPageSkipTo = () => {

      let accumulatedHeight = staticElementHeight; // Start with the static element height
      let startIndex = 0;
      let endIndex = 0;
  
      const elementHeight = fixedHeight+marginHeight

      // Adjust start index to keep the item visible until its bottom is out of view
      while (accumulatedHeight < containerHeight) {
          accumulatedHeight += elementHeight;
          if (accumulatedHeight > containerHeight) {
              break; // Break as soon as the bottom of the item exceeds the top of the scroll
          }
          startIndex++;
      }
  
      // Determine the last visible index, ensuring it does not go beyond the list's length
      accumulatedHeight = containerHeight;
      endIndex = startIndex; // Start calculating from the first visible index
      while (accumulatedHeight < containerHeight + containerHeight) {
          accumulatedHeight += elementHeight;
          endIndex++;
      }
  
      //console.log("START1: "+startIndex)
      //console.log("END1: "+endIndex)
      
      const numberOfElementsToSkip = endIndex - startIndex
      
      //console.log("SKIP: "+numberOfElementsToSkip)

      const totalHeightDesired = numberOfElementsToSkip * elementHeight
      const cutOffPixels = totalHeightDesired - containerHeight
      const scrollTop = staticElementHeight + (elementHeight * 2) + cutOffPixels
      
      startIndex = 2
      endIndex = startIndex + numberOfElementsToSkip

      const numberOfElementsToAppend = numberOfElementsToSkip + 2

      //console.log("APPEND: "+numberOfElementsToAppend)

      //console.log("SCROLL: "+scrollTop)

      //console.log("START2: "+startIndex)

      //console.log("END2: "+endIndex)

      return {numberOfElementsToAppend, scrollTop, startIndex, endIndex}

  }

  const calculatePrevPageSkipTo = () => {

    let accumulatedHeight = staticElementHeight; // Start with the static element height
    let startIndex = 0;
    let endIndex = 0;

    const elementHeight = fixedHeight+marginHeight

    // Adjust start index to keep the item visible until its bottom is out of view
    while (accumulatedHeight < containerHeight) {
        accumulatedHeight += elementHeight;
        if (accumulatedHeight > containerHeight) {
            break; // Break as soon as the bottom of the item exceeds the top of the scroll
        }
        startIndex++;
    }

    // Determine the last visible index, ensuring it does not go beyond the list's length
    accumulatedHeight = containerHeight;
    endIndex = startIndex; // Start calculating from the first visible index
    while (accumulatedHeight < containerHeight + containerHeight) {
        accumulatedHeight += elementHeight;
        endIndex++;
    }

    //console.log("START1: "+startIndex)
    //console.log("END1: "+endIndex)
    
    const numberOfElementsToSkip = endIndex - startIndex
    
    //console.log("SKIP: "+numberOfElementsToSkip)

    const totalHeightDesired = numberOfElementsToSkip * elementHeight
    const cutOffPixels = totalHeightDesired - containerHeight
    const scrollTop = staticElementHeight + elementHeight + cutOffPixels
    
    startIndex = 2
    endIndex = startIndex + numberOfElementsToSkip

    const numberOfElementsToAppend = numberOfElementsToSkip + 2

    // console.log("APPEND: "+numberOfElementsToAppend)

    // console.log("SCROLL: "+scrollTop)

    // console.log("START2: "+startIndex)

    // console.log("END2: "+endIndex)

    return {numberOfElementsToAppend}

  }

  const handleScrollAll = () => {
      const scrollTop = containerRef.current.scrollTop;
      let accumulatedHeight = staticElementHeight; // Start with the static element height
      let startIndex = 0;
      let endIndex = 0;
  
      // Adjust start index to keep the item visible until its bottom is out of view
      while (accumulatedHeight < scrollTop && startIndex < itemHeightsAll.length) {
          accumulatedHeight += itemHeightsAll[startIndex];
          if (accumulatedHeight > scrollTop) {
              break; // Break as soon as the bottom of the item exceeds the top of the scroll
          }
          startIndex++;
      }
  
      // Determine the last visible index, ensuring it does not go beyond the list's length
      accumulatedHeight = scrollTop;
      endIndex = startIndex; // Start calculating from the first visible index
      while (accumulatedHeight < scrollTop + containerHeight && endIndex < itemHeightsAll.length) {
          accumulatedHeight += itemHeightsAll[endIndex];
          endIndex++;
      }
  
      setVisibleStartIndexAll(startIndex);
      setVisibleEndIndexAll(endIndex); // Ensure we set the state to the last calculated index
  };

  const handleScrollFollowing = () => {
    const scrollTop = containerRef.current.scrollTop;
    let accumulatedHeight = staticElementHeight; // Start with the static element height
    let startIndex = 0;
    let endIndex = 0;

    // Adjust start index to keep the item visible until its bottom is out of view
    while (accumulatedHeight < scrollTop && startIndex < itemHeightsFollowing.length) {
        accumulatedHeight += itemHeightsFollowing[startIndex];
        if (accumulatedHeight > scrollTop) {
            break; // Break as soon as the bottom of the item exceeds the top of the scroll
        }
        startIndex++;
    }

    // Determine the last visible index, ensuring it does not go beyond the list's length
    accumulatedHeight = scrollTop;
    endIndex = startIndex; // Start calculating from the first visible index
    while (accumulatedHeight < scrollTop + containerHeight && endIndex < itemHeightsFollowing.length) {
        accumulatedHeight += itemHeightsFollowing[endIndex];
        endIndex++;
    }

    setVisibleStartIndexFollowing(startIndex);
    setVisibleEndIndexFollowing(endIndex); // Ensure we set the state to the last calculated index
  };
  

  useEffect(() => {
    handleScrollAll(); // Handle initial load and resize events
    handleScrollFollowing();
    const handleResize = () => {
      handleScrollAll(); // Handle resize and adjust for 'All' list
      handleScrollFollowing(); // Handle resize and adjust for 'Following' list
  };
    window.addEventListener('resize', handleResize); // Adjust for viewport size changes
    return () => window.removeEventListener('resize', handleResize);
  }, [usersAll, usersFollowing]); // React to changes in expansion and the list



  const [isLoadingFollowingPage, setIsLoadingFollowingPage] = useState(false);
  const [hasMoreFollowing, sethasMoreFollowing] = useState(true);

  const observerBottomFollowing = useRef();
  const observerTopFollowing = useRef();

  // scroll to next page, fetch picks, slice virtual list, append last 5 elements from current list to start of new list, scroll to same post/elements new position
  const lastElementRefFollowing = useCallback(node => {
    if (isLoadingFollowingPage) return;

    if (observerBottomFollowing.current) observerBottomFollowing.current.disconnect();
    observerBottomFollowing.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMoreFollowing) {
            setIsLoadingFollowingPage(true);
            const {numberOfElementsToAppend, scrollTop, startIndex, endIndex} = calculateNewPageSkipTo()
            dispatch(searchUsersFollowing(searchTerm, searchToggle ? 'tags':'username',  usersSelection, pageFollowing + 1, false, false, numberOfElementsToAppend))
                .then((data) => {
                  if(data){
                    setPageAll(prevPage => prevPage + 1);
                    
                    setVisibleStartIndexAll(startIndex);
                    setVisibleEndIndexAll(endIndex);
                    // This ensures that the scrollable container scrolls back to top after indices are reset
                    if (containerRef.current) {
                      containerRef.current.scrollTop = scrollTop;
                    }
                  }
                  setIsLoadingAllPage(false);
                }).catch(error => {
                  console.log('Failed to fetch data:', error);
                  setIsLoadingFollowingPage(false);
                });
        }
    });
    if (node) observerBottomFollowing.current.observe(node);
}, [isLoadingFollowingPage, hasMoreFollowing, dispatch, searchUsersFollowing, usersSelection, pageFollowing]);

// scroll back to previous page, fetch picks, slice virtual list, append first 5 elements from current list to end of new list, scroll to same post/elements new position
const firstElementRefFollowing = useCallback(node => {
    if (isLoadingFollowingPage) return;
    if (observerTopFollowing.current) observerTopFollowing.current.disconnect();
    observerTopFollowing.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && pageFollowing > 1) {
            setIsLoadingFollowingPage(true);
            const {numberOfElementsToAppend} = calculatePrevPageSkipTo()
            dispatch(searchUsersFollowing(searchTerm, searchToggle ? 'tags':'username',  usersSelection, pageFollowing - 1, true, false, numberOfElementsToAppend))
                .then((users) => {
                  if(users){
                    setPageFollowing(prevPage => prevPage - 1);
                    setVisibleStartIndexAll(users.length-numberOfElementsToAppend);// @TODO: set to end of the stack
                    setVisibleEndIndexAll(users.length);
                    // This ensures that the scrollable container scrolls back to bottom after indices are reset
                    if (containerRef.current) {
                      containerRef.current.scrollTop = staticElementHeight+((users.length-numberOfElementsToAppend)*(marginHeight+fixedHeight));
                    }
                  }
                  setIsLoadingFollowingPage(false);
                }).catch(error => {
                  console.log('Failed to fetch data:', error);
                  setIsLoadingFollowingPage(false);
                });
        }
    });
    if (node) observerTopFollowing.current.observe(node);
}, [isLoadingFollowingPage, pageFollowing, dispatch, searchUsersFollowing, usersSelection]);



const [isLoadingAllPage, setIsLoadingAllPage] = useState(false);
const [hasMoreAll, sethasMoreAll] = useState(true);

const observerBottomAll = useRef();
const observerTopAll = useRef();

useEffect(()=>{
  //console.log("(start, end): ("+visibleStartIndexAll+", "+visibleEndIndexAll+")")
},[visibleEndIndexAll, visibleStartIndexAll])

// scroll to next page, fetch picks, slice virtual list, append last 5 elements from current list to start of new list, scroll to same post/elements new position
const lastElementRefAll = useCallback(node => {
  if (isLoadingAllPage) return;
  
  if (observerBottomAll.current) observerBottomAll.current.disconnect();
  observerBottomAll.current = new IntersectionObserver(async entries => {
    //console.log('Observer triggered'); // To check if observer is triggered
      if (entries[0].isIntersecting && hasMoreAll) {
          setIsLoadingAllPage(true);      
          const {numberOfElementsToAppend, scrollTop, startIndex, endIndex} = await calculateNewPageSkipTo()
          dispatch(searchUsersAll(searchTerm, searchToggle ? 'tags':'username',  usersSelection, pageAll + 1, false, false, numberOfElementsToAppend))
              .then((data) => {
                if(data){
                  setPageAll(prevPage => prevPage + 1);
                  setVisibleStartIndexAll(startIndex);
                  setVisibleEndIndexAll(endIndex);
                  // This ensures that the scrollable container scrolls back to top after indices are reset
                  if (containerRef.current) {
                    containerRef.current.scrollTop = scrollTop;
                  }
                }
                setIsLoadingAllPage(false);
              }).catch(error => {
                console.log('Failed to fetch data:', error);
                setIsLoadingAllPage(false);
              });
      }
  });
  if (node) observerBottomAll.current.observe(node);
}, [isLoadingAllPage, hasMoreAll, dispatch, searchUsersAll, usersSelection, pageAll]);

// scroll back to previous page, fetch picks, slice virtual list, append first 5 elements from current list to end of new list, scroll to same post/elements new position
const firstElementRefAll = useCallback(node => {
  if (isLoadingAllPage) return;
  if (observerTopAll.current) observerTopAll.current.disconnect();
  observerTopAll.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && pageAll > 1) {
          setIsLoadingAllPage(true);
          const {numberOfElementsToAppend} = calculatePrevPageSkipTo()
          dispatch(searchUsersAll(searchTerm, searchToggle ? 'tags':'username',  usersSelection, pageAll - 1, true, false, numberOfElementsToAppend))
              .then((users) => {
                if(users){
                  setPageAll(prevPage => prevPage - 1);
                  setVisibleStartIndexAll(users.length-numberOfElementsToAppend);// @TODO: set to end of the stack
                  setVisibleEndIndexAll(users.length);
                  // This ensures that the scrollable container scrolls back to bottom after indices are reset
                  if (containerRef.current) {
                    containerRef.current.scrollTop = staticElementHeight+((users.length-numberOfElementsToAppend)*(marginHeight+fixedHeight));
                  }
                }
                setIsLoadingAllPage(false);
              }).catch(error => {
                console.log('Failed to fetch data:', error);
                setIsLoadingAllPage(false);
              });
      }
  });
  if (node) observerTopAll.current.observe(node);
}, [isLoadingAllPage, pageAll, dispatch, searchUsersAll, usersSelection]);

  {/******************VIRTUAL LIST END******************/}


  useEffect(()=>{
      const searchTags = searchParams.get('searchTags');
      if(searchTags){
        dispatch(setSearchToggle(true))
        setSearchTerm(searchTags)
      }else{
        dispatch(setSearchToggle(false))
        setSearchTerm('')
      }
  },[location])

  {/******************TAB UNDERLINE ANIMATIONS******************/}
  const updateUnderlinePosition = () => {

    const activeTab = selectedLeaderboards ? leaderboardsRef.current : followingRef.current;
    const width = activeTab?.offsetWidth;
    const left = activeTab?.offsetLeft;
    setUnderlineStyle({
      width: `${width}px`,
      left: `${left}px`,
    });
  
  };

  // Update underline position on tab change
  useEffect(() => {
    updateUnderlinePosition();
  }, [selectedLeaderboards]);

  // Adjust underline position when the component mounts and if the window is resized
  useEffect(() => {

    dispatch(searchUsersAll('', 'username', usersSelection, pageAll))
    const timer = setTimeout(() => {
      updateUnderlinePosition();
    }, 300);

    // Cleanup function to clear the timeout if the component unmounts
    window.addEventListener('resize', updateUnderlinePosition);
    return () => {
      window.removeEventListener('resize', updateUnderlinePosition);
      clearTimeout(timer);
    };
  }, []);



  {/******************BODY ANITMATIONS******************/}
  {/* SHIFT BODY LEFT => RIGHT */}
  const selectLeaderboards = () => {
    if (!selectedLeaderboards) {
      setSelectedLeaderboards(true);
      // change css for animation (refs required for animation transition)
      const ref = bodyRef.current
      ref.classList.toggle("move-right")
    }
  };

  {/* SHIFT BODY RIGHT => LEFT */}
  const selectFollowing = () => {
    if (selectedLeaderboards) {
      setSelectedLeaderboards(false);
      // change css for animation (refs required for animation transition)
      const ref = bodyRef.current
      ref.classList.toggle("move-right")
    }
  };

  {/******************SEARCH FUNCTIONS******************/}
  {/* SEARCH USERS */}
  useEffect(() => {
      if(selectedLeaderboards){
        dispatch(searchUsersAll(searchTerm, searchToggle ? 'tags':'username',  usersSelection, pageAll))
      }else{
        dispatch(searchUsersFollowing(searchTerm, searchToggle ? 'tags':'username',  usersSelection, pageFollowing))
      }  
  }, [searchTerm]);

  {/* CHANGE SEARCH BASED ON FOLLOWING OR ALL */}
  useEffect(() => {
    // check which tab is clicked, All or Following
    if(selectedLeaderboards){
      // only search if we dont have picks yet
      if( !usersAll || usersAll?.length == 0 ){
        dispatch(searchUsersAll(searchTerm, searchToggle ? 'tags':'username',  usersSelection, pageAll))
      }
    }else{
      if( !usersFollowing || usersFollowing?.length == 0 ){
        dispatch(searchUsersFollowing(searchTerm, searchToggle ? 'tags':'username',  usersSelection, pageFollowing))
      }
    } 
  }, [selectedLeaderboards]);

  {/* LISTEN FOR KEY LOGS FOR SEARCH */}
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  {/******************FILTER FUNCTIONS******************/}
  {/* APPLY FILTERS */}
  useEffect(() => {
    if(selectedLeaderboards){
      dispatch(searchUsersAll(searchTerm, searchToggle ? 'tags':'username',  usersSelection, pageAll))
    }else{
      dispatch(searchUsersFollowing(searchTerm, searchToggle ? 'tags':'username',  usersSelection, pageFollowing))
    }  
  }, [usersSelection]);

  {/* FILTER TRIGGERS */}
  const dropdownSelectionFilter = () => {

    // show effects
    dispatch(toggleShowDropdownFiltersUsers(!showDropdownFiltersUsers))

    // show text
    dispatch(toggleShowContentFiltersUsers(true))


    // change css for animation (refs required for animation transition)
    const ref = popupRef.current
    ref.classList.toggle("users-is-filter-menu-open")

    const refZ = zIndexRef.current
    refZ.classList.toggle("users-z-indices")
  }

  {/* GET ICONS */}
  function getIcon(value){

    switch(value){
      case "Best Record":
        return  <img src={percentG} className='users-icon-dropdown-left'/>
      case "Hot Streak":
        return  <img src={flameG} className='users-icon-dropdown-left'/>
      case "Popularity":
        return  <img src={eyeG} className='users-icon-dropdown-left'/>
      case "Most Bets":
        return  <img src={hashtagG} className='users-icon-dropdown-left'/>
      case "Most Streaks":
        return  <img src={counterG} className='users-icon-dropdown-left'/>
      case "Profit":
        return  <img src={profitG} className='users-icon-dropdown-left'/>
    }
  }

  useEffect(()=>{
    // if we reroute or click the menu, close the filter popup
    if(openCloseMenu){
      if(showDropdownFiltersUsers){
          dropdownSelectionFilter()
      }
    }
  },[openCloseMenu])

  const toggled = () => {
    setSearchTerm('')
    searchRef?.current?.focus()
  }

  return (
  <div>
    <HeaderUsers />
    <div className="users-page" ref={containerRef} onScroll={()=>{selectedLeaderboards ? handleScrollAll() : handleScrollFollowing()}}>
      {(selectedLeaderboards && pageAll != 1) || !selectedLeaderboards && pageFollowing != 1
      ?
        <div className={auth?.loggedIn ? 'loading-indicator' : 'loading-indicator-small'}>
          <ThreeDots color="white" height={80} width={80}/>
        </div>
      :
        <div className='filter-elements'>
          {/* TAB SELECTION */}
          {auth?.loggedIn &&
          <div className='discovery-type-row'>
            <div ref={leaderboardsRef} className="discovery-type" onClick={() => selectLeaderboards()} >
              <h3>All</h3>
            </div>
            <div ref={followingRef} className="discovery-type" onClick={() => selectFollowing()}>
              <h3>Following</h3>
            </div>
            <div className="underline" style={underlineStyle}></div> {/* This is the moving underline */}
          </div>}
          
          {/* SEARCH */}
          <div className='search-and-toggle'>
            <input ref={searchRef} type="text" placeholder={searchToggle? "Search Tags": "Search Usernames"} onChange={handleSearchChange} className='search-users' value={searchTerm}/>
            <ToggleSwitch toggled={toggled} />
          </div>

          {/* FILTERS */}
          <div className='users-filters'>
            <div className='users-row-dropdowns'>
              <div onClick={()=>{dropdownSelectionFilter()}} className={'users-text-selection'}>
                {usersSelection ? getIcon(usersSelection) : getIcon("Profit")}
                {usersSelection ? usersSelection : "Profit"}
                <img src={arrowDown} className='users-icon-dropdown-right'/>
              </div> 
            </div>
          </div>
        </div>}
      {/* BODY */}
      <div className="discovery-body" ref={bodyRef}>
        {!isLoadingAll ? 
          <div className={'users-list'}>
            {/*usersAll?.map((user, index) => (
              <UsersRow key={index} user={user} />
            ))*/}
            <div style={{ height: `${totalContentHeightAll}px`, position: 'relative' }}>
              {usersAll.slice(visibleStartIndexAll, visibleEndIndexAll + 1).map((user, index) => (
                  <div 
                  key={user._id}
                  ref={(visibleStartIndexAll + index) === 0 ? firstElementRefAll : (visibleEndIndexAll === usersAll.length - 1 ? lastElementRefAll : null)}
                  style={{
                      position: 'absolute',
                      top: `${itemHeightsAll.slice(0, visibleStartIndexAll + index).reduce((acc, height) => acc + height, 0)}px`,
                      width: '100%',
                      }}>
                        <UsersRow user={user} />
                  </div>
              ))}
            </div>
          </div>
          :
          <div className={'users-list'}>
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </div>
        }
        {!isLoadingFollowing ? 
        <div className={'users-list'}>
          {/*usersFollowing?.map((user, index) => (
            <UsersRow key={index} user={user} />
          ))*/}
          <div style={{ height: `${totalContentHeightFollowing}px`, position: 'relative' }}>
              {usersFollowing.slice(visibleStartIndexFollowing, visibleEndIndexFollowing + 1).map((user, index) => (
                  <div 
                  key={user._id} 
                  ref={(visibleStartIndexFollowing + index) === 0 ? firstElementRefFollowing : (visibleEndIndexFollowing === usersFollowing.length - 1 ? lastElementRefFollowing : null)} 
                  style={{
                      position: 'absolute',
                      top: `${itemHeightsFollowing.slice(0, visibleStartIndexFollowing + index).reduce((acc, height) => acc + height, 0)}px`,
                      width: '100%'
                  }}>
                      <UsersRow user={user} />
                  </div>
              ))}
          </div>
        </div>
        :
        <div className={'users-list'}>
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
        </div>
        }
      </div>
    </div>

    <div ref={zIndexRef} className='users-feed-overlay'>
      <div className='users-parent-container-secondary'>
        <div ref={popupRef} className='users-filter-popup'>
            <PopupSelectionFiltersUsers dropdownSelectionFilter={dropdownSelectionFilter}/>
        </div>
      </div>
    </div>

    {showDropdownFiltersUsers &&
      <div onClick={()=>{dropdownSelectionFilter()}} className='users-darken-background'/>
    }
    
  </div>
  );
};

export default Users;
