import { ChatState } from "../model/ChatState";

// @todo: read msg, create convo, open convo, send msg, rcv msg
export const chat_reducer = (state = new ChatState(), action) => {
  switch (action.type) {

      case 'SHOW_MODAL':
        return {
            ...state,
            showModal: action.payload
        };
      case 'JOIN_CONVERSATION':
        //console.log("pod name: "+action.payload.podName)
        //console.log("pod name staging: "+action.payload.podNameStag)
        return {
            ...state,
            conversationId: action.payload.conversationId,
            lastMessage: action.payload.lastMessage,
            activeParticipants: action.payload.activeParticipants,
            participants: action.payload.participants,
            conversationName: action.payload.conversationName,
            conversationCreator: action.payload.conversationCreator,
            messages: action.payload.messages,
            read: action.payload.read
        };
      case 'UPDATE_MESSAGE':
        return {
            ...state,
            lastMessage: action.payload.messages[action.payload.messages.length-1],
            messages: action.payload.messages,
            read: action.payload.read
        };
      case 'UPDATE_ACTIVE_USERS':
        return {
            ...state,
            activeParticipants: action.payload
        };
      case 'UPDATE_CONVERSATION_CREATOR':
        return {
            ...state,
            conversationCreator: action.payload
        };
      case 'UPDATE_PARTICIPANTS':
        return {
            ...state,
            participants: action.payload
        };
      case 'UPDATE_CONVO_NAME':
        return {
            ...state,
            conversationName: action.payload
        };
      case 'UPDATE_CONVO_ID':
        return {
            ...state,
            conversationId: action.payload
        };
      case 'UPDATE_CONVOS':
        return {
            ...state,
            conversations: action.payload
        };
      case 'UPDATE_CONVO_FILTERS':
        return {
            ...state,
            filters: action.payload
        };
      case 'CREATE_CONVO_SEARCH_USERS':
        return {
            ...state,
            usersSearch: action.payload
        };
      case 'UPDATE_USERS_READ_MSG':
        return {
            ...state,
            read: action.payload.read
        };
      case 'UPDATE_CONVERSATION_PHOTOS':
      
        return {
            ...state,
            profilePhotos: action.payload.profilePhotos
        };
      case 'CHANGE_CONVO_NAME':
        return {
            ...state,
            conversationName: action.payload.conversationName
        };
      case 'CONVERSATION_USER_TYPING':
        return {
            ...state,
            typing: action.payload
        };
      case 'UPDATE_ACTIVE_PARTICIPANTS':
       
        return {
            ...state,
            activeParticipants: action.payload.activeParticipants
        };
      case 'TOGGLE_CONNECTION_REJOIN_ROOM':
        return {
            ...state,
            connectionToggle: action.payload
        };
      default:
          return state;
  }
};

