// components/Conversation.jsx
import React, { createRef, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import "../../Assets/css/payment.css"
import back from "../../Assets/icons/back-grey.png"
import ellipsis from "../../Assets/icons/ellipsis-grey.png"
import { useNavigate, useParams } from 'react-router-dom';
import { popRoute, pushRoute, setPaymentPopup, setVerifyPopup } from '../../State/redux/actions/StateActions';
import cancel from '../../Assets/icons/cancel-grey.png'
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import PaymentForm from './SubComponents/PaymentForm';
import VerifiedCheckmark from '../Account/SubComponents/PhoneUpdates/Verified';
import ProductSelection from './SubComponents/ProductSelection';

const UnlockPost = () => {
    const {currency} = useSelector(state=>state.auth_reducer)
    const {errorMsgPayment} = useSelector(state => state.user_reducer);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [description, setDesciption] = useState('')
    const [amount, setAmount] = useState('')
    const [type, setType] = useState(1)
    const [selected, setSelected] = useState(false)
    // public key
    const stripePromise = loadStripe('pk_test_51Oq6EXECExZiZHgCh4173Fnj7xsqS714rUjvsiYvsMGvbCGL0H2TMdlWKeFa7A63C03cz0YiCvbPZCQGG3PmXWRM008yLBi9G2');

    const exitPopup = () => {
        dispatch(setPaymentPopup(false))
    }

    const onSelection = (amount, description, type) => {
        setAmount(amount)
        setDesciption(description)
        setType(type)
        setSelected(true)
    }

    const back = () => {
        setSelected(false)
        setAmount('')
        setDesciption('')
    }

    const amountInCents = Math.round(amount * 100);
    const options = {
        mode: 'payment',
        amount: amountInCents,
        currency: currency,
        // Fully customizable with appearance API.
        appearance: {
          /*...*/
        },
      };

    return ( 
        <div className='payment-popup-container'>
         
                <div className='payment-row-top'>
                    <h1>Unlock</h1>
                    <div className='verify-cancel-btn-container'>
                        <img src={cancel} className='verify-cancel-btn' onClick={()=>{exitPopup()}}/>
                    </div>
                </div>

                { !selected ?
                    <ProductSelection onSelection={onSelection}/>
                :
                <Elements stripe={stripePromise} options={options}>
                    <PaymentForm amount={amount} description={description} purchaseType={type} back={back}/>
                </Elements>}
          
        </div>
    );
};

export default UnlockPost;
