import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

const Countdown = () => {
    const [count, setCount] = useState(30);
    const dispatch = useDispatch()

    useEffect(() => {
        if (count > 0) {
        const timer = setInterval(() => {
            setCount(prevCount => prevCount - 1);
        }, 1000);

        return () => clearInterval(timer); // Cleanup the interval on component unmount
        }
    }, [count]);

    useEffect(() => {
        if (count === 0) {
            dispatch({ type: 'END_LOADING_SCREENSHOT' });
        }
    }, [count]);

    return (

        <div style={styles.text}>
            {count}
        </div>

    );
};

const styles = {
  text: {
    fontSize: '15px',
    color: '#838383',
  },
};

export default Countdown;
