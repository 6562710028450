
import { createRef, useEffect, useState, React, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setShareAsDM, setShareLink, setShareToggle, toggleExpandPickCard, toggleShowContentFilters, toggleShowContentLegs, toggleShowDropdownFilters, toggleShowDropdownLegs, } from '../../State/redux/actions/StateActions.js';
import { useNavigate, useParams } from 'react-router-dom';
import "../../Assets/css/profilepage.css"
import "../../Assets/css/profile-bet-filters.css"

import { TailSpin } from 'react-loader-spinner';
import PickCard from '../Picks/SubComponents/PicksCard.js';
import EntirePost from '../Picks/SubComponents/EntirePost.js';
import Followers from './SubComponents/Followers.js';
import PopupSelectionFilters from './SubComponents/PopupSelectionFilters.js';
import { getPicksByCreator } from '../../State/redux/actions/PickActions.js';
import { getProfile } from '../../State/redux/actions/UserActions.js';
import Header from './SubComponents/Header.js';
import PhotoNameTagsFollowers from './SubComponents/PhotoNameTagsFollowers.js';
import Statistics from './SubComponents/Statistics.js';
import ProfileActions from './SubComponents/ProfileActions.js';
import Filters from './SubComponents/Filters.js';
import BetweenPostsAdComponent from '../GoogleAds/BetweenPostsAdComponent.js';
import SkeletonProfile from './SubComponents/SkeletonProfile.js';
import Skeleton from '../Picks/SubComponents/Skeleton.js';
import empty from "../../Assets/icons/empty-w.png"
import plus from "../../Assets/icons/add-g.png"
import SharePopup from './SubComponents/SharePopup.js';

// Profile component
function ProfilePage() {

  const dispatch = useDispatch();
  const {revealFollowers, revealEntirePost, showDropdownFilters, showDropdownLegs, numberOfLegsProfile, openCloseMenu, stack, shareToggle, profileSelection} = useSelector(state => state.state_reducer)
  const { picks, pick, isLoadingPicks } = useSelector((state) => state.pick_reducer);
  const { user, isLoadingProfile } = useSelector((state) => state.user_reducer);
  const [numLegs, setNumLegs] = useState('');
  const popupRef = createRef();
  const zIndexRef = createRef();
  let { username } = useParams();
  const [page, setPage] = useState(1)
  const [scrollTo, setScrollTo] = useState(0)

  const containerRef = useRef(null);
  const [visibleStartIndex, setVisibleStartIndex] = useState(0);
  const [visibleEndIndex, setVisibleEndIndex] = useState(5); // Start with an arbitrary number of items
  const expandedPickId = useSelector(state => state.state_reducer.expandPickCard); // Redux state for the expanded card

  const percentageHeaderSize = 0.15;
  const viewportHeight = window.innerHeight; // Total height of the viewport
  const headerHeight = viewportHeight * percentageHeaderSize; // Percentage-based header
  const secondHeaderHeight = 55; // Fixed height second header
  const containerHeight = viewportHeight - headerHeight - secondHeaderHeight; // Calculate remaining height for list

  const fixedHeight = 255; // Fixed height for collapsed items
  const marginHeight = 20; // Margin height for each item
  const calculateExpandedHeight = (pick) => 375 + (90 * (pick.legs.length - 1)); // Dynamic height calculation for expanded items

  const itemHeights = picks.map(pick =>
      (pick._id === expandedPickId ? calculateExpandedHeight(pick) : fixedHeight) + marginHeight
  );

  const staticElementHeight = 350; // Height of the static element at the start
  const totalContentHeight = itemHeights.reduce((acc, height) => acc + height, 0); // Total height of all elements

  useEffect(() => {
      scrollTo == 0 && handleScroll(); // Handle initial load and resize events
      window.addEventListener('resize', handleScroll); // Adjust for viewport size changes
      return () => window.removeEventListener('resize', handleScroll);
  }, [expandedPickId, picks]); // React to changes in expansion and the list

  const handleScroll = () => {
    const scrollTop = containerRef.current.scrollTop;
    let accumulatedHeight = staticElementHeight; // Start with the static element height
    let startIndex = 0;
    let endIndex = 0;

    // Adjust start index to keep the item visible until its bottom is out of view
    while (accumulatedHeight < scrollTop && startIndex < itemHeights.length) {
        accumulatedHeight += itemHeights[startIndex];
        if (accumulatedHeight > scrollTop) {
            break; // Break as soon as the bottom of the item exceeds the top of the scroll
        }
        startIndex++;
    }

    // Determine the last visible index, ensuring it does not go beyond the list's length
    accumulatedHeight = scrollTop;
    endIndex = startIndex; // Start calculating from the first visible index
    while (accumulatedHeight < scrollTop + containerHeight && endIndex < itemHeights.length) {
        accumulatedHeight += itemHeights[endIndex];
        endIndex++;
    }


    // close the pick card if its not being rendered
    if(expandedPickId !== 0){
      var flagged = false
      var numberOfLegs = 1
      picks.slice(startIndex, endIndex).map((post)=>{
        if(post._id === expandedPickId){
          flagged = true
          numberOfLegs = post.legs.length-1
        }
      })
      if(!flagged){
        dispatch(toggleExpandPickCard(0))
        if (containerRef.current) {
          containerRef.current.scrollTop = containerRef.current.scrollTop-(255+(90 * (numberOfLegs)));
        }
      }

      // minimum 4 items being rendered
      if((endIndex - startIndex) <= 4){
        endIndex = startIndex+4
      }
    }

    setVisibleStartIndex(startIndex);
    setVisibleEndIndex(endIndex); // Ensure we set the state to the last calculated index

    // dont let user see static elements at top of page (filters, bio, etc) if on page 2 or more
    if (page > 1 && (scrollTop < 350)) { 
      if (containerRef.current) {
            containerRef.current.scrollTop = 350;
      }
    }

  };

  const calculateNewPageSkipTo = () => {

    let accumulatedHeight = staticElementHeight; // Start with the static element height
    let startIndex = 0;
    let endIndex = 0;

    const elementHeight = fixedHeight+marginHeight

    // Adjust start index to keep the item visible until its bottom is out of view
    while (accumulatedHeight < containerHeight) {
        accumulatedHeight += elementHeight;
        if (accumulatedHeight > containerHeight) {
            break; // Break as soon as the bottom of the item exceeds the top of the scroll
        }
        startIndex++;
    }

    // Determine the last visible index, ensuring it does not go beyond the list's length
    accumulatedHeight = containerHeight;
    endIndex = startIndex; // Start calculating from the first visible index
    while (accumulatedHeight < containerHeight + containerHeight) {
        accumulatedHeight += elementHeight;
        endIndex++;
    }

    const numberOfElementsToSkip = endIndex - startIndex

    const totalHeightDesired = numberOfElementsToSkip * elementHeight
    const cutOffPixels = totalHeightDesired - containerHeight
    const scrollTop = staticElementHeight + (elementHeight * 2) + cutOffPixels
    
    startIndex = 2
    endIndex = startIndex + numberOfElementsToSkip

    const numberOfElementsToAppend = numberOfElementsToSkip + 2

    return {numberOfElementsToAppend, scrollTop, startIndex, endIndex}

  }

  const calculatePrevPageSkipTo = () => {

    let accumulatedHeight = staticElementHeight; // Start with the static element height
    let startIndex = 0;
    let endIndex = 0;

    const elementHeight = fixedHeight+marginHeight

    // Adjust start index to keep the item visible until its bottom is out of view
    while (accumulatedHeight < containerHeight) {
        accumulatedHeight += elementHeight;
        if (accumulatedHeight > containerHeight) {
            break; // Break as soon as the bottom of the item exceeds the top of the scroll
        }
        startIndex++;
    }

    // Determine the last visible index, ensuring it does not go beyond the list's length
    accumulatedHeight = containerHeight;
    endIndex = startIndex; // Start calculating from the first visible index
    while (accumulatedHeight < containerHeight + containerHeight) {
        accumulatedHeight += elementHeight;
        endIndex++;
    }
    
    const numberOfElementsToSkip = endIndex - startIndex
    const totalHeightDesired = numberOfElementsToSkip * elementHeight
    const cutOffPixels = totalHeightDesired - containerHeight
    const scrollTop = staticElementHeight + elementHeight + cutOffPixels
    
    startIndex = 2
    endIndex = startIndex + numberOfElementsToSkip

    const numberOfElementsToAppend = numberOfElementsToSkip + 2

    return {numberOfElementsToAppend}

  }

  // component did mount
  useEffect(()=>{

    dispatch(getProfile(username))
    // @TODO: Filters, tags search
    dispatch(getPicksByCreator(username, profileSelection, page, numberOfLegsProfile, ))
  },[])

  useEffect(()=>{

    dispatch(getProfile(username))
    // @TODO: Filters, tags search
    dispatch(getPicksByCreator(username, profileSelection, page, numberOfLegsProfile))
  },[username])

  useEffect(()=>{
    // if we reroute or click the menu, close the filter popup
    if(openCloseMenu){
      if(showDropdownFilters){
          dropdownSelectionFilter()
      }else if(showDropdownLegs){
          dropdownSelectionLegs()
      }
    }
  },[openCloseMenu])

  useEffect(()=>{
    dispatch(getPicksByCreator(username, profileSelection, page, numberOfLegsProfile))
  },[profileSelection, numberOfLegsProfile])

  const dropdownSelectionFilter = () => {

    dispatch(toggleShowDropdownFilters(!showDropdownFilters))
    dispatch(toggleShowDropdownLegs(false))
    dispatch(toggleShowContentFilters(true))
    dispatch(toggleShowContentLegs(false))

    // change css for animation (refs required for animation transition)
    const ref = popupRef.current
    ref.classList.toggle("profile-is-menu-open")

    const refZ = zIndexRef.current
    refZ.classList.toggle("profile-z-indices")

  }

  const dropdownSelectionLegs = () => {
    
    dispatch(toggleShowDropdownLegs(!showDropdownLegs))
    dispatch(toggleShowContentFilters(false))
    dispatch(toggleShowContentLegs(true))

    // change css for animation (refs required for animation transition)
    const refPopup = popupRef.current
    refPopup.classList.toggle("profile-is-menu-open")

    const refZ = zIndexRef.current
    refZ.classList.toggle("profile-z-indices")

    // reset input box on exit
    if(numberOfLegsProfile != numLegs){
      if(numberOfLegsProfile>4){
        setNumLegs(numberOfLegsProfile)
      }else{
        setNumLegs('')
      }
    }
  }

  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, sethasMore] = useState(true);

  const observerBottom = useRef();
  const observerTop = useRef();

  useEffect(()=>{
    //console.log("(start, end): ("+visibleStartIndex+", "+visibleEndIndex+")")
  },[visibleEndIndex, visibleStartIndex])

  // scroll to next page, fetch picks, slice virtual list, append last 5 elements from current list to start of new list, scroll to same post/elements new position
  const lastElementRef = useCallback(node => {
    if (isLoading) return;

    if (observerBottom.current) observerBottom.current.disconnect();
    observerBottom.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMore) {
            setIsLoading(true);
            const {numberOfElementsToAppend, scrollTop, startIndex, endIndex} = calculateNewPageSkipTo()
            dispatch(getPicksByCreator(username, profileSelection, page + 1, numberOfLegsProfile, false, false, numberOfElementsToAppend))
                .then((posts) => {
                    if(posts){
                      setPage(prevPage => prevPage + 1);

                      setVisibleStartIndex(startIndex);
                      setVisibleEndIndex(endIndex);
                      // This ensures that the scrollable container scrolls back to top after indices are reset
                      if (containerRef.current) {
                        containerRef.current.scrollTop = scrollTop;
                      }
                    }
                    setIsLoading(false);
                }).catch(error => {
                  console.log('Failed to fetch data:', error);
                  setIsLoading(false);
                });
        }
    });
    if (node) observerBottom.current.observe(node);
  }, [isLoading, hasMore, dispatch, getPicksByCreator, profileSelection, page, numberOfLegsProfile]);

  // scroll back to previous page, fetch picks, slice virtual list, append first 5 elements from current list to end of new list, scroll to same post/elements new position
  const firstElementRef = useCallback(node => {
      if (isLoading) return;
      if (observerTop.current) observerTop.current.disconnect();
      
      observerTop.current = new IntersectionObserver(entries => {
          if (entries[0].isIntersecting && page > 1) {
              setIsLoading(true);
              const {numberOfElementsToAppend} = calculatePrevPageSkipTo()
              dispatch(getPicksByCreator(username, profileSelection, page - 1, numberOfLegsProfile, true, false, numberOfElementsToAppend))
                  .then((posts) => {
                      if(posts){
                        setPage(prevPage => prevPage - 1);
                        setVisibleStartIndex(posts.length-numberOfElementsToAppend);// @TODO: set to end of the stack
                        setVisibleEndIndex(posts.length-1);
                        // This ensures that the scrollable container scrolls back to bottom after indices are reset
                        setScrollTo(staticElementHeight+((posts.length-numberOfElementsToAppend)*(marginHeight+fixedHeight)))
                      }
                      setIsLoading(false);
                  }).catch(error => {
                    console.log('Failed to fetch data:', error);
                    setIsLoading(false);
                  });
          }
      });
      if (node) observerTop.current.observe(node);
  }, [isLoading, page, dispatch, getPicksByCreator, profileSelection, numberOfLegsProfile]);

  // if the container height changes and we queue up the scrollTo, then scroll there
  useEffect(()=>{

    // This ensures that the scrollable container scrolls back to bottom of the page after indices are reset (when going back a page)
    if(scrollTo != 0){
      if (containerRef.current) {
        containerRef.current.scrollTop = scrollTo;
        setScrollTo(0)    
      }
    }
  })

  function mapPicks(){
    // return(picks?.map((pick, index)=>{
    //   return (
    //     <div key={index} className='pick-card-ad-container'>
    //       <PickCard id={pick._id} pick={pick} photo={user?.profilePhoto}/>
    //       {/*((index + 1) % 3 === 0) && <BetweenPostsAdComponent />*/}
    //     </div>)
    // }))

      if(picks && picks?.length && picks?.length>0 &&
        picks[0].username === username
        ){
        return(
          <div style={{ height: `${totalContentHeight}px`, position: 'relative',transition: "height .5s cubic-bezier(.82,.085,.395,.895)" }}>
              {picks?.slice(visibleStartIndex, visibleEndIndex + 1).map((pick, index) => (
                  <div 
                  key={pick._id} 
                  ref={(visibleStartIndex + index) === 0 ? firstElementRef : (visibleEndIndex === picks.length - 1 ? lastElementRef : null)}
                  style={{
                      position: 'absolute',
                      top: `${itemHeights.slice(0, visibleStartIndex + index).reduce((acc, height) => acc + height, 0)}px`,
                      width: '100%',
                      transition: "top .5s cubic-bezier(.82,.085,.395,.895)"
                  }}>
                      <PickCard id={pick._id} pick={pick} photo={user?.profilePhoto} />
                  </div>
              ))}
          </div>
        )
      }else{
        return(
          <div className='empty-container'>
            <h3 className='empty-title'>Such Empty!</h3>
            <img className='empty-img' src={empty}/>

            <div className='empty-button'>
              Create Picks
              <img className='icon-button margin-left' src={plus}/>
            </div>
          </div>
        )
      }
    

  }

  useEffect(()=>{
    //console.log("(start, end): ("+visibleStartIndex+", "+visibleEndIndex+")")
  },[visibleEndIndex, visibleStartIndex])

  const showMenu = () =>{

      if (shareToggle) {
          // Add the 'exit' class to start the slide-out animation
          const element = document.querySelector('.slide-in-out');
          element.classList.add('exit');
      
          dispatch(setShareAsDM(false))
          // Wait for the animation to finish before changing state
          setTimeout(() => {
            dispatch(setShareToggle(false))
            
          }, 500); // Match the duration of your CSS animation

          dispatch(setShareLink(''))
      } else {
          dispatch(setShareToggle(true))
      }
  };

  return (
    
    <>
  
    {/* VIEW ENTIRE POST */}
    {revealEntirePost !== '' && revealEntirePost && pick?._id === revealEntirePost &&
    <EntirePost />}

    {/* VIEW FOLLOWERS */}
    {revealFollowers && user?.followers && user?.followers.length>0 &&
    <Followers followers={user?.followers}/>}

    <Header showMenu={showMenu}/>

    {shareToggle &&
      <SharePopup showMenu={showMenu} username={username}/>
    }

    {/* VIEW PROFILE */}
    <div className='profile-background' ref={containerRef} onScroll={()=>{handleScroll()}}>
      
      { isLoadingProfile ?
        <div className='profile-summary-card'>
          <SkeletonProfile />
        </div>
        :
        <div className='profile-summary-card'>
          <PhotoNameTagsFollowers />
          <Statistics/>
          {user?.bio != '' && <div className='row-centered'>
              <p className='bio'>{user?.bio}</p>
          </div>}
        </div>
      }

      <ProfileActions />
  
      <Filters dropdownSelectionFilter={dropdownSelectionFilter} dropdownSelectionLegs={dropdownSelectionLegs}/>

      {isLoadingPicks ?
        <div className='profile-feed'>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </div>
      :
        <div className='profile-feed'>
            {mapPicks()}
        </div>
      }
    </div>

    <div ref={zIndexRef} className='profile-feed-overlay'>
      <div className='profile-parent-container-secondary'>
        <div ref={popupRef} className='profile-filter-popup'>
            <PopupSelectionFilters dropdownSelectionLegs={dropdownSelectionLegs} dropdownSelectionFilter={dropdownSelectionFilter}/>
        </div>
      </div>
    </div>

    {showDropdownFilters &&
      <div onClick={()=>{dropdownSelectionFilter()}} className='profile-darken-background'/>
    }
    {showDropdownLegs &&
      <div onClick={()=>{dropdownSelectionLegs()}} className='profile-darken-background'/>
    }
    </>
    );

}

export default ProfilePage;
