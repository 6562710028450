import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSearchToggle } from '../../../State/redux/actions/StateActions';

const ToggleSwitch = ({ darkMode }) => {

    const dispatch = useDispatch()
    const { searchToggle } = useSelector((state) => state.state_reducer)

    const getBackgroundColor = () => {
        if (searchToggle) {
        return darkMode ? '#F7F7F8' : '#43A047'; // Different color for dark mode when toggled
        }
        return darkMode ? '#555' : '#ccc'; // Darker background in dark mode when not toggled
    };

    const toggleStyles = {
        container: {
            
        display: 'flex',
        justifyContent:'center',
        alignItems: 'center',
        width: '50px',
        height:'40px',
        marginLeft: '-50px',

        },
        switch: {
        width: '40px',
        height: '20px',
        backgroundColor: getBackgroundColor(),
        borderRadius: '10px',
        position: 'relative',
        cursor: 'pointer',
        transition: 'background-color 0.2s',
        },
        knob: {
        width: '18px',
        height: '18px',
        backgroundColor: searchToggle&&darkMode?'#54595F':'white',
        borderRadius: '50%',
        position: 'absolute',
        top: '1px',
        left: searchToggle ? '20px' : '1px',
        transition: 'left 0.2s',
        },

    };

    const setToggle = () =>{
        dispatch(setSearchToggle(!searchToggle))
    }

    return (
        <div style={toggleStyles.container}>
            <div style={toggleStyles.switch} onClick={() => setToggle()}>
                <div style={toggleStyles.knob}></div>
            </div>
        </div>
    );
};

export default ToggleSwitch;
