
import { useRef, useEffect, useState, React } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import { pushRoute, setPaymentPopup, toggleExpandPickCard, toggleRevealEntirePost, setTappedStatistics } from '../../../State/redux/actions/StateActions.js';
import "../../../Assets/css/pick-card-preview.css"
import heartRed from '../../../Assets/icons/heart-red.png';
import heartGrey from '../../../Assets/icons/heart-grey.png';
import arrowDownGrey from '../../../Assets/icons/down-grey-sharp.png';
import arrowUpGrey from '../../../Assets/icons/up-grey-sharp.png';
import locked from '../../../Assets/icons/locked-grey.png';
import { useNavigate } from 'react-router-dom';
import PickRow from './PickRow.js';
import { getPick, likePick } from '../../../State/redux/actions/PickActions.js';
import { convertToAmericanOdds, decimalToAmerican, getFormattedNumber, getTimeDate, stringToColor } from '../../../Utilities/UtilityFunctions.js';
import Tag from './Tag.js';
import {PickPost} from '../../../State/redux/model/PickPost.js';

// app component
function PicksCardPreview() {

  const dispatch = useDispatch()
  const expandRef = useRef();
  const navigate = useNavigate();
  const scrollRef = useRef(null);
  const [scrollDirection, setScrollDirection] = useState(1); // 1 for right, -1 for left
  const {title, description, price, lock, height, stepperBetUnits, expandPickCard, stack, tappedStatistics} = useSelector(state => state.state_reducer)
  const auth = useSelector(state => state.auth_reducer)
  const {parlayOdds, legs, tags,} = useSelector(state => state.pick_reducer)
  
  const myUnits = auth?.units ? parseFloat(auth?.units) : 10

  const pick = new PickPost()
  pick.title = title
  pick.description = description
  pick.legs = legs?.length ? legs : null
  pick.price = price
  pick.lock = lock
  pick.tags = tags
  pick.userId = auth?.id ?? null
  pick.token = auth?.token ?? null
  pick.username = auth?.username ?? "Username"

  pick.overallRecordString = `${auth?.statistics.winsOverall}-${auth?.statistics.lossesOverall}` ?? "0-0"
  pick.overallRecord =  auth?.statistics.winRateOverall ?? 0
  pick.winRate = auth?.statistics.winRateOverall ?? 0
  pick.winRateCategory = (auth?.statistics.categories?.length && auth?.statistics.categories?.find(catgeory => catgeory.numberOfLegs === legs.length).winRate) ?? 0
  pick.hotStreak = auth?.statistics.winStreakOverall ?? 0
  pick.numberOfLegs = legs.length 
  pick.totalOdds = parlayOdds
  pick.totalBetsOverall = auth?.statistics.totalBetsOverall ?? 0
  pick.profit = auth?.statistics.profitUnitsOverall ?? 0
  pick.profitCategory = (auth?.statistics.categories?.length && auth?.statistics.categories?.find(catgeory => catgeory.numberOfLegs === legs.length).profitUnits) ?? 0
  pick.units = stepperBetUnits

  const {username, overallRecordString, winRateCategory, hotStreak, likes, totalOdds, createdAt, profitCategory, winRate, wholeBetGraded, wholeBetWin, units } = pick

  const id = 'preview'
  const photo = ''

  // component did mount
  useEffect(() => {


  }, [])

  // component did update
  useEffect(() => {
  
      {/*AUTOSCROLL*/}
      const interval = setInterval(() => {
        if (scrollRef.current) {
          const isAtRightEnd = scrollRef.current.scrollWidth <= scrollRef.current.scrollLeft + scrollRef.current.offsetWidth;
          const isAtLeftEnd = scrollRef.current.scrollLeft === 0;
  
          if (isAtRightEnd) {
            setScrollDirection(-1);
          } else if (isAtLeftEnd) {
            setScrollDirection(1);
          }
  
          scrollRef.current.scrollLeft += scrollDirection;
        }
      }, 35); // Adjust the interval for smoother or faster scrolling
    return () => clearInterval(interval);
  }, [scrollDirection])

  const routeTo = (route) => {
    if(route != stack[stack.length-1]){
      dispatch(pushRoute(route));
      navigate(route)
    }
  }

  const ProfileIcon = () => {
      return (
          (photo !== '' ?
              <img src={photo} className='preview-pick-profile-img'/>
          :
              <div className='preview-pick-profile-img-default' style={{backgroundColor: `${stringToColor(pick?.username)}`}} >
                {pick?.username.charAt(0)}
              </div>
          )
      )
  };

  function picks(){
    return(
      <div className={'preview-pick-card-picks'}>
        {legs.map((leg, index)=>{
          return (
            <div className="gradient-border-top">
              <PickRow key={index} leg={leg} last={index==(legs.length-1)}/>
            </div>
          )
        })}  
      </div>
    )
  }

  function getTags(){
    return(
      tags.map((tag, index)=>{
          return (
             <Tag tag={tag} color={photo == '' ? `${stringToColor(pick?.username)}` : null} key={index} className={(index+1) == pick.tags.length?'margin-right-end':''}/>
          )
      })
    )
  }

  function getOdds(){
    let odds = totalOdds
    if(totalOdds>0){
      odds = "+"+totalOdds
    }
    //return odds
    return  auth?.oddsDisplay === 'Decimal'?("x "+totalOdds+" odds"):decimalToAmerican(totalOdds >= 0 ? "+"+totalOdds:totalOdds)

  }
  
  function openPayment(){
    dispatch(setPaymentPopup(true, id, username))
  }

  function determineGoodWinRate(rate, legs){

    switch(legs){
      case 1:
        if(rate>=50){
          return true
        }else{
          return false
        } 
      case 2:
        if(rate>=25){
          return true
        }else{
          return false
        } 
      case 3:
        if(rate>=12){
          return true
        }else{
          return false
        } 
      case 4:
        if(rate>=6){
          return true
        }else{
          return false
        } 
      case 5:
        if(rate>=3){
          return true
        }else{
          return false
        } 
      default:
        if(rate>=2){
          return true
        }else{
          return false
        } 
    }
  }
  
  return (
      <div ref={expandRef} className={'preview-pick-card-parent'} style={{ height: "auto" }}>
        <div className={'preview-pick-card-header'}>
            
            <div className={'preview-pick-card-header-left'}>
              
              <div className='preview-pick-img-container' >
                <h4 className='preview-pick-text-name'>{username}</h4>
                <ProfileIcon />
                <h4 className='preview-pick-details-text'>({overallRecordString})</h4>
              </div>
            </div>

              {wholeBetGraded ?
                <div className='preview-pick-record-parent' >
                  <div className= {wholeBetWin ?'win-card':'loss-card'} >
                      <div className='preview-text-inner-record'>
                        {wholeBetWin ? "Win" : "Loss"}
                      </div>
                  </div>
                  <div className={wholeBetWin ?'win-card':'loss-card'} >
                    <div className='preview-text-inner-record'>
                    { wholeBetWin ? 
                    "$"+(((parseFloat(totalOdds)*parseFloat(units))-parseFloat(units))*myUnits).toFixed(0)
                      :
                    "-$"+((parseFloat(units)*myUnits).toFixed(0))}
                    </div>
                  </div>  
                </div>
              : 
                (tappedStatistics ? 
                <div className='preview-pick-record-parent' >
                  <div className='preview-pick-record-container' onClick={()=>{dispatch(setTappedStatistics(tappedStatistics))}}>
                    <p className='preview-pick-record-text'>
                      Win Rate
                    </p>
                    <div className={determineGoodWinRate(winRateCategory, legs.length)?'good-record':'bad-record'}>
                      {parseFloat(winRateCategory).toFixed(0)}%
                    </div>
                    <p className='preview-pick-record-text'>
                      {legs && legs.length > 1 ? legs.length + " legs" : "Single bets"}
                    </p>
                  </div>

                  <div className='preview-pick-record-container' onClick={()=>{dispatch(setTappedStatistics(tappedStatistics))}}>
                    <p className='preview-pick-record-text'>
                      Profit
                    </p>
                    <div className={parseFloat(profitCategory) >= 0 ?'good-record  money-small':'bad-record  money-small'}>
                      {parseFloat(profitCategory) >= 0 ? "$"+(parseFloat(profitCategory)*myUnits).toFixed(0) : "-$"+((parseFloat(profitCategory)*myUnits).toFixed(0)*-1)}
                    </div>
                    <p className='preview-pick-record-text'>
                      {legs && legs.length > 1 ? legs.length + " legs" : "Single bets"}
                    </p>
                  </div>  
                </div>
                : 
                <div className='preview-pick-record-parent'>
                  <div className='preview-pick-record-container' onClick={()=>{dispatch(setTappedStatistics(tappedStatistics))}}>
                    <p className='preview-pick-record-text'>
                      Win Rate
                    </p>
                    <div className={determineGoodWinRate(winRate, legs.length)?'good-record':'bad-record'}>
                      {parseFloat(winRate).toFixed(0)}%
                    </div>
                    <p className='preview-pick-record-text'>
                      All bets
                    </p>
                  </div>
                  <div className='preview-pick-record-container' onClick={()=>{dispatch(setTappedStatistics(tappedStatistics))}}>
                    <p className='preview-pick-record-text'>
                      Hot Streak
                    </p>
                    <div className={hotStreak >= 0 ?'good-record':'bad-record'}>
                      {hotStreak}
                    </div>
                    <p className='preview-pick-record-text'>
                      {hotStreak >= 0 ? "Wins" : "Losses"}
                    </p>
                  </div>
                </div>)
              }

            <div className={'preview-pick-card-header-right'}>
              <div className='preview-pick-like-counter'>
                <img src={heartGrey} className='preview-vote-arrows' />
                <p className='preview-vote-count'>{0}</p>
                {/*<img src={down} className='preview-vote-arrows' onClick={()=>{downVote()}}/>*/}
              </div>
            </div>
        </div>

        {picks()}
        <div className='preview-expand-unlocked-picks'>
          <div className='preview-card-legs'>{legs.length > 1 ? legs.length + " leg parlay" : "single bet"}</div>
          
          <div className='preview-card-icon'>
           
          </div>
          
          <div className='preview-card-odds'>{getOdds()} &bull; {units} units</div>
        </div>

        <div className='preview-description margin-bottom-10'>
            <div className='preview-mini-text'>
              <p>Created now</p>
              <p>0 comments</p>
            </div>
            <h3>{title}</h3>
            <p>{description!=''?description:"No description"}</p>
        </div>

      </div>
    
  )

}

export default PicksCardPreview;
