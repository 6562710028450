
import { createRef, useRef, useEffect, useState, React } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import {pushRoute, toggleRevealFollowers, } from '../../../State/redux/actions/StateActions.js';
import "../../../Assets/css/followers.css"
import { useNavigate } from 'react-router-dom';
import { getFormattedNumber } from '../../../Utilities/UtilityFunctions.js';


// app component
function Followers({followers}) {

  const dispatch = useDispatch()
  const {user} = useSelector(state => state.user_reducer)
  const {height} = useSelector(state => state.state_reducer)
  const auth = useSelector(state => state.auth_reducer)
  
  const revealRef = useRef();
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false)
  const scrollRef = useRef(null);
  const [scrollDirection, setScrollDirection] = useState(1); // 1 for right, -1 for left
  const [yPosition, setYPosition] = useState(null)
  

  // component did mount
  useEffect(() => {


    setTimeout(()=>{
      const ref = revealRef.current
      ref.classList.toggle('reveal-followers')
    }, 100)

  }, [])

  
  // component did update
  useEffect(() => {
  
      // const interval = setInterval(() => {
      //   if (scrollRef.current) {
      //     const isAtRightEnd = scrollRef.current.scrollWidth <= scrollRef.current.scrollLeft + scrollRef.current.offsetWidth;
      //     const isAtLeftEnd = scrollRef.current.scrollLeft === 0;
  
      //     if (isAtRightEnd) {
      //       setScrollDirection(-1);
      //     } else if (isAtLeftEnd) {
      //       setScrollDirection(1);
      //     }
  
      //     scrollRef.current.scrollLeft += scrollDirection;
      //   }
      // }, 50); // Adjust the interval for smoother or faster scrolling
      // return () => clearInterval(interval);
  
  }, [scrollDirection])


  const routeTo = (route) => {
        
    dispatch(pushRoute(route));
    navigate(route)
    window.location.reload()
}

  function getFollowers(){

    const followerList = followers?.map((person, index)=>{
      return(
        <div 
          key={index}
          className='follower'
          onClick={()=>{routeTo(`/profile/${person}`)}}>
            {person}
        </div>
      )
    })
    return followerList
  }

  const goBack = () =>{

    setYPosition(null)
    const ref = revealRef.current
    ref.classList.toggle('reveal-followers')
    setTimeout(()=>{
      dispatch(toggleRevealFollowers(false))
    },500)
  }

  const handleStart = (event, mobile) => {
    // Start dragging logic
    if(mobile){
      if (event.touches.length > 0) {
        const touch = event.touches[0];
        const y = touch.clientY;
        setYPosition(y)
      } 
    }else{
      setYPosition(event.clientY)
    }
  };

  const handleMove = (event, mobile) => {
    // Dragging logic
    if(mobile){
      if (event.touches.length > 0) {
        const touch = event.touches[0];
        const y = touch.clientY;
        setYPosition(y)
      } 
    }else{
      setYPosition(event.clientY)
    }
  };

  const handleEnd = (event, mobile) => {
    // End dragging logic
    if(yPosition > height*0.3){
      goBack()
    }
  };

  return (
      <div ref={revealRef} className={'followers-parent'} style={yPosition && height && (yPosition > height*0.2) ? {top: yPosition, transition:'none'}:{}}>

        <div 
        onMouseDown={(e)=>{handleStart(e, false)}}
        onMouseMove={(e)=>{handleMove(e, false)}}
        onMouseUp={(e)=>{handleEnd(e, false)}}
        onTouchStart={(e)=>{handleStart(e, true)}}
        onTouchMove={(e)=>{handleMove(e, true)}}
        onTouchEnd={(e)=>{handleEnd(e, true)}}
        onClick={()=>{goBack()}} 
        className='back-button-container'>
          <div className='minimize-button'>
            {/*<img src={back} className='back-button-img'/>*/}
          </div>
        </div>

        <div className='followers-body'>
          <div className='followers-decription mt-40'>
              <p>{followers && followers.length > 0 ? (getFormattedNumber(followers.length) + (followers.length > 1 ? " Followers": " Follower")) : "No Followers"}</p>
          </div>

          {getFollowers()}
        </div> 
      </div>
    
  )

}

export default Followers;
