import React, { useState, useEffect, useRef } from 'react';
import ConversationRow from './SubComponents/ConversationRow.js';
import "../../Assets/css/conversation.css";
import { useDispatch, useSelector } from 'react-redux';
import { getConversations } from '../../State/redux/actions/ChatActions';
import CreateConversation from './SubComponents/CreateConversation';
import { createConnection, getSocket } from '../../State/api/socket.js';
import HeaderMessages from './SubComponents/HeaderMessages.js';

const ListConversations = () => {
  
  const [filteredConversations, setFilteredConversations] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const {showModal, conversations } = useSelector((state)=>state.chat_reducer)
  const dispatch = useDispatch()

  useEffect(() => {
    // Call redux to call websocket connection
    // once backend emits all convos, socket.js handles our incoming data from websockets, sets conversations in redux
    //dispatch(getConversations())
  }, []);

  useEffect(() => {
        
    createConnection(); // Ensures websocket connection is created
    dispatch(getConversations())
    return () => {
      // disconnect from websocket connection
      var socket = getSocket();
      if (socket) socket.disconnect();
    };
  }, []);

  useEffect(() => {
    const filtered = conversations.filter(convo => {
      // Logic to filter by name, participants, and last 10 messages
      // based on searchTerm
      return convo; // Replace with actual condition
    });
    setFilteredConversations(filtered);
  }, [searchTerm, conversations]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div className="conversations-page">
    
      <HeaderMessages />
      <input type="text" placeholder="Search" onChange={handleSearchChange} className='search-conversations'/>
      <div className="conversation-list">
        {filteredConversations?.length > 0 && filteredConversations.map((convo, i) => {
          return <ConversationRow key={i} conversation={convo} index={i} />
        })}
      </div>
      {showModal && <CreateConversation/>}
    </div>
  );
};

export default ListConversations;
