// AUTH
export const AUTH = 'AUTH';
export const LOGOUT = 'LOGOUT';

// PICKS
export const CREATE = 'CREATE';
export const UPDATE = 'UPDATE';
export const DELETE = 'DELETE';
export const FETCH_ALL = 'FETCH_ALL';
export const FETCH_BY_SEARCH = 'FETCH_BY_SEARCH';
export const FETCH_PICK = 'FETCH_PICK';
export const LIKE = 'LIKE';
export const LIKE_ALL = 'LIKE_ALL';
export const LIKE_FOLLOWING = 'LIKE_FOLLOWING';
export const COMMENT = 'COMMENT';
export const REPLY = 'REPLY';
export const LIKE_COMMENT = 'LIKE_COMMENT';
export const LIKE_REPLY = 'LIKE_REPLY';
export const END_LOADING = 'END_LOADING';
export const START_LOADING = 'START_LOADING';
export const FETCH_BY_CREATOR = 'FETCH_BY_CREATOR';
export const SPORTS = 'SPORTS';
export const GAMES = 'GAMES';
export const MARKETS = 'MARKETS';
export const ODDS = 'ODDS';
export const ERROR_MESSAGE = 'ERROR_MESSAGE';
export const UPDATE_LEG = 'UPDATE_LEG';
export const ADD_LEG = 'ADD_LEG';
export const REMOVE_LEG = 'REMOVE_LEG';
export const REMOVE_LEGS = 'REMOVE_LEGS';
export const UPDATE_TAGS = 'UPDATE_TAGS';
export const REMOVE_TAG = 'REMOVE_TAG';
export const REMOVE_TAGS = 'REMOVE_TAGS';

// USERS
export const FOLLOW = 'FOLLOW';
export const PROFILE = 'PROFILE';
export const ERROR_MESSAGE_CHANGE_USERNAME = 'ERROR_MESSAGE_CHANGE_USERNAME';
export const SEARCH_USERS = 'SEARCH_USERS';

// STATE
export const CHANGE_HEIGHT = 'CHANGE_HEIGHT';
export const CHANGE_WIDTH = 'CHANGE_WIDTH';
export const TOGGLE_OPEN_CLOSE_MENU = 'TOGGLE_OPEN_CLOSE_MENU';
export const TOGGLE_SHOW_PARLAYS = 'TOGGLE_SHOW_PARLAYS';
export const TOGGLE_SHOW_SINGLES = 'TOGGLE_SHOW_SINGLES';
export const TOGGLE_SHOW_DROPDOWN_FILTERS = 'TOGGLE_SHOW_DROPDOWN_FILTERS';
export const TOGGLE_SHOW_DROPDOWN_LEGS = 'TOGGLE_SHOW_DROPDOWN_LEGS';
export const SELECT_OVERALL_RECORD = 'SELECT_OVERALL_RECORD';
export const SELECT_HOT_STREAK = 'SELECT_HOT_STREAK';
export const SELECT_POPULAR = 'SELECT_POPULAR';
export const SELECT_NEW = 'SELECT_NEW';
export const UPDATE_SELECTION = 'UPDATE_SELECTION';
export const SELECT_PROFILE_NEW = 'SELECT_PROFILE_NEW';
export const SELECT_PROFILE_POPULAR = 'SELECT_PROFILE_POPULAR';
export const SELECT_PROFILE_PROFIT = 'SELECT_PROFILE_PROFIT';
export const UPDATE_PROFILE_SELECTION = 'UPDATE_PROFILE_SELECTION';
export const CHANGE_NUMBER_OF_LEGS = 'CHANGE_NUMBER_OF_LEGS';
export const TOGGLE_EXPAND_PICK_CARD = 'TOGGLE_EXPAND_PICK_CARD';
export const SELECT_PICKS_NEW = 'SELECT_PICKS_NEW';
export const SELECT_PICKS_POPULAR = 'SELECT_PICKS_POPULAR';
export const SELECT_PICKS_RECORD = 'SELECT_PICKS_RECORD';
export const SELECT_PICKS_HOT = 'SELECT_PICKS_HOT';
export const UPDATE_PICKS_SELECTION = 'UPDATE_PICKS_SELECTION';
export const UPDATE_VOTES = 'UPDATE_VOTES';
export const CHANGE_TITLE = 'CHANGE_TITLE';
export const CHANGE_DESCRIPTION = 'CHANGE_DESCRIPTION';
export const TOGGLE_LOCK = 'TOGGLE_LOCK';
export const UPDATE_PRICE = 'UPDATE_PRICE';
export const UPDATE_LEGS = 'UPDATE_LEGS';
export const UPDATE_SPORTS = 'UPDATE_SPORTS';
export const TOGGLE_REVEAL_ENTIRE_POST = 'TOGGLE_REVEAL_ENTIRE_POST';
export const UPDATE_STATE = 'UPDATE_STATE';
export const ERASE_FORM = 'ERASE_FORM';
export const INITIALIZE_STATE = 'INITIALIZE_STATE';
export const ALERT_USER = 'ALERT_USER';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const TOGGLE_SHOW_CONTENT_LEGS = 'TOGGLE_SHOW_CONTENT_LEGS';
export const TOGGLE_SHOW_CONTENT_FILTERS = 'TOGGLE_SHOW_CONTENT_FILTERS';
export const TOGGLE_REVEAL_FOLLOWERS = 'TOGGLE_REVEAL_FOLLOWERS';
export const TOGGLE_EDIT_PROFILE = 'TOGGLE_EDIT_PROFILE';
export const REROUTE_APP = 'REROUTE_APP';
export const PUSH_ROUTE = 'PUSH_ROUTE';
export const POP_ROUTE = 'POP_ROUTE';
export const RESET_ROUTES = 'RESET_ROUTES';
export const GET_PROFILE_PHOTOS_FOR_PICKS = "GET_PROFILE_PHOTOS_FOR_PICKS";
export const TOGGLE_SHOW_CONTENT_FILTERS_USERS = 'TOGGLE_SHOW_CONTENT_FILTERS_USERS';
export const TOGGLE_SHOW_DROPDOWN_FILTERS_USERS = 'TOGGLE_SHOW_DROPDOWN_FILTERS_USERS';

export const SELECT_USERS_PROFIT = 'SELECT_USERS_PROFIT';
export const SELECT_USERS_NUM_BETS = 'SELECT_USERS_NUM_BETS';
export const SELECT_USERS_NUM_STREAKS = 'SELECT_USERS_NUM_STREAKS';
export const SELECT_USERS_POPULAR = 'SELECT_USERS_POPULAR';
export const SELECT_USERS_RECORD = 'SELECT_USERS_RECORD';
export const SELECT_USERS_HOT = 'SELECT_USERS_HOT';
export const UPDATE_USERS_SELECTION = 'UPDATE_USERS_SELECTION';
export const SET_SEARCH_TOGGLE = 'SET_SEARCH_TOGGLE';
export const SET_SEARCH_TOGGLE_PICKS = 'SET_SEARCH_TOGGLE_PICKS';
export const SET_VERIFY_POPUP = 'SET_VERIFY_POPUP';
export const ERROR_MSG_PHONE = 'ERROR_MSG_PHONE';
export const ERROR_MSG_CODE = 'ERROR_MSG_CODE';
export const UPDATE_TIMESTAMP = 'UPDATE_TIMESTAMP';
export const SET_WITHDRAWAL_INFO_POPUP = 'SET_WITHDRAWAL_INFO_POPUP';
export const SET_PAYMENT_POPUP = 'SET_PAYMENT_POPUP';
export const ERROR_MSG_WITHDRAWAL_INFO = 'ERROR_MSG_WITHDRAWAL_INFO';
export const ERROR_MSG_PAYMENT = 'ERROR_MSG_PAYMENT';
export const UPDATE_ERROR_MSG_SCREENSHOT = 'UPDATE_ERROR_MSG_SCREENSHOT';
export const SEARCH_PICKS = 'SEARCH_PICKS';
export const SEARCH_PICKS_FOLLOWING = 'SEARCH_PICKS_FOLLOWING';
export const SEARCH_USERS_FOLLOWING = 'SEARCH_USERS_FOLLOWING';
export const SEARCH_USERS_ALL = 'SEARCH_USERS_ALL';

export const START_LOADING_FOLLOWING = 'START_LOADING_FOLLOWING'
export const END_LOADING_FOLLOWING = 'END_LOADING_FOLLOWING'

export const START_LOADING_ALL = 'START_LOADING_ALL'
export const END_LOADING_ALL = 'END_LOADING_ALL'

export const START_LOADING_PROFILE = 'START_LOADING_PROFILE'
export const END_LOADING_PROFILE = 'END_LOADING_PROFILE'

export const START_LOADING_PICKS = 'START_LOADING_PICKS'
export const END_LOADING_PICKS = 'END_LOADING_PICKS'

export const START_LOADING_ALL_PICKS = 'START_LOADING_ALL_PICKS';
export const END_LOADING_ALL_PICKS = 'END_LOADING_ALL_PICKS';

export const START_LOADING_FOLLOWING_PICKS = 'START_LOADING_FOLLOWING_PICKS'
export const END_LOADING_FOLLOWING_PICKS = 'END_LOADING_FOLLOWING_PICKS'

export const START_LOADING_SCREENSHOT = 'START_LOADING_SCREENSHOT';
export const END_LOADING_SCREENSHOT = 'END_LOADING_SCREENSHOT';

export const CHANGE_NUMBER_OF_LEGS_PROFILE = 'CHANGE_NUMBER_OF_LEGS_PROFILE';
export const SET_TAPPED_STATS = 'SET_TAPPED_STATS';
export const SET_STEPPER_UNITS = 'SET_STEPPER_UNITS';

export const SET_PAGE_NUMBER = 'SET_PAGE_NUMBER'

export const SET_PARLAY_ODDS = 'SET_PARLAY_ODDS'

export const START_LOADING_PHONE = 'START_LOADING_PHONE'
export const END_LOADING_PHONE = 'END_LOADING_PHONE'