import React, { useEffect } from 'react';
import "../../../Assets/css/users.css";
import { useNavigate } from 'react-router-dom';
import { getFormattedNumber, getTimeDate, stringToColor } from '../../../Utilities/UtilityFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { pushRoute } from '../../../State/redux/actions/StateActions';
import Statistics from './Statistics';

const UsersRow = ({ user, index }) => {
  
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {profilePhotos,} = useSelector(state => state.chat_reducer);

    const reRoute = (name) =>{
        const path = `/profile/${name}`
        dispatch(pushRoute(path));
        navigate(path)
    }

    const getProfilePhotoByUsername = (username) => {
        const userObj = profilePhotos?.find(obj => obj.hasOwnProperty(username));
        return userObj ? userObj[username] : '';
    }

    const ProfileIcon = () => {
      
        return (
        (getProfilePhotoByUsername(user.username) !== '' ?
            <img src={getProfilePhotoByUsername(user.username)} className='users-img' onClick={()=>{reRoute(user.username)}}/>
        :
            <div className='users-img-default' style={{backgroundColor: `${stringToColor(user.username)}`}} onClick={()=>{reRoute(user.username)}}>
               {user.username.charAt(0)}
            </div>
        ))
    };

    return (
    <div className={index==0 ? "user-row": "user-row border"} onClick={() => reRoute(user.username)}>
        <div className='column-img'>
            <ProfileIcon />
        </div>
        <div className="column-user-details">
            
            <div className="user-sub-info">
                <div className="user-title">
                    {user?.username}
                </div>
                <div className="user-detail">
                    {user.followers && user.followers.length && user.followers.length > 1
                    ?
                        getFormattedNumber(user?.followers.length) + " followers"
                    :
                        (user.followers && user.followers.length && user?.followers.length === 1 
                            ? 
                        "1 follower" 
                            : 
                        "0 followers")
                    }

                </div>
            </div>
            
            <div className="user-sub-info">
                <Statistics user={user}/>
            </div>
        </div>
    </div>
    );
};

export default UsersRow;
