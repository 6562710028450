
import { React, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector,} from 'react-redux';
import { pushRoute, toggleRevealFollowers, } from '../../../State/redux/actions/StateActions.js';
import { useNavigate } from 'react-router-dom';
import "../../../Assets/css/profilepage.css"
import "../../../Assets/css/profile-bet-filters.css"
import profile from '../../../Assets/icons/profile-grey.png';
import { getFormattedNumber, stringToColor } from '../../../Utilities/UtilityFunctions.js';
import Tag from './Tag.js';

// Profile component
function PhotoNameTagsFollowers() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, } = useSelector((state) => state.user_reducer);
  const scrollRef = useRef(null);
  const [scrollDirection, setScrollDirection] = useState(1); // 1 for right, -1 for left

  function viewFollowers(){
    if(user?.followers && user?.followers.length>0){
      dispatch(toggleRevealFollowers(true))
    }
  }

  function getTags(){
    const {tags} = user
    return tags.map((tag, index)=>{
      return (
         <Tag tag={tag} color={user?.profilePhoto == '' ? `${stringToColor(user?.username)}` : null} key={index} className={(index+1) == tags.length?'margin-right-end':''}/>
      )
    })
  }

  const routeTo = (route) => {
        
    dispatch(pushRoute(route));
    navigate(route)
  }

  const ProfileIcon = () => {
      
    return (
    user?.profilePhoto ?
        <img src={user?.profilePhoto} className='profile-page-img'/>
    :
        <div className='profile-page-img-default' style={{backgroundColor: `${stringToColor(user?.username)}`}} >
           {user?.username.charAt(0)}
        </div>
    )
  };

    // component did update
    useEffect(() => {
  
      {/*AUTOSCROLL*/}
      const interval = setInterval(() => {
        if (scrollRef.current) {
          const isAtRightEnd = scrollRef.current.scrollWidth <= scrollRef.current.scrollLeft + scrollRef.current.offsetWidth;
          const isAtLeftEnd = scrollRef.current.scrollLeft === 0;
  
          if (isAtRightEnd) {
            setScrollDirection(-1);
          } else if (isAtLeftEnd) {
            setScrollDirection(1);
          }
          scrollRef.current.scrollLeft += scrollDirection;
        }
      }, 35); // Adjust the interval for smoother or faster scrolling
    return () => clearInterval(interval);
  }, [scrollDirection])

  return (
      <div className='profile-row-image-tags'>
       { user && <ProfileIcon />}
        
        <div className='main-column-profile'>
          <h3 className={'name'}>{user?.username}</h3>
          <div className='profile-row-tags margin-top-3' ref={scrollRef}>
            {user && getTags()}
          </div>
          <div 
            onClick={()=>{viewFollowers()}}
            className='row margin-top-3'>
              {user?.followers && user?.followers.length > 0 ? (getFormattedNumber(user.followers.length) + (user.followers.length > 1 ? " Followers": " Follower")) : "No Followers"}
          </div>
        </div>
      </div>
  );

}

export default PhotoNameTagsFollowers;
