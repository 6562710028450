import { START_LOADING,END_LOADING, PROFILE, FOLLOW, ERROR_MESSAGE_CHANGE_USERNAME, SEARCH_USERS, ERROR_MSG_PHONE, UPDATE_TIMESTAMP, ERROR_MSG_CODE, ERROR_MSG_WITHDRAWAL_INFO, ERROR_MSG_PAYMENT, SEARCH_USERS_FOLLOWING, SEARCH_USERS_ALL, START_LOADING_FOLLOWING, END_LOADING_FOLLOWING, START_LOADING_ALL, END_LOADING_ALL, START_LOADING_PROFILE, END_LOADING_PROFILE, START_LOADING_PHONE, END_LOADING_PHONE } from '../actions/ActionTypes.js';
import { UserState } from '../model/UserState.js';

export const user_reducer = (state = new UserState(), action) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true };
    case END_LOADING:
      return { ...state, isLoading: false };
    case START_LOADING_PHONE:
      return { ...state, isLoadingPhone: true };
    case END_LOADING_PHONE:
      return { ...state, isLoadingPhone: false };
    case START_LOADING_FOLLOWING:
      return { ...state, isLoadingFollowing: true };
    case END_LOADING_FOLLOWING:
      return { ...state, isLoadingFollowing: false };
    case START_LOADING_ALL:
      return { ...state, isLoadingAll: true };
    case END_LOADING_ALL:
      return { ...state, isLoadingAll: false };
    case START_LOADING_PROFILE:
      return { ...state, isLoadingProfile: true };
    case END_LOADING_PROFILE:
      return { ...state, isLoadingProfile: false };
    case PROFILE:
      return {
        ...state,
        user: action.payload,
      };
    case FOLLOW:
      return {
        ...state,
        user: action.payload,
      };
    case SEARCH_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case SEARCH_USERS_ALL:
      return {
        ...state,
        usersAll: action.payload,
      };
    case SEARCH_USERS_FOLLOWING:
      return {
        ...state,
        usersFollowing: action.payload,
      };
    case ERROR_MESSAGE_CHANGE_USERNAME:
      return {
        ...state,
        errorMsgUsername: action.payload,
      };
    case ERROR_MSG_PHONE:
      return {
        ...state,
        errorMsgPhone: action.payload,
      };
    case ERROR_MSG_CODE:
      return {
        ...state,
        errorMsgCode: action.payload,
      };
    case UPDATE_TIMESTAMP:
      return {
        ...state,
        timestampSMS: action.payload,
      };
    case ERROR_MSG_PAYMENT:
      return {
        ...state,
        errorMsgPayment: action.payload,
      };
    case ERROR_MSG_WITHDRAWAL_INFO:
      return {
        ...state,
        errorMsgWithdrawalInfo: action.payload,
      };
    default:
      return state;
  }
};

