
import { createRef, useEffect, useState, React } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import { popRoute, pushRoute, setVerifyPopup, toggleEditProfile,  } from '../../State/redux/actions/StateActions.js';
import { useNavigate, useParams } from 'react-router-dom';
import "../../Assets/css/accountpage.css"
import { editProfile, getProfile, saveProfileEdits, updatePhone } from '../../State/redux/actions/UserActions.js';
import Header from './SubComponents/Header.js';
import eyeGrey from '../../Assets/icons/eye-grey.png';
import VerifiedCheckMark from "./SubComponents/PhoneUpdates/Verified.js"
import {ApplyCode} from "./SubComponents/PhoneUpdates/ApplyCode.js"
import {NewNumber} from "./SubComponents/PhoneUpdates/NewNumber.js"

import cancel from '../../Assets/icons/cancel-grey.png'

// Profile component
function PhoneUpdate() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector(state => state.auth_reducer)
  const { user, stack } = useSelector((state) => state.user_reducer);
  const [showAnimation, setShowAnimation] = useState(false)
  const [changeNumberClicked, setChangeNumberClicked] = useState(false)
  const {errorMsgPhone} = useSelector(state => state.user_reducer);


  // component did mount
  useEffect(()=>{
    if(!user){
      getUser()
    }

  },[])

  const getUser = async () => {

    const username = JSON.parse(localStorage.getItem('profile'))?.username;
    if(username){
      const u = await dispatch(getProfile(username))
      if(u){
        u.oldUsername = u.username
        dispatch(editProfile(u))
      }
    }else{
      routeTo('/login')
    }
  }

  const routeTo = (route) => {
        
    dispatch(pushRoute(route));
    navigate(route)
  }

  const goBack = () =>{

    const route = stack && stack.length > 1 ? stack[stack.length-2] : `/`
    navigate(route)
    dispatch(popRoute())
}

  function cancelForm(){

     goBack()
  }

  const showAnimationCallback = () => {
    // if verified, get rid of popup
    setShowAnimation(true)
    setTimeout(()=>{
        goBack()
    },3000)
    
  }

  return (
    <>
    <Header label="Update Phone" />
    <div className='account-background'>
      
      <div className="verify-identity mt-65">
          <div className='verify-row'>
              <h1>Verify Phone Number</h1>
              {/*<div className='verify-cancel-btn-container'>
                  <img src={cancel} className='verify-cancel-btn' onClick={()=>{cancelForm()}}/>
              </div>*/}
          </div>
          { showAnimation ?
              <VerifiedCheckMark />
          :
              (auth?.phone != 0 && auth?.phone != null && errorMsgPhone === '' && !changeNumberClicked ?
                  <ApplyCode setChangeNumberClicked={setChangeNumberClicked} showAnimation={showAnimationCallback}/>
              :
                  <NewNumber setChangeNumberClicked={setChangeNumberClicked}/>)
              }
      </div>

      <div className='cancel-edit-account-button mb-20' onClick={()=>{cancelForm()}}>
        Cancel
      </div>

    </div>
    </>
    );

}

export default PhoneUpdate;
