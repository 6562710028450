
import { createRef, useEffect, useState, React, useRef } from 'react';
import { useDispatch, useSelector, connect, ReactReduxContext, } from 'react-redux';
import { createState, updateState, } from '../../../State/redux/actions/StateActions.js';
import "../../../Assets/css/createpickspage.css"
import undo from '../../../Assets/icons/undo-grey.png'
import exit from '../../../Assets/icons/cancel-r.png'
import poofImg from '../../../Assets/icons/cloud.png'
import { getMarketOddsJam, getOdds, errorMessage, updateLeg, getMarkets, getSportsOddsJam, removeTags, updateTags, getGamesOddsJam, getMarketsOddsJam, getOddsOddsJam,getLogosOddsJam } from '../../../State/redux/actions/PickActions.js';
import { convertToAmericanOdds, decimalToAmerican, getDate, getFormattedDate } from '../../../Utilities/UtilityFunctions.js';


//const desiredSports = ['Football','Baseball','Basketball','Hockey','Soccer','Volleyball','Formula-1','Handball','Golf','Tennis','Boxing','MMA','Cricket','Table Tennis','Futsal','Badminton','Horse Racing','Greyhounds','Esports','Politics' ]

// app component
function NewPick({id, deleteForm, counter, removeFlag}) {
  
  const dispatch = useDispatch() 
  const { legs } = useSelector(state => state.pick_reducer)  
  const leg = useSelector(state => state.pick_reducer.legs.find(leg=>leg.id==id))
  const [poof, setPoof] = useState(false);
  const [showPoof, setShowPoof] = useState(true);
  const [showContent, setShowContent] = useState(false);

  // component did mount
  useEffect(()=>{
    dispatch(getSportsOddsJam(leg))
    setTimeout(()=>{
      setShowContent(true)
    },500)
  },[])

  const selectSport = (e) => {

    const l = leg

    l.league = e.target.value

    dispatch(getGamesOddsJam(l.league, l))
    l.marketOptions = []
    
    // reset values
    removeUniqueTags()
    l.date = ''
    l.sportsbook = ''
    l.game = ''
    l.market = ''
    l.pick = ''
    l.gameOptions = []
    l.oddsOptions = []
    l.tags = []
    l.complete=false
    l.tournament = ''
    l.future_id = ''
    l.logos = []
    l.game_id = ''
    dispatch(errorMessage('', leg))
    

    dispatch(updateLeg(l))
  }

  const selectGame = (e) => {

    const l = leg
    const game = leg.gameOptions.find(game=>game.name === e.target.value)
    l.date = getFormattedDate(game.start_date)
    l.dateComparable = game.start_date
    l.gameObject = game
    l.game = game
    dispatch(getMarketsOddsJam(game, l))
    // reset values
    removeUniqueTags()
    l.game = e.target.value
    l.market = ''
    l.pick = ''
    l.oddsOptions = []
    l.tags = []
    l.complete=false
    l.tournament = ''
    l.future_id = ''
    l.logos = []
    l.game_id = l.gameObject.id
    dispatch(errorMessage('', leg))
    
    dispatch(updateLeg(l))
  }

  const selectMarket = (e) => {

    const l = leg
    l.market = e.target.value
    const game_id = leg.gameOptions.find(game=> game.name === leg.game).id
    const market_name = leg.marketOptions.find(market=> market.label === e.target.value).id
    dispatch(getOddsOddsJam(game_id, market_name,l))
    
    // reset values
    removeUniqueTags()
    l.pick = ''
    l.oddsOptions = []
    l.oddsOptions = []
    l.tags = []
    l.complete=false
    l.logos = []

    dispatch(updateLeg(l))
  }

  const selectOdds = (e) => {

    const l = leg
    
    // check if this pick is compatible with other legs
    var compatible = ensureCompatibleMarkets() 
    if(compatible == true){
      l.pick = e.target.value
      l.complete=true

      // get odds price and odds object from dropdown label
      let odds = e.target.value.split('(')[1]
      if(odds){
        odds = odds.split(')')[0]
      }
      l.odds = odds
      l.oddsObject = l.oddsOptions.find(o=>parseInt(o.price) == parseInt(odds) && e.target.value.split('(')[0].includes(o.name)) 
      
      // if future bet
      // add team name and add tournament name 
      if(!l.gameObject.away_team){
        l.teams = []
        l.teams.push(l.oddsObject.name)
        l.tournament = l.gameObject.tournament
        l.future_id = l.gameObject.future_id
      }

      // update tags
      let tags = []
      let league = l.league
      let game = l.gameObject
      tags = [...tags, league]
      
      if(game.away_team){
        tags = [...tags, game.away_team]
        tags = [...tags, game.home_team]
        //tags = [...tags, l.oddsObject.market_name]
      }else{
        tags = [...tags, game.name]
      }
      l.tags = tags

      // data for better grading/verifying win/loss
      l.sport = l.gameObject.sport
      l.league = l.gameObject.league
      l.game_id = l.gameObject.id
      l.market_name = l.oddsObject.market_name
      l.bet_name = l.oddsObject.name
      l.player_id = l.oddsObject.player_id

      l.flag == true && removeFlag()
      l.flag = false
      dispatch(updateTags(l.tags))
      dispatch(updateLeg(l))
      dispatch(getLogosOddsJam(l))
    }else{
   
      // reset values
      l.marketOptions = [] 
      l.date = ''
      l.sportsbook = ''
      l.game = ''
      l.market = ''
      l.pick = ''
      l.oddsOptions = []
      l.tags = []
      l.complete=false
      l.tournament = ''
      l.future_id = ''
      l.logos = []
      dispatch(updateLeg(l))
         
    }
  }

  function deleteThisForm(id) {
    if(legs.length > 1){
      removeUniqueTags()
      setPoof(true)
      setTimeout(()=>{
        setShowPoof(false)
      },250)
      setTimeout(()=>{
        deleteForm(id)
      },900)
    }
  }

  const removeUniqueTags = () =>{

    let tagsToRemove = leg.tags
    if(tagsToRemove){
      let tagsToKeep = []
      legs.forEach((otherLeg)=>{
        // check that these tags are not being used for other legs, before deleting them
        if(otherLeg.tags){
          tagsToKeep = [...tagsToKeep, ...otherLeg.tags.filter((t)=>tagsToRemove.includes(t) && otherLeg.id !== id)]
        }
      })
      tagsToRemove = tagsToRemove?.filter((t)=>!tagsToKeep?.includes(t))
    
      dispatch(removeTags(tagsToRemove))
    }
  }

  const ensureCompatibleMarkets = () =>{

      var compatible = true
      legs.forEach((otherLeg)=>{
        // get other legs
        if(otherLeg.id !== leg.id){
          // compare game id's
          if(otherLeg.game_id === leg.game_id){
            dispatch(errorMessage("Can't do same-game parlays just yet", leg))
            compatible = false
          }
        }
      })
    
      return compatible
  }

  return (
    poof?
      <div className='poof-container'>
        {<img src={poofImg} className={showPoof?'poof-img':'poof-img hidden'} />}
      </div>
      :
      (showContent ? 
      <div className={leg.flag?'pick-form-new flagged':'pick-form-new'}>
        <div className='pick-form-header'>
          
          <div className='header-wrapper'>
            <h4>Create Pick #{counter}</h4>
          </div>
          
          <div className='buttons-corner'>
            {counter > 1 &&
            <div className='img-cancel-container'>
              <img src={exit} className='img-delete' onClick={()=>{deleteThisForm(id)}}/>
            </div>}  
          </div>
          
        </div>
        <div className='pick-form-body'>
          <div className='dropdown-container'>
            <select 
              placeholder={'Sport'} name='sport' className='dropdown-select' value={leg.league} 
              onChange={selectSport}>
                <option value="" disabled>
                  Sport
                </option>
                {leg?.sportsOptions?.map((sport,key) => {
                  return(<option className='dropdown-option' key={key}>{sport}</option>)
                })}
            </select>
          </div>
          
          <div className='dropdown-container'>
            <select 
              placeholder={'Game'} name='game' className='dropdown-select' value={leg.game} 
              onChange={selectGame} 
              disabled={leg.league  == '' || leg.gameOptions.length == 0}>
                <option value="" disabled>
                  Games
                </option>
                {leg?.gameOptions?.map((game,key) => {
                  return(<option className='dropdown-option' key={key}>{game.name}</option>)
                })}
            </select>
          </div>

          <div className='dropdown-container'>
            <select 
              placeholder={'Market/Line'} name='market' className='dropdown-select' value={leg.market} 
              onChange={selectMarket} 
              disabled={leg?.marketOptions?.length == 0 && leg?.oddsOptions?.length > 0 ? (true) : (leg.league == '' || leg.game == '' || leg.marketOptions.length == 0)}>
                <option value="" disabled>
                  Market / Line
                </option>
                {leg?.marketOptions?.map((market,key) => {
                  return(<option className='dropdown-option' key={key}>{market.label}</option>)
                })}
            </select>
          </div>

          <div className='dropdown-container'>
            <select 
              placeholder={'Pick'} name='pick' className='dropdown-select' value={leg.pick} 
              onChange={selectOdds} 
              disabled={leg.marketOptions?.length == 0 && leg?.oddsOptions?.length > 0?(leg.league == '' || leg.game == ''):(leg.league == '' || leg.game == '' || leg.market == '' || leg.oddsOptions.length == 0)}>
                <option value="" disabled>
                Pick (with odds)
                </option>
                {leg?.oddsOptions?.map((line,key) => {
                    return(<option className='dropdown-option' key={key}>{line.name}&nbsp;{"("}{convertToAmericanOdds(line.price)}{")"}</option>)
                  })}
            </select>
          </div>
          
          {leg.errorMessage == '' &&
          <div className='bottom-bar-info'>
            {leg.date != '' && <h3 className='date'>{getFormattedDate(leg.date)}</h3>}
            {leg.complete && <h3 className='odds'>{leg.odds}</h3>}
          </div>}
          {leg.errorMessage != '' && <h3 className='red-error'>{leg.errorMessage}</h3>}
        </div>
      </div>
      :
      <div className='pick-form-new'>
        <div className='pick-form-header'>
            <div className='header-wrapper'>
              <h4>Create Pick #{counter}</h4>
            </div>
        </div>
      </div>)
  )

}

export default NewPick;


  /*
  RESET FORM BUTTON

  function resetForm(){
    const l = initialState
    dispatch(updateLeg(l))
    setForm(l)
    dispatch(errorMessage('', leg))

  }

  {formChanged? 
  <>
    <div className='img-undo-container'>
      {leg.league!=''&&<img src={undo} className='img-undo' onClick={()=>{resetForm()}}/>}
    </div>
    <div className='img-cancel-container'>
      <img src={exit} className='img-delete' onClick={()=>{deleteThisForm(id)}}/>
    </div>
  </>
  :
  <div className='img-undo-container-first'>
    {leg.league!=''&&<img src={undo} className='img-undo' onClick={()=>{resetForm(1)}}/>}
  </div>}
  */


  {/*
  SELECT SPORTSBOOK / DATE

  <div className='select-date'>
    <input type='date' className='input-select-date' label="Day of Game" name="date" value={form?.date} onChange={handleChange} disabled={form?.sport == ''}/>
  </div>
  
  <div className='dropdown-container'>
    <select placeholder={'Sportsbook'} name='sportsbook' className='dropdown-select' value={form?.sportsbook} onChange={handleChange} disabled={form?.sport  == '' || form?.date  == ''}>
      <option value="" disabled >Sportsbook</option>
      {sportsbooks.map((e,key) => {
        return(<option className='dropdown-option' key={key}>{e}</option>)
      })}
    </select>
  </div>*/}