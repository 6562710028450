
import { React, useState } from 'react';
import { useDispatch, useSelector,  } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import "../../../Assets/css/editprofilepage.css"
import "../../../Assets/css/profile-bet-filters.css"
import profile from '../../../Assets/icons/profile-grey.png';
import crayon from '../../../Assets/icons/crayon.png';
import { checkUsername, editProfile } from '../../../State/redux/actions/UserActions.js';
import { hasItBeen90Days,addDaysToDate } from '../../../Utilities/UtilityFunctions';
import HashTagsProfile from './HashTagsProfile';

// Profile component
function MainEdits() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector(state => state.auth_reducer)
  const { picks, pick } = useSelector((state) => state.pick_reducer);
  const { user, isLoading, errorMsgUsername } = useSelector((state) => state.user_reducer);
  const timeLeft = addDaysToDate(user?.lastNameChange, 90)
  const [size,setSize] = useState(0);
  const [errorMessage,setErrorMessage] = useState("");

  function changeBio(e){
      const u = user
      u.bio = e.target.value
      dispatch(editProfile(u))
  }

  function changeUsername(e){
    const u = user
    u.username = e.target.value
    dispatch(editProfile(u))
    dispatch(checkUsername(e.target.value))
  }

   // Function to handle file upload
   const handleFileUpload = (event) => {
    try{
      
      // cancelled
      if(!event.target.files.length){
        setErrorMessage('')
      }else{
        const file = event.target.files[0];
        var maxSize = 20 * 1024 * 1024; // max 20 MB
        const validTypes = ['image/png', 'image/jpeg', 'image/jpg','image/bmp', 'image/ico', 'image/webp', 'image/vnd.microsoft.icon']
        console.log(file.type)
        if (file && file.size <= maxSize) {
          if (validTypes.includes(file.type)) {
            // READ the file
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (e) => {
                // COMPRESS the file (over 99% reduction in size for files that are 20 MB, 95% reduction for files that are 2 MB)
                const img = new Image();
                img.src = e.target.result;
                img.onload = () => {
                  const canvas = document.createElement('canvas');
                  const ctx = canvas.getContext('2d');
                  // make them max 200 pixels
                  canvas.width = 200;
                  canvas.height = 200;
                  ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                  canvas.toBlob((blob) => {
                    console.log('Final size of the compressed image in bytes:', blob.size);
                    setSize(blob.size)
                    // READ the file back again
                    const read = new FileReader();
                    read.onloadend = () => {
                      const base64String = read.result;
                      // Now, you can send this base64String to your backend
                      const u = user
                      u.profilePhoto = base64String
                      dispatch(editProfile(u))
                      setErrorMessage("")
                    };
                    read.readAsDataURL(blob);
                  }, file.type, 1.0); // Adjust the '0.7' quality parameter as needed
                };
            };
          }else{
            setErrorMessage('The image should be either format: .jpg, .png, .jpeg, .bmp, .ico, or .webp')
          }
        }else if(file.size > maxSize){
          setErrorMessage('The image should be smaller than 20 MB')
        }
      }
    }catch(e){
      console.log(e)
      setErrorMessage('Theres something wrong with that image. Try refreshing the page or try another image.')
    }

};

  return (
      
      <div className='edit-profile-summary-card'>
        <h3 className='edit-row-image'>Edit Profile</h3>
        <div className="profile-container">
            {/* Display the uploaded image if available, otherwise show a placeholder */}
            {user?.profilePhoto ? (
              <img src={user?.profilePhoto} alt="Profile" className="profile-pic" onClick={()=>document.getElementById('file-upload').click()}/>
            ) : (
              <img src={profile} alt="Profile" className="profile-pic" onClick={()=>document.getElementById('file-upload').click()}/>
            )}
            {/*<button className="crayon-icon-button" onClick={()=>document.getElementById('file-upload').click()}>
                <img src={crayon} alt="Edit" />
            </button>*/}
            <input type="file" id="file-upload" accept=".png,.jpg,.jpeg,.bmp,.webp,.ico, .vnd.microsoft.icon" style={{display: 'none'}} onChange={(e)=>{handleFileUpload(e)}}/>
        </div>
        <p className='error-message-img'>{errorMessage}</p>

        <div className='edit-row-name'>
          <div className='main-column-edit-profile'>
            <h4 className='edit-profile-label'>Username</h4>
            <input 
              rows={4} 
              className={
                (errorMsgUsername == "" || user?.username == auth?.username || user?.username == "") 
                ? 'edit-username-input' : 'edit-username-input-error'} 
              name="username" label="Username" placeholder="Username" type="text"
              onChange={(e)=>{changeUsername(e)}}  
              value={user?.username ?? ""}
              disabled={!hasItBeen90Days(user?.lastNameChange)}/>
            <p 
            className={
              !hasItBeen90Days(user?.lastNameChange) 
              || 
              (errorMsgUsername != "" 
                && user?.username != auth?.username 
                && user?.username != "") 
              ? "red-print" : "regular-print"} >

                {!hasItBeen90Days(user?.lastNameChange) ? 
                ("You can change your name every 90 days. Please wait until: "+timeLeft) 
                : (errorMsgUsername == "" || user?.username == auth?.username || user?.username == "" ? 
                  ( user?.username == auth?.username ?
                    "You can only change your name every 90 days."
                    : "Username Available")
                  :
                  errorMsgUsername)}
            </p>
    
            {/*<HashTagsProfile/>*/}

            <h4 className='edit-profile-label'>Bio</h4>
            <textarea rows={4} className={'edit-profile-input'} name="bio" label="Bio" placeholder="Bio" onChange={(e)=>{changeBio(e)}} type="text" value={user?.bio}/>
          </div>
        </div>
        
      </div>
    );
}

export default MainEdits;
