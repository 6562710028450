
import { createRef, useEffect, useState, React } from 'react';
import { useDispatch, useSelector, connect, ReactReduxContext, } from 'react-redux';
import { updateUsersSelection, selectUsersHot, selectUsersProfit, selectUsersRecord,selectUsersPopular } from '../../../State/redux/actions/StateActions.js';
import "../../../Assets/css/users-bet-filters.css"
import arrowDown from '../../../Assets/icons/down-green.png';
import percentG from '../../../Assets/icons/percent-g.png';
import percentW from '../../../Assets/icons/percent-w.png';
import profitG from '../../../Assets/icons/profit-g.png';
import profitW from '../../../Assets/icons/profit-w.png';
import flameG from '../../../Assets/icons/flame-green.png';
import flameW from '../../../Assets/icons/flame-white.png';
import eyeG from '../../../Assets/icons/eye-g.png';
import eyeW from '../../../Assets/icons/eye-w.png';
import checkmark from '../../../Assets/icons/checkmark.png'


// app component
function PopupSelectionFiltersUsers({dropdownSelectionFilter}) {

  const dispatch = useDispatch()
  const {showContentFiltersUsers, usersProfitSelected, usersPopularSelected, usersRecordSelected, usersHotStreakSelected, usersSelection } = useSelector(state => state.state_reducer)

  // component did mount
  useEffect(() => {

  }, [])

  // component did update
  useEffect(() => {

  })

  function close(){
    dropdownSelectionFilter()
  }

  function selectedFilter(value){

    switch(value){
      case "Best Record":
        dispatch(selectUsersRecord(true))
        dispatch(selectUsersHot(false))
        dispatch(selectUsersPopular(false))
        dispatch(selectUsersProfit(false))
        dispatch(updateUsersSelection("Best Record"))
        break;
      case "Hot Streak":
        dispatch(selectUsersRecord(false))
        dispatch(selectUsersHot(true))
        dispatch(selectUsersPopular(false))
        dispatch(selectUsersProfit(false))
        dispatch(updateUsersSelection("Hot Streak"))
        break;
      case "Popularity":
        dispatch(selectUsersRecord(false))
        dispatch(selectUsersHot(false))
        dispatch(selectUsersPopular(true))
        dispatch(selectUsersProfit(false))
        dispatch(updateUsersSelection("Popularity"))
        break;
      case "Profit":
        dispatch(selectUsersRecord(false))
        dispatch(selectUsersHot(false))
        dispatch(selectUsersPopular(false))
        dispatch(selectUsersProfit(true))
        dispatch(updateUsersSelection("Profit"))
        break;
    }

    dropdownSelectionFilter()
  }

  if(showContentFiltersUsers){
      return(
      <div className='users-popup-container'>
        <p className='users-popup-title'>Filter by leaders in each category</p>
            
        {usersProfitSelected?
        <div onClick={()=>selectedFilter("Profit")} className='users-option'>
          <img src={profitW}className='users-icon-dropdown-selection'/>
          <h3 className='users-option-text users-white-selected'>Profit</h3>
        </div>
          :
        <div onClick={()=>selectedFilter("Profit")} className='users-option'>
          <img src={profitG}className='users-icon-dropdown-selection'/>
          <h3 className='users-option-text users-green-unselected'>Profit</h3>
        </div>}
        
        {!usersRecordSelected?
        <div onClick={()=>selectedFilter("Best Record")} className='users-option'>
          <img src={percentG} className='users-icon-dropdown-selection'/>
          <h3 className='users-option-text users-green-unselected'>Best Record</h3>
        </div>
        :
        <div onClick={()=>selectedFilter("Best Record")} className='users-option'>
          <img src={percentW} className='users-icon-dropdown-selection'/>
          <h3 className='users-option-text users-white-selected'>Best Record</h3>
        </div>
        }
        
        {usersHotStreakSelected?
        <div onClick={()=>selectedFilter("Hot Streak")} className='users-option'>
          <img src={flameW} className='users-icon-dropdown-selection'/>
          <h3 className='users-option-text users-white-selected'>Hot Streak</h3>
        </div>
          :
        <div onClick={()=>selectedFilter("Hot Streak")} className='users-option'>
          <img src={flameG} className='users-icon-dropdown-selection'/>
          <h3 className='users-option-text users-green-unselected'>Hot Streak</h3>
        </div>}

        {usersPopularSelected?
        <div onClick={()=>selectedFilter("Popularity")} className='users-option'>
          <img src={eyeW}className='users-icon-dropdown-selection'/>
          <h3 className='users-option-text users-white-selected'>Popularity</h3>
        </div>
          :
        <div onClick={()=>selectedFilter("Popularity")} className='users-option'>
          <img src={eyeG}className='users-icon-dropdown-selection'/>
          <h3 className='users-option-text users-green-unselected'>Popularity</h3>
        </div>}

        <div className='users-close-button-container'>
          <div onClick={()=>{close()}} className='users-close-button'>
            Close
          </div>
        </div>
      </div>
      )
    }

}

export default PopupSelectionFiltersUsers;
