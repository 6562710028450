
import { createRef, useEffect, useState, React } from 'react';
import { useDispatch, useSelector, connect, ReactReduxContext, } from 'react-redux';
import { createState, resetRoutes, updateState, } from '../../State/redux/actions/StateActions.js';
import { useNavigate } from 'react-router-dom';
import { GoogleLogin, GoogleLogout } from '@react-oauth/google'
import { signin, signup } from '../../State/redux/actions/AuthActions.js';
import { loginWithGoogle } from '../../State/redux/actions/AuthActions.js';
import "../../Assets/css/loginpage.css"
import eyeGrey from '../../Assets/icons/eye-grey.png';
import { TailSpin } from 'react-loader-spinner';

// login component
function LoginPage() {

  const initialState = { login: '',  password: ''};
  const [form, setForm] = useState(initialState);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authState = useSelector(state => state.auth_reducer)
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('')
  
  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  }

  useEffect(()=>{
    setErrorMessage(authState?.errorMessage)
    setTimeout(()=>{
      setIsLoading(false)
    }, 3000)
    
  },[authState])

  const handleSubmit = (e) => {
    if(form.login == '' || form.password == ''){
        setErrorMessage('Username and Password cannot be empty')
    }else{
      setIsLoading(true)
      e.preventDefault();
      var browser = window.navigator.userAgent
      dispatch(signin(form, window.innerHeight, window.innerWidth, browser, navigate));
    }
   
  };

  const googleSuccess = async (res) => {
   
    const token = res?.credential;

    try {
      // save to state
      var browser = window.navigator.userAgent
      dispatch(loginWithGoogle(token, window.innerHeight, window.innerWidth, browser));
      // go to home page
      dispatch(resetRoutes())
      navigate('/');

    } catch (error) {
      console.log(error);
    }
  };

  const googleError = () => console.log('Google Sign In was unsuccessful. Try again later');

  const handleChange = (e) => setForm({ ...form, [e.target.name]: e.target.value });

  function routeTo(route){
    dispatch(resetRoutes())
    navigate(route);
  }

  return (
    <div className='login-body'>
      
      <h1 className={'header'}>Sign In</h1>
     
      <div className={'form-container'} >
          <p>Username or Email</p>
          {isLoading?
            <div className='form-container loading-wheel'>
              <TailSpin 
                height="80"
                width="80"
                color="#838383"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
            :
            <>
              <input className={'form-input'} name="login" label="User" placeholder="User" onChange={handleChange} type="email" />
              <div className={'input-row'}>
                <input className={'form-input'} name="password" label="Password" placeholder="Password" onChange={handleChange} type={showPassword ? 'text' : 'password'} />
                <div className={'show-password'} onClick={()=>{handleShowPassword()}}>
                  <img src={eyeGrey} className='icon-dropdown-selection'/>
                </div>
              </div>
            </>
          }
          
          <p className='error-message'>{errorMessage}</p>
          <div className={'submit'} onClick={handleSubmit}>
            Login
          </div>
      
          <h5 className={'small-title'}>
            Dont have an account? &nbsp; 
            <span className='sign-in-mini' onClick={()=>{routeTo('/signup')}}>
              Sign up
            </span>
          </h5> 
          <h5>
            Or
          </h5>
          <div className={'google-login'}>
            <GoogleLogin
              onSuccess={googleSuccess}
              onError={googleError}
            />
          </div>
      </div>
      
    </div>
    );

}

export default LoginPage;
