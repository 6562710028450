import React from 'react';
import '../../../Assets/css/tag.css'; // Your CSS file
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { pushRoute } from '../../../State/redux/actions/StateActions';

const Tag = ({ tag, color }) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {stack} = useSelector(state=>state.state_reducer)

    const routeTo = (route) => {
        if(route != stack[stack.length-1]){
          dispatch(pushRoute(route));
          navigate(route)
        }
    }

  return (
    <div className='tag-container' onClick={()=>{routeTo(`/users?searchTags=${tag}`)}}>
        <div className="tag-body" style={color && {backgroundColor: color}}>
            <div className='column-cutouts-left'>
                <div className='circle-top'/>
                <div className='tooth' />
                <div className='tooth' />
                <div className='tooth' />
                <div className='tooth' />
                <div className='tooth' />
                <div className='tooth' />
                <div className='tooth' />
                <div className='circle-bottom'/>
            </div>
            <div className="tag-inner-rect">
                <div className="tag-inner-rect-left">
                    {tag}
                </div>
                <div className="tag-inner-rect-right">
                    TAGS
                </div>
            </div>
            <div className='column-cutouts-right'>
                <div className='circle-top'/>
                <div className='tooth' />
                <div className='tooth' />
                <div className='tooth' />
                <div className='tooth' />
                <div className='tooth' />
                <div className='tooth' />
                <div className='tooth' />
                <div className='circle-bottom'/>
            </div>
        </div>
    </div>
  );
};

export default Tag;
