import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isTimestampMoreThan30SecondsAgo } from '../../../../Utilities/UtilityFunctions';
import "../../../../Assets/css/verify-sms.css"
import { sendSMS } from '../../../../State/redux/actions/UserActions';

export const ResendCode = ({ startTime }) => {
  const [timeLeft, setTimeLeft] = useState(null);
  const {phone} = useSelector(state=>state.auth_reducer)
  const dispatch = useDispatch();

  useEffect(() => {
    // Calculate the difference in seconds between now and the start time
    const updateCountdown = () => {
      const now = new Date();
      const differenceInSeconds = Math.max(30 - ((now - new Date(startTime)) / 1000), 0);
      setTimeLeft(differenceInSeconds);
    };

    // Immediately update the countdown to prevent initial delay
    updateCountdown();

    // Update the countdown every second
    const intervalId = setInterval(updateCountdown, 1000);

    // Clear the interval on component unmount
    return () => clearInterval(intervalId);
  }, [startTime]);

  return (

    <div className="resend-section">
        {!isTimestampMoreThan30SecondsAgo(startTime) || phone!==0 ?
          <p>
          {timeLeft ? `You can resend the code in ${Math.floor(timeLeft)} seconds` : 'You can now resend the code'}
          </p>
        :
          <p>
              Add a number to send the verification code
          </p>}
        <div 
            className={!isTimestampMoreThan30SecondsAgo(startTime) || phone===0 ? "verify-button-resend verify-disabled":'verify-button-resend'} 
            disabled={!isTimestampMoreThan30SecondsAgo(startTime) || phone===0} 
            onClick={()=>{dispatch(sendSMS())}}>
                Resend
        </div>
    </div>

   
  );
};
